import { axiosV3 } from '../../axios'
import { checkValidateErrors, getUserId } from '../../utils/helpers'
import Notification from '../../components/notification/Notification'
import i18n from 'i18next'
import { UserData, Wallet } from './initialState'
import FormDataV3 from "../../utils/formDataApiV3"


type Response = {
  data: UserData
  included: Array<{ [key: string]: any }>
  meta: { [key: string]: any }
}

export async function downloadUserData() {

  const getVerified = (response: Response): Boolean => !!response.data.stores?.length

  try {
    const id = getUserId()
    if (!id) return

    const aggregates = [
      'total_referral_transactions_amount',
      'total_referrals',
      'total_referrals_with_task',
      'is_devastated'
    ].join(',')

    const response: Response = await axiosV3.get(`users/${id}?include=stores,wallets&aggregates=${aggregates}`)

    return {
      ...response.data,
      ...response.meta,
      verified: getVerified(response),
      holdBalanceRub: await getHoldBalanceRub(id)
    }
  } catch (e) {
    if (e.logout || e.offline) return
    Notification({ noticeType: 'warn', text: i18n.t('User data error') })
    console.info('Error in the method "downloadUserData": ', e)
    return false
  }
}

export async function uploadUserData(userData, id) {
  const data = new FormDataV3()
  data.addParam('id', id)
  data.addParam('type', 'users')
  for (const [field, value] of Object.entries(userData)) {
    if (field === 'image' && !value) continue
    data.addAttr(field, value as string || '')
    if (field === 'password') data.addAttr('password_confirmation', value as string || '')
  }
  const aggregates = [
    'total_referral_transactions_amount',
    'total_referrals',
    'total_referrals_with_task',
    'is_devastated'
  ].join(',')
  try {
    const response = await axiosV3.patch(`users/${id}?include=stores,wallets&aggregates=${aggregates}`, data)
    response.data.holdBalanceRub = await getHoldBalanceRub(id)

    Notification({ noticeType: 'success', text: i18n.t('Profile update msg') })
    return response.data
  } catch (e) {
    if (e.logout || e.offline) return
    if (e.response && e.response.status === 422) {
      checkValidateErrors(e)
    } else {
      Notification({ noticeType: 'warn', text: i18n.t('User data save error') })
    }
  }
}

async function getHoldBalanceRub(userId) {
  try {
    const response = await axiosV3.get(`wallets?filter[user][id]=${userId}&aggregates=total_hold_amount`)
    const walletRub = response.data.find(item => item.name === 'RUB') as Wallet
    if (!walletRub) return 0
    return Number(walletRub.total_hold_amount)
  } catch (e) {
    if (e.logout || e.offline) return
    console.info('Error in the method "getHoldBalanceRub": ', e)
  }
}
