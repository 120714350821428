import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Span, { TextSize, TextWeight } from './common_kit/text/Span'
import { useDispatch } from 'react-redux'
import { resetPage } from '../store/taskList/actions'

import '../style/sass/NavMenu.scss'
import { MENU_ITEMS } from '../utils/constants'
import { useTranslation } from 'react-i18next'


export default function NavMenu() {

  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()

  const [currentPath, setCurrentPath] = useState(location.pathname)

  useEffect(() => {
    if (currentPath !== location.pathname) {
      setCurrentPath(location.pathname)
    }
  }, [currentPath, location.pathname])

  const handlerTabClick = (path: string) => {
    if (currentPath !== location.pathname) {
      dispatch(resetPage())
      setCurrentPath(path)
    }
  }

  return <div className="nav-menu">
    {MENU_ITEMS.map(tab => <div
      key={tab.name}
      className={`nav-menu__tab${tab.path === currentPath ? ' active' : ''}`}
      onClick={() => handlerTabClick(tab.path)}>
      <Link
        key={tab.name}
        to={tab.path}>
        <Span
          text={t(tab.title)}
          size={TextSize.P14}
          fontWeight={TextWeight.BOLD}
        />
      </Link>
    </div>)}
  </div>

}
