import React from 'react'
import Paragraph from '../../common_kit/text/Paragraph'
import IconWebMoney from '../../icons/IconWebMoney'


type Data = {
  countDetails: string
}

export default function WebMoney(props: Data) {
  return (
    <div className={'payment-count'}>
      <IconWebMoney viewBox={'20 0 190 44'} fillBack={'none'} fill={'#2A3E58'}/>
      <Paragraph text={props.countDetails}/>
    </div>
  )
}

