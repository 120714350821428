import React, { useEffect } from 'react'
import '../style/sass/LoginPage.scss'
import { startIntercomChat } from '../utils/helpers'
import { useDispatch } from 'react-redux'
import { setSidebarAvailability } from '../store/sidebar/actions'
import LoginFormIllustration from './illustrations/LoginFormIllustration'
import H3 from './common_kit/text/H3'
import Paragraph, { TextSize } from './common_kit/text/Paragraph'
import { Link } from 'react-router-dom'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import LoginForm from './loginPage/LoginForm'


export default function LoginPage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setSidebarAvailability(false))
    startIntercomChat()
  }, [])

  return (
    <div className="login-page">
      <LoginFormIllustration/>
      <H3 className="login-page-title" text={t('Log in')}/>
      <div className="invitation-register">
        <Paragraph
          text={t('Dont have account')}
          size={TextSize.P_18}
        />
        <Link to={`/${i18n.language}/sign-up`}>
          {t('Lets sign up')}
        </Link>
      </div>
      <LoginForm/>
    </div>
  )
}
