import React, { useCallback } from 'react'
import '../../style/sass/userDataBlock/UserDataBlock.scss'
import H5 from '../common_kit/text/H5'
import Paragraph from '../common_kit/text/Paragraph'
import { getAmountWithSign, getFullFileUrl } from '../../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { hideSidebar, showSidebar } from '../../store/sidebar/actions'
import { RootState } from '../../store/rootState'
import MqAfterSmall from '../mq/MqAfterSmall'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


type Data = {
  isAuthenticated: boolean
  firstName: string,
  lastName?: string,
  cash: string,
  holden_cash?: string,
  phone?: string,
  image: string,
  accountStatus: AccountStatus
}

export enum AccountStatus {
  NOT_VERIFIED = 'Not verified',
  VERIFIED = 'Verified',
  BLOCKED = 'Blocked'
}

export default function UserDataBlock(props: Data) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()

  const sidebarShow = useSelector((state: RootState) => state.sidebar.show)
  const sidebarAvailable = useSelector((state: RootState) => state.sidebar.available)

  const lastName = props.lastName ? ' ' + props.lastName[0].toUpperCase() + '.' : ''

  const handleClickAvatar = () => {
    if (!props.isAuthenticated || !sidebarAvailable) return
    if (sidebarShow) {
      dispatch(hideSidebar())
    } else {
      dispatch(showSidebar())
    }
  }

  const handleClickBalance = useCallback(() => {
    const withdrawalPath = '/withdrawal'
    if (location.pathname === withdrawalPath) return
    history.push(withdrawalPath)
  }, [location])

  const classList = [
    'user-data',
    props.accountStatus?.toLowerCase().replace(/\s+/g, '-') ?? ''
  ].join(' ')

  return (
    <div className={classList}>
      <MqAfterSmall>
        <div className="user-data__info">
          <div className='account-status'>{ t(props.accountStatus) }</div>
          <H5 text={props.isAuthenticated ? `${props.firstName}${lastName}` : 'User'}/>
          <div className="user-data__info-balance" onClick={handleClickBalance}>
            <img alt="" className="user-data__info-icon" src={'/cash_blue.svg'}/>
            <Paragraph
              color="#2A3E58"
              text={getAmountWithSign(Number(props.cash))}
            />
            <Paragraph
              className="user-data__info-holden-cash"
              text={`/ ${getAmountWithSign(props.isAuthenticated ? Number(props.holden_cash) : 0)}`}
            />
          </div>
        </div>
      </MqAfterSmall>
      <div className="user-data__avatar" onClick={handleClickAvatar}>
        <img
          src={(props.image && props.isAuthenticated) ? getFullFileUrl(props.image) : '/avatar_dummy.svg'}
          alt=""/>
      </div>
    </div>
  )
}
