import { SET_TASK_COMPLETE_MODAL, VERIFICATION_MODAL_SHOW } from './actionTypes'

export const setVerificationModal = data => ({
  type: VERIFICATION_MODAL_SHOW,
  data
})

export const setTaskCompleteModal = data => ({
  type: SET_TASK_COMPLETE_MODAL,
  data
})
