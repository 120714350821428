import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import RadioButtonGroup from '../../inputs/RadioButtonGroup'
import BankCardUsdForm, { BankCardUsdFormModel } from './BankCardUsdForm'
import { PaymentType } from '../../../store/payments/initialState'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/rootState'
import { CSSTransition } from 'react-transition-group'
import { Controller, useForm } from 'react-hook-form'
import IconClear from '../../icons/IconClear'
import { InfoCircleFilled } from '@ant-design/icons'
import InputMask from 'react-input-mask'
import { BANK_CARD_REGEX, PHONE_RUS_REGEX } from '../../../utils/constants'


interface Data {
  paymentType: PaymentType,
  handlerDataChange: (valid: boolean, model: purseDetails) => void
}

export type purseDetails = {
  purseData: string,
  bankCountry?: string,
  bankCardUsdData?: BankCardUsdFormModel
}

export default function WithdrawalAccountForm(props: Data) {

  const { t } = useTranslation()

  const locale = useSelector((state: RootState) => state.locale.locale.name)
  const countries = useSelector((state: RootState) => state.types.countries)

  const {
    control,
    register,
    watch,
    setError,
    formState: {
      errors,
      isValid
    },
    reset,
    setValue,
    setFocus
  } = useForm<{ purseData: string }>({
    mode: 'onChange',
    defaultValues: {
      purseData: ''
    }
  })

  const purseData = watch('purseData')

  const [title, setTitle] = useState<string>()
  const [bankCardSelected, setBankCardSelected] = useState<boolean>()
  const [usdtTrc20Selected, setUsdtTrc20Selected] = useState<boolean>()
  const [bankCountry, setBankCountry] = useState<string>(locale === 'ru' ? 'ru' : '')
  const [bankCardUsdFormData, setBankCardUsdFormData] = useState<BankCardUsdFormModel | undefined>(undefined)
  const [bankCardDataIsValid, setBankCardDataIsValid] = useState<boolean>(false)
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true)

  useEffect(() => {
    if (locale === 'ru') {
      setBankCountry('ua')
    }
  }, [locale])

  useEffect(() => {
    if (!isFirstTime || !isValid) return
    setIsFirstTime(false)
  }, [isValid])

  useEffect(() => {
    const isAll = [PaymentType.USDTTRC20, PaymentType.PAYTM].includes(props.paymentType)
    setBankCardSelected(props.paymentType === PaymentType.BANK)
    setUsdtTrc20Selected(props.paymentType === PaymentType.USDTTRC20)
    setBankCountry(isAll ? 'all' : 'ua')
    setIsFirstTime(true)
  }, [props.paymentType])

  useEffect(() => {
    setTitle({
      [PaymentType.MOBILE]: 'Номер телефона (только для абонентов РФ)',
      [PaymentType.BANK]: t('Number of Bank card'),
      [PaymentType.QIWI_MONEY]: 'Введите номер телефона с QIWI кошельком',
      [PaymentType.USDTTRC20]: 'USDT TRC-20 wallet (Network: Tron)',
      [PaymentType.PAYTM]: 'Paytm wallet'
    }[props.paymentType])
    reset()
    setBankCardSelected(props.paymentType === PaymentType.BANK)
  }, [props.paymentType, locale])

  useEffect(() => {
    if (locale === 'en' && bankCardSelected) {
      props.handlerDataChange(isValid && bankCardDataIsValid, {
        purseData: purseData.replaceAll(/[()\s]+/g, ''),
        bankCardUsdData: bankCardUsdFormData
      })
    } else {
      props.handlerDataChange(isValid, {
        purseData: purseData.replaceAll(/[()\s]+/g, ''),
        bankCountry
      })
    }
  }, [
    isValid,
    bankCardDataIsValid,
    bankCardSelected,
    purseData,
    bankCountry,
    bankCardUsdFormData
  ])

  const handleInputBlur = () => {
    if (!isFirstTime) return
    setIsFirstTime(false)
  }

  const getPurseInput = () => {

    const transformUSDTWallet = value => {
      if (value.match(/[^a-zA-Z\d]/)) return value.replaceAll(/[^a-zA-Z\d]/g, '')
      return value
    }

    const transformDigitalWallet = value => {
      if (value.match(/\D/)) return value.replaceAll(/\D/g, '')
      return value
    }

    const validateFirstChar = value => {
      return !!value.match(/^T/g)
    }

    const getSimpleInput = rules => (
      <input
        id="purse-input"
        className={!isFirstTime && errors.purseData ? 'invalid' : ''}
        type="text"
        {...register('purseData', {
          required: true,
          onBlur: handleInputBlur,
          ...rules
        })}
      />
    )

    const getMaskedInput = (mask, placeholder, rules) => (
      <Controller
        name="purseData"
        control={control}
        rules={{
          required: true,
          onBlur: handleInputBlur,
          ...rules
        }}
        render={({ field }) => (
          <InputMask
            {...field}
            id="purse-input"
            className={!isFirstTime && errors.purseData ? 'invalid' : ''}
            mask={mask}
            placeholder={placeholder}
            maskChar={null}>
            {props => (
              <input
                {...props}
                {...field}
              />
            )}
          </InputMask>
        )}
      />
    )

    const getters = {
      [PaymentType.PAYTM]: () => ({
        input: getSimpleInput({
          setValueAs: transformDigitalWallet,
          onChange: event => {
            event.preventDefault()
            event.target.value = transformDigitalWallet(event.target.value)
          }
        }),
        errors: {
          required: 'Wallet number is required'
        }
      }),
      [PaymentType.USDTTRC20]: () => ({
        input: getSimpleInput({
          validate: {
            firstChar: validateFirstChar
          },
          minLength: 30,
          setValueAs: transformUSDTWallet,
          onChange: event => {
            event.preventDefault()
            event.target.value = transformUSDTWallet(event.target.value)
          }
        }),
        errors: {
          required: 'Wallet number is required',
          firstChar: 'Wallet number first char',
          minLength: 'Wallet number min length'
        }
      }),
      [PaymentType.QIWI_MONEY]: () => ({
        input: getSimpleInput({
          setValueAs: transformDigitalWallet,
          onChange: event => {
            event.preventDefault()
            event.target.value = transformDigitalWallet(event.target.value)
          }
        }),
        errors: {
          required: 'Phone is required'
        }
      }),
      [PaymentType.BANK]: () => ({
        input: getMaskedInput('9999 9999 9999 9999', '**** **** **** ****', {
          pattern: BANK_CARD_REGEX
        }),
        errors: {
          required: 'Empty card number',
          pattern: 'Card number length'
        }
      }),
      [PaymentType.MOBILE]: () => ({
        input: getMaskedInput('+7 (999) 999 99 99', '+7 (***) *** ** **', {
          pattern: PHONE_RUS_REGEX
        }),
        errors: {
          required: 'Phone is required',
          pattern: 'Validator error phone'
        }
      })
    }

    const options = getters[props.paymentType]()

    return (
      <div className="labeled-input gray-styled">
        <label htmlFor="purse-input">{title}</label>
        {options.input}
        {purseData && (
          <div className="form-control__icon">
            <IconClear
              width={10}
              height={10}
              fill={'#FF4848'}
              onClick={() => {
                setValue('purseData', '')
                setError('purseData', { type: 'required' })
                setFocus('purseData')
              }}
            />
          </div>
        )}
        {!isFirstTime && Object.entries(options.errors).map(item => {
          const type: string = item[0]
          const key: string = item[1] as string
          if (errors.purseData?.type !== type) return
          return <div key={key} className="error-message">{t(key)}</div>
        })}
      </div>
    )
  }

  const getBankCountryOptions = () => {
    const rus = countries.find(item => item.name === 'ru')
    const ukr = countries.find(item => item.name === 'ua')
    const kaz = countries.find(item => item.name === 'kz')
    const items = [ukr, kaz].map(item => ({
      value: item!.name,
      label: item!.meta[locale].title
    }))

    return (
      <div className="bank-card-options">
        <RadioButtonGroup
          label={t('Bank country label')}
          items={items}
          value={bankCountry}
          handler={setBankCountry}
        />
        <div className="bank-card-hint">
          <div className="bank-card-hint__title">
            Другая страна?
          </div>
          <div className="tooltip-container__tooltip-text bank-card-hint__text">
            Для вывода средств на карты других стран переключите язык интерфейса на EN
          </div>
        </div>
      </div>
    )
  }

  const handleBankCardUsdFormChange = (valid: boolean, model: BankCardUsdFormModel) => {
    setBankCardDataIsValid(valid)
    setBankCardUsdFormData(model)
  }

  return (
    <div className={`account-form ${props.paymentType}-type ${locale === 'en' ? 'eng-lang' : ''}`}>
      <div className="account-form__input-section">
        {getPurseInput()}
        <CSSTransition
          in={usdtTrc20Selected}
          timeout={300}
          classNames="fade"
          mountOnEnter
          unmountOnExit>
          <div className="account-form__input-hint">
            <div className="input-hint-icon">
              <InfoCircleFilled/>
            </div>
            <div className="input-hint-text">
              <span>{t('usdtTrc20 hint part 1')}</span>
              <a href="https://www.binance.com/" target="_blank" rel="noopener noreferrer">Binance</a>
              <span>{t('usdtTrc20 hint part 2')}</span>
              <a href="https://www.coinbase.com/" target="_blank" rel="noopener noreferrer">Coinbase</a>
              <span>{t('usdtTrc20 hint part 3')}</span>
            </div>
          </div>
        </CSSTransition>
        <CSSTransition
          in={bankCardSelected && locale === 'ru'}
          timeout={300}
          classNames="fade"
          mountOnEnter
          unmountOnExit>
          {getBankCountryOptions()}
        </CSSTransition>
      </div>
      {bankCardSelected && locale === 'en' && (
        <BankCardUsdForm
          handlerChange={handleBankCardUsdFormChange}
        />
      )}
    </div>
  )
}
