export default function ErrorHandler(data: any, field: string = 'message'): string | undefined {
  try {
    const result = data.response.data
    const errorData = result.error
    let errorMessage: string = 'При обработке данных произошла ошибка'
    try {
      errorMessage = JSON.parse(errorData)[field] || JSON.parse(errorData)['message']
    } catch (e) {
      if (field === 'phone') {
        errorMessage = errorData[field][0]
      } else if (typeof errorData === 'string' && errorData) {
        errorMessage = errorData
      } else {
        console.error(e)
      }
    }
    return errorMessage
  } catch (e) {
    console.error('Произошла ошибка', e)
  }
}
