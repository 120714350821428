export interface Wallet {
  name: string
  balance: string
  total_hold_amount: string
}

export interface UserData {
  id: string
  country?: string
  email?: string
  first_name: string
  last_name?: string
  image?: string
  is_task_block: boolean
  phone: string
  verified?: boolean
  stores: Array<unknown>
  wallets: Array<Wallet>
  holdBalanceRub: number
  referral_code: string
  referral_percentage: number
  uuid: string
  social_account: string
  is_devastated: boolean
  total_referral_transactions_amount: number
  total_referrals: number
  total_referrals_with_task: number
  allow_google_account_verification: boolean,
  whatsapp: string | null
}

export interface UserState {
  id?: number,
  userData?: UserData
}

const initialStore: UserState = {
  id: undefined,
  userData: undefined
}

export default initialStore
