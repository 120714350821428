import React, { useCallback, useEffect, ChangeEvent } from 'react'
import Paragraph, { TextSize, TextStyle, Weight } from '../common_kit/text/Paragraph'
import SubmitButton from '../inputs/SubmitButton'
import { checkFileFormat, checkFileSize, FileUploadStatus, getFullFileUrl, getUrlFromFile } from '../../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'
import { useTranslation } from 'react-i18next'
import { setAvatarImage, setImgPath, setImgStatus } from '../../store/userProfile/actions'
import { Spin } from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'


type adaptiveCssModel = {
  title_size: TextSize,
  description_size: TextSize
}

type photoCssModel = {
  s: adaptiveCssModel,
  m: adaptiveCssModel,
  l: adaptiveCssModel
}

type Data = {
  onChange: (changed: boolean, valid: boolean) => void
}

export default function Photo(props: Data) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const initialImage = useSelector((state: RootState) => state.user.userData?.image)
  const size = useSelector((state: RootState) => state.mq.size)
  const updatedImage = useSelector((state: RootState) => state.userProfile.image)
  const status = useSelector((state: RootState) => state.userProfile.status)

  let photoCss: photoCssModel = {
    s: {
      title_size: TextSize.P_14,
      description_size: TextSize.P_10
    },
    m: {
      title_size: TextSize.P_14,
      description_size: TextSize.P_11
    },
    l: {
      title_size: TextSize.P_16,
      description_size: TextSize.P_12
    }
  }

  const currentImage = getFullFileUrl(updatedImage || initialImage)

  useEffect(() => {
    const changed = !!updatedImage && status !== FileUploadStatus.PROGRESS
    const valid = !updatedImage || status === FileUploadStatus.SUCCESS
    props.onChange(changed, valid)
  }, [status, updatedImage])

  const handleChangeFile = async (event:ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const target = event.target as HTMLInputElement
    const file = target?.files?.item(0)
    if (!file) return

    const sizeIsValid = checkFileSize(file, 12)
    const formatIsValid = checkFileFormat(file, ['jpg', 'jpeg', 'png'])
    const valid = sizeIsValid && formatIsValid

    dispatch(setImgStatus(valid ? FileUploadStatus.PROGRESS : FileUploadStatus.FAIL))
    if (valid) {
      const imgUrl = await getUrlFromFile(file)
      dispatch(setAvatarImage(imgUrl))
      await dispatch(setImgPath(file))
    }
  }

  const getLoadingIcon = () => {
    if (!status) return null
    const icon = {
      [FileUploadStatus.PROGRESS]: (<Spin size="small" spinning/>),
      [FileUploadStatus.SUCCESS]: (<CheckCircleTwoTone twoToneColor="#25ec2a"/>),
      [FileUploadStatus.FAIL]: (<CloseCircleTwoTone twoToneColor="#ff1f1f"/>)
    }[status]
    return (
      <div className="loading-status">
        {icon}
      </div>
    )
  }

  return (
    <div className="user-profile__avatar">
      <div className="user-profile__form-avatar-img-box">
        <img
          className={currentImage ? 'user-profile__form-avatar-img' : ''}
          src={currentImage || '/viewfinder.svg'}
          alt=""
        />
        {getLoadingIcon()}
      </div>
      <div className="user-profile__form-avatar-toolbar">
        <Paragraph
          className="user-profile__form-avatar-title"
          text={t('Avatar')}
          size={photoCss[size].title_size}
          fontWeight={Weight.BOLD}
          color={'#2A3E58'}
        />
        <Paragraph
          className="user-profile__form-avatar-description"
          size={photoCss[size].description_size}
          text={t('Avatar format')}
          textStyle={TextStyle.ITALIC}
        />
        <label className="user-profile__form-avatar-btn">
          {t('Upload photo')}
          <input className="user-profile__form-avatar-btn-upload" type="file" onChange={handleChangeFile}/>
        </label>
      </div>
    </div>
  )
}
