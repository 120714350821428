import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'
import '../../style/sass/AsideMenu.scss'
import { hideMenu } from '../../store/sidebar/actions'
import { MENU_ITEMS } from '../../utils/constants'
import { Link, useLocation } from 'react-router-dom'
import Span, { TextSize, TextWeight } from '../common_kit/text/Span'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import _ from 'lodash'


export default function AsideMenu() {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const location = useLocation()

  const show = useSelector((state: RootState) => state.sidebar.shownMenu)
  const size = useSelector((state: RootState) => state.mq.size)
  const userData = useSelector((state: RootState) => state.user.userData, _.isEqual)

  const [showClass, setShowClass] = useState<string>('')
  const [currentPath, setCurrentPath] = useState<string>('')

  useEffect(() => {
    if (currentPath !== location.pathname) {
      setCurrentPath(location.pathname)
    }
  })

  useEffect(() => {
    setShowClass(show ? 'show' : '')
  }, [show])

  const handlerItemClick = (path: string) => {
    setCurrentPath(path)
    if (size === 's') {
      dispatch(hideMenu())
    }
  }

  if (!userData) return null

  return (
    <div className={`aside-area ${showClass}`}>
      <div className="aside-menu">
        {MENU_ITEMS.map(item => <div
          key={item.name}
          className={`aside-menu__item ${item.path === currentPath ? 'active' : ''}`}
          onClick={() => handlerItemClick(item.path)}>
          <Link
            key={item.name}
            to={item.path}>
            <Span
              text={t(item.title)}
              size={TextSize.P14}
              fontWeight={TextWeight.BOLD}
            />
          </Link>
        </div>)}
      </div>
      <div className="aside-area__buttons">
        <div className="aside-area__close-btn" onClick={() => dispatch(hideMenu())}>
          <CloseOutlined/>
        </div>
      </div>
    </div>
  )
}
