import React, { useEffect, useState } from 'react'
import SubmitButton from '../inputs/SubmitButton'
import { useTranslation } from 'react-i18next'
import Paragraph, { TextSize, TextStyle } from '../common_kit/text/Paragraph'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { uploadDataForLogin } from '../../store/auth/api'
import IconPassword from '../icons/IconPassword'
import IconClear from '../icons/IconClear'
import { getFPVisitorId } from '../../utils/helpers'
import { FAILED_FINGERPRINT_ID, FINGERPRINT_EXTRA_TIME } from '../../utils/constants'


interface LoginFormModel {
  phone: string,
  password: string,
}

export default function LoginForm() {
  const { t } = useTranslation()
  const history = useHistory()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setFocus,
    setError,
    resetField,
    formState: { errors },
    getValues
  } = useForm<LoginFormModel>({
    mode: 'onTouched'
  })

  const [hiddenPassword, setHiddenPassword] = useState<boolean>(true)
  const [visitorId, setVisitorId] = useState<string>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [waitingForFP, setWaitingForFP] = useState<boolean>(false)
  const [timer, setTimer] = useState<NodeJS.Timeout>()

  const phone = watch('phone')
  const password = watch('password')

  useEffect(() => {
    getFPVisitorId()
      .then(id => { setVisitorId(id) })
  }, [])

  useEffect(() => {
    if (!waitingForFP) return
    timer && clearTimeout(timer)
    login(visitorId)
  }, [visitorId])

  const login = async fingerprintId => {
    const { phone, password } = getValues()
    setIsSubmitting(true)
    const success = await uploadDataForLogin(phone, password, fingerprintId)
    if (!success) {
      setIsSubmitting(false)
    }
  }

  const handleClickSubmitBtn = () => {
    if (!visitorId) {
      setWaitingForFP(true)
      const timer: NodeJS.Timeout = setTimeout(async () => {
        setWaitingForFP(false)
        login(FAILED_FINGERPRINT_ID)
      }, FINGERPRINT_EXTRA_TIME)
      setTimer(timer)
      return
    }
    login(visitorId)
  }

  const transformPhone = value => {
    if (value === '+') return ''
    if (value.match(/^\d+$/)) return `+${value}`
    if (value.match(/^\+\d+$/)) return value
    if (value.length === 1) {
      resetField('phone', { keepError: true })
    }
    return value.replaceAll(/\D+/g, '')
  }

  return (
    <div className="login-form">

      {/*Телефон*/}
      <div className="form-block">
        <label htmlFor="login-phone-input">{t('Phone number')}</label>
        <input
          type="text"
          id="login-phone-input"
          className={errors.phone ? 'invalid' : ''}
          autoComplete="off"
          {...register('phone', {
            required: true,
            setValueAs: transformPhone,
            onChange: event => {
              event.preventDefault()
              event.target.value = transformPhone(event.target.value)
            }
          })}
        />
        {phone && (
          <div className="form-control__icon">
            <IconClear
              width={10}
              height={10}
              fill={'#FF4848'}
              onClick={() => {
                setValue('phone', '')
                setError('phone', { type: 'required' })
                setFocus('phone')
              }}
            />
          </div>
        )}
        {errors.phone && (
          <div className="error-message">{t('This field is required')}</div>
        )}
      </div>

      {/*Пароль*/}
      <div className="form-block">
        <label htmlFor="login-password-input">{t('Password')}</label>
        <input
          type={hiddenPassword ? 'password' : 'text'}
          id="login-password-input"
          className={errors.password ? 'invalid' : ''}
          {...register('password', { required: true })}
        />
        {password && (
          <IconPassword
            closed={hiddenPassword}
            onClick={() => {
              setHiddenPassword(!hiddenPassword)
            }}
          />
        )}
        {errors.password && (
          <div className="error-message">{t('This field is required')}</div>
        )}
      </div>

      <SubmitButton
        text={isSubmitting || waitingForFP ? t('Checking') : t('Log in')}
        isDisabled={isSubmitting || waitingForFP}
        onClick={handleSubmit(handleClickSubmitBtn)}
      />

      <div
        className="forgot-password"
        onClick={() => {
          window.Intercom('show')
        }}>
        <Paragraph
          text={t('Forgot password')}
          size={TextSize.P_12}
          textStyle={TextStyle.ITALIC}
        />
      </div>

    </div>
  )
}
