import React from 'react'
import Paragraph from '../../common_kit/text/Paragraph'
import IconBankCard from '../../icons/IconBankCard'


type Data = {
  countDetails: string
}

export default function BankCard(props: Data) {

  const hiddenDetails = String(props.countDetails).slice(-4)

  return <div className="payment-count">
    <IconBankCard fillBack={'none'} fill={'#2A3E58'}/>
    <Paragraph text={`**** **** **** ${hiddenDetails}`}/>
  </div>
}
