import initialState from './initialState'
import {
  CLEAR_TASKS,
  RESET_TASK_LIST_STATE,
  SET_MORE_COMPLETED_TASKS,
  SET_MORE_SIMPLE_TASKS,
  SET_RESPONSE_ID,
  SET_SELECTED_TASK_FOR_DETAILS,
  SET_PUBLIC_SELECTED_TASK_FOR_DETAILS,
  SET_TASKS,
  UPDATE_TASK_GMAIL,
  SET_CURRENT_SIMPLE_INDEX,
  RESET_SIMPLE_TASK_DATA,
  RESET_PAGE,
  UPDATE_TASK_LIST
} from './actionTypes'
import { setProgressToIDB } from "../../utils/helpers"

export default function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case SET_TASKS:
      return {
        ...state,
        publicationTasks: action.data.publicationTasks,
        simpleTasks: action.data.simpleTasks,
        completedTasks: action.data.completedTasks
      }
    case SET_MORE_SIMPLE_TASKS:
      const simpleTasks = {
        data: [...state.simpleTasks.data, ...action.tasks.data],
        meta: action.tasks.meta
      }
      return {
        ...state,
        simpleTasks
      }
    case SET_MORE_COMPLETED_TASKS:
      const completedTasks = {
        data: [...state.completedTasks.data, ...action.tasks.data],
        meta: action.tasks.meta
      }
      return {
        ...state,
        completedTasks
      }
    case SET_SELECTED_TASK_FOR_DETAILS:
      return {
        ...state,
        selectedSimpleTask: action.task
      }
    case SET_PUBLIC_SELECTED_TASK_FOR_DETAILS:
      return {
        ...state,
        selectedTask: action.task
      }
    case SET_RESPONSE_ID:
      return {
        ...state,
        responseId: {
          ...state.responseId,
          [action.name]: action.id
        }
      }
    case UPDATE_TASK_GMAIL:
      return {
        ...state,
        simpleTaskGmail: {
          ...state.simpleTaskGmail,
          ...action.data
        }
      }
    case SET_CURRENT_SIMPLE_INDEX:
      const needUpdateProgress = action.needUpdateProgress || (!state.simpleTaskGmail.progress || state.simpleTaskGmail.progress < action.index)
      const progressOption = needUpdateProgress ? { progress: action.index } : {}
      if (needUpdateProgress) setProgressToIDB(action.taskId, action.userId, action.index, action.locale)
      return {
        ...state,
        simpleTaskGmail: {
          ...state.simpleTaskGmail,
          prevIndex: action.prevIndex,
          currentIndex: action.index,
          currentStep: state.simpleTaskGmail?.steps ? state.simpleTaskGmail?.steps[action.index] : undefined,
          ...progressOption
        }
      }
    case RESET_SIMPLE_TASK_DATA:
      return {
        ...state,
        selectedSimpleTask: undefined,
        simpleTaskGmail: {
          steps: undefined,
          currentIndex: undefined,
          currentStep: undefined,
          progress: undefined
        }
      }
    case CLEAR_TASKS:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          data: [],
          meta: {}
        }
      }
    case RESET_TASK_LIST_STATE:
      return {
        publicationTasks: {
          data: undefined,
          meta: {}
        },
        simpleTasks: {
          data: [],
          meta: {}
        },
        completedTasks: {
          data: [],
          meta: {}
        },
        responseId: {
          publicationTasks: 0,
          simpleTasks: 0,
          completedTasks: 0
        },
        simpleTaskGmail: {
          main_task_type: '',
          steps: undefined,
          currentStep: undefined,
          prevIndex: undefined,
          currentIndex: undefined,
          progress: undefined,
          phone: undefined,
          countryCode: undefined,
          codeVerify: undefined,
          code2Auth: undefined,
          textFile: undefined,
          fileName: undefined,
          fileStatus: undefined,
          changePhone: undefined,
          changePhoneProcess: undefined
        }
      }
    case RESET_PAGE:
      return {
        ...state,
        simpleTasks: {
          ...state.simpleTasks,
          meta: {}
        },
        publicationTasks: {
          ...state.publicationTasks,
          meta: {}
        },
        completedTasks: {
          ...state.completedTasks,
          meta: {}
        },
      }
    case UPDATE_TASK_LIST:
      return {
        ...state,
        simpleTasks: {
          data: [...action.tasks],
          meta: {...state.simpleTasks.meta}
        },
      }
    default:
      return state
  }
}
