import React from 'react'
import NotAvailableTasksIllustration from '../illustrations/NotAvailableTasksIllustration'
import {useTranslation} from "react-i18next";


type Data = {
  message?: string
}

NotAvailableTaskCardWrap.defaultProps = {
  message: 'No available tasks'
}

export default function NotAvailableTaskCardWrap(props: Data) {

  const { t } = useTranslation()

  return (
    <div className="task-card-wrap not-available-tasks-card">
      <NotAvailableTasksIllustration/>
      <div className='send-tech-block'>
        <p>
          {t(props.message as string)}
        </p>
      </div>
    </div>)
}
