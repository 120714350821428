import React, {Component} from "react";
import "../../../style/sass/common_kit/Text.scss"

type Data = {
    className?: string
    color?: string
    text: string
}

class H4 extends Component<Data> {

    render() {
        return <h4 className={`ui-kit ${this.props.className ?? ""}`} style={{color: this.props.color}}>{this.props.text}</h4>
    }

}

export default H4;
