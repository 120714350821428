import { axiosV3 } from '../../axios'
import store from '../index'
import { updatePaymentsHistory } from './actions'
import moment from 'moment'
import { updateUserData } from '../user/actions'
import Notification from '../../components/notification/Notification'
import i18n from 'i18next'
import { Payment } from './initialState'
import { RootState } from '../rootState'
import FormDataV3 from "../../utils/formDataApiV3";


type Response = {
  data: Array<Payment>
}

export async function downloadPaymentsHistory() {
  try {
    const state: RootState = store.getState()
    const userData = state.user.userData
    const id = userData?.id
    if (!id) return

    const currentPage = state.payments.meta.page?.current_page ?? 0
    const lastPage = state.payments.meta.page?.last_page ?? 1

    if (currentPage === lastPage) return

    const transactionResponse: Promise<Response> = axiosV3.get(`transactions?filter[user][id]=${id}&filter[type]=withdraw&filter[confirmed]=1&page[limit]=10&page[number]=${currentPage + 1}`)

    return await transactionResponse
  } catch (e) {
    if (e.logout || e.offline) return
    Notification({ noticeType: 'warn', text: i18n.t('Payments history error') })
    console.info('Error in the method "downloadPaymentsHistory": ', e)
  }
}

export async function requestPayout(type, amount, currency, purseDetails, isDevastated) {
  const { purseData, bankCountry, bankCardUsdData } = purseDetails
  const data = new FormDataV3()
  let sendType = type
  if (type === 'usdttrc20' && isDevastated) sendType = `${type}Special`
  data.addParam('type', 'payouts')
  data.addAttr('target', sendType)
  data.addAttr('amount', Number(amount))
  data.addAttr('purse', purseData)
  data.addAttr('currency', currency.toUpperCase())
  data.addAttr('country', bankCountry.toUpperCase())
  if (type === 'card' && !bankCardUsdData) {
    data.addMeta('holder_name', '-')
    data.addMeta('holder_surname', '-')
    data.addMeta('holder_birthday', '0001-01-01')
    data.addMeta('country_code', '-')
    data.addMeta('city', '-')
    data.addMeta('holder_address', '-')
    data.addMeta('card_expire_month', '00')
    data.addMeta('card_expire_year', '0000')
    data.addMeta('payment_system', 'mastercard')
  }
  if (type === 'card' && bankCardUsdData) {
    const day = bankCardUsdData.birthDateYear
    const month = bankCardUsdData.birthDateMonth
    const year = bankCardUsdData.birthDateYear
    const dateOfBirth = moment(`${day}-${month}-${year}`, 'DD-MM-YYYY').format('YYYY-MM-DD')
    data.addMeta('holder_name', bankCardUsdData.firstName)
    data.addMeta('holder_surname', bankCardUsdData.lastName)
    data.addMeta('holder_birthday', dateOfBirth)
    data.addMeta('country_code', bankCardUsdData.countryCode)
    data.addMeta('city', bankCardUsdData.city.trim())
    data.addMeta('holder_address', bankCardUsdData.street.trim())
    data.addMeta('card_expire_month', bankCardUsdData.cardExpiryMonth)
    data.addMeta('card_expire_year', bankCardUsdData.cardExpiryYear)
    data.addMeta('payment_system', 'mastercard')
  }

  try {
    await axiosV3.post('/payouts', data)
    Notification({ noticeType: 'success', text: i18n.t('Withdrawal was successful') })
    store.dispatch(updateUserData())
    store.dispatch(updatePaymentsHistory())
    return true
  } catch (e) {
    if (e.logout || e.offline) return
    Notification({ noticeType: 'warn', text: i18n.t('Request payout error') })
    console.info('Error in the method "requestPayout": ', e)
  }
}
