import React from 'react'
import { useTranslation } from 'react-i18next'
import FooterItem from './FooterItem'
import '../../style/sass/footer/Footer.scss'

export default function Footer() {
  const { i18n } = useTranslation()

  return (
    <div className="footer-wrapper">
      <div className="footer-container">
        <div className="footer-logo">
          <a href={`https://app-work.org/${i18n.language === 'en' ? 'en' : ''}`}>
            <img src="/logo/appwork_logo_white.png" alt=""/>
          </a>
        </div>
        <div className="footer-main">
          <FooterItem header={'AppWork'} links={['User reviews']}
                      href={`https://app-work.org/${i18n.language === 'en' ? 'en/' : ''}reviews`}/>
          <FooterItem header={'Documentation'} links={['Policy']}
                      href={`https://app-work.org/${i18n.language === 'en' ? 'en/' : ''}policy`}/>
          {/* Добавить для ru версии 'О проекте'*/}
          <FooterItem header={'User information'} links={i18n.language === 'ru' ? ['FAQ'] : ['FAQ']}
                      href={`https://app-work.org/${i18n.language === 'en' ? 'en/' : ''}faq`}/>
          <div className="footer-main__contacts">
            {i18n.language === 'ru' &&
            <a href="https://vk.com/public210342771">
              <img src="/vk_logo.png" className="vk-logo" alt="vk_link"/>
            </a>
            }
            <a href="https://t.me/app_work">
              <img src="/telegram_logo.png" className="telegram-logo" alt="telegram_link"/>
            </a>
            {/*<a href="/">*/}
            {/*  <img src="/instagram_logo.png" className="instagram-logo" alt="instagram_link"/>*/}
            {/*</a>*/}
            <a href="https://www.facebook.com/AppWork-112812954631519">
              <img src="/facebook_logo.svg" className="facebook-logo" alt="facebook_link"/>
            </a>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&#169; 2023 AppWork</p>
        </div>
      </div>
    </div>
  )
}
