import React from 'react'
import { useTranslation } from 'react-i18next'
import Paragraph, { TextSize, TextStyle } from '../common_kit/text/Paragraph'

export default function FAQ() {
  const { t, i18n } = useTranslation()

  const localeFAQ = i18n.language === 'en' ? 'en/faq#!/tab/410844804-2' : 'faq#!/tab/406099842-2'

  return (
    <div className="faq-block">
      <Paragraph
        text={t('FAQ part 1')}
        textStyle={TextStyle.ITALIC}
        size={TextSize.P_12}/>
      <Paragraph
        text={t('FAQ part 2')}
        textStyle={TextStyle.ITALIC}
        size={TextSize.P_12}
        children={<a href={`https://app-work.org/${localeFAQ}`} target="_blank">
          {t('FAQ link')}
        </a>}/>
    </div>
  )
}
