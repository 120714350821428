import React, { useEffect, useState } from 'react'
import Img from '../common_kit/Img'
import { useTranslation } from 'react-i18next'
import H3 from '../common_kit/text/H3'
import H5 from '../common_kit/text/H5'
import Paragraph, { TextSize } from '../common_kit/text/Paragraph'
import SubmitButton from '../inputs/SubmitButton'
import Timer from '../common_kit/Timer'
import moment from 'moment'
import { getRate } from '../../utils/helpers'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'


type Data = {
  startTime: string | undefined,
}

type adaptiveCssModel = {
  textSize: string
}

type announcementCardCssModel = {
  s: adaptiveCssModel,
  m: adaptiveCssModel,
  l: adaptiveCssModel
}

export default function AnnouncementCard(props: Data) {
  const size = useSelector((state: RootState) => state.mq.size)

  const [time, setTime] = useState<string>()

  const { t, i18n } = useTranslation()

  let announcementCardCss: announcementCardCssModel = {
    s: {
      textSize: 'P_14'
    },
    m: {
      textSize: 'P_14'
    },
    l: {
      textSize: 'P_18'
    }
  }

  const AMOUNT = (600 / getRate()).toFixed(i18n.language === 'ru' ? 0 : 2)

  useEffect(() => {
    const endTime = moment(props.startTime).parseZone().add(15, 'days')
    const now = moment().parseZone()
    setTime(endTime > now ? endTime.toString() : undefined)
  }, [])

  const getTimerBlock = () => {
    if (!time) {
      return (
        <div className="announcement-card__description">
          <Paragraph
            className="announcement-card__description-text"
            size={TextSize[announcementCardCss[size].textSize]}
            text={t('The task will be available soon')}
          />
        </div>
      )
    }
    return (
      <div className="announcement-card__description">
        <Paragraph
          className="announcement-card__description-text"
          size={TextSize[announcementCardCss[size].textSize]}
          text={t('The task will be available through')}
        />
        <Timer
          endMoment={time}
          showWords
          hideSeconds
          inGenitive
          handleTimeIsOver={() => setTime(undefined)}
        />
      </div>
    )
  }

  return (
    <div
      className="announcement-card card-box">
      <div className="announcement-card__price">
        <H3 text={`+${AMOUNT} ${t('currency sign')}`}/>
      </div>
      <div className="announcement-card__title">
        <H5 text={t('Update app on Google Play')}/>
      </div>
      {getTimerBlock()}
      <div className="announcement-card__icon">
        <Img src="hourglass.svg"/>
      </div>
      <div className="announcement-card__btn">
        <SubmitButton
          isDisabled
          text={t('Start task')}
        />
      </div>
    </div>
  )
}
