import React, { ReactElement } from 'react'
import EncircleBorder from '../common_kit/EncircleBorder'
import '../../style/sass/instructions/guides/GuideSteps.scss'


type Data = {
  className?: string
  children: ReactElement
}

export default function GuideComponent(props: Data) {
  return (
    <EncircleBorder className={`guide-component ${props.className ?? ''}`}>
      {props.children}
    </EncircleBorder>
  )
}

