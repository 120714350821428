import { SET_CNV_ID, SET_SEO_DATA, UPDATE_PHONE_CODE_STATUS, DELETE_SEO_DATA } from './actionTypes'
import { get } from 'idb-keyval'

export const setLocalCnvId = () => async dispatch => {
  const data = await get('cnvId')
  if (!data) return
  dispatch({
    type: SET_CNV_ID,
    data
  })
}

export const setSeoData = data => ({
  type: SET_SEO_DATA,
  data
})

export const updPhoneCodeStatus = data => ({
  type: UPDATE_PHONE_CODE_STATUS,
  data
})

export const deleteSeoData = () => ({
  type: DELETE_SEO_DATA
})
