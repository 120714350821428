import {WarningFilled} from "@ant-design/icons"
import React from "react"
import {useTranslation} from "react-i18next"
import '../../style/sass/tasksPage/WarningGmailPhone.scss'

type Data = {
  taskInstruction?: boolean
}

export default function WarningGmailPhone(props: Data) {
  const { t } = useTranslation()

  const blockCss = ['google-alert-start-task', props.taskInstruction ? 'instruction-alert' : '']

  return (
    <div className={blockCss.join(' ')}>
      <div className="google-alert-phone">
        <WarningFilled/>
        <span className="google-alert-msg">
                {t('Simple task phone limit warning part 1')}
              </span>
      </div>
      <div className="google-alert-phone">
        <WarningFilled/>
        <span className="google-alert-msg">
                {t('Simple task phone limit warning part 2')}
              </span>
      </div>
    </div>
  )
}
