export interface SidebarState {
  show: boolean
  available: boolean
  shownMenu: boolean
}

const initialState: SidebarState = {
  show: false,
  available: false,
  shownMenu: false
}

export default initialState
