import React from 'react'
import Paragraph from '../../common_kit/text/Paragraph'
import IconQiwi from '../../icons/IconQiwi'


type Data = {
  countDetails: string
}

export default function QiwiMoney(props: Data) {
  return (
    <div className={'payment-count'}>
      <IconQiwi viewBox={'50 0 190 44'} fillBack={'none'} fill={'#2A3E58'}/>
      <Paragraph text={props.countDetails}/>
    </div>
  )
}

