import { SET_INTERCOM_SETTINGS, STOP_INTERCOM_CHAT } from './actionTypes'

export const setIntercomSettings = settings => {
  window.Intercom('update', settings)
  return {
    type: SET_INTERCOM_SETTINGS,
    settings
  }
}

export const stopIntercomChat = () => {
  window.Intercom('shutdown')
  return {
    type: STOP_INTERCOM_CHAT
  }
}
