import React, { useEffect, useState } from 'react'
import Img from '../common_kit/Img'
import IconAdd from '../icons/IconAdd'
import {
  checkFileFormat,
  checkFileSize,
  FileUploadStatus,
  getFullFileUrl,
  getUrlFromFile,
  shortenFileName,
  uploadFile
} from '../../utils/helpers'
import { useTranslation } from 'react-i18next'
import { Image, Popover, Spin } from 'antd'
import { CheckCircleTwoTone, ExclamationCircleTwoTone, EyeOutlined } from '@ant-design/icons'
import '../../style/sass/fileUploader/Screenshoter.scss'
import Notification from '../notification/Notification'


type File = {
  id: number,
  image: string,
  path: string,

  name?: string,
  status?: FileUploadStatus
}

type Data = {
  imagesUpdateHandler: (files: Array<string>) => void
  imageUrls: Array<string>,
  onUploading: (value) => void,

  limit?: number,
  noMoreUpload?: boolean
}

MultiScreenshoter.defaultProps = {
  limit: 5,
  noMoreUpload: false
}

export default function MultiScreenshoter(props: Data) {

  const { t } = useTranslation()

  const [files, setFiles] = useState<Array<File>>([])
  const [showGallery, setShowGallery] = useState<boolean>()
  const [showInput, setShowInput] = useState<boolean>()
  const [uploading, setUploading] = useState<boolean>(false)

  useEffect(() => {
    setFilesFromProps()
  }, [])

  useEffect(() => {
    setFilesFromProps()
  }, [props.imageUrls])

  useEffect(() => {
    const uploading = files.some(i => i.status === FileUploadStatus.PROGRESS)
    setUploading(uploading)
    props.onUploading(uploading)
  }, [files])

  useEffect(() => {
    if (!files.length) {
      setShowGallery(false)
      setShowInput(!props.noMoreUpload)
      return
    } else if (files.length < props.limit!) {
      setShowGallery(true)
      setShowInput(!props.noMoreUpload)
      return
    }
    setShowGallery(true)
    setShowInput(false)
  }, [files, props.noMoreUpload])

  const setFilesFromProps = () => {
    if (!props.imageUrls.length) return
    const _files = [...props.imageUrls]
    setFiles(_files.splice(0, props.limit).map((path, index) => ({
      id: index + Date.now(),
      image: getFullFileUrl(path),
      name: undefined,
      status: undefined,
      path
    })))
  }

  const validateFileList = list => {
    return list.filter(inputedFile => {
      const hasClone: boolean = files.some(item => item.name === inputedFile.name)
      if (hasClone) {
        Notification({
          noticeType: 'success',
          text: t('Same file load error', { name: shortenFileName(inputedFile.name, 20) })
        })
        return false
      }
      const formatIsValid: boolean = checkFileFormat(inputedFile, ['jpg', 'jpeg', 'png'])
      const sizeIsValid: boolean = checkFileSize(inputedFile, 2)
      return formatIsValid && sizeIsValid
    })
  }

  const handleClickAddBtn = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.multiple = true
    input.onchange = handleFileInputChange
    input.click()
  }

  const handleFileInputChange = async event => {
    const fileList = [...event.target.files]
    const setFileWithUrl = async data => {
      const { id, file } = data
      const image = await getUrlFromFile(file)
      const newFile = {
        id,
        image,
        name: file.name,
        status: FileUploadStatus.PROGRESS,
        path: ''
      }
      setFiles(files => [...files, newFile])
    }
    const loadFileAndSetPath = async data => {
      const { id, file } = data
      const { path, status } = await uploadFile(file)
      setFiles(files => files.map(item => {
        if (item.id !== id) return item
        return {
          ...item,
          path,
          status
        }
      }))
      return { status, path }
    }

    //  1. Отбросить лишние файлы, которые не попадают в лимит
    const availableLength = props.limit! - files.length
    const filteredFiles = validateFileList(fileList)
    if (filteredFiles.length > availableLength) {
      Notification({ noticeType: 'info', text: t('Maximum files count', { limit: props.limit! }) })
    }
    const addedFiles = filteredFiles.slice(0, availableLength).map((file, index) => ({ id: index + Date.now(), file }))

    //  2. Добавить файлы в files, чтобы они отображались со статусом PROGRESS
    await Promise.allSettled(addedFiles.map(data => setFileWithUrl(data)))

    //  3. Загрузить файлы на бэк (параллельно для всех файлов).
    //     После каждой загрузки менять у файла статус на SUCCESS либо FAIL
    const result = await Promise.allSettled(addedFiles.map(data => loadFileAndSetPath(data)))

    //  4. После завершения всех загрузок передать данные в props.imagesUpdateHandler
    // @ts-ignore
    const data = [...files, ...result.map(item => item.value)]
    const hasError = data.some(i => [FileUploadStatus.PROGRESS, FileUploadStatus.FAIL].includes(i.status))
    const paths = data.map(item => item.path)
    props.imagesUpdateHandler(hasError ? [] : paths)
  }

  const handleFileRemove = id => {
    const updatedFiles = files.filter(item => item.id !== id)
    setFiles(updatedFiles)
    const hasError = updatedFiles.every(i => !i.status)
    props.imagesUpdateHandler(hasError ? [] : updatedFiles.map(item => item.path))
  }

  const getGallery = () => files.map((item, index) => {
    const getLoadingIcon = () => {
      return {
        [FileUploadStatus.PROGRESS]: (<Spin size="small" spinning/>),
        [FileUploadStatus.SUCCESS]: (<CheckCircleTwoTone twoToneColor="#25ec2a"/>),
        [FileUploadStatus.FAIL]: (<ExclamationCircleTwoTone twoToneColor="#ff1f1f"/>)
      }[item.status!]
    }

    return (
      <div key={index} className="multi-screenshoter__gallery-item">
        <Image
          src={item.image}
          height={100}
          width={100}
          preview={{
            mask: <div className="screenshot-zone__preview-mask">
              <EyeOutlined/>
              <div className="preview-text">
                {t('View')}
              </div>
            </div>
          }}/>
        {item.status && (
          <Popover content={<span>{t('Delete file')}</span>} overlayClassName="file-status-popover">
            <div className="loading-status" onClick={() => handleFileRemove(item.id)}>
              {getLoadingIcon()}
            </div>
          </Popover>
        )}
      </div>
    )
  })

  const getBtnClass = () => {
    const list = ['multi-screenshoter__button']
    uploading && list.push('disabled')
    return list.join(' ')
  }

  return (
    <div className="screenshot-zone multi-screenshoter">
      <div className="screenshot-zone__border">
        <svg width="100%" height="100%" viewBox="0 0 753 234" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M752 233V1H1V233" stroke="#B6CFF0" strokeWidth="0.5" strokeDasharray="8 8"/>
        </svg>
      </div>

      {showGallery && (
        <div className="multi-screenshoter__gallery">
          <Image.PreviewGroup>
            {getGallery()}
          </Image.PreviewGroup>
        </div>
      )}

      {showInput && (
        <div className={getBtnClass()} onClick={uploading ? undefined : handleClickAddBtn}>
          <Img className="screenshot-zone--dummy" src={'/screenshot_dummy.svg'}/>
          <IconAdd className="screenshot-zone__icon"/>
        </div>
      )}

    </div>
  )
}
