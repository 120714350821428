import React, { ReactNode } from 'react'
import '../../style/sass/common_kit/Tooltip.scss'
import IconHint from '../icons/IconHint'


type Data = {
  className?: string,
  svg?: string,
  text?: string,
  color?: CardStatusColor,
  children?: ReactNode,
  inline?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export enum CardStatusColor {
  RED = '#FF5F5F',
  BLUE = '#2684FE',
  GRAY = '#859AB6',
  DARK_BLUE = '#2A3E58'
}

Tooltip.defaultProps = {
  inline: false
}

export default function Tooltip(props: Data) {

  const getHint = () => {
    if (props.text) return (
      <p
        className="tooltip-container__tooltip-text"
        style={{ color: `${props.color}` }}>
        {props.text}
      </p>
    )
    if (props.children) return (
      <div className="tooltip-container__tooltip-content">
        {props.children}
      </div>
    )
    return null
  }

  return (
    <div
      className={`tooltip-container ${props.className ?? ''} ${props.inline ? 'inline' : ''}`}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}>
      <IconHint
        className={'tooltip-container__tooltip-icon'}
        fill={props.svg}
      />
      {getHint()}
    </div>
  )
}
