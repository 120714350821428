import { RESET_AUTH_DATA, SET_ACCESS_TOKEN } from './actionTypes'
import { get } from 'idb-keyval'

export const setLocalToken = () => async dispatch => {
  const token = await get('token')
  if (!token) return
  dispatch({
    type: SET_ACCESS_TOKEN,
    token
  })
}

export const setAccessToken = token => {
  return {
    type: SET_ACCESS_TOKEN,
    token
  }
}

export const resetAuthData = () => ({
  type: RESET_AUTH_DATA
})
