import React, { useEffect, useState } from 'react'
import TaskSidebar from './TaskSidebar'
import {useSelector, useDispatch} from "react-redux";
import {RootState} from "../../store/rootState";
import {useTranslation, WithTranslation} from 'react-i18next'
import {get} from "idb-keyval"
import {setSidebarAvailability} from "../../store/sidebar/actions";
import {updateUserData} from "../../store/user/actions";
import {setLocalScreenshots} from "../../store/taskScreenshots/actions"
import {resetSimpleTaskData, setStepSimpleIndexFromProgress, downloadAndSetSimpleTask} from "../../store/taskList/actions"
import GuideContent from "../instructions/GuideContent"
import {Spin} from "antd"

interface Data extends WithTranslation {
  match: {
    params: {
      id: string
    }
  }
}

export default function OtherTaskPage(props: Data) {

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const taskStatus = useSelector((state: RootState) => state.taskList.selectedTask?.status.name)
  const isLoading = useSelector((state: RootState) => !state.taskList.simpleTaskGmail?.steps?.length)
  const locale = useSelector((state: RootState) => state.locale.locale.name)
  const textData = useSelector((state: RootState) => state.taskList.simpleTaskGmail?.textFile)

  const [isFirstChangeLocale, setIsFirstChangeLocale] = useState<boolean>(true)
  const [id, setId] = useState<number>()

  const countingDown = ['process'].includes(taskStatus ?? '')


  useEffect(() => {
    get('activeSimpleTask')
      .then(taskId => {
        setId(taskId)
        dispatch(setSidebarAvailability(false))
        dispatch(updateUserData())
        dispatch(setLocalScreenshots())
      })
    return () => {
      dispatch(resetSimpleTaskData())
    }
  }, [])

  useEffect(() => {
    if (isFirstChangeLocale) {
      setIsFirstChangeLocale(false)
      return
    }
    dispatch(resetSimpleTaskData(true))
    dispatch(downloadAndSetSimpleTask(id))
  }, [locale, id])

  useEffect(() => {
    if (isLoading || !id) return
    dispatch(setStepSimpleIndexFromProgress(id))
  }, [isLoading, id])

  const getContent = () => {
    if (isLoading) return (
      <div className="task-loading-dummy">
        {t('Download instructions')}
      </div>
    )
    return (<GuideContent text={textData} typeTask={'taskList'}/>)
  }

  return(
    <div className='task-page'>
      <TaskSidebar countingDown={countingDown} typeTask={'taskList'}/>
      <div className="instruction col col-sm-9">
        <Spin spinning={isLoading}>
          {getContent()}
        </Spin>
      </div>
    </div>
  )
}
