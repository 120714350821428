export interface LocaleState {
  locale: Locale,
  rate: Rate,
}

export interface Locale {
  name: string;
}

export interface Rate {
  en: number,
}

const initialState: LocaleState = {
  locale: {
    name: ''
  },
  rate: {
    en: 77.67
  }
}

export default initialState
