import React from 'react'
import Paragraph from '../../common_kit/text/Paragraph'
import IconYandex from '../../icons/IconYandex'


type Data = {
  countDetails: string
}

export default function YandexMoney(props: Data) {
  return (
    <div className={'payment-count'}>
      <IconYandex viewBox={'35 0 190 44'} fillBack={'none'} fill={'#2A3E58'}/>
      <Paragraph text={props.countDetails}/>
    </div>
  )
}
