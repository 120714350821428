import {
  RESET_TASK_DATA,
  SET_BTN_DISABLED,
  SET_CURRENT_INDEX,
  SET_SELECTED_TASK,
  UPDATE_AVAILABLE_PHONE,
  UPDATE_RECEIVE_PHONE,
  UPDATE_GOOGLE_ADMIN_EMAIL
} from './actionTypes'
import {downloadTaskData, getStepsForPublishTask, getStepsForUpdateTask, getStepsForVerificationTask} from './api'
import store from '../index'
import {calculateCompleteAt, removeProgressFromIDB, setProgressToIDB, updateDataIndexedDB} from '../../utils/helpers'
import {get, set} from 'idb-keyval'
import _ from 'lodash/index'
import {RootState} from '../rootState'
import {AnyAction} from 'redux'
import {PublisherTaskType} from '../taskList/initialState'
import {OrderedTaskStep, Task} from './initialState'
import countryPhoneCodes from "../../tempDictionary/countryPhoneCodes.json";
import {getTmpPhone} from "../taskList/api";
import {updSelectedSimpleGmailTask} from "../taskList/actions";


export const setBtnDisabled = (data) => {
  return {
    type: SET_BTN_DISABLED,
    data
  }
}

export const setSelectedTask = (task: Task): AnyAction => {
  const getSteps: (task?: Task) => Array<OrderedTaskStep> = {
    [PublisherTaskType.PUBLICATION]: getStepsForPublishTask,
    [PublisherTaskType.UPDATE]: getStepsForUpdateTask,
    [PublisherTaskType.VERIFICATION]: getStepsForVerificationTask
  }[task.type]
  let modifiedTask = calculateCompleteAt(task)
  if ('country_show_prices' in task && 'original_country_show_prices' in task) {
    modifiedTask.calcAddingCountries = _.difference(task.application.country_show_prices, task.original_country_show_prices)
    modifiedTask.calcRemovingCountries = _.difference(task.original_country_show_prices, task.application.country_show_prices)
  }
  return {
    type: SET_SELECTED_TASK,
    task: modifiedTask,
    steps: getSteps(modifiedTask)
  }
}

export const downloadAndSetSelectedTask = (id?: string | number) => async dispatch => {
  const state: RootState['task'] = store.getState().task
  const selectedTask = state.selectedTask
  if (selectedTask) {
    dispatch(setSelectedTask(selectedTask))
    return
  }
  if (!id) return
  downloadTaskData(id)
}

export const setCurrentIndex = (taskId, index, needUpdateProgress = false, taskType = '') => {
  const userId = store.getState().user.userData.id
  const locale = store.getState().locale.locale.name

  return {
    type: SET_CURRENT_INDEX,
    index,
    taskId,
    userId,
    needUpdateProgress,
    locale,
    taskType
  }
}

export const setStartStepIndex = id => setCurrentIndex(id, 0, true)

export const setNextStepIndex = () => {
  const state: RootState['task'] = store.getState().task
  const id = state.selectedTask!.id
  const nextIndex = state.currentIndex! + 1
  return setCurrentIndex(id, nextIndex)
}

export const setPrevStepIndex = () => {
  const state: RootState['task'] = store.getState().task
  const id = state.selectedTask!.id
  const prevIndex = state.currentIndex! - 1
  return setCurrentIndex(id, prevIndex)
}

export const setStepIndexFromProgress = id => async dispatch => {
  const state: RootState = store.getState()
  const getLocalProgress = async () => {
    const userId = state.user.userData?.id || state.user.id
    const allProgress = await get('advancement')
    if (!allProgress || !userId) return 0
    const locale = state.locale.locale.name
    const key = `${userId}_${id}_${locale}`
    if (!allProgress[key]) return 0
    const steps = state.task.steps
    if (steps && steps.length - 1 < allProgress[key]) return steps.length - 1
    return allProgress[key] || 0
  }
  const taskType = state.task.selectedTask?.type
  const index = await getLocalProgress()
  dispatch(setCurrentIndex(id, index, false, taskType))
}

export const resetTaskData = (resetAdvancement = false) => {
  const state: RootState = store.getState()
  const taskId = state.task?.selectedTask?.id
  const userId = state.user.userData?.id || state.user.id
  const locale = state.locale.locale.name
  if (resetAdvancement && taskId && userId) {
    removeProgressFromIDB(taskId, userId, locale)
  }
  return {
    type: RESET_TASK_DATA
  }
}

export const goToStepByLinkId = linkId => {
  const state: RootState = store.getState()
  const userId = state.user.userData?.id || state.user.id
  const steps = state.task.steps
  const selectedTask = state.task.selectedTask
  if (!steps || !userId || !selectedTask) return
  const locale = state.locale.locale.name
  const newIndex = steps.findIndex(item => item.stepOptions.linkId === linkId)
  setProgressToIDB(selectedTask.id, userId, newIndex, locale)
  return setCurrentIndex(selectedTask.id, newIndex, true)
}

export const updateRepeatReceivePhone = count => {
  return {
    type: UPDATE_RECEIVE_PHONE,
    count
  }
}

export const updateAvailablePhone = status => {
  return {
    type: UPDATE_AVAILABLE_PHONE,
    status
  }
}

export const setGoogleAdminEmail = email => {
  return {
    type: UPDATE_GOOGLE_ADMIN_EMAIL,
    email
  }
}
