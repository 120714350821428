import React, { useEffect, useState } from 'react'
import GuideContent from '../instructions/GuideContent'
import { useTranslation, WithTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserData } from '../../store/user/actions'
import { setSidebarAvailability } from '../../store/sidebar/actions'
import { setLocalScreenshots } from '../../store/taskScreenshots/actions'
import { ScreenshotType } from '../../store/taskScreenshots/initialState'
import { RootState } from '../../store/rootState'
import { downloadAndSetSelectedTask, resetTaskData, setStepIndexFromProgress } from '../../store/task/actions'
import { Spin } from 'antd'
import TaskSidebar from './TaskSidebar'


interface Data extends WithTranslation {
  match: {
    params: {
      id: string
    }
  }
}

export default function UpdateTaskPage(props: Data) {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const taskStatus = useSelector((state: RootState) => state.task.selectedTask?.status.name)

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const id = Number(props.match.params.id)

  useEffect(() => {
    setIsLoading(!taskStatus)
    dispatch(downloadAndSetSelectedTask(id))
    dispatch(setSidebarAvailability(false))
    dispatch(updateUserData())
    dispatch(setLocalScreenshots())
    return () => {
      dispatch(resetTaskData())
    }
  }, [])

  useEffect(() => {
    setIsLoading(!taskStatus)
  }, [taskStatus])

  useEffect(() => {
    if (!isLoading) {
      dispatch(setStepIndexFromProgress(id))
    }
  }, [isLoading])

  const getContent = () => {
    if (isLoading) return (
      <div className="task-loading-dummy">
        {t('Download instructions')}
      </div>
    )
    return (<GuideContent moderationScreenshotType={ScreenshotType.UPDATE_MODERATION} typeTask={'task'}/>)
  }

  const countingDown = taskStatus === 'process'
  const loadingClass = isLoading ? 'loading' : ''

  return (
    <div className={`task-page ${loadingClass}`}>
      <TaskSidebar countingDown={countingDown} loading={isLoading}  typeTask={'task'}/>
      <div className="instruction col col-sm-9">
        <Spin spinning={isLoading}>
          {getContent()}
        </Spin>
      </div>
    </div>
  )
}
