import { SET_HEIGHT, SET_ORIENTATION, SET_SIZE } from './actionTypes'
import initialState from './initialState'

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SIZE:
      return {
        ...state,
        size: action.size
      }
    case SET_HEIGHT:
      return {
        ...state,
        height: action.height
      }
    case SET_ORIENTATION:
      return {
        ...state,
        orientation: action.orientation
      }
    default:
      return state
  }
}
