import {
  MainTaskType,
  PublishTaskSection,
  UpdateTaskSection,
  VerificationTaskSection
} from '../store/task/initialState'
import Notification from '../components/notification/Notification'
import i18n from 'i18next'
import { PublisherTaskType } from '../store/taskList/initialState'
import { GoogleAccountTaskSection } from '../store/task/initialState'

export const ONLY_LATIN_REGEX = /^[a-zA-Z]*$/
export const CITY_REGEX = /^[a-zA-Zа-яёА-ЯЁ\-\s]*$/
export const PHONE_RUS_REGEX = /^\+7\s\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}$/
export const BANK_CARD_REGEX = /^\d{4}\s\d{4}\s\d{4}\s\d{4}$/
const STREET_REGEX = /[^а-яА-Я]/gm
export const UUID_REGEX = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/g
export const ME_REGEX = /me=\w+&?/gm
export const MU_REGEX = /mu=\w+&?/gm
export const MT_REGEX = /mt=\w+&?/gm

export const MONTHS = [
  { name: 'January', days: 31 },
  { name: 'February', days: 29 },
  { name: 'March', days: 31 },
  { name: 'April', days: 30 },
  { name: 'May', days: 31 },
  { name: 'June', days: 30 },
  { name: 'July', days: 31 },
  { name: 'August', days: 31 },
  { name: 'September', days: 30 },
  { name: 'October', days: 31 },
  { name: 'November', days: 30 },
  { name: 'December', days: 31 }
]

export const PUBLISH_TYPES = [
  MainTaskType.PUBLISH,
  MainTaskType.NO_DEVELOP,
  MainTaskType.WITH_DEVELOP,
  MainTaskType.UNITY
]

export const UPDATE_TYPES = [
  MainTaskType.UPDATE_ASSEMBLY,
  MainTaskType.UPDATE_ASO_IMG,
  MainTaskType.UPDATE_ASO_IMG_ASSEMBLY,
  MainTaskType.UPDATE_ASO_TEXT_IMG,
  MainTaskType.UPDATE_PURCHASES,
  MainTaskType.UPDATE_ASO_ASSEMBLY,
  MainTaskType.UPDATE_IMG
]

export const cardholderValidator = {
  firstName: [],
  lastName: [],
  birthDateDay: [],
  birthDateMonth: [],
  birthDateYear: []
}

export const addressValidator = {
  countryCode: [
    {
      condition: value => !!value,
      errorMessage: 'Country is required'
    }
  ],
  city: [
    {
      condition: value => !!value,
      errorMessage: 'City is required'
    }
  ],
  street: [
    {
      condition: value => !!value,
      errorMessage: 'Street is required'
    }
  ]
}

export const bankCardValidator = {
  cardExpiryMonth: [
    {
      condition: value => !!value,
      errorMessage: 'Month is required'
    }
  ],
  cardExpiryYear: []
}

export const cardholderFormatter = {
  firstName: [
    value => {
      if (!!value.match(ONLY_LATIN_REGEX)) {
        return value
      } else {
        Notification({
          noticeType: 'warn',
          text: i18n.t('Allowed only Latin letters in the "First name on card" field')
        })
        return null
      }
    },
    value => value?.toUpperCase() ?? null
  ],
  lastName: [
    value => {
      if (!!value.match(ONLY_LATIN_REGEX)) {
        return value
      } else {
        Notification({
          noticeType: 'warn',
          text: i18n.t('Allowed only Latin letters in the "Last name on card" field')
        })
        return null
      }
    },
    value => value?.toUpperCase() ?? null
  ]
}

export const addressFormatter = {
  city: [
    value => {
      if (!!value.match(CITY_REGEX)) {
        return value
      } else {
        Notification({
          noticeType: 'warn',
          text: i18n.t('Allowed only letters in the "City" field')
        })
        return null
      }
    },
    value => {
      if (!!value.match(ONLY_LATIN_REGEX)) {
        return value
      } else {
        Notification({
          noticeType: 'warn',
          text: 'Allowed only Latin letters in the "City" field'
        })
        return null
      }
    }
  ],
  street: [
    value => {
      if (!!value.match(STREET_REGEX)) {
        return value
      } else {
        Notification({
          noticeType: 'warn',
          text: 'Allowed only Latin letters and numbers in the "House number, street" field'
        })
        return null
      }
    }
  ]
}

export const MENU_ITEMS = [
  {
    name: 'availableTasks',
    title: 'Available tasks',
    path: '/available-tasks'
  },
  {
    name: 'referrals',
    title: 'Referrals',
    path: '/referrals'
  },
  {
    name: 'withdrawal',
    title: 'Withdraw funds',
    path: '/withdrawal'
  }
]

export const TASK_STAGES_OPTIONS = {
  [PublisherTaskType.VERIFICATION]: [
    {
      number: '1',
      sections: [
        {
          key: VerificationTaskSection.NONE,
          title: ''
        },
        {
          key: VerificationTaskSection.REG_IN_GOOGLE,
          title: ''
        }
      ]
    },
    {
      number: '2',
      sections: [
        {
          key: VerificationTaskSection.REG_GMAIL_ACCOUNT,
          title: ''
        }
      ]
    },
    {
      number: '3',
      sections: [
        {
          key: VerificationTaskSection.RESERVE_PASSWD,
          title: ''
        }
      ]
    },
  ],
  [PublisherTaskType.PUBLICATION]: [
    {
      number: '1',
      sections: [
        {
          key: PublishTaskSection.GO_TO_GOOGLE,
          title: ''
        }
      ]
    },
    {
      number: '2',
      sections: [
        {
          key: PublishTaskSection.MAIN_STORE_LISTING,
          title: 'Main store listing'
        },
        {
          key: PublishTaskSection.STORE_SETTINGS,
          title: 'Store settings'
        },
        {
          key: PublishTaskSection.PRODUCTION,
          title: 'Production'
        },
        {
          key: PublishTaskSection.DASHBOARD,
          title: 'Dashboard'
        },
        {
          key: PublishTaskSection.APP_CONTENT,
          title: 'App content'
        }
      ]
    }
  ],
  [PublisherTaskType.UPDATE]: [
    {
      number: '1',
      sections: [
        {
          key: UpdateTaskSection.GO_TO_GOOGLE,
          title: ''
        }
      ]
    },
    {
      number: '2',
      sections: [
        {
          key: UpdateTaskSection.APP_DESCRIPTION,
          title: 'App description'
        },
        {
          key: UpdateTaskSection.APP_VERSIONS,
          title: 'App versions'
        }
      ]
    }
  ],
  [PublisherTaskType.SIMPLE]: [
    {
      number: '1',
      sections: [
        {
          key: GoogleAccountTaskSection.REG_GMAIL_ACCOUNT,
          title: ''
        }
      ]
    },
    {
      number: '2',
      sections: [
        {
          key: GoogleAccountTaskSection.RESERVE_PASSWD,
          title: ''
        }
      ]
    },
  ],
  [PublisherTaskType.USER_SIMPLE]: [
    {
      number: '1',
      sections: [
        {
          key: GoogleAccountTaskSection.REG_GMAIL_ACCOUNT,
          title: ''
        }
      ]
    },
    {
      number: '2',
      sections: [
        {
          key: GoogleAccountTaskSection.RESERVE_PASSWD,
          title: ''
        }
      ]
    },
  ]
}

export const GOOGLE_PLAY_CONSOLE_URL = 'https://play.google.com/apps/publish/signup/'

export const CUSTOM_VALID_PHONE = {
  880: {
    validate: value => !value.match(/^0/),
    showCorrect: value => value.replace(/^0/g, '')
  },
  7: {
    validate: value => value.length === 10
  }
}

export const LOCAL_STORAGE_FIELDS = [
  'advancement',
  'cnvId',
  'informedBlockAlert',
  'locale',
  'screenshots',
  'showBalanceTooltip',
  'token',
  'types',
  'userData',
  'viewedAppUnpublishedAfterMarathonNotice',
  'viewedDeletedAppCheckedNotice',
  'viewedFailureModerationNotice',
  'viewedFailureNotice',
  'viewedFailurePostModerationNotice',
  'viewedTaskCompleteNotice'
]

export const STATUSES_WITH_ENABLED_BTN = [
  'published',
  'assigned',
  'process',
  'process_assigned',
  'verification',
  'confirmation',
  'verified'
]

export const META_TAGS = [
  {
    property: 'og:title',
    content: {
      ru: 'AppWork личный кабинет',
      en: 'AppWork personal account '
    }
  },
  {
    property: 'og:site_name',
    content: {
      ru: 'AppWork',
      en: 'AppWork'
    }
  },
  {
    property: 'og:url',
    content: {
      ru: 'https://lk.app-work.org',
      en: 'https://lk.app-work.org'
    }
  },
  {
    property: 'og:description',
    content: {
      ru: 'Публикация мобильных приложений в Google Play',
      en: 'Publishing mobile apps on Google Play'
    }
  },
  {
    property: 'og:image',
    content: {
      ru: '',
      en: ''
    }
  }
]

export const FINGERPRINT_EXTRA_TIME = 3000

export const FAILED_FINGERPRINT_ID = 'failed_to_receive'

export const RESEND_SMS_TIMEOUT = 3

export const MARATHON_STATUS_STOP = [
  'stop_task_update',
  'stop_app_manager',
  'stop_removed_from_store'
]

export const WEB_GL_1 = [
  'MAX_COMBINED_TEXTURE_IMAGE_UNITS',
  'MAX_CUBE_MAP_TEXTURE_SIZE',
  'MAX_FRAGMENT_UNIFORM_VECTORS',
  'MAX_RENDERBUFFER_SIZE',
  'MAX_TEXTURE_IMAGE_UNITS',
  'MAX_TEXTURE_SIZE',
  'MAX_VARYING_VECTORS',
  'MAX_VERTEX_ATTRIBS',
  'MAX_VERTEX_TEXTURE_IMAGE_UNITS',
  'MAX_VERTEX_UNIFORM_VECTORS',
  'MAX_VIEWPORT_DIMS'
]

export const WEB_GL_2 = [
  'MAX_3D_TEXTURE_SIZE',
  'MAX_ARRAY_TEXTURE_LAYERS',
  'MAX_CLIENT_WAIT_TIMEOUT_WEBGL',
  'MAX_COLOR_ATTACHMENTS',
  'MAX_COMBINED_FRAGMENT_UNIFORM_COMPONENTS',
  'MAX_COMBINED_UNIFORM_BLOCKS',
  'MAX_COMBINED_VERTEX_UNIFORM_COMPONENTS',
  'MAX_DRAW_BUFFERS',
  'MAX_ELEMENT_INDEX',
  'MAX_ELEMENTS_INDICES',
  'MAX_ELEMENTS_VERTICES',
  'MAX_FRAGMENT_INPUT_COMPONENTS',
  'MAX_FRAGMENT_UNIFORM_BLOCKS',
  'MAX_FRAGMENT_UNIFORM_COMPONENTS',
  'MAX_PROGRAM_TEXEL_OFFSET',
  'MAX_SAMPLES',
  'MAX_SERVER_WAIT_TIMEOUT',
  'MAX_TEXTURE_LOD_BIAS',
  'MAX_TRANSFORM_FEEDBACK_INTERLEAVED_COMPONENTS',
  'MAX_TRANSFORM_FEEDBACK_SEPARATE_ATTRIBS',
  'MAX_TRANSFORM_FEEDBACK_SEPARATE_COMPONENTS',
  'MAX_UNIFORM_BLOCK_SIZE',
  'MAX_UNIFORM_BUFFER_BINDINGS',
  'MAX_VARYING_COMPONENTS',
  'MAX_VERTEX_OUTPUT_COMPONENTS',
  'MAX_VERTEX_UNIFORM_BLOCKS',
  'MAX_VERTEX_UNIFORM_COMPONENTS'
]


export const HANDLE_REGENERATE_PHONE = 3

export const FORCED_REGENERATE_PHONE = 20

export const GOOGLE_ACCOUNT_REG_URL = 'https://accounts.google.com/signup/v2/createaccount?biz=false&cc=RU&continue=https%3A%2F%2Fwww.google.com%2F&dsh=S-503279735%3A1692605885442220&flowEntry=SignUp&flowName=GlifWebSignIn&hl=en-GB&authuser=0'

export const DELAY_SMS_NUM = 30000

export const MAX_COUNT_PHONE_TIME = 6

export const PaymentCurrency = Object.freeze({
  RUB: 'rub',
  USD: 'usd',
  USDT: 'usdt',
})
