import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { CardStatusColor } from '../common_kit/Tooltip'
import StatusBlock from './StatusBlock'
import IconAccepted from '../icons/IconAccepted'
import { useTranslation } from 'react-i18next'
import { STATUSES_WITH_ENABLED_BTN } from '../../utils/constants'
import { checkDevice, checkTranslate, getCorrectWordVariant, parseTaskTimeToString } from '../../utils/helpers'
import Paragraph from '../common_kit/text/Paragraph'
import { takeTaskToProcess } from '../../store/task/api'
import InfoModal from '../InfoModal/InfoModal'
import { Spin } from 'antd'
import IconClear from '../icons/IconClear'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store/rootState'
import SubmitButton from '../inputs/SubmitButton'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { set } from 'idb-keyval'
import Timer from '../common_kit/Timer'
import RejectReason from './RejectReason'
import { PublisherTaskType, TaskListItem } from '../../store/taskList/initialState'
import store from "../../store";
import {updateAvailablePhone} from "../../store/task/actions";


export enum CardType {
  PREVIEW,
  DETAILS,
}

export type WrapTaskCardData = {
  task: TaskListItem,
  cardType: CardType,
}

export default function taskCardWrap(WrappedComponent, props: WrapTaskCardData) {
  return () => {
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const task = props.task
    const taskStatus = task.status
    const taskType = task.type
    const isVerificationTask = taskType === PublisherTaskType.VERIFICATION
    const isPublishTask = taskType === PublisherTaskType.PUBLICATION
    const isUpdateTask = taskType === PublisherTaskType.UPDATE
    const disabled = task.available === false

    const size = useSelector((state: RootState) => state.mq.size)
    const userId = useSelector((state: RootState) => state.user.userData?.id)

    const [showDeviceModal, setShowDeviceModal] = useState<boolean>(false)
    const [showTakeTaskModal, setShowTakeTaskModal] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [isExpired, setIsExpired] = useState<boolean>(false)

    const [submitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(() => {
      return !STATUSES_WITH_ENABLED_BTN.includes(taskStatus?.name) || isExpired || disabled
    })

    useEffect(() => {
      setSubmitBtnDisabled(!STATUSES_WITH_ENABLED_BTN.includes(taskStatus?.name) || isExpired || disabled)
    }, [isExpired])

    const handleTimeIsOver = useCallback(() => setIsExpired(true), [])

    const getSubmitButtonText = (): string => {
      switch (taskStatus?.name) {
        case 'published':
        case 'waiting':
        case 'assigned':
          return t(isVerificationTask ? 'Start verification' : 'Start task')
        case 'completed':
          return t('Task completed')
      }
      return t(isVerificationTask ? 'Continue verification' : 'Continue task')
    }

    const navigateToTask = (id: string) => {
      set('activeTask', id)
        .then(() => {
          const getPath = () => {
            if (isVerificationTask) return `verification/task/${id}`
            return isPublishTask ? 'task/publish' : `update/task/${id}`
          }
          const path = getPath()
          history.push(path)
        })
    }

    const handleSubmitButtonClick = useCallback(async () => {
      if (disabled) return

      const correctDevice = checkDevice()
      if (!correctDevice) {
        setShowDeviceModal(true)
        return
      }
      const id = task.id
      if (isVerificationTask) {
        if (task.status.name === 'published') {
          await takeOtherTask()
          if (process.env.REACT_APP_ENV === 'prod') {
            window.dataLayer.push({event: 'startVerification'})
          }
        } else {
          setIsLoading(true)
          if (task.status.name === 'failed_verification') {
            dispatch(updateAvailablePhone(false))
            set('failedTask', task.id)
            await takeTaskToProcess({ id })
          }
          navigateToTask(id)
        }
      } else if (isPublishTask) {
        if (['published', 'assigned'].includes(task.status.name)) {
          setShowTakeTaskModal(true)
        } else {
          setIsLoading(true)
          navigateToTask(id)
        }
      } else {
        if (task.status.name === 'published') {
          setShowTakeTaskModal(true)
        } else {
          setIsLoading(true)
          if (task.status.name === 'purchase_moderation_confirmed') {
            await takeTaskToProcess({ id })
          }
          navigateToTask(id)
        }
      }
    }, [])

    const getStatusBlock = (): ReactNode => {

      enum ContentType {
        TIME_TO_COMPLETE,
        TIMER,
        TASK_STATUS_BLOCK,
        FULL_STATUS_BLOCK,
        ACCEPTED_ICON,
        EXPIRED_MSG,
        ESTIMATED_TIME
      }

      const appStatus = task.application?.status

      const getContentType = (): ContentType => {
        if (isVerificationTask) {
          return taskStatus.name === 'published' ? ContentType.ESTIMATED_TIME : ContentType.TASK_STATUS_BLOCK
        }
        if (isExpired) return ContentType.EXPIRED_MSG
        if (isPublishTask) {
          switch (taskStatus.name) {
            case 'published':
            case 'waiting':
            case 'assigned':
              return ContentType.TIME_TO_COMPLETE
            case 'process':
            case 'process_assigned':
              return ContentType.TIMER
            case 'verification':
            case 'confirmation':
            case 'verified':
              return ContentType.TASK_STATUS_BLOCK
            case 'failed_post_moderation':
            case 'completed':
              return ContentType.ACCEPTED_ICON
          }
          return ContentType.FULL_STATUS_BLOCK
        }
        switch (taskStatus.name) {
          case 'published':
          case 'waiting':
            return ContentType.TIME_TO_COMPLETE
          case 'process':
            return ContentType.TIMER
          case 'completed':
            return ContentType.ACCEPTED_ICON
        }
        return ContentType.TASK_STATUS_BLOCK
      }

      const getAppStatusColor = (): CardStatusColor => {
        switch (appStatus.name) {
          case 'application_deleted':
          case 'failed_moderation_application':
          case 'failed_pre_moderation_application':
          case 'failed_post_moderation_application':
          case 'application_unpublished_after_marathon':
          case 'application_unpublished_stuck_on_update':
          case 'application_unpublished':
            return CardStatusColor.RED
          case 'application_published':
          case 'application_published_stuck_on_update':
            return CardStatusColor.DARK_BLUE
        }
        return CardStatusColor.BLUE
      }

      const getTaskStatusColor = (): CardStatusColor => {
        switch (taskStatus.name) {
          case 'failed':
          case 'failed_assigned':
          case 'moderation_rejected':
          case 'failed_verification':
          case 'purchase_moderation_rejected':
            return CardStatusColor.RED
          case 'verified':
            return CardStatusColor.DARK_BLUE
        }
        return CardStatusColor.BLUE
      }

      const getContent = (): ReactNode => {
        const locale = i18n.language

        switch (getContentType()) {
          case ContentType.TIME_TO_COMPLETE:
            const hours = Math.floor(task.task_time / 3600)
            const minutes = Math.floor((task.task_time % 3600) / 60)
            const hoursText = hours ? `${hours} ${getCorrectWordVariant('hour', hours)}` : ''
            const minutesText = minutes ? `${minutes} ${getCorrectWordVariant('minute', minutes)}` : ''
            const isDetailCard: boolean = props.cardType === CardType.DETAILS
            return <StatusBlock
              bottomTitle={isDetailCard}
              title={isDetailCard ? t('Time to complete with details') : t('Time to complete')}
              text={`${hoursText} ${minutesText}`}
              color={CardStatusColor.RED}
            />
          case ContentType.TIMER:
            const calcCompleteAt = props.task.calcCompleteAt
            return (
              <div>
                <Timer endMoment={calcCompleteAt} handleTimeIsOver={handleTimeIsOver}/>
                <Paragraph text={t('Task in progress')} color={CardStatusColor.RED}/>
              </div>
            )
          case ContentType.TASK_STATUS_BLOCK:
            return <StatusBlock
              className={`task-card__task-status ${(locale === 'en') ? 'en-version' : ''}`}
              title={t(isVerificationTask ? 'Verification status' : 'Task status')}
              text={checkTranslate(taskStatus, locale, 'title', 'status')}
              hint={isVerificationTask ? '' : checkTranslate(taskStatus, locale, 'description', 'status')}
              color={getTaskStatusColor()}
            />
          case ContentType.FULL_STATUS_BLOCK:
            return taskStatus.meta && <div>
              <StatusBlock
                className={`task-card__app-status ${(locale === 'en') ? 'en-version' : ''}`}
                title={t('App status')}
                text={t(appStatus.name)}
                hint={appStatus.meta[locale].description}
                color={getAppStatusColor()}
              />
              <StatusBlock
                className={`task-card__task-status ${(locale === 'en') ? 'en-version' : ''}`}
                title={t('Task status')}
                text={checkTranslate(taskStatus, locale)}
                hint={taskStatus.meta[locale].description}
                color={getTaskStatusColor()}
              />
            </div>
          case ContentType.ACCEPTED_ICON:
            return <IconAccepted/>
          case ContentType.EXPIRED_MSG:
            return (
              <div className="task-card__expired-msg">
                {t('Time is up')}
              </div>
            )
          case ContentType.ESTIMATED_TIME:
            if (props.cardType === CardType.PREVIEW) return (
              <div className="task-card__estimated_time">
                <div>
                  {t('verification takes')}
                </div>
                <div className="accent">
                  {t('less then 60 min')}
                </div>
              </div>
            )
            return (
              <div className="task-card__estimated_time">
                <div className="accent">
                  {t('Task takes less then 60 min')}
                </div>
              </div>
            )
        }
      }

      return getContent()
    }

    const getCardTitle = (): string => {
      if (isVerificationTask) return t('Verification task title')
      return t(isPublishTask ? 'Publish task title' : 'Update task title')
    }

    const getDeviceModal = (): ReactNode => {
      const width = {
        s: '280px',
        m: '500px',
        l: '680px'
      }[size]
      return (
        <InfoModal showModal={showDeviceModal} width={width}>
          <div className="device-modal">
            <div className="message">
              <p className="ui-kit-regular-18 modal__text title">
                {t('Attention')}
              </p>
              <p className="ui-kit-regular-18 modal__text">
                {t('Incorrect device modal')}
              </p>
            </div>
            <img src="/deviceImage.svg" alt="" className="modal__icon"/>
            <button
              className="btn btn-primary modal__button"
              onClick={() => setShowDeviceModal(false)}>
              {t('Clear')}
            </button>
          </div>
        </InfoModal>
      )
    }

    async function takeOtherTask() {

      const id = task.id
      setIsLoading(true)
      await takeTaskToProcess({ id, start: true })
      navigateToTask(id)
    }

    const getTakeTaskModal = (): ReactNode => {
      const time: string = parseTaskTimeToString(task.task_time)
      const id = task.id
      const takePublishTask = async () => {

        setIsLoading(true)
        const result = await takeTaskToProcess({ id, start: true })
        if (!userId) return
        if (process.env.REACT_APP_ENV === 'prod') {
          window.ym(61234636, 'reachGoal', 'starttask')
          window.gtag('event', 'click', {
            'event_category': 'start'
          })
        }
        if (result) {
          navigateToTask(id)
        } else {
          setIsLoading(false)
          setShowTakeTaskModal(false)
        }
      }
      const getHandler = () => {
        const method = isPublishTask ? takePublishTask : takeOtherTask
        const event = isPublishTask ? 'takeInProcessTaskPublish' : 'takeInProcessTaskUpdate'
        return async () => {
          await method()
          if (process.env.REACT_APP_ENV === 'prod') {
            window.dataLayer.push({ event })
          }
        }
      }
      const getBtnId = () => {
        {/*
          !!! ВНИМАНИЕ !!!
          Изменять значение id допускается только по согласованию с руководством
        */
        }
        const getType = () => {
          if (isVerificationTask) return 'verification'
          if (isPublishTask) return 'published'
          if (isUpdateTask) return 'update'
        }
        const type = getType()
        if (!type) return
        return `uploader__take-${type}-task-btn`
      }
      return (
        <InfoModal showModal={showTakeTaskModal}>
          <Spin spinning={isLoading}>
            <div>
              <IconClear
                className={'modal-close'}
                fill={'#dfdefd'}
                onClick={() => {
                  setShowTakeTaskModal(false)
                }}/>
              <img src="/time_modal.svg" alt="" className="modal__icon"/>
              <p className="ui-kit-regular-14 modal__text">
                {t('Modal Start task')}
                <span className="time-block">{time}</span>
              </p>
              <button
                id={getBtnId()}
                className="btn btn-primary modal__button"
                onClick={getHandler()}>
                {t('Start task')}
              </button>
            </div>
          </Spin>
        </InfoModal>
      )
    }

    const getBtnBlock = () => {
      const isConfirmedVerificationTask = isVerificationTask && taskStatus.name === 'confirmation'
      const isFailedVerificationTask = isVerificationTask && taskStatus.name === 'failed_verification'

      if (isConfirmedVerificationTask) {
        return null
      } else if (isFailedVerificationTask) {
        if (props.cardType === CardType.PREVIEW) {
          return <RejectReason reason={task.comment} clickBtnHandler={handleSubmitButtonClick}/>
        } else {
          return null
        }
      }

      const getBtn = () => {
        let id
        if (isLoading) return <Spin tip={t('Download instructions')}/>
        /*
          !!! ВНИМАНИЕ !!!
          Изменять значение id допускается только по согласованию с руководством
        */
        isVerificationTask && taskStatus.name === 'published' && (id = 'uploader__take-verification-task-btn')
        return (
          <SubmitButton
            id={id}
            isDisabled={submitBtnDisabled}
            text={getSubmitButtonText()}
            onClick={handleSubmitButtonClick}
          />
        )
      }

      return (
        <div className="task-card__submit-btn-wrap">
          <SwitchTransition>
            <CSSTransition
              key={isLoading ? 1 : 0}
              timeout={200}
              classNames="fade">
              {getBtn()}
            </CSSTransition>
          </SwitchTransition>
        </div>
      )
    }

    return (
      <div className="task-card-wrap">
        <WrappedComponent
          task={task}
          cost={isVerificationTask ? undefined : task.cost}
          btnBlock={getBtnBlock()}
          statusBlock={getStatusBlock()}
          cardTitle={getCardTitle()}
          isExpired={isExpired}
          isLoading={isLoading}
          disabled={disabled}
        />
        {getDeviceModal()}
        {getTakeTaskModal()}
      </div>
    )
  }
}
