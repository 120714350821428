import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { matchPath } from "react-router-dom"
import { createBrowserHistory } from "history";

const history = createBrowserHistory()

const routes = [
  {path: '/available-tasks'},
  {path: '/:locale/sign-in'},
  {path: '/:locale/sign-up'},
  {path: '/task/publish'},
  {path: '/update/task/:id'},
  {path: '/verification/task/:id'},
  {path: '/withdrawal'},
  {path: '/referrals'},
  {path: '/profile'}
];

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes, matchPath),
  }),],
  trackComponents: true,
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACE_RATE,
  normalizeDepth: 10,
  environment: process.env.REACT_APP_SENTRY_ENV,
  beforeSend(event) {
    if (event.message?.match(/code 401/)) return
    return event
  },
  enabled: process.env.REACT_APP_SENTRY_ENV !== 'local'
})

export default Sentry
