import initialState from './initialState'
import {
  SET_PHONE_VERIFIED,
  CHANGE_PHONE_MODEL
} from './actionTypes'

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case SET_PHONE_VERIFIED:
      return {
        ...state,
        phoneVerified: action.status
      }
    case CHANGE_PHONE_MODEL:
      return {
        ...state,
        model: {...state.model, ...action.object}
      }
    default:
      return state
  }
}
