import React from 'react'


export default function QiwiLabelIcon() {
  return (
    <svg width="55" height="17" viewBox="0 0 55 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.1455 8.23921C36.3367 10.5636 35.4758 12.7869 34.7713 15.0607C34.6244 15.5191 34.3299 15.9199 33.9309 16.2046C33.5319 16.4892 33.0493 16.6428 32.5537 16.6428C32.0581 16.6428 31.5755 16.4892 31.1765 16.2046C30.7775 15.9199 30.483 15.5191 30.3361 15.0607C29.0056 10.9426 27.5445 6.87491 26.1357 2.782C25.6139 1.29137 25.7183 1.19031 27.388 1.13978C29.8665 1.13978 29.8665 1.13978 30.6231 3.43889C31.2216 5.49592 31.9804 7.50603 32.8929 9.45193C33.7017 7.10229 34.5626 4.77793 35.3192 2.42829C35.401 2.03376 35.6294 1.68202 35.9613 1.43984C36.2931 1.19766 36.7052 1.0819 37.1194 1.11452C37.3611 1.07096 37.6094 1.07445 37.8498 1.12478C38.0901 1.17511 38.3177 1.27128 38.5193 1.40768C38.7208 1.54408 38.8923 1.71798 39.0238 1.91923C39.1552 2.12049 39.244 2.34507 39.2848 2.57988C39.9892 4.85372 40.8502 7.1023 41.6329 9.37613C42.3112 9.22454 42.259 8.69398 42.3895 8.34027C43.0417 6.31908 43.6418 4.29789 44.294 2.30197C44.6332 1.24084 47.79 0.609222 48.4944 1.4177C48.8075 1.77141 48.6249 2.15038 48.4944 2.50409C47.0073 6.87491 45.468 11.2205 44.007 15.6166C43.6418 16.7029 42.8069 16.5766 41.972 16.5766C41.1372 16.5766 40.3023 16.7282 39.9632 15.6166C39.337 13.5954 38.6065 11.5994 37.9021 9.60352C37.7455 9.17402 37.7455 8.64345 37.1455 8.23921Z"
        fill="#859AB6"/>
      <path
        d="M20.5278 8.82034C20.5278 6.69809 20.5278 4.60111 20.5278 2.47886C20.5278 0.836646 21.728 1.19035 22.6933 1.13982C23.6586 1.08929 24.5717 1.13982 24.5456 2.52939C24.5456 6.75705 24.5456 10.9679 24.5456 15.1618C24.5456 16.6525 23.6064 16.5514 22.5889 16.5514C21.5714 16.5514 20.4496 16.7282 20.5018 15.1113C20.58 13.0396 20.5278 10.9173 20.5278 8.82034Z"
        fill="#859AB6"/>
      <path
        d="M50.112 8.79494C50.112 6.69796 50.112 4.57571 50.112 2.47872C50.112 1.19021 50.8425 1.06389 51.8078 1.16495C52.7732 1.26601 54.0255 0.735447 54.0255 2.42819C54.0255 6.72322 54.0255 11.0435 54.0255 15.3385C54.0255 16.2733 53.608 16.5513 52.6949 16.5765C50.0859 16.5765 50.0859 16.7028 50.0859 14.2269L50.112 8.79494Z"
        fill="#859AB6"/>
      <path
        d="M17.4739 14.6058C17.0511 14.2618 16.767 13.7845 16.6715 13.2577C16.5761 12.731 16.6755 12.1885 16.9521 11.7256C17.5191 10.4076 17.6901 8.96138 17.4455 7.55339C17.2008 6.1454 16.5505 4.83295 15.5694 3.7672C14.6955 2.67129 13.5282 1.82814 12.1944 1.3293C10.8606 0.830448 9.41115 0.694958 8.00342 0.937536C6.41116 1.11721 4.91426 1.76602 3.7151 2.79623C2.51595 3.82645 1.67237 5.18839 1.29841 6.69793C0.824221 8.16148 0.819742 9.72992 1.28556 11.196C1.75139 12.6621 2.66559 13.9568 3.90736 14.909C5.08682 15.9516 6.5586 16.6327 8.13718 16.8665C9.71576 17.1003 11.3305 16.8763 12.7778 16.2228C13.1952 16.0459 13.6649 15.5406 14.1084 16.0207C14.5519 16.5007 15.752 16.5007 16.7173 16.5765C17.6826 16.6523 18.3871 16.9555 18.7262 16.2986C19.0654 15.6417 17.9696 15.0606 17.4739 14.6058ZM12.256 10.3361C11.9609 10.3108 11.6641 10.3108 11.369 10.3361C10.8994 10.3361 10.2471 10.1592 9.98623 10.6392C9.72533 11.1193 10.2993 11.4983 10.6124 11.8267C11.4733 12.7362 11.0559 13.0647 10.0123 13.2415C9.37507 13.3275 8.72609 13.2765 8.11147 13.0922C7.49685 12.9079 6.93166 12.5949 6.45603 12.1753C5.98041 11.7557 5.606 11.2398 5.35941 10.6644C5.11282 10.0889 5.00009 9.46787 5.02921 8.84544C5.00419 8.27023 5.10099 7.69613 5.31367 7.1585C5.52634 6.62088 5.85039 6.13109 6.26586 5.71928C6.68133 5.30747 7.17944 4.98237 7.72952 4.76397C8.2796 4.54557 8.87002 4.4385 9.46443 4.44935C10.0436 4.46891 10.613 4.59883 11.1403 4.83168C11.6675 5.06452 12.1422 5.39573 12.5372 5.80637C12.9321 6.217 13.2396 6.699 13.4421 7.22481C13.6445 7.75061 13.738 8.30991 13.717 8.87071C13.6127 9.8813 13.2474 10.4371 12.1517 10.3361H12.256Z"
        fill="#859AB6"/>
    </svg>
  )
}
