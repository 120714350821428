import { REMOVE_SCREENSHOT, SET_SCREENSHOTS, UPDATE_SCREENSHOT_DATA, CLEAR_SCREENSHOTS } from './actionTypes'
import initialState from './initialState'

const updateState = (state, screenshotType, payload, taskId) => {
  let result
  const initScreenshots = state.screenshots
  let screenshotData = initScreenshots[`${taskId}-${screenshotType}`]
  if (screenshotData) {
    screenshotData = {
      ...screenshotData,
      ...payload
    }
    result = {
      ...initScreenshots,
      [`${taskId}-${screenshotType}`]: screenshotData
    }
  } else {
    result = initScreenshots
    result[`${taskId}-${screenshotType}`] = {
      type: screenshotType,
      ...payload
    }
  }
  return {
    ...state,
    screenshots: result
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SCREENSHOTS:
      return {
        ...state,
        screenshots: action.screenshots
      }
    case UPDATE_SCREENSHOT_DATA:
      return updateState(state, action.screenshotType, action.payload, action.taskId)
    case REMOVE_SCREENSHOT:
      const initScreenshots = state.screenshots
      delete initScreenshots[`${action.taskId}-${action.screenshotType}`]
      return {
        ...state,
        screenshots: initScreenshots
      }
    case CLEAR_SCREENSHOTS:
      return {
        ...state,
        screenshots: []
      }
    default:
      return state
  }
}
