import React from "react";
import Paragraph, {Align, TextSize} from "../../common_kit/text/Paragraph";
import {useTranslation} from "react-i18next";


export default function CleanAccrualsStory() {

  const {t} = useTranslation()

  return <div className='clean-story'>
    <svg width="711" height="430" viewBox="0 0 711 430" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M449.848 398.769H78.8258C27.2303 341.425 9.22401 276.444 28.0099 262.918C53.6996 244.367 125.077 316.411 150.212 303.655C222.012 267.201 85.5324 154.486 132.873 140.725C176.428 128.073 209.491 231.349 246.95 202.324C272.931 182.205 249.233 149.47 253.986 124.053C261.5 83.6631 307.413 74.6271 363.151 156.656C380.678 182.458 404.846 149.573 431.719 140.237C456.704 131.567 518.576 188.188 518.576 277.787C518.604 315.218 499.001 361.685 449.848 398.769Z"
        fill="#E3F0FF"/>
      <path
        d="M559.595 400.638C642.912 400.638 710.455 333.095 710.455 249.777C710.455 166.46 642.912 98.9172 559.595 98.9172C476.277 98.9172 408.734 166.46 408.734 249.777C408.734 333.095 476.277 400.638 559.595 400.638Z"
        fill="#FFD259"/>
      <path
        d="M664.176 358.51L662.927 357.158C663.622 356.491 664.307 355.814 664.993 355.129C671.604 348.501 677.579 341.268 682.84 333.525L684.399 334.577C679.055 342.416 672.989 349.737 666.28 356.444C665.585 357.148 664.88 357.834 664.176 358.51Z"
        fill="#0958BF"/>
      <path
        d="M687.714 329.467L686.127 328.472C686.7 327.532 687.273 326.593 687.827 325.654L689.443 326.593C688.879 327.579 688.287 328.528 687.714 329.467Z"
        fill="#0958BF"/>
      <path
        d="M689.443 326.631L687.828 325.691C700.394 304.362 707.466 280.245 708.408 255.507L710.286 255.582C709.333 280.626 702.171 305.041 689.443 326.631Z"
        fill="#0958BF"/>
      <path
        d="M707.788 235.068C704.392 200.819 689.257 168.798 664.946 144.436C648.51 127.87 628.372 115.446 606.193 108.188L606.776 106.404C629.233 113.753 649.624 126.331 666.271 143.102C690.891 167.771 706.22 200.197 709.657 234.88L707.788 235.068Z"
        fill="#0958BF"/>
      <path
        d="M417.883 301.504C417.545 300.565 417.216 299.626 416.897 298.687L418.672 298.085C418.991 299.025 419.32 299.964 419.611 300.847L417.883 301.504Z"
        fill="#0958BF"/>
      <path
        d="M559.604 400.647C529.45 400.641 499.989 391.6 475.023 374.69C450.057 357.78 430.73 333.778 419.536 305.778L421.283 305.083C429.148 324.713 441.097 342.446 456.338 357.105C471.579 371.764 489.763 383.015 509.684 390.109C529.605 397.204 550.807 399.981 571.883 398.257C592.959 396.532 613.427 390.344 631.93 380.105L632.869 381.749C610.449 394.17 585.234 400.674 559.604 400.647Z"
        fill="#0958BF"/>
      <path
        d="M559.604 386.558C541.631 386.613 523.826 383.102 507.219 376.229C490.612 369.356 475.533 359.256 462.857 346.516C409.523 293.182 409.523 206.41 462.857 153.086C475.557 140.385 490.635 130.31 507.229 123.436C523.824 116.562 541.609 113.024 559.571 113.024C577.533 113.024 595.319 116.562 611.913 123.436C628.507 130.31 643.585 140.385 656.286 153.086C709.609 206.41 709.609 293.182 656.286 346.516C643.616 359.248 628.548 369.342 611.953 376.215C595.358 383.088 577.566 386.603 559.604 386.558Z"
        fill="#FFC359"/>
      <path
        d="M472.841 336.531C458.577 322.265 448.05 304.702 442.195 285.396C436.339 266.091 435.335 245.639 439.27 225.853C443.206 206.066 451.961 187.556 464.759 171.961C477.557 156.366 494.003 144.167 512.641 136.446C531.279 128.725 551.533 125.719 571.61 127.695C591.687 129.671 610.967 136.568 627.742 147.774C644.518 158.98 658.27 174.151 667.783 191.942C677.295 209.732 682.273 229.594 682.276 249.768C682.276 317.398 627.233 372.468 559.604 372.468C543.484 372.513 527.515 369.36 512.622 363.192C497.729 357.023 484.208 347.961 472.841 336.531Z"
        fill="#FFD259"/>
      <path
        d="M472.841 336.531C449.834 313.523 436.909 282.317 436.91 249.779C436.911 217.241 449.837 186.036 472.846 163.029C495.854 140.022 527.06 127.097 559.598 127.098C592.136 127.099 623.34 140.025 646.348 163.034L472.841 336.531Z"
        fill="#FFC359"/>
      <path
        d="M546.022 317.022C539.467 317.026 533.066 315.037 527.668 311.32C519.008 305.373 511.519 297.881 505.576 289.219C501.285 282.988 499.311 275.451 499.997 267.917C500.684 260.383 503.987 253.327 509.333 247.974L513.297 244.01L517.289 247.993L513.315 251.966C508.898 256.384 506.166 262.209 505.595 268.43C505.023 274.651 506.648 280.877 510.188 286.025C515.75 294.122 522.755 301.127 530.852 306.689C535.769 310.09 541.683 311.746 547.65 311.393C553.618 311.04 559.296 308.699 563.777 304.742C568.258 300.785 571.285 295.441 572.374 289.563C573.463 283.685 572.552 277.611 569.786 272.311L551.742 237.473C548.426 231.061 547.347 223.726 548.676 216.631C550.005 209.536 553.666 203.088 559.078 198.312C564.491 193.535 571.343 190.704 578.548 190.267C585.753 189.83 592.898 191.812 598.848 195.9C607.513 201.853 615.008 209.352 620.959 218.02C625.25 224.251 627.223 231.787 626.537 239.321C625.851 246.856 622.548 253.912 617.202 259.264L613.238 263.228L609.246 259.246L613.219 255.272C617.635 250.854 620.366 245.029 620.937 238.808C621.508 232.587 619.884 226.363 616.347 221.214C610.788 213.114 603.782 206.108 595.682 200.549C590.767 197.163 584.861 195.519 578.904 195.878C572.947 196.237 567.281 198.578 562.808 202.529C558.335 206.481 555.312 211.814 554.221 217.682C553.13 223.549 554.033 229.613 556.786 234.908L574.83 269.747C577.386 274.684 578.627 280.197 578.435 285.753C578.243 291.309 576.624 296.723 573.734 301.472C570.844 306.221 566.779 310.147 561.932 312.87C557.085 315.593 551.619 317.023 546.059 317.022H546.022Z"
        fill="#FF9D45"/>
      <path d="M624.33 188.585L606.942 205.974L610.927 209.959L628.316 192.57L624.33 188.585Z" fill="#FF9D45"/>
      <path d="M515.779 297.12L498.391 314.509L502.376 318.494L519.764 301.105L515.779 297.12Z" fill="#FF9D45"/>
      <path
        d="M542.265 313.255C535.711 313.266 529.309 311.281 523.911 307.563C515.251 301.613 507.759 294.121 501.809 285.461C497.522 279.229 495.55 271.693 496.237 264.16C496.923 256.627 500.224 249.571 505.567 244.217L509.53 240.253L513.522 244.236L509.549 248.209C505.131 252.626 502.4 258.451 501.828 264.673C501.257 270.894 502.882 277.119 506.421 282.268C511.984 290.365 518.989 297.37 527.086 302.932C532.002 306.338 537.918 307.998 543.888 307.647C549.859 307.296 555.539 304.955 560.023 300.997C564.507 297.039 567.535 291.692 568.623 285.811C569.712 279.931 568.799 273.855 566.029 268.554L547.985 233.715C544.669 227.304 543.59 219.968 544.919 212.873C546.248 205.778 549.91 199.331 555.322 194.554C560.734 189.778 567.586 186.946 574.791 186.509C581.997 186.072 589.141 188.055 595.091 192.142C603.759 198.093 611.255 205.592 617.202 214.263C621.493 220.493 623.467 228.03 622.78 235.564C622.094 243.098 618.791 250.154 613.445 255.507L609.481 259.471L605.489 255.488L609.462 251.515C613.878 247.096 616.609 241.272 617.18 235.051C617.751 228.83 616.127 222.605 612.59 217.456C607.027 209.359 600.023 202.354 591.925 196.792C587.009 193.415 581.106 191.779 575.153 192.141C569.201 192.504 563.54 194.846 559.07 198.794C554.601 202.742 551.578 208.071 550.484 213.933C549.389 219.796 550.285 225.856 553.029 231.151L571.073 265.99C573.629 270.926 574.871 276.438 574.68 281.994C574.488 287.55 572.869 292.963 569.978 297.712C567.088 302.461 563.023 306.386 558.176 309.108C553.328 311.83 547.862 313.258 542.302 313.255H542.265Z"
        fill="white"/>
      <path d="M620.567 184.828L603.179 202.217L607.164 206.202L624.552 188.813L620.567 184.828Z" fill="white"/>
      <path d="M512.034 293.411L494.643 310.796L498.627 314.782L516.019 297.396L512.034 293.411Z" fill="white"/>
      <path
        d="M661.856 185.379C653.674 172.491 643.163 161.24 630.859 152.204L631.967 150.682C637.066 154.43 641.873 158.559 646.348 163.034C652.818 169.493 658.551 176.651 663.443 184.374L661.856 185.379Z"
        fill="white"/>
      <path
        d="M617.23 143.543C615.981 142.858 614.703 142.2 613.473 141.571L614.309 139.889C615.586 140.528 616.882 141.204 618.16 141.89L617.23 143.543Z"
        fill="white"/>
      <path
        d="M553.659 372.327C535.754 371.434 518.262 366.628 502.415 358.247C486.568 349.866 472.75 338.113 461.933 323.817C451.116 309.521 443.564 293.028 439.807 275.499C436.05 257.97 436.181 239.831 440.189 222.358C444.197 204.885 451.986 188.502 463.007 174.363C474.028 160.224 488.013 148.671 503.979 140.519C519.946 132.366 537.504 127.812 555.42 127.176C573.336 126.54 591.173 129.837 607.677 136.837L606.945 138.565C590.686 131.644 573.108 128.373 555.448 128.982C537.788 129.592 520.477 134.067 504.734 142.092C488.991 150.117 475.2 161.497 464.332 175.43C453.465 189.363 445.785 205.51 441.834 222.733C437.883 239.956 437.757 257.835 441.466 275.112C445.175 292.389 452.627 308.642 463.298 322.726C473.969 336.811 487.599 348.383 503.228 356.629C518.856 364.874 536.103 369.592 553.753 370.449L553.659 372.327Z"
        fill="white"/>
      <path d="M414.568 139.269L474.889 116.078L478.806 124.598L421.96 155.359L414.568 139.269Z" fill="#FFAFAF"/>
      <path
        d="M271.128 142.322C276.388 138.828 281.545 138.565 285.067 133.314C290.017 125.894 281.779 118.642 289.294 109.832C295.324 102.768 310.108 108.977 311.132 89.1672C311.564 80.7136 324.282 89.0545 330.153 77.426C336.907 64.0505 344.242 76.2519 344.825 61.2702C345.576 42.0052 360.558 26.4035 379.842 26.5162C385.37 26.5378 390.813 27.8708 395.725 30.4058C400.638 32.9408 404.878 36.6054 408.098 41.0983C411.319 45.5912 413.427 50.7842 414.249 56.2504C415.072 61.7167 414.585 67.3001 412.83 72.5417C412.717 72.8893 412.595 73.218 412.473 73.5655C411.741 75.521 410.836 77.4071 409.768 79.2013C400.722 95.686 409.608 102.89 408.359 111.344C406.151 126.222 387.694 132.647 387.694 146.211C387.694 151.724 386.99 179.321 364.682 194.819C336.334 214.544 313.02 208.909 297.522 205.151C255.441 195.12 249.684 156.562 271.128 142.322Z"
        fill="#0958BF"/>
      <path
        d="M471.113 128.759L466.726 119.206L418.71 137.682L378.677 113.335L367.405 134.939L414.464 155.839C415.672 156.321 416.972 156.532 418.27 156.456C419.569 156.379 420.835 156.018 421.978 155.397L471.113 128.759Z"
        fill="#FFD4CC"/>
      <path
        d="M488.669 113.241C487.946 113.17 487.216 113.217 486.508 113.382L475.519 115.9C474.451 116.161 473.509 116.788 472.856 117.672C472.203 118.556 471.881 119.642 471.945 120.739C472.01 121.836 472.458 122.876 473.21 123.677C473.963 124.478 474.972 124.99 476.063 125.124L487.279 126.326C489.014 126.509 490.75 125.995 492.107 124.898C493.463 123.8 494.328 122.209 494.511 120.474C494.694 118.739 494.181 117.002 493.083 115.646C491.986 114.289 490.394 113.425 488.659 113.241H488.669Z"
        fill="#FFAFAF"/>
      <path
        d="M487.983 126.354C487.751 126.368 487.519 126.368 487.288 126.354L478.834 125.462L479.031 123.583L487.485 124.522C488.724 124.647 489.963 124.274 490.927 123.486C491.891 122.698 492.503 121.558 492.628 120.319C492.752 119.08 492.379 117.842 491.591 116.877C490.803 115.913 489.664 115.301 488.424 115.176C488.265 115.167 488.105 115.167 487.945 115.176V113.298C488.171 113.298 488.396 113.298 488.622 113.298C490.365 113.389 492.002 114.169 493.171 115.466C494.339 116.763 494.945 118.472 494.854 120.216C494.763 121.96 493.983 123.596 492.686 124.765C491.388 125.933 489.68 126.539 487.936 126.448L487.983 126.354Z"
        fill="#FF809F"/>
      <path
        d="M417.77 156.43C416.648 156.429 415.535 156.215 414.492 155.801L414.295 155.726L391.62 145.675L392.381 143.957L415.178 154.054C416.129 154.426 417.15 154.588 418.17 154.528C419.19 154.468 420.185 154.188 421.086 153.706L437.279 144.943L438.219 146.596L422.025 155.36C420.718 156.066 419.256 156.434 417.77 156.43Z"
        fill="#FFAFAF"/>
      <path d="M446.057 140.137L441.373 142.671L442.266 144.324L446.951 141.79L446.057 140.137Z" fill="#FFAFAF"/>
      <path d="M369.791 428.121H340.805V430H369.791V428.121Z" fill="#4792F3"/>
      <path d="M155.707 414.023H95.7424V415.901H155.707V414.023Z" fill="#4792F3"/>
      <path d="M412.332 413.807H212.131V415.685H412.332V413.807Z" fill="#4792F3"/>
      <path d="M521.309 428.121H414.37V430H521.309V428.121Z" fill="#4792F3"/>
      <path d="M620.085 413.807H443.826V415.685H620.085V413.807Z" fill="#4792F3"/>
      <path d="M696.121 398.769H565.615V400.647H696.121V398.769Z" fill="#6AACFF"/>
      <path d="M546.83 398.769H0V400.647H546.83V398.769Z" fill="#6AACFF"/>
      <path d="M249.712 0H247.833V5.26945H249.712V0Z" fill="#4792F3"/>
      <path d="M249.712 31.6167H247.833V36.8861H249.712V31.6167Z" fill="#4792F3"/>
      <path d="M240.363 1.99479L238.737 2.93408L241.371 7.49756L242.998 6.55826L240.363 1.99479Z" fill="#4792F3"/>
      <path d="M256.172 29.3749L254.545 30.3142L257.18 34.8777L258.807 33.9384L256.172 29.3749Z" fill="#4792F3"/>
      <path d="M233.273 8.42265L232.333 10.0496L236.897 12.6843L237.836 11.0574L233.273 8.42265Z" fill="#4792F3"/>
      <path d="M260.644 24.2032L259.704 25.8301L264.268 28.4648L265.207 26.8379L260.644 24.2032Z" fill="#4792F3"/>
      <path d="M235.594 17.499H230.325V19.3776H235.594V17.499Z" fill="#4792F3"/>
      <path d="M267.211 17.499H261.941V19.3776H267.211V17.499Z" fill="#4792F3"/>
      <path d="M236.89 24.2323L232.326 26.8662L233.265 28.4933L237.829 25.8594L236.89 24.2323Z" fill="#4792F3"/>
      <path d="M264.28 8.42516L259.716 11.0591L260.655 12.6862L265.219 10.0522L264.28 8.42516Z" fill="#4792F3"/>
      <path d="M241.359 29.3606L238.725 33.9246L240.352 34.8636L242.986 30.2996L241.359 29.3606Z" fill="#4792F3"/>
      <path d="M257.171 1.97807L254.537 6.54199L256.164 7.481L258.798 2.91708L257.171 1.97807Z" fill="#4792F3"/>
      <path
        d="M74.5426 102.984H72.664C72.6615 100.182 71.5477 97.4943 69.5668 95.5117C67.5859 93.529 64.8996 92.4128 62.0969 92.4078V90.5292C64.898 90.5243 67.5829 89.4094 69.5635 87.4287C71.5441 85.4481 72.659 82.7632 72.664 79.9622H74.5426C74.5476 82.7648 75.6638 85.4511 77.6464 87.432C79.6291 89.4129 82.3164 90.5267 85.1191 90.5292V92.4078C82.3148 92.4103 79.626 93.5254 77.6431 95.5083C75.6602 97.4913 74.5451 100.18 74.5426 102.984ZM66.8404 91.4685C69.9031 92.7404 72.3356 95.1763 73.6033 98.2408C74.8749 95.1757 77.3105 92.7401 80.3756 91.4685C77.3111 90.2009 74.8752 87.7684 73.6033 84.7056C72.9755 86.2218 72.0551 87.5995 70.8947 88.7599C69.7343 89.9203 68.3566 90.8407 66.8404 91.4685Z"
        fill="#4792F3"/>
      <path
        d="M99.4527 265.041C98.1384 265.052 96.8504 264.672 95.7522 263.95C94.6541 263.228 93.7954 262.196 93.2851 260.984C92.7748 259.773 92.636 258.438 92.8862 257.147C93.1364 255.857 93.7645 254.67 94.6905 253.737C95.6166 252.805 96.799 252.168 98.0875 251.909C99.376 251.649 100.713 251.779 101.927 252.28C103.142 252.782 104.181 253.633 104.911 254.726C105.641 255.819 106.03 257.105 106.028 258.419C106.025 260.166 105.333 261.842 104.102 263.082C102.871 264.322 101.2 265.026 99.4527 265.041ZM99.4527 253.666C98.5103 253.655 97.5859 253.924 96.797 254.44C96.008 254.955 95.39 255.693 95.0216 256.561C94.6533 257.428 94.5511 258.385 94.728 259.311C94.905 260.237 95.3532 261.089 96.0156 261.759C96.678 262.43 97.5247 262.888 98.4482 263.076C99.3716 263.264 100.33 263.173 101.202 262.815C102.074 262.457 102.819 261.848 103.344 261.065C103.869 260.282 104.149 259.361 104.149 258.419C104.147 257.169 103.653 255.97 102.774 255.081C101.896 254.191 100.703 253.683 99.4527 253.666Z"
        fill="#4792F3"/>
      <path
        d="M656.465 70.6069L659.405 82.4796L671.277 85.429L659.405 88.3784L656.465 100.251L653.515 88.3784L641.643 85.429L653.515 82.4796L656.465 70.6069Z"
        fill="#FFE159"/>
      <path
        d="M559.595 23.8018L564.366 43.0385L583.603 47.8102L564.366 52.5818L559.595 71.8186L554.823 52.5818L535.586 47.8102L554.823 43.0385L559.595 23.8018Z"
        fill="#FFE159"/>
      <path
        d="M476.805 65.5442L479.05 74.5802L488.077 76.8251L479.05 79.0607L476.805 88.0967L474.56 79.0607L465.534 76.8251L474.56 74.5802L476.805 65.5442Z"
        fill="#FFE159"/>
      <path d="M639.651 398.769H492.811V400.647H639.651V398.769Z" fill="#0958BF"/>
      <path d="M477.791 398.769H462.603V400.647H477.791V398.769Z" fill="#0958BF"/>
      <path d="M422.88 398.769H330.313V400.647H422.88V398.769Z" fill="#0958BF"/>
      <path d="M322.329 398.769H312.231V400.647H322.329V398.769Z" fill="#0958BF"/>
      <path d="M275.909 398.769H180.11V400.647H275.909V398.769Z" fill="#0958BF"/>
      <path d="M168.134 398.769H143.552V400.647H168.134V398.769Z" fill="#0958BF"/>
      <path
        d="M377.23 112.631L335.723 94.2303C334.675 93.7662 333.535 93.5481 332.389 93.5926C331.244 93.637 330.124 93.9429 329.115 94.4868C328.106 95.0307 327.235 95.7982 326.568 96.7306C325.902 97.6629 325.457 98.7355 325.269 99.8661L312.701 172.755C311.539 175.378 310.905 178.203 310.835 181.071C310.765 183.938 311.26 186.792 312.293 189.467C313.326 192.143 314.876 194.59 316.854 196.667C318.832 198.744 321.2 200.411 323.822 201.573C326.445 202.735 329.27 203.369 332.138 203.439C335.005 203.509 337.858 203.013 340.534 201.981C343.21 200.948 345.656 199.398 347.734 197.42C349.811 195.442 351.478 193.074 352.64 190.452L383.204 129.285C383.948 127.806 384.383 126.192 384.483 124.541C384.583 122.889 384.347 121.234 383.788 119.677C383.23 118.119 382.36 116.692 381.233 115.481C380.106 114.269 378.744 113.3 377.23 112.631Z"
        fill="#FFD4CC"/>
      <path
        d="M353.401 188.395L335.113 182.327L311.94 174.625L309.122 175.62C304.442 177.275 300.202 179.981 296.729 183.528C293.256 187.076 290.642 191.372 289.087 196.087L286.128 205.001C285.043 208.313 285.316 211.921 286.889 215.031C288.462 218.142 291.205 220.5 294.516 221.589L330.529 233.518C333.838 234.615 337.448 234.352 340.564 232.788C343.68 231.224 346.047 228.486 347.145 225.177L351.193 212.967C353.808 205.054 354.563 196.646 353.401 188.395Z"
        fill="#6AACFF"/>
      <path
        d="M377.24 112.631C375.434 111.832 373.461 111.483 371.49 111.613C369.52 111.744 367.61 112.351 365.926 113.382C364.241 114.412 362.832 115.836 361.818 117.531C360.805 119.226 360.217 121.142 360.107 123.114L358.679 148.907C358.484 150.565 358.631 152.245 359.11 153.844C359.59 155.443 360.392 156.926 361.468 158.203C362.544 159.48 363.87 160.522 365.364 161.266C366.859 162.01 368.49 162.44 370.157 162.528C371.824 162.617 373.491 162.363 375.056 161.782C376.621 161.201 378.05 160.306 379.255 159.15C380.461 157.995 381.416 156.605 382.063 155.066C382.71 153.527 383.034 151.872 383.016 150.203L384.444 124.41C384.572 121.949 383.952 119.507 382.666 117.404C381.381 115.302 379.489 113.638 377.24 112.631Z"
        fill="#FFD4CC"/>
      <path
        d="M370.852 161.765C369.15 161.765 367.466 161.407 365.912 160.713C363.462 159.615 361.439 157.745 360.154 155.387L361.807 154.448C362.889 156.445 364.599 158.03 366.673 158.957C367.987 159.546 369.412 159.85 370.852 159.849C372.854 159.849 374.813 159.268 376.49 158.176C378.168 157.083 379.492 155.527 380.302 153.697C380.803 152.553 381.096 151.328 381.166 150.08L381.232 149.141L382.021 134.892L383.899 134.995L383.016 150.203C382.841 153.321 381.477 156.253 379.206 158.397C376.935 160.541 373.929 161.733 370.805 161.728L370.852 161.765Z"
        fill="#FFAFAF"/>
      <path
        d="M311.83 209.427C311.974 202.166 306.205 196.163 298.944 196.018C291.683 195.874 285.679 201.643 285.535 208.904C285.39 216.165 291.159 222.169 298.42 222.313C305.682 222.458 311.685 216.688 311.83 209.427Z"
        fill="#6AACFF"/>
      <path
        d="M367.312 70.7854L353.457 102.055C352.875 103.3 352.547 104.648 352.492 106.022C352.438 107.395 352.658 108.766 353.14 110.053C353.622 111.34 354.356 112.519 355.299 113.519C356.242 114.519 357.375 115.321 358.631 115.878C359.888 116.434 361.243 116.735 362.617 116.761C363.992 116.788 365.357 116.54 366.635 116.032C367.912 115.524 369.075 114.766 370.056 113.803C371.036 112.84 371.815 111.691 372.346 110.424L386.201 79.1639L367.312 70.7854Z"
        fill="#FFAFAF"/>
      <path
        d="M363.93 74.0352L368.626 73.7816L368.128 64.3887L363.432 64.6329C362.816 64.6653 362.212 64.8189 361.655 65.0848C361.098 65.3507 360.599 65.7237 360.187 66.1826C359.774 66.6414 359.456 67.1771 359.25 67.7589C359.045 68.3408 358.956 68.9574 358.989 69.5736C359.019 70.1914 359.171 70.7972 359.436 71.3562C359.701 71.9152 360.073 72.4164 360.532 72.831C360.991 73.2456 361.528 73.5654 362.111 73.772C362.694 73.9787 363.312 74.0681 363.93 74.0352Z"
        fill="#FFAFAF"/>
      <path
        d="M398.675 44.9077L363.423 46.7863L365.827 92.2952L387.713 91.1117C389.786 91.0031 391.814 90.4717 393.674 89.55C395.534 88.6283 397.186 87.3358 398.527 85.7522C399.869 84.1685 400.873 82.3271 401.476 80.3411C402.08 78.3552 402.271 76.2668 402.037 74.2043L398.675 44.9077Z"
        fill="#FFAFAF"/>
      <path
        d="M364.372 64.6049H363.432L361.995 37.5156L389.92 36.0315C391.677 35.9352 393.435 36.1863 395.094 36.7703C396.753 37.3544 398.281 38.2599 399.59 39.4352C400.898 40.6104 401.962 42.0322 402.721 43.6193C403.479 45.2063 403.917 46.9273 404.01 48.6839V49.2099L403.071 49.2568C395.083 49.6804 387.452 52.6978 381.335 57.8514C376.564 61.8724 370.612 64.2271 364.381 64.558L364.372 64.6049Z"
        fill="#0958BF"/>
      <path d="M366.354 131.539L375.164 111.673L344.872 98.2502L366.354 131.539Z" fill="#FFAFAF"/>
      <path
        d="M285.893 205.95L269.362 271.907L240.131 388.85C239.776 390.372 240.018 391.973 240.809 393.323C241.599 394.672 242.877 395.666 244.379 396.101C245.881 396.536 247.492 396.378 248.881 395.66C250.27 394.941 251.331 393.717 251.844 392.24L289.754 277.815L311.132 213.267L285.893 205.95Z"
        fill="#6AACFF"/>
      <path d="M277.749 238.534L276.776 242.415L278.598 242.872L279.571 238.991L277.749 238.534Z" fill="#0958BF"/>
      <path
        d="M243.4 383.515L241.577 383.054L269.352 271.954L269.371 271.907H269.362L276.003 245.391L277.825 245.851L271.616 270.649L271.86 270.733L271.128 272.593L243.4 383.515Z"
        fill="#0958BF"/>
      <path
        d="M420.034 178.316C420.092 179.243 419.873 180.167 419.406 180.971C418.938 181.774 418.243 182.42 417.408 182.828C416.573 183.236 415.636 183.387 414.715 183.262C413.794 183.137 412.931 182.741 412.235 182.126C411.539 181.51 411.041 180.701 410.805 179.803C410.568 178.904 410.604 177.955 410.907 177.077C411.21 176.198 411.767 175.429 412.507 174.867C413.247 174.305 414.137 173.976 415.065 173.92C416.307 173.846 417.527 174.266 418.459 175.091C419.39 175.915 419.957 177.075 420.034 178.316Z"
        fill="#FFAFAF"/>
      <path
        d="M433.278 179.387C433.226 178.524 433.003 177.681 432.625 176.904C432.246 176.128 431.718 175.434 431.07 174.861C430.423 174.289 429.669 173.85 428.852 173.569C428.035 173.289 427.171 173.172 426.309 173.225L416.202 173.845L358.069 148.324L352.02 164.959L414.013 183.078H414.079L424.937 186.075C425.637 186.269 426.364 186.345 427.088 186.3C428.825 186.201 430.451 185.417 431.611 184.122C432.771 182.826 433.371 181.123 433.278 179.387Z"
        fill="#FFAFAF"/>
      <path
        d="M426.731 186.366C426.138 186.364 425.547 186.282 424.975 186.121L414.107 183.134L409.317 181.744L409.843 179.941L414.539 181.331L423.341 183.754L425.398 184.309C426.01 184.487 426.652 184.539 427.285 184.462C427.917 184.385 428.528 184.179 429.079 183.858C429.63 183.537 430.109 183.107 430.489 182.595C430.868 182.082 431.139 181.498 431.285 180.877C431.431 180.257 431.45 179.613 431.339 178.985C431.228 178.357 430.991 177.758 430.641 177.225C430.292 176.692 429.837 176.236 429.306 175.884C428.774 175.532 428.176 175.292 427.549 175.179L427.915 173.3C429.479 173.612 430.877 174.483 431.847 175.749C432.817 177.016 433.294 178.592 433.188 180.184C433.082 181.776 432.402 183.275 431.272 184.402C430.143 185.529 428.643 186.207 427.051 186.309L426.731 186.366Z"
        fill="#FF809F"/>
      <path
        d="M252.163 390.315H239.952V398.768H257.057C257.752 398.768 258.419 398.492 258.91 398.001C259.401 397.509 259.678 396.843 259.678 396.148C259.676 395.687 259.554 395.235 259.323 394.836C259.092 394.437 258.761 394.106 258.363 393.875L252.163 390.315Z"
        fill="#0958BF"/>
      <path
        d="M395.124 267.615C395.124 267.352 395.171 267.098 395.18 266.835C395.222 265.369 394.957 263.91 394.404 262.552C393.851 261.194 393.022 259.965 391.968 258.945L343.218 211.539L325.607 230.982L373.802 271.372L370.599 389.855C370.473 391.345 370.899 392.83 371.797 394.027C372.695 395.223 374.001 396.048 375.468 396.344C376.934 396.64 378.458 396.386 379.75 395.631C381.041 394.876 382.01 393.673 382.472 392.25C382.674 391.644 382.779 391.01 382.782 390.371L395.133 267.615H395.124Z"
        fill="#6AACFF"/>
      <path d="M373.496 282.718L373.245 292.032L375.123 292.082L375.373 282.768L373.496 282.718Z" fill="#0958BF"/>
      <path d="M373.141 295.437L370.781 383.22L372.659 383.27L375.019 295.487L373.141 295.437Z" fill="#0958BF"/>
      <path
        d="M361.413 149.789L342.477 101.068C341.33 98.188 339.13 95.8519 336.324 94.5338C333.518 93.2156 330.316 93.0141 327.367 93.97C324.418 94.926 321.943 96.9678 320.444 99.6814C318.945 102.395 318.534 105.577 319.295 108.583C319.535 109.516 319.892 110.415 320.356 111.26L346.657 160.61C347.591 162.366 349.09 163.756 350.912 164.555C351.268 164.702 351.632 164.83 352.001 164.94L405.635 180.645L409.223 170.764L361.413 149.789Z"
        fill="#FFD4CC"/>
      <path d="M368.822 153.018L368.067 154.738L381.878 160.804L382.634 159.084L368.822 153.018Z" fill="#FFAFAF"/>
      <path
        d="M326.819 123.395L320.356 111.25C319.903 110.405 319.55 109.51 319.304 108.583C318.49 105.449 318.954 102.12 320.594 99.328C322.234 96.5361 324.915 94.5099 328.049 93.6948L328.519 95.5077C327.205 95.8489 325.971 96.4456 324.888 97.2639C323.804 98.0822 322.893 99.1059 322.206 100.277C321.518 101.447 321.068 102.742 320.882 104.086C320.695 105.431 320.775 106.799 321.117 108.113C321.342 108.901 321.657 109.661 322.056 110.377L322.517 111.316L328.509 122.588L326.819 123.395Z"
        fill="#FFAFAF"/>
      <path d="M330.995 127.255L329.337 128.139L330.623 130.551L332.281 129.667L330.995 127.255Z" fill="#FFAFAF"/>
      <path
        d="M394.119 177.283L352.02 164.94C351.654 164.841 351.296 164.715 350.949 164.565C349.122 163.77 347.62 162.375 346.694 160.61L332.238 133.521L333.891 132.638L348.357 159.727C349.083 161.115 350.263 162.212 351.7 162.836C351.981 162.96 352.27 163.064 352.565 163.146L353.551 163.447L394.664 175.479L394.119 177.283Z"
        fill="#FFAFAF"/>
      <path
        d="M382.8 390.315H370.59V398.768H387.713C388.408 398.768 389.075 398.492 389.566 398.001C390.058 397.509 390.334 396.843 390.334 396.148C390.335 395.686 390.214 395.233 389.983 394.834C389.752 394.435 389.419 394.104 389.019 393.875L382.8 390.315Z"
        fill="#0958BF"/>
      <path d="M316.997 145.96L312.393 172.769L314.253 173.088L318.856 146.279L316.997 145.96Z" fill="#FFAFAF"/>
      <path
        d="M386.022 77.3698C386.057 78.0482 386.224 78.7132 386.516 79.3267C386.807 79.9403 387.217 80.4904 387.721 80.9457C388.225 81.401 388.814 81.7526 389.454 81.9803C390.094 82.208 390.773 82.3074 391.451 82.2729C392.13 82.2383 392.794 82.0705 393.408 81.779C394.022 81.4875 394.572 81.0779 395.027 80.5738C395.482 80.0696 395.834 79.4808 396.062 78.8408C396.289 78.2008 396.389 77.5222 396.354 76.8438L386.022 77.3698Z"
        fill="white"/>
      <path
        d="M334.662 234.223C333.256 234.223 331.861 233.994 330.529 233.546L311.874 227.375L312.466 225.591L331.12 231.771C332.814 232.333 334.617 232.484 336.38 232.213C338.144 231.942 339.818 231.256 341.265 230.212L342.364 231.734C340.124 233.357 337.428 234.228 334.662 234.223Z"
        fill="#0958BF"/>
    </svg>

    <Paragraph
      className='clean-story__text'
      text={t('Empty profit history')}
      size={TextSize.P_18}
      color='#859AB6'
      align={Align.CENTER}
    />
  </div>
}
