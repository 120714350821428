import React from 'react'

export default function SignupSocialInstagramBackground() {
  return (
    <div className="social-background-img">
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="15" fill="#D21988"/>
        <rect width="48" height="48" rx="15" fill="url(#paint0_radial_9918_12004)"/>
        <rect width="48" height="48" rx="15" fill="url(#paint1_radial_9918_12004)" fillOpacity="0.8"/>
        <defs>
          <radialGradient id="paint0_radial_9918_12004" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                          gradientTransform="translate(10.5 48) rotate(-23.1063) scale(40.7707 33.1184)">
            <stop stopColor="#FEDA77"/>
            <stop offset="0.616854" stopColor="#F16041" stopOpacity="0.9"/>
            <stop offset="1" stopColor="#F16041" stopOpacity="0"/>
          </radialGradient>
          <radialGradient id="paint1_radial_9918_12004" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                          gradientTransform="translate(37 -6) rotate(126.69) scale(31.8002)">
            <stop stopColor="#4E57D6"/>
            <stop offset="0.911458" stopColor="#4E57D6" stopOpacity="0.1"/>
            <stop offset="1" stopColor="#4E57D6" stopOpacity="0"/>
          </radialGradient>
        </defs>
      </svg>
    </div>
  )
}
