import React from 'react'


type Data = {
  className?: string
  fill?: string,
  width?: number,
  height?: number
}

IconArrowBack.defaultProps = {
  fill: '#000000',
  width: 26,
  height: 20
}

export default function IconArrowBack(props: Data) {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 11.25C24.6904 11.25 25.25 10.6904 25.25 10C25.25 9.30964 24.6904 8.75 24 8.75L24 11.25ZM1.11612 9.11612C0.62796 9.60427 0.627962 10.3957 1.11612 10.8839L9.07107 18.8388C9.55922 19.327 10.3507 19.327 10.8388 18.8388C11.327 18.3507 11.327 17.5592 10.8388 17.0711L3.76777 10L10.8388 2.92893C11.327 2.44078 11.327 1.64932 10.8388 1.16117C10.3507 0.67301 9.55922 0.673011 9.07107 1.16117L1.11612 9.11612ZM24 8.75L2 8.75L2 11.25L24 11.25L24 8.75Z"
        fill={props.fill || props.fill}/>
    </svg>
  )
}
