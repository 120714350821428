import React from 'react'

export default function SignupSocialTelegram() {
  return (
    <div className="social-icon">
      <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.34052 7.10321C6.575 4.7747 10.0655 3.2396 11.8119 2.49791C16.7985 0.380254 17.8346
          0.0123942 18.51 0.000117762C18.6585 -0.00242431 18.9906 0.0351611 19.2058 0.213395C19.3874
          0.363893 19.4374 0.567193 19.4613 0.709881C19.4852 0.852569 19.515 1.17762 19.4913 1.4316C19.2211
          4.33051 18.0519 11.3654 17.457 14.6122C17.2053 15.9861 16.7097 16.4467 16.2299 16.4918C15.1872
          16.5898 14.3954 15.7882 13.3855 15.1123C11.8051 14.0546 10.9123 13.3962 9.37832 12.364C7.60552
          11.1712 8.75475 10.5156 9.76506 9.44423C10.0295 9.16384 14.6237 4.89715 14.7127 4.5101C14.7238
          4.46169 14.7341 4.28125 14.6291 4.18597C14.5241 4.09069 14.3692 4.12327 14.2573 4.14919C14.0988
          4.18592 11.5742 5.88967 6.68349 9.26045C5.96689 9.76287 5.31781 10.0077 4.73626 9.99483C4.09514
          9.98069 2.86189 9.62472 1.9451 9.32044C0.820618 8.94723 -0.0730986 8.74991 0.00472406 8.11609C0.0452589
          7.78596 0.490525 7.44833 1.34052 7.10321Z"
          fill="#859AB6"/>
      </svg>
    </div>
  )
}
