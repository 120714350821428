import {OrderedTaskStep, TaskStep} from "../task/initialState";
import {FileUploadStatus} from "../../utils/helpers";

export enum PublisherTaskType {
  PUBLICATION = 'uploader',
  UPDATE = 'uploader_update',
  VERIFICATION = 'uploader_verification',
  SIMPLE = 'simple',
  USER_SIMPLE = 'user_simple',
}

export interface TaskListItem {
  id: string
  type: PublisherTaskType
  title: string
  description: string
  comment: string | null
  cost: number
  priority: number
  is_internal: boolean
  task_time: number
  complete_in: number
  completed_at: string
  created_at: string
  days_count: number
  status: Status,
  application: {
    id: string
    status: Status,
    marathon?: { id?: number }
    default_locale: {
      icon: string
    }
  }
  calcCompleteAt: string
  available: boolean,
  settings?: SimpleTaskResult,
  google_account_password?: string
}

export interface LocaleMeta {
  description: string
  title: string
}

export interface Status {
  id: string
  name: string
  meta: {
    en: LocaleMeta,
    ru: LocaleMeta,
  }
}


export interface SimpleTaskResult {
  file: boolean
  image: boolean
  link: boolean,
  google_account: boolean
}

export interface TakenSimpleTaskSettings extends SimpleTaskResult {
  remove: boolean
}

export interface TakenSimpleTaskResult {
  file: string | null
  image: string | null
  link: string | null
  google_account: string | null
}

interface ManagerSimpleTask {
  status: Status
}

export interface PublishedSimpleTask {
  id: string
  cost: number
  description: string
  ends_at: string | null
  limit: number
  published_at: string
  remove_after_fail: boolean
  result: SimpleTaskResult
  settings: SimpleTaskResult
  starts_at: string | null
  status: Status
  tag: string
  task_country_show: Array<string>
  task_time: number
  title: string
  user_id: number | null
  calcCompleteAt: string
  type: PublisherTaskType,
  google_account_password: string
}

export interface TakenSimpleTask {
  id: string
  comment: string | null
  complete_at: string | null
  complete_in: number
  completed_at: string | null
  cost: number
  description: string
  parent_id: number
  published_at: string
  result: TakenSimpleTaskResult
  settings: TakenSimpleTaskSettings
  started_at: string
  status_id: number
  status: Status
  task_country_show: Array<string>
  task_time: number
  title: string
  user_id: number
  calcCompleteAt: string
  type: PublisherTaskType
  tag: string,
  google_account_password: string,
  related_manager_simple: ManagerSimpleTask | null
}

export type AnyTaskListItem = TaskListItem | PublishedSimpleTask | TakenSimpleTask

export interface TaskListState {
  publicationTasks: {
    data: Array<TaskListItem> | undefined,
    meta: { [key: string]: any }
  }
  simpleTasks: {
    data: Array<PublishedSimpleTask | TakenSimpleTask>,
    meta: { [key: string]: any }
  }
  completedTasks: {
    data: Array<AnyTaskListItem>,
    meta: { [key: string]: any }
  }
  selectedTask?: AnyTaskListItem,
  selectedSimpleTask?: AnyTaskListItem,
  responseId: {
    publicationTasks: number,
    simpleTasks: number,
    completedTasks: number
  },
  simpleTaskGmail: {
    main_task_type: string,
    steps?: Array<TaskStep>,
    prevIndex?: number,
    currentIndex?: number,
    currentStep?: OrderedTaskStep,
    progress?: number,
    phone?: string,
    countryCode?: string,
    codeVerify?: string,
    code2Auth?: string,
    textFile?: string,
    fileName?: string,
    fileStatus?: FileUploadStatus,
    changePhone?: boolean,
    changePhoneProcess?: boolean
  }
}

const initialStore: TaskListState = {
  publicationTasks: {
    data: undefined,
    meta: {}
  },
  simpleTasks: {
    data: [],
    meta: {}
  },
  completedTasks: {
    data: [],
    meta: {}
  },
  responseId: {
    publicationTasks: 0,
    simpleTasks: 0,
    completedTasks: 0
  },
  simpleTaskGmail: {
    main_task_type: '',
    steps: undefined,
    currentStep: undefined,
    prevIndex: undefined,
    currentIndex: undefined,
    progress: undefined,
    phone: undefined,
    countryCode: undefined,
    codeVerify: undefined,
    code2Auth: undefined,
    textFile: undefined,
    fileName: undefined,
    fileStatus: undefined,
    changePhone: undefined,
    changePhoneProcess: undefined
  }
}

export default initialStore
