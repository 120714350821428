export enum PaymentType {
  MOBILE = 'mc',
  BANK = 'card',
  QIWI_MONEY = 'qiwi',
  WEB_MONEY = 'webmoney',
  YANDEX_MONEY = 'yandex',
  PAYTM = 'paytm',
  USDTTRC20 = 'usdttrc20',
  USDTTRC20_SPECIAL = 'usdttrc20Special'
}

export interface Payment {
  id: number,
  purse: string,
  created_at: string,
  payment_type: PaymentType,
  amount: number,
  confirmed: boolean,
  currency: string,
  meta: {
    amount: number,
  }
}

export interface PaymentsState {
  history: Array<Payment>,
  meta: {
    [key: string]: any
  }

  responseId?: number
}

const initialState: PaymentsState = {
  history: [],
  meta: {},
  responseId: undefined
}

export default initialState
