import { axiosV3 } from '../../axios'
import store from '../index'
import Axios from 'axios'
import { AnyTaskListItem, PublishedSimpleTask, TakenSimpleTask } from './initialState'
import { RootState } from '../rootState'
import { Task } from '../task/initialState'
import FormDataV3 from "../../utils/formDataApiV3"
import {setTaskCompleteModal} from "../modal/actions"
import {getUserId} from "../../utils/helpers"

type Response<T> = {
  data: T
  meta: { [key: string]: any }
}

export type ArrayResponse<T> = {
  data: Array<T>,
  meta: { [key: string]: any },
  included?: Array<{ [key: string]: any }>
}

const getPromiseSettledResultValue = <T>(responseItem: PromiseSettledResult<ArrayResponse<T>>): ArrayResponse<T> => {
  if (responseItem.status === 'fulfilled') return responseItem.value
  return { data: [], meta: {} }
}

export async function downloadPublishTaskList(signal: AbortSignal): Promise<ArrayResponse<Task>> {
  const state: RootState = store.getState()
  const id = state.user.userData?.id ?? state.user.id
  if (!id) return { data: [], meta: {} }

  const publicationInclusions = [
    'status',
    'task.type',
    'application.status',
    'application.default_locale',
    'deletion_reason'
  ].join(',')

  const publicationAggregates = [
    'marathon_price'
  ].join(',')

  // 1. Проверить, что аккаунт верифицирован
  const verified = state.user.userData?.verified

  // 1.1. Если нет:
  if (!verified) {
    const verificationFilters = [
      `filter[feed_for]=${id}`,
      'filter[type]=uploader_verification',
      'filter[status][not_name]=verified'
    ].join('&')

    const publicationFilters = [
      `filter[demo_for]=${id}`,
      'filter[type]=uploader',
      'filter[status][name]=published,assigned'
    ].join('&')

    const verificationUrl = `subtasks?${verificationFilters}&include=status&page[limit]=1`
    const publicationUrl = `subtasks?${publicationFilters}&include=${publicationInclusions}&aggregates=${publicationAggregates}&page[limit]=3&sort=-priority,carousel,-priority2`

    const verificationResponse: Promise<ArrayResponse<Task>> = axiosV3.get(verificationUrl)
    const publicationResponse: Promise<ArrayResponse<Task>> = axiosV3.get(publicationUrl)

    try {
      const response = await Promise.allSettled([
        verificationResponse,
        publicationResponse
      ])

      const verificationTasks = getPromiseSettledResultValue(response[0])
      const publicationTasks = getPromiseSettledResultValue(response[1])

      const unavailablePublicationTasks = publicationTasks.data.map(item => {
        item.available = false
        return item
      })

      const data = [...verificationTasks.data, ...unavailablePublicationTasks]
      const meta = {
        page: {
          current_page: 1,
          last_page: 1,
          total: data.length
        }
      }

      return { data, meta }
    } catch (e) {
      if (Axios.isCancel(e) || e.logout) return { data: [], meta: {} }
      console.info('Error in the method "downloadPublishTaskList":', e)
      return { data: [], meta: {} }
    }
  }

  // 1.2. Если да:
  // 1.2.1. Проверить, что была взята хотя бы одна задача на публикацию
  const takenTasks = await axiosV3.get(`subtasks?filter[used_by]=${id}&filter[type]=uploader`, { signal })
  const hasTakenTasks = !!takenTasks.data.length

  // 1.2.1.1. Если нет:
  if (!hasTakenTasks) {

    const filters = [
      `filter[available_for]=${id}`,
      'filter[type]=uploader',
      'filter[status][name]=published,assigned'
    ].join('&')

    const url = `subtasks?${filters}&include=${publicationInclusions}&aggregates=${publicationAggregates}&page[limit]=3&sort=-priority,carousel,-priority2`

    try {
      const response: ArrayResponse<Task> = await axiosV3.get(url, { signal })

      return {
        data: response.data,
        meta: {
          page: {
            current_page: 1,
            last_page: 1,
            total: response.data.length
          }
        }
      }
    } catch (e) {
      if (Axios.isCancel(e) || e.logout) return { data: [], meta: {} }
      console.info('Error in the method "downloadPublishTaskList":', e)
      return { data: [], meta: {} }
    }
  }

  // 1.2.1.2. Если да:
  const filters = [
    `filter[feed_for]=${id}`,
    'filter[type]=uploader,uploader_update',
    'filter[status][not_name]=completed'
  ].join('&')

  const sortParams = ['-assigned', '-priority', 'carousel', '-priority2']

  const page = state.taskList.simpleTasks.meta.page?.current_page ?? 0
  const url = `subtasks?${filters}&include=${publicationInclusions}&aggregates=${publicationAggregates}&page[limit]=3&page[number]=${page + 1}&sort=${sortParams}`

  try {
    const response: ArrayResponse<Task> = await axiosV3.get(url, { signal })

    const meta = {
      ...response.meta,
      page: {
        ...response.meta.page,
        total: response.data.length
      }
    }
    return {
      data: response.data,
      meta
    }
  } catch (e) {
    if (Axios.isCancel(e) || e.logout) return { data: [], meta: {} }
    console.info('Error in the method "downloadPublishTaskList":', e)
    return { data: [], meta: {} }
  }
}

export async function downloadSimpleTaskList(signal: AbortSignal): Promise<ArrayResponse<PublishedSimpleTask | TakenSimpleTask>> {
  // Получение с бэка списка "Других задач" (во всех статусах, кроме completed)

  const state: RootState = store.getState()

  const id = state.user.userData?.id ?? state.user.id
  if (!id) return { data: [], meta: {} }

  const page = state.taskList.simpleTasks.meta.page?.current_page ?? 0

  const filters = [
    `filter[feed_for]=${id}`,
    'filter[type]=simple,user_simple',
    'filter[status][not_name]=completed'
  ].join('&')

  const url = `subtasks?${filters}&include=status&page[limit]=10&page[number]=${page + 1}`

  try {
    const response: ArrayResponse<PublishedSimpleTask | TakenSimpleTask> = await axiosV3.get(url, { signal })

    return {
      data: response.data,
      meta: response.meta
    }
  } catch (e) {
    if (Axios.isCancel(e) || e.logout) return { data: [], meta: {} }
    console.info('Error in the method "downloadSimpleTaskList":', e)
    return { data: [], meta: {} }
  }
}

export async function downloadCompletedTaskList(signal: AbortSignal): Promise<ArrayResponse<AnyTaskListItem>> {
  const state: RootState = store.getState()
  const id = state.user.userData?.id ?? state.user.id
  if (!id) return { data: [], meta: {} }

  const page = state.taskList.simpleTasks.meta.page?.current_page ?? 0

  const filters = [
    `filter[used_by]=${id}`,
    'filter[status][name]=completed,verified'
  ].join('&')

  const inclusions = [
    'status',
    'application.status',
    'application.default_locale',
    'application.marathon'
  ].join(',')

  const aggregates = [
    'marathon_price'
  ].join(',')

  const url = `subtasks?${filters}&include=${inclusions}&aggregates=${aggregates}&page[limit]=10&page[number]=${page + 1}`

  try {
    const response: ArrayResponse<AnyTaskListItem> = await axiosV3.get(url, { signal })

    return {
      data: response.data,
      meta: response.meta
    }
  } catch (e) {
    if (Axios.isCancel(e) || e.logout) return { data: [], meta: {} }
    console.info('Error in the method "downloadCompletedTaskList":', e)
    return { data: [], meta: {} }
  }
}

export async function downloadAllTaskList(signal: AbortSignal) {
  const state: RootState['user'] = store.getState().user
  const id = state.userData?.id ?? state.id
  if (!id) return { publicationTasks: [], simpleTasks: [], completedTasks: [] }

  const response = await Promise.allSettled([
    downloadPublishTaskList(signal),
    downloadSimpleTaskList(signal),
    downloadCompletedTaskList(signal),
    downloadApplicationList()
  ])

  const publicationTasks = getPromiseSettledResultValue(response[0])
  const simpleTasks = getPromiseSettledResultValue(response[1])
  const completedTasks = getPromiseSettledResultValue(response[2])
  const appList =  getPromiseSettledResultValue(response[3])

  let taskLists: { [p: string]: any } = { publicationTasks, simpleTasks, completedTasks }
  if (appList.data.length) taskLists = addMarathonDays(taskLists, appList)
  return taskLists
}

export async function takeSimpleTaskToProcess(id) {
  // Взятие "Другой задачи" в работу (перевод в статус "process" из статуса "published")

  try {
    const response: Response<AnyTaskListItem> = await axiosV3.post(`subtasks/${id}/process?include=status`)
    return {
      ...response.data,
      ...response.meta
    }
  } catch (e) {
    if (e.logout) return
    console.info('Error in the method "takeSimpleTaskToProcess":', e)
  }
}

export async function sendSimpleTaskToModeration(id, model) {
  // Отправка "Другой задачи" на проверку (перевод в статус "moderation")
  const data = new FormDataV3()
  data.addParam('id', String(id))
  data.addParam('type', 'subtasks')
  Object.entries(model).map(([key, value]) => { data.addMeta(key, value as string) })
  try {
    await axiosV3.post(`subtasks/${id}/review`, data)
    return true
  } catch (e) {
    if (e.logout) return
    console.info('Error in the method "sendSimpleTaskToModeration":', e)
  }
}

export async function takeSimpleTaskAgain(id: string): Promise<TakenSimpleTask | undefined> {
  // Повторное взятие "Другой задачи" в работу (перевод в статус "process" из статуса "moderation_rejected")

  try {
    const response: Response<TakenSimpleTask> = await axiosV3.post(`/subtasks/${id}/process?include=status`)
    return {
          ...response.data,
          ...response.meta
      }
  } catch (e) {
    if (e.logout) return
    console.info('Error in the method "takeSimpleTaskAgain":', e)
  }
}

export async function downloadSimpleTask(id) {
  // Получение с бэка данных "Другой задачи";
  try {
    const response: Response<AnyTaskListItem> = await axiosV3.get(`subtasks/${id}?include=related_manager_simple.status&aggregates=simple_task_stats`)
    return {
      ...response.data,
      ...response.meta
    }
  } catch (e) {
    if (Axios.isCancel(e) || e.logout) return
    console.info('Error in the method "downloadSimpleTask":', e)
  }
}

export async function getTmpPhone(id, step, recreate) {
  // получение временного номера тлф
  const data = new FormDataV3()
  data.addParam('type', 'subtasks')
  data.addParam('id', id)
  data.addMeta('recreate', recreate)
  data.addMeta('step', step)
  try {
    const response: Response<AnyTaskListItem> = await axiosV3.post(`subtasks/${id}/sms-number`, data)
    return {
      ...response.data,
      ...response.meta
    }
  } catch (e) {
    if (e.logout) return
    if (e.response?.status === 422) return 'notPhones'
    console.info('Error in the method "getTmpPhone":', e)
  }
}

export const uploadGmailText = async (payload) => {
  const {id, text} = payload
  const email = text.match(/\(.+@gmail\.com\)/gm)[0].replace('(', '').replace(')', '')
  const codes = text.match(/\d.\s\d{4}\s\d{4}/gm)
  const codeArr = codes.map(code => {
    return code.replace(/\d\.\s/, '')
  })
  const data = new FormDataV3()
  data.addParam('type', 'subtasks')
  data.addParam('id', String(id))
  data.addMeta('google_account', {'email': email, codes: codeArr})
  try {
    await axiosV3.post(`subtasks/${id}/review`, data)
    store.dispatch(setTaskCompleteModal({ show: true, iconType: 'success', text: 'Modal Complete Google task' }))
  } catch (e) {
    if (e.logout) return
    console.info('Error in the method "uploadGmailText":', e)
    store.dispatch(setTaskCompleteModal({ show: true, iconType: 'failed', text: 'Modal Error task' }))
  }
}

export const sendBrowserData = (payload) => {
  const {data} = payload
  try {
    axiosV3.post(`user-browsers`, data)
  } catch (e) {
    if (e.logout) return
    console.info('Error in the method "uploadGmailText":', e)
  }
}

async function downloadApplicationList() {
  const id = getUserId()
  if (!id) return { data: [], meta: {} }
  const appListUrl = `applications?filter[uploader][id]=${id}&aggregates=total_marathon_days`
  try {
    const response: ArrayResponse<AnyTaskListItem> = await axiosV3.get(appListUrl)
    return {
      data: response.data,
      meta: response.meta
    }
  } catch (e) {
    if (Axios.isCancel(e) || e.logout) return { data: [], meta: {} }
    console.info('Error in the method "downloadApplicationList":', e)
    return { data: [], meta: {} }
  }
}

function addMarathonDays(taskLists: { [key: string]: any }, appList: any) {
  for (let [key, value] of Object.entries(taskLists)) {
    if (!value.data.length) continue
    value = value.data.map(item => {
      if ('application' in item) {
        const appId = item.application.id
        item.days_count = appList.data.find(appTask => appTask.id === appId)?.total_marathon_days
      }
      return item
    })
  }
  return taskLists
}
