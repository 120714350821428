import React, { ReactElement } from 'react'
import '../../../style/sass/common_kit/Text.scss'


export type ParagraphData = {
  className?: string
  text?: string,
  size?: TextSize,
  align?: Align
  fontWeight?: Weight,
  textStyle?: TextStyle,
  color?: string,
  children?: ReactElement
}


export enum Weight {
  NORMAL = 'normal',
  BOLD = 'bold',
  BOLDER = 'bolder'
}

export enum TextStyle {
  NORMAL = 'normal',
  ITALIC = 'italic'
}

export enum TextSize {
  P_10 = 'ui-kit-regular-10',
  P_11 = 'ui-kit-regular-11',
  P_12 = 'ui-kit-regular-12',
  P_14 = 'ui-kit-regular-14',
  P_16 = 'ui-kit-regular-16',
  P_18 = 'ui-kit-regular-18'
}

export enum Align {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

Paragraph.defaultProps = {
  size: TextSize.P_14,
  align: Align.LEFT,
  fontWeight: Weight.NORMAL
}

export default function Paragraph(props: ParagraphData) {
  return (
    <p className={`${props.size} ${props.className ?? ''}`}
       style={{
         textAlign: props.align,
         fontWeight: props.fontWeight,
         color: props.color ? props.color : '',
         fontStyle: props.textStyle
       }}>
      {props.text}
      {props.children}
    </p>
  )
}

