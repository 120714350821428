import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { RootState } from '../../store/rootState'
import IconClear from '../icons/IconClear'
import { checkTranslate, getAmountWithSign } from '../../utils/helpers'
import { useTranslation } from 'react-i18next'
import Timer from '../common_kit/Timer'
import { Collapse, Spin } from 'antd'
import SubmitButton from '../inputs/SubmitButton'
import TakeSimpleTaskModal from './TakeSimpleTaskModal'
import MqSmall from '../mq/MqSmall'
import MqAfterSmall from '../mq/MqAfterSmall'
import ResultLinkInput from './simpleTaskDetailsCard/ResultLinkInput'
import ResultFileInput from './simpleTaskDetailsCard/ResultFileInput'
import LabeledResultInput from './simpleTaskDetailsCard/LabeledResultInput'
import { sendSimpleTask, setSelectedTaskForDetails } from '../../store/taskList/actions'
import { PublishedSimpleTask, TakenSimpleTask } from '../../store/taskList/initialState'

const { Panel } = Collapse

interface ResultModel {
  link?: string,
  image?: string,
  file?: string
}

interface ValidationStatus {
  link: boolean,
  image: boolean,
  file: boolean
}

export default function SimpleTaskDetailsCard() {

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const task = useSelector((state: RootState) => state.taskList.selectedSimpleTask as PublishedSimpleTask | TakenSimpleTask)
  const locale = useSelector((state: RootState) => state.locale.locale.name)
  const size = useSelector((state: RootState) => state.mq.size)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showTakeTaskModal, setShowTakeTaskModal] = useState<boolean>(false)
  const [validationStatus, setValidationStatus] = useState<ValidationStatus>({
    link: false,
    image: false,
    file: false
  })
  const [model, setModel] = useState<ResultModel>({
    link: undefined,
    image: undefined,
    file: undefined
  })
  const [invalidData, setInvalidData] = useState<boolean>()
  const [resultChanged, setResultChanged] = useState<boolean>(false)

  const showTimer = task?.status?.name === 'process' && task.calcCompleteAt
  const showBtn = !['moderation', 'completed', 'failed'].includes(task?.status?.name ?? '')
  const showResult = !['published', 'failed'].includes(task?.status?.name ?? '')
  const canEditResult = task?.status?.name === 'process'
  const showRejectionReason = task?.status?.name === 'process' && 'comment' in task && task?.comment
  const showTip = task?.status?.name === 'moderation'

  useEffect(() => {
    updateValidationStatus()
  }, [])

  useEffect(() => {
    updateValidationStatus()
  }, [task?.status?.name])

  useEffect(() => {
    setInvalidData(Object.values(validationStatus).some(i => !i))
  }, [validationStatus])

  useEffect(() => {
    if (!task || !('settings' in task)) return
    setResultChanged(Object.entries(model)
      .map(([key, value]) => value !== undefined && value !== task.result[key])
      .some(i => i)
    )
  }, [model])

  const updateValidationStatus = () => {
    if (!task || !('settings' in task)) return
    setValidationStatus(prevState => {
      const newValidationStatus = {...prevState}
      for (const [key, value] of Object.entries(task.settings)) {
        if (value || prevState[key] === undefined) continue
        newValidationStatus[key] = true
      }
      return {...prevState, ...newValidationStatus}
    })
  }

  const handleModalClose = useCallback(() => {
    setShowTakeTaskModal(false)
  }, [])

  const openTakeTaskModal = useCallback(() => {
    setShowTakeTaskModal(true)
  }, [])

  const handleInputChange = fieldName => (value, valid) => {
    setValidationStatus(prevState => ({ ...prevState, [fieldName]: valid }))
    setModel(prevState => ({ ...prevState, [fieldName]: value }))
  }

  const handleSendBtnClick = useCallback(async () => {
    if (!task) return
    setIsLoading(true)
    const filteredModel = Object.fromEntries(
      Object.entries(model).filter(([key, value]) => value)
    )
    await dispatch(sendSimpleTask(task.id, filteredModel))
    setIsLoading(false)
  }, [model])

  if (!task) return null

  const getStatus = () => {
    if (showTimer) {
      return (
        <div className="task-status">
          <div className="status-title">
            {t('Task in progress')}
          </div>
          <Timer endMoment={task.calcCompleteAt}/>
        </div>
      )
    } else {
      return (
        <div className="task-status">
          <div className="status-title">
            {t('Task status')}
          </div>
          <div className="status-value">
            {checkTranslate(task.status, locale)}
          </div>
        </div>
      )
    }
  }

  const getInputs = () => {
    if (!('settings' in task)) return
    const mapper = {
      link: {
        label: canEditResult ? 'Enter URL' : 'URL',
        component: ResultLinkInput
      },
      file: {
        label: canEditResult ? 'Attach ZIP file' : 'ZIP file',
        component: ResultFileInput
      },
      image: {
        label: canEditResult ? 'Attach an image' : 'Image',
        component: ResultFileInput
      }
    }
    const inputs = Object.keys(task.settings)
      .filter(key => task.settings[key])
      .map(key => {
        const item = mapper[key]
        if (!item) return
        return { ...item, key }
      })
      .filter(item => !!item)

    const inputComponents = inputs.map(input => {
      const Component = input.component
      return (
        <LabeledResultInput labelKey={input.label} key={input.key} className={input.key + '-input'}>
          <Component
            value={task.result[input.key]}
            fieldName={input.key}
            disabled={!canEditResult || isLoading}
            onChange={handleInputChange(input.key)}
          />
        </LabeledResultInput>
      )
    })

    return (
      <div className="task-result-inputs">{inputComponents}</div>
    )
  }

  const getBtn = () => {
    if (isLoading) return <Spin tip={t('Sending results')}/>
    if (task.status.name === 'published') return (
      <SubmitButton
        text={t('Start task')}
        onClick={openTakeTaskModal}
      />
    )
    if (task.status.name === 'process') return (
      <SubmitButton
        isDisabled={invalidData || !resultChanged}
        text={t('Send task to checking')}
        onClick={handleSendBtnClick}
      />
    )
  }

  const getDescription = () => (
    <div className="task-description custom-scroll" dangerouslySetInnerHTML={{ __html: task.description }}/>
  )

  const getClass = () => {
    const list = ['card-box', 'simple-task-details-card']
    list.push(task.status.name)
    return list.join(' ')
  }

  return (
    <div className={getClass()}>
      <IconClear
        fill={'#859AB6'}
        width={size === 's' ? 12 : 20}
        height={size === 's' ? 12 : 20}
        onClick={() => { dispatch(setSelectedTaskForDetails()) }}
      />
      <div className="task-card-header">
        {getStatus()}
        <div className="task-cost">
          + {getAmountWithSign(task.cost)}
        </div>
      </div>
      {showRejectionReason && (<div className="task-rejection-reason">
        {/*@ts-ignore*/}
        {task.comment}
      </div>)}
      <div className="task-title">
        {task.title}
      </div>
      <MqSmall>
        <Collapse
          defaultActiveKey="description"
          className="task-collapse"
          expandIcon={() => (
            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.375 4.49902L6.10671 0.749024L1.625 4.49902" stroke="#859AB6" strokeWidth="1.5"
                    strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}>
          <Panel header={t('Description') + ':'} key="description">
            {getDescription()}
          </Panel>
        </Collapse>
      </MqSmall>
      <MqAfterSmall>
        {getDescription()}
      </MqAfterSmall>
      {showResult && (
        <div className="task-result-adding">
          <div className="result-adding-title">
            {t(canEditResult ? 'Upload solution' : 'Solution uploaded')}
          </div>
          {showTip && (
            <div className="result-adding-subtitle">
              {t('Moderation tip')}
            </div>
          )}
          {getInputs()}
        </div>
      )}
      {showBtn && (
        <div className="card-action-block">
          <div className="card-action-wrap">
            <SwitchTransition>
              <CSSTransition
                key={Number(isLoading)}
                timeout={200}
                classNames="fade">
                {getBtn()}
              </CSSTransition>
            </SwitchTransition>
          </div>
        </div>
      )}
      <TakeSimpleTaskModal
        open={showTakeTaskModal}
        taskId={task.id}
        taskTime={task.task_time}
        googleTask={false}
        onCloseHandler={handleModalClose}
      />
    </div>
  )
}
