import React, { useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useForm } from 'react-hook-form'
import Paragraph, { TextSize, TextStyle } from '../common_kit/text/Paragraph'


type Data = {
  disabled: boolean,
  handleCodeEntered: (value: string) => void,
  showIndicator: boolean,
  emptyValue: boolean,
  resetValueCallback: () => void,
  typePhone?: string
}

export default function CodeInput(props: Data) {
  const { t } = useTranslation()

  const { register, watch, resetField } = useForm<{ code: string }>({ mode: 'onTouched' })

  const code = watch('code')

  useEffect(() => {
    if (code?.length === 4) props.handleCodeEntered(code)
  }, [code])

  useEffect(() => {
    if (!props.emptyValue) return
    resetField('code')
    props.resetValueCallback()
  }, [props.emptyValue])

  const transformCode = value => {
    if (!value.match(/\D+/)) return value
    return value.replaceAll(/\D+/g, '')
  }

  return (
    <div className="code-input-block">
      <label htmlFor="signup-code-input">{t('Confirmation code')}</label>
      <input
        id="signup-code-input"
        type="text"
        disabled={props.disabled}
        autoComplete="off"
        {...register('code', {
          setValueAs: transformCode,
          onChange: event => {
            event.preventDefault()
            const initValue = event.target.value
            event.target.value = transformCode(initValue)
          }
        })}
      />
      {!props.typePhone && <p className="ui-kit-regular-14 code-input-info"
          style={{textAlign: 'left', fontWeight: 'normal', fontStyle: 'italic'}}>
        <Trans i18nKey="Info about call robot">
          В течение 1-й минуты Вам поступит звонок. Последние <span>4 цифры</span> номера, с которого поступил звонок -
          это код подтверждения
        </Trans>
      </p>}
      {props.showIndicator && (
        <Paragraph
          className='check-indicator'
          text={t('Checking the code')}
          size={TextSize.P_12}
          textStyle={TextStyle.ITALIC}
        />
      )}
    </div>
  )
}
