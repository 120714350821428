import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'
import _ from 'lodash'
import { TASK_STAGES_OPTIONS } from '../../utils/constants'
import { useTranslation } from 'react-i18next'
import {
  MainTaskType,
  PublishTaskSection,
  UpdateTaskSection,
  VerificationTaskSection
} from '../../store/task/initialState'
import { setCurrentIndex } from '../../store/task/actions'
import { useHistory } from 'react-router-dom'
import Timer from '../common_kit/Timer'
import { PublisherTaskType } from '../../store/taskList/initialState'
import { setCurrentSimpleIndex } from '../../store/taskList/actions'


interface Data {
  countingDown: boolean
  loading: boolean,
  typeTask: string
}

interface initSidebarSection {
  key: PublishTaskSection | UpdateTaskSection | VerificationTaskSection
  title: string
}

interface SidebarSection extends initSidebarSection {
  firstStepIndex: number
  lastStepIndex: number

  active?: boolean
  passed?: boolean
  inProgress?: boolean
}

interface SidebarStage {
  number: string
  title: string

  sections?: Array<SidebarSection>
  active?: boolean
  passed?: boolean
  inProgress?: boolean
}

TaskSidebar.defaultProps = {
  loading: false
}

export default function TaskSidebar(props: Data) {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const typeSelectTask = props.typeTask === 'task' ? 'selectedTask' : 'selectedSimpleTask'
  const locale = useSelector((state: RootState) => state.locale.locale)
  const taskId = useSelector((state: RootState) => state[props.typeTask][typeSelectTask]?.id)
  const taskStatus = useSelector((state: RootState) => state[props.typeTask][typeSelectTask]?.status.name)
  const calcCompleteAt = useSelector((state: RootState) => state[props.typeTask][typeSelectTask]?.calcCompleteAt)
  const taskType = useSelector((state: RootState) => state[props.typeTask][typeSelectTask]?.type)
  const mainTaskType = useSelector((state: RootState) => props.typeTask === 'task' ? state[props.typeTask].selectedTask?.task?.type?.name : state[props.typeTask].simpleTaskGmail.main_task_type)
  const progress = useSelector((state: RootState) => props.typeTask === 'task' ?  state[props.typeTask].progress : state[props.typeTask].simpleTaskGmail.progress)
  const currentStep = useSelector((state: RootState) => props.typeTask === 'task' ?  state.task.currentStep : state[props.typeTask].simpleTaskGmail.currentStep, _.isEqual)
  const steps = useSelector((state: RootState) => props.typeTask === 'task' ?  state.task.steps : state[props.typeTask].simpleTaskGmail.steps, _.isEqual)
  const availablePhone = useSelector((state: RootState) => state.task.availablePhone)

  const [stages, setStages] = useState<Array<SidebarStage>>([])

  useEffect(() => {
    setStages(getStages())
  }, [taskStatus, currentStep, progress, locale])

  const handleSectionClick = section => {
    if (!section.passed && !section.inProgress) return
    const getSetter = () => {
      const mapper = {
        task: setCurrentIndex,
        taskList: setCurrentSimpleIndex
      }
      return mapper[props.typeTask]
    }
    const setter = getSetter()
    dispatch(setter(taskId, section.inProgress ? progress : section.firstStepIndex))
  }

  const handleStageClick = stage => {
    const section = stage.sections[0]
    if (!section.passed && !section.inProgress) return
    const getSetter = () => {
      const mapper = {
        task: setCurrentIndex,
        taskList: setCurrentSimpleIndex
      }
      return mapper[props.typeTask]
    }
    const setter = getSetter()
    dispatch(setter(taskId, stage.inProgress ? progress : section.firstStepIndex))
  }

  const handleTimeIsOver = () => {
    history.push('/available-tasks')
  }

  const getStages = () => {
    if (!mainTaskType || !taskType) return []
    let _stages: Array<SidebarStage> = []
    let options = [...TASK_STAGES_OPTIONS[taskType]]
    if (taskType === PublisherTaskType.VERIFICATION && !availablePhone) options = [options[0]]
    const getStageTitleKey = (number: string): string => {
      switch (number) {
        case '1':
          if (mainTaskType === 'otherTask') return 'Create gmail account'
          return taskType === PublisherTaskType.VERIFICATION ? 'Sign up Developer account' : 'Log in to Google'
        case '2':
          if (mainTaskType === 'otherTask') return 'Create reserve password'
          if (taskType === PublisherTaskType.VERIFICATION) return 'Create gmail account'
          if (taskType === PublisherTaskType.PUBLICATION) return 'Publish App'
          return 'Update App'
        case '3':
          if (taskType === PublisherTaskType.VERIFICATION) return 'Create reserve password'
          return ''
        default:
          return ''
      }
    }
    const getSections = (number: string): Array<SidebarSection> => {
      let initList
      const initStage = options.find(stage => stage.number === number)
      if (!initStage) return []
      const sections: Array<initSidebarSection> = initStage.sections
      if (taskType === PublisherTaskType.UPDATE && number === '2') {
        switch (mainTaskType) {
          case MainTaskType.UPDATE_ASO_IMG:
          case MainTaskType.UPDATE_ASO_TEXT_IMG:
          case MainTaskType.UPDATE_IMG:
            initList = sections.filter(section => section.key === UpdateTaskSection.APP_DESCRIPTION)
            break
          case MainTaskType.UPDATE_ASSEMBLY:
          case MainTaskType.UPDATE_ASO_ASSEMBLY:
          case MainTaskType.UPDATE_PURCHASES:
            initList = sections.filter(section => section.key === UpdateTaskSection.APP_VERSIONS)
            break
          default:
            initList = sections
        }
      } else {
        initList = sections
      }
      return initList.map(section => {
        const firstStepIndex = steps?.findIndex(step => step.stepOptions.section === section.key)
        const getLastStepIndex = () => {
          let result = 0
          steps?.map((step, index) => {
            if (step.stepOptions.section === section.key) {
              result = index
            }
          })
          return result
        }
        return {
          key: section.key,
          title: t(section.title),
          firstStepIndex,
          lastStepIndex: getLastStepIndex()
        }
      })
    }
    const updateStageActivity = () => {
      if (!_stages.length) return
      _stages = _stages.map(stage => {
        const activeSectionInStep = stage.sections?.find(section => section.key === currentStep?.stepOptions.section)
        stage.active = !!activeSectionInStep
        if (activeSectionInStep) {
          stage.sections?.map(section => {
            section.active = section.key === activeSectionInStep.key
          })
        } else {
          stage.sections?.map(section => {
            section.active = false
          })
        }
        return stage
      })
    }
    const updateStageProgress = () => {
      if (!_stages.length || !steps || progress === undefined) return
      _stages = _stages.map(stage => {
        const sections = stage.sections
        if (!sections) return stage
        const lastSectionInStage = sections[sections.length - 1]
        const lastStepInStage = lastSectionInStage.lastStepIndex
        const stepInProgress = steps[progress]
        stage.passed = lastStepInStage < progress
        stage.inProgress = lastStepInStage >= progress && sections[0].firstStepIndex < progress
        sections.map(section => {
          section.passed = section.lastStepIndex < progress
          section.inProgress = section.key === stepInProgress.stepOptions.section
        })
        return stage
      })
    }

    options.map(option => {
      const stage: SidebarStage = {
        number: option.number,
        title: t(getStageTitleKey(option.number)),
        sections: getSections(option.number)
      }
      _stages.push(stage)
    })

    updateStageActivity()
    updateStageProgress()

    return _stages
  }

  const getContent = () => {
    const skeleton = (
      <div className="skeleton">
        <div className="time">
          <div className="time-block"/>
          <div className="time-delimiter">:</div>
          <div className="time-block"/>
          <div className="time-delimiter">:</div>
          <div className="time-block"/>
        </div>
        <div className="stage">
          <div className="stage-num"/>
          <div className="stage-sections">
            <div className="section"/>
          </div>
        </div>
        <div className="stage">
          <div className="stage-num"/>
          <div className="stage-sections">
            <div className="section"/>
            <div className="section"/>
            <div className="section"/>
            <div className="section"/>
            <div className="section"/>
            <div className="section"/>
          </div>
        </div>
      </div>
    )

    const getNavigation = () => {
      const getClassList = () => {
        const classList = ['navigation']
        if (stages.length === 1) {
          classList.push('one-step')
        }
        return classList.join(' ')
      }

      return (
        <div className={getClassList()}>
          {stages.map(stage => (
            <div
              key={stage.number}
              className={`stage ${stage.active ? 'active' : ''} ${stage.passed ? 'passed' : ''} ${stage.inProgress ? 'in-progress' : ''}`}>
              <div className="stage-number">
                {!stage.passed ? stage.number : (
                  <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.9459 1.11514L14.9459 1.11512C14.5227 0.628277 13.8199 0.628281 13.3966 1.1152L6.26709 9.31728L2.60342 5.10247C2.18015 4.61553 1.47737 4.61556 1.05407 5.10245C0.648637 5.5688 0.648655 6.31241 1.05405 6.77879L5.49238 11.8848C5.69954 12.1231 5.9794 12.25 6.26703 12.25C6.55467 12.25 6.83459 12.123 7.04168 11.8848L14.9459 2.79148C15.3514 2.32514 15.3513 1.58152 14.9459 1.11514Z"
                      fill="#2684FE" stroke="#2684FE" strokeWidth="0.5"/>
                  </svg>
                )}
              </div>
              <div className="stage-titles">
                <div
                  className="stage-title"
                  onClick={() => handleStageClick(stage)}>
                  {stage.title}
                </div>
                {stage.sections?.filter(section => section.title).map(section => (
                  <div
                    key={section.title}
                    onClick={() => handleSectionClick(section)}
                    className={`section-title ${section.active ? 'active' : ''} ${section.passed ? 'passed' : ''} ${section.inProgress ? 'in-progress' : ''}`}>
                    {section.title}
                    <div className="passed-mark">
                      <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.9459 1.11514L14.9459 1.11512C14.5227 0.628277 13.8199 0.628281 13.3966 1.1152L6.26709 9.31728L2.60342 5.10247C2.18015 4.61553 1.47737 4.61556 1.05407 5.10245C0.648637 5.5688 0.648655 6.31241 1.05405 6.77879L5.49238 11.8848C5.69954 12.1231 5.9794 12.25 6.26703 12.25C6.55467 12.25 6.83459 12.123 7.04168 11.8848L14.9459 2.79148C15.3514 2.32514 15.3513 1.58152 14.9459 1.11514Z"
                          fill="#2684FE" stroke="#2684FE" strokeWidth="0.5"/>
                      </svg>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )
    }

    return props.loading ? skeleton : getNavigation()
  }

  const getTimer = () => {
    const isVerification = mainTaskType === MainTaskType.VERIFICATION
    if (props.loading || !calcCompleteAt || isVerification) return null
    return (
      <Timer endMoment={calcCompleteAt} handleTimeIsOver={handleTimeIsOver}/>
    )
  }

  return (
    <div className="task-sidebar col col-sm-3">
      {getTimer()}
      {getContent()}
    </div>
  )
}
