export interface DeletedApplication {
  id: string,
  application: {
    id: string,
    default_locale: {
      store_name: string
    }
  },
  deletionReason: {
    id: number | null,
    status: boolean,
    files: Array<string> | null,
  }
}

export interface ApplicationState {
  deletedApplications: Array<DeletedApplication>,

  selectedDeletedApp?: DeletedApplication,
}

const initialState: ApplicationState = {
  deletedApplications: [],
  selectedDeletedApp: undefined
}

export default initialState
