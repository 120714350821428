import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { configuration } from '../configuration'
import { useSelector } from 'react-redux'
import { RootState } from '../store/rootState'


export default function LocaleChecker(props) {
  const { i18n } = useTranslation()

  const location = useLocation()

  const locale = useSelector((state: RootState) => state.locale.locale.name)

  const setLocaleFromUrl = (): boolean => {
    const path = location.pathname
    const parts = path.slice(1).split('/')
    if (parts.length < 2) return false

    const localeCandidate = parts[0]
    if (!configuration.localeList.includes(localeCandidate)) return false
    if (locale !== localeCandidate) {
      i18n.changeLanguage(localeCandidate)
    }
    return true
  }

  useEffect(() => {
    setLocaleFromUrl()
  })

  useEffect(() => {
    if (setLocaleFromUrl()) return
    if (locale && i18n.language !== locale) {
      i18n.changeLanguage(locale)
    }
  }, [])

  return props.children
}
