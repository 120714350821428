import React from 'react'
import '../../../style/sass/common_kit/Button.scss'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/rootState'
import _ from 'lodash'
import { PublisherTaskType } from '../../../store/taskList/initialState'


type NextButtonData = {
  onClick: () => void,

  text?: string,
  className?: string,
  isDisabled?: boolean
}

NextButton.defaultProps = {
  isDisabled: false
}

export default function NextButton(props: NextButtonData) {
  const { t } = useTranslation()

  const linkId = useSelector((state: RootState) => state.task?.currentStep?.stepOptions.linkId, _.isEqual)
  const taskType = useSelector((state: RootState) => state.task.selectedTask?.type)

  const getId = () => {
    // Активная кнопка “Далее“ после подгрузки скрина компенсации         - uploader__google-play-payment-btn
    // Активная кнопка “Завершить задание“ в задаче на верификацию        - enabled-next-btn-verification
    // Активная кнопка “Завершить задание“ в задаче на публикацию         - enabled-next-btn-moderation
    // Активная кнопка “Завершить задание“ в задаче на обновление         - enabled-next-btn-update-moderation

    // !!! ВНИМАНИЕ !!!
    // Изменять значения id допускается только по согласованию с руководством

    const btnName = 'next-btn'
    if (!linkId || props.isDisabled || !taskType) return btnName
    const prefix = taskType === PublisherTaskType.UPDATE ? 'update-' : ''
    return {
      compensation: 'uploader__google-play-payment-btn'
    }[linkId] || `enabled-${btnName}-${prefix}${linkId}`
  }

  return (
    <button
      id={getId()}
      onClick={props.onClick}
      type="button"
      disabled={props.isDisabled}
      className={`btn btn-primary button-next ${props.className ?? ''}`}>
      {props.text || t('Next step')}
    </button>
  )
}
