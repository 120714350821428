import React from 'react'


type Data = {
  className?: string
  fill?: string,
  fillBack?: string,
  width?: number,
  height?: number,
  viewBox?: string
}

IconYandex.defaultProps = {
  fill: '#859AB6',
  fillBack: '#F5F6FA',
  width: 180,
  height: 44,
  viewBox: '0 0 180 44'
}

export default function IconYandex(props: Data) {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="180" height="44" rx="15" fill={props.fillBack}/>
      <path
        d="M42.566 23.638L39.354 30.854H37L40.542 23.132C38.87 22.296 37.77 20.756 37.77 17.94C37.77 13.98 40.278 12 43.248 12H46.284V30.854H44.26V23.638H42.566ZM44.26 13.716H43.182C41.554 13.716 39.97 14.794 39.97 17.94C39.97 20.976 41.422 21.944 43.182 21.944H44.26V13.716Z"
        fill={props.fill}/>
      <path
        d="M56.0738 17.5662V30.8542H54.0498V24.8922H50.9918V30.8542H48.9238V17.5662H50.9918V23.2202H54.0498V17.5662H56.0738ZM66.6558 33.4722H64.8078V30.8542H59.1098V33.4722H57.2618V29.1822H58.0758C59.2858 26.3662 59.4178 22.2522 59.4178 18.7102V17.5662H65.5998V29.1822H66.6558V33.4722ZM63.5758 19.2382H61.2878V19.4802C61.2878 22.2742 61.1558 26.3662 60.0558 29.1822H63.5978V19.2382H63.5758ZM72.3318 31.0522C69.5818 31.0522 67.9098 28.8742 67.9098 24.7822C67.9098 20.5142 69.0978 17.3902 71.9138 17.3902C74.1358 17.3902 75.5878 19.0622 75.5878 23.5722V24.7162H70.0438C70.0438 27.7302 70.8798 29.3142 72.5298 29.3142C73.6958 29.3142 74.6198 28.7862 75.1478 28.4122L75.6318 29.9962C74.7518 30.6342 73.6518 31.0522 72.3318 31.0522ZM70.0658 23.0002H73.4758C73.4758 20.7342 73.1018 19.0622 71.9138 19.0622C70.6378 19.0622 70.1978 20.6242 70.0658 23.0002ZM79.7458 24.2322V30.8542H77.6778V17.5662H79.7458V23.7042L82.9358 17.5662H85.0258L81.7258 23.8142L85.4878 30.8542H83.2438L79.7458 24.2322ZM85.7518 24.2102C85.7518 20.0522 87.3578 17.3682 90.4818 17.3682C91.6698 17.3682 92.3518 17.6762 92.9458 18.0502L92.6598 20.0082C92.1098 19.5242 91.3178 19.0842 90.4598 19.0842C88.9858 19.0842 87.9298 20.7562 87.9298 24.1002C87.9298 27.4222 88.7218 29.3142 90.3938 29.3142C91.4058 29.3142 92.1538 28.9182 92.6378 28.5002L93.1218 30.0402C92.4178 30.6562 91.5598 31.0522 90.3498 31.0522C87.4678 31.0522 85.7518 28.7422 85.7518 24.2102Z"
        fill={props.fill}/>
      <path
        d="M107.069 30.854H98.9952L98.8192 34H97.7852V29.776H98.3352C99.2592 29.006 99.6992 26.894 99.9632 24.21C100.227 21.416 100.315 17.236 100.381 12H106.981V29.776H108.279V34H107.245L107.069 30.854ZM105.683 13.078H101.591C101.503 17.852 101.371 21.482 101.173 24.21C100.931 27.026 100.513 28.896 99.8092 29.776H105.683V13.078Z"
        fill={props.fill}/>
      <path
        d="M113.759 30.0398C115.519 30.0398 116.443 28.6098 116.443 28.6098L116.839 29.6218C116.839 29.6218 115.827 31.1178 113.737 31.1178C110.921 31.1178 109.623 28.5658 109.623 24.2098C109.623 19.8538 111.251 17.3018 113.495 17.3018C115.783 17.3018 117.257 19.6998 116.839 24.4298H110.833C110.877 28.0598 111.823 30.0398 113.759 30.0398ZM110.855 23.4398H115.673C115.893 19.8978 114.837 18.3138 113.473 18.3138C112.087 18.3358 110.965 20.1178 110.855 23.4398Z"
        fill={props.fill}/>
      <path
        d="M124.957 24.4524H120.425V30.8544H119.193V17.5664H120.425V23.4404H124.957V17.5664H126.189V30.8544H124.957V24.4524Z"
        fill={props.fill}/>
      <path
        d="M129.135 17.5664H130.367V22.4944H131.335C133.865 22.4944 135.493 23.8584 135.493 26.6304C135.493 29.4464 133.755 30.8544 131.247 30.8544H129.113V17.5664H129.135ZM130.367 23.4844V29.8864H131.159C133.029 29.8864 134.305 28.9624 134.305 26.6744C134.305 24.4304 133.205 23.4844 131.291 23.4844H130.367Z"
        fill={props.fill}/>
      <path d="M138.86 18.6004V30.8764H137.65V17.5664H143.26V18.6004H138.86Z" fill={props.fill}/>
      <path
        d="M151.379 21.5924C151.379 20.9104 151.423 20.3164 151.489 19.7664H151.401C151.225 20.3384 150.983 20.9104 150.697 21.4824L146.363 30.8544H145.307V17.5664H146.473V26.8064C146.473 27.5104 146.429 28.1044 146.363 28.6764H146.451C146.627 28.0604 146.891 27.4884 147.155 26.9164L151.489 17.5884H152.567V30.8764H151.379V21.5924Z"
        fill={props.fill}/>
    </svg>
  )
}
