import { axiosV3 } from '../../axios'
import Notification from '../../components/notification/Notification'
import i18n from 'i18next'
import store from '../index'
import { Accrual } from './initialState'


type Response = {
  data: Array<Accrual>
}

export async function downloadAccrualsHistory() {
  try {
    const state = store.getState()
    const userData = state.user.userData
    const id = userData?.id
    if (!id) return

    const currentPage = state.accruals.meta.page?.current_page ?? 0
    const lastPage = state.accruals.meta.page?.last_page ?? 1
    if (currentPage === lastPage) return

    const transactionResponse: Promise<Response> = axiosV3.get(`transactions?filter[user][id]=${id}&filter[type]=deposit&filter[confirmed]=1&include=deposit_type,subtask&page[limit]=10&page[number]=${currentPage + 1}`)

    return await transactionResponse
  } catch (e) {
    if (e.logout || e.offline) return
    Notification({ noticeType: 'warn', text: i18n.t('Accruals history error') })
    console.info('Error in the method "downloadAccrualsHistory": ', e)
  }
}
