import React from 'react'

export default function SignupSocialFacebook() {
  return (
    <div className="social-icon">
      <svg width="13" height="24" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.0899 23.9999V13.0523H11.7646L12.3147 8.78588H8.0899V6.06189C8.0899 4.82668 8.43295 3.98482
          10.2044 3.98482L12.4636 3.98377V0.167932C12.0727 0.116127 10.7317 0 9.17155 0C5.91422 0 3.68417
          1.98818 3.68417 5.63957V8.786H0V13.0524H3.68406V24L8.0899 23.9999Z"
          fill="#859AB6"/>
      </svg>
    </div>
  )
}
