import React, { useEffect, useState } from 'react'
import '../style/sass/Header.scss'
import UserDataBlock, { AccountStatus } from './userDataBlock/UserDataBlock'
import { findPhoneNumbersInText } from 'libphonenumber-js'
import { Link } from 'react-router-dom'
import NavMenu from './NavMenu'
import LanguageSwitcher from './languageSwitcher/LanguageSwitcher'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store/rootState'
import MqAfterSmall from './mq/MqAfterSmall'
import MqLarge from './mq/MqLarge'
import MqBeforeLarge from './mq/MqBeforeLarge'
import { hideMenu, showMenu } from '../store/sidebar/actions'
import { useTranslation } from 'react-i18next'
import AdBanner from './AdBanner'

export default function Header() {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const balance = useSelector((state: RootState) => {
    const walletRub = state.user.userData?.wallets?.find(item => item.name === 'RUB')
    return walletRub?.balance ?? '0'
  })
  const holdBalanceRub = useSelector((state: RootState) => state.user.userData?.holdBalanceRub)
  const userFirstName = useSelector((state: RootState) => state.user.userData?.first_name)
  const userLastName = useSelector((state: RootState) => state.user.userData?.last_name)
  const userPhone = useSelector((state: RootState) => state.user.userData?.phone)
  const userImage = useSelector((state: RootState) => state.user.userData?.image)
  const userIsBlocked = useSelector((state: RootState) => state.user.userData?.is_task_block)
  const userIsVerified = useSelector((state: RootState) => state.user.userData?.verified)
  const shownMenu = useSelector((state: RootState) => state.sidebar.shownMenu)

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [cash, setCash] = useState<string>('0')
  const [holdenCash, setHoldenCash] = useState<string>('0')
  const [phone, setPhone] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [image, setImage] = useState<string>('')
  const [isShowIntercom, setShowIntercom] = useState<boolean>(false)

  const accountStatus = (() => {
    if (userIsBlocked) return AccountStatus.BLOCKED
    if (userIsVerified) return AccountStatus.VERIFIED
    return AccountStatus.NOT_VERIFIED
  })()

  useEffect(() => {
    if (!userFirstName) {
      setIsAuthenticated(false)
      setCash('0')
      setHoldenCash('0')
    } else {
      const hold = String(holdBalanceRub)
      setIsAuthenticated(true)
      setCash(balance)
      setHoldenCash(hold)
      setFirstName(userFirstName ?? '')
      setLastName(userLastName ?? '')
      setPhone(userPhone ?? '')
      setImage(userImage ?? '')
    }
  }, [balance, holdBalanceRub, userFirstName, userLastName, userPhone, userImage])

  const formatPhone = (unformatedPhone?: string): string | undefined => {
    const search = findPhoneNumbersInText(`+${unformatedPhone}`)
    if (!search || !search.length) return unformatedPhone
    const numberObj = search[0].number
    return numberObj.formatInternational()
  }

  const handleClickToggleMenu = () => {
    const action = shownMenu ? hideMenu : showMenu
    dispatch(action())
  }

  const handleShowIntercom = () => {
    window.Intercom(isShowIntercom ? 'hide' : 'show')
    setShowIntercom(!isShowIntercom)
  }

  window.Intercom('onHide', () => {
    setShowIntercom(false)
  })

  window.Intercom('onShow', () => {
    setShowIntercom(true)
  })

  return (
    <header className='header'>
      <div className={`header__container ${isAuthenticated ? 'header-auth' : ''}`}>
        <MqBeforeLarge>
          {!isAuthenticated
            ? <div className='toggle-menu-btn'>
              <Link to='/' className='header__container-logo'>
                <img className='logo' src='/logo/appwork_logo_mini.png' alt=''/>
                <img className='menu' src='/menu_icon.svg' alt=''/>
              </Link>
            </div>
            : <div className='toggle-menu-btn' onClick={handleClickToggleMenu}>
              <img className='logo' src='/logo/appwork_logo_mini.png' alt=''/>
              <img className='menu' src='/menu_icon.svg' alt=''/>
            </div>
          }
        </MqBeforeLarge>
        <MqLarge>
          <Link to='/' className='header__container-logo'>
            <img src='/logo/appwork_logo.png' alt=''/>
          </Link>
        </MqLarge>
        {isAuthenticated && (
          <MqLarge>
            <NavMenu/>
          </MqLarge>
        )}
        {isAuthenticated && (
          <MqAfterSmall>
            <AdBanner/>
          </MqAfterSmall>
        )}
        <div className='right-block'>
          {!isAuthenticated && (
            <button type='button' className='header_support-block' onClick={handleShowIntercom}>
              <img src='/support.svg' className='header_support' alt='support'/>
              <MqAfterSmall>
                <p>{t('Write to support')}</p>
              </MqAfterSmall>
            </button>
          )}
          <LanguageSwitcher/>
          {isAuthenticated && (
            <UserDataBlock
              isAuthenticated={isAuthenticated}
              cash={cash}
              holden_cash={holdenCash}
              phone={formatPhone(phone)}
              firstName={firstName}
              lastName={lastName ?? undefined}
              image={image}
              accountStatus={accountStatus}
            />
          )}
        </div>
      </div>
    </header>
  )
}
