import React, { useEffect } from 'react'
import '../../style/sass/usersidebar/UserSidebar.scss'
import { Link } from 'react-router-dom'
import H4 from '../common_kit/text/H4'
import Paragraph, { TextSize } from '../common_kit/text/Paragraph'
import H5 from '../common_kit/text/H5'
import Span from '../common_kit/text/Span'
import H3 from '../common_kit/text/H3'
import Tooltip, { CardStatusColor } from '../common_kit/Tooltip'
import { getAmountWithSign, getFullFileUrl } from '../../utils/helpers'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { hideSidebar, showSidebar } from '../../store/sidebar/actions'
import { logout } from '../../store/auth/api'
import { RootState } from '../../store/rootState'
import _ from 'lodash'

type adaptiveSizeModel = {
  countrySize: string,
  contactSize: string
}

type userSidebarCssModel = {
  s: adaptiveSizeModel,
  m: adaptiveSizeModel,
  l: adaptiveSizeModel
}

export default function UserSidebar() {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const userData = useSelector((state: RootState) => state.user.userData, _.isEqual)
  const sidebar = useSelector((state: RootState) => state.sidebar, _.isEqual)
  const size = useSelector((state: RootState) => state.mq.size)

  let userSidebar: userSidebarCssModel = {
    s: {
      countrySize: 'P_14',
      contactSize: 'P_14'
    },
    m: {
      countrySize: 'P_14',
      contactSize: 'P_14'
    },
    l: {
      countrySize: 'P_18',
      contactSize: 'P_16'
    }
  }

  useEffect(() => {
    if (sidebar.available && sidebar.show) {
      dispatch(showSidebar())
    } else {
      dispatch(hideSidebar())
    }
  }, [])

  if (!userData) return null

  const fullName = `${userData.first_name}${userData.last_name ? ` ${userData.last_name}` : ''}`
  const cash = userData.wallets?.find(i => i.name === 'RUB')?.balance ?? 0
  const hold = userData.holdBalanceRub

  const getContacts = () => {
    const options = [
      {
        field: 'phone',
        icon: 'phone.svg',
        link: false
      },
      {
        field: 'email',
        icon: 'email.svg',
        link: false
      },
      {
        field: 'social_account',
        icon: 'globe_network.svg',
        link: true
      }
    ]
    return options.map(option => (
      <div key={option.field} className="user-sidebar__contacts-item">
        <div className="user-sidebar__contacts-item-icon">
          <img src={`/contactIcons/${option.icon}`} alt=""/>
        </div>
        <div className="user-sidebar__contacts-item-data">
          {option.link
            ? (
              <a href={userData[option.field]} target="_blank" rel="noopener noreferrer">
                <Paragraph size={TextSize[userSidebar[size]?.contactSize]} text={userData[option.field]}/>
              </a>
            ) : (
              <Paragraph size={TextSize[userSidebar[size]?.contactSize]} text={userData[option.field]}/>
            )
          }
        </div>
      </div>
    ))
  }

  return <div className={`user-sidebar custom-scroll ${sidebar.show ? 'show' : ''}`}>
    <img
      className="user-sidebar__close-btn"
      src={'/close-sidebar.svg'}
      onClick={() => dispatch(hideSidebar())}
      alt=""
    />
    <section className="user-sidebar__personal">
      <div className="user-sidebar__personal-img">
        <img
          src={userData.image ? getFullFileUrl(userData.image) : '/avatar_dummy.svg'}
          alt=""/>
      </div>
      <div className="user-sidebar__personal-name">
        <H4 text={fullName}/>
        <Link to="/profile" onClick={() => dispatch(hideSidebar())}>
          <svg className="user-sidebar__personal-edit-btn" width="19" height="19" viewBox="0 0 19 19" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.3564 3.68895L15.88 5.16539L12.8371 2.1225L14.3136 0.646054C14.5083 0.451315 14.8217 0.451315 15.0164 0.646053L17.3564 2.98605C17.5512 3.18079 17.5512 3.49421 17.3564 3.68895ZM3.54289 17.5025H0.5V14.4596L11.06 3.89961L14.1029 6.9425L3.54289 17.5025Z"
              stroke="#2684FE"/>
          </svg>
        </Link>
      </div>
      <div className="user-sidebar__personal-country">
        <Paragraph
          text={userData.country}
          size={TextSize[userSidebar[size]?.countrySize]}
        />
      </div>
    </section>
    <section className="user-sidebar__balance">
      <div className="user-sidebar__balance-title">
        <H5 text={t('Funds')}/>
        <Link to="/withdrawal" onClick={() => dispatch(hideSidebar())}>
          <div className="user-sidebar__balance-pay-btn">
            <Span text={t('Withdraw funds')} color={'#2684FE'}/>
            <img alt="" className="user-sidebar__balance-icon"
                 src={(size === 's') ? '/cash_white.svg' : '/cash_blue.svg'}/>
          </div>
        </Link>
      </div>
      <div className="user-sidebar__balance-amount">
        <div className="user-sidebar__balance-amount-info">
          <H3 fontSize={30} text={getAmountWithSign(cash)}/>
          <H3
            color="#859AB6"
            fontSize={30}
            text={`/ ${getAmountWithSign(hold)}`}
          />
        </div>
        <Tooltip
          text={t('Sidebar hint balance')}
          svg={CardStatusColor.BLUE}
          color={CardStatusColor.GRAY}
        />
      </div>
      <div className="user-sidebar__balance-referral">
        <div className="user-sidebar__balance-referral-amount">
          <H4 color="#859AB6"
              text={getAmountWithSign(userData.total_referral_transactions_amount)}/>
          <Paragraph
            className="user-sidebar__balance-referral-text"
            text={t('from referrals')}
          />
        </div>
        <Tooltip
          text={t('Sidebar hint referrals')}
          svg={CardStatusColor.BLUE}
          color={CardStatusColor.GRAY}
        />
      </div>
    </section>
    <section className="user-sidebar__contacts">
      <div className="user-sidebar__contacts-title">
        <H5 text={t('Contact information')}/>
      </div>
      {getContacts()}
    </section>
    <section className="user-sidebar__toolbar">
      <Link to={`/${i18n.language}/sign-in`} onClick={logout} className="user-sidebar__toolbar-btn">
        <div className="user-sidebar__toolbar-btn-icon">
          <img src={'/exit.svg'} alt=""/>
        </div>
        <Paragraph
          className="user-sidebar__toolbar-btn-text"
          text={t('sign out')}
          size={TextSize.P_16}
        />
      </Link>
    </section>
  </div>
}
