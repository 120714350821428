import {
  SET_SCREENSHOTS, UPDATE_SCREENSHOT_DATA, REMOVE_SCREENSHOT, CLEAR_SCREENSHOTS
} from './actionTypes'
import { get, set, del } from 'idb-keyval'
import { DisplayMode } from './initialState'
import { FileUploadStatus } from '../../utils/helpers'
import store from '../index'


const setDataToIDB = async (screenshotType, payload) => {
  let result
  const taskId = store.getState().task.selectedTask?.id
  const localScreenshots = await get('screenshots')
  const initScreenshots = localScreenshots || {}
  let screenshotData = initScreenshots[`${taskId}-${screenshotType}`]
  if (screenshotData) {
    screenshotData = {
      ...screenshotData,
      ...payload
    }
    result = {
      ...initScreenshots,
      [`${taskId}-${screenshotType}`]: screenshotData
    }
  } else {
    result = initScreenshots
    result[`${taskId}-${screenshotType}`] = {
      type: screenshotType,
      ...payload
    }
  }
  await set('screenshots', result)
}

export const setLocalScreenshots = () => async dispatch => {
  const screenshots = await get('screenshots')
  if (!screenshots) return
  dispatch({
    type: SET_SCREENSHOTS,
    screenshots
  })
}

export const setDisplayMode = (screenshotType, displayMode) => async dispatch => {
  const payload = { displayMode }
  const taskId = store.getState().task.selectedTask?.id
  await setDataToIDB(screenshotType, payload)
  dispatch({
    type: UPDATE_SCREENSHOT_DATA,
    screenshotType,
    payload,
    taskId
  })
}

export const setError = (screenshotType, error) => async dispatch => {
  const payload = { error }
  const taskId = store.getState().task.selectedTask?.id
  await setDataToIDB(screenshotType, payload)
  dispatch({
    type: UPDATE_SCREENSHOT_DATA,
    screenshotType,
    payload,
    taskId
  })
}

export const setScreen = (screenshotType, image) => async dispatch => {
  const payload = {
    image,
    displayMode: DisplayMode.PREVIEW,
    status: FileUploadStatus.PROGRESS,
  }
  const taskId = store.getState().task.selectedTask?.id
  await setDataToIDB(screenshotType, payload)
  dispatch({
    type: UPDATE_SCREENSHOT_DATA,
    screenshotType,
    payload,
    taskId
  })
}

export const setPath = (screenshotType, path, status) => async dispatch => {
  const payload = { path, status }
  const taskId = store.getState().task.selectedTask?.id
  await setDataToIDB(screenshotType, payload)
  dispatch({
    type: UPDATE_SCREENSHOT_DATA,
    screenshotType,
    payload,
    taskId
  })
}

export const removeScreenshot = screenshotType => async dispatch => {
  const taskId = store.getState().task.selectedTask?.id
  get('screenshots')
    .then(localScreenshots => {
      delete localScreenshots[`${taskId}-${screenshotType}`]
      set('screenshots', localScreenshots)
    })
  dispatch({
    type: REMOVE_SCREENSHOT,
    ...{
      screenshotType,
      taskId
    }
  })
}

export const clearScreenshots = () => async dispatch => {
  del('screenshots')
  dispatch({
    type: CLEAR_SCREENSHOTS
  })
}
