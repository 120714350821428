import { axiosV3 } from '../../axios'
import countryPhoneCodes from '../../tempDictionary/countryPhoneCodes.json'
import { changePhoneModel } from './actions'
import store from '../index'

export async function defineCountryIP() {
  try {
    const response = await axiosV3.get('ipcountry')
    const countryShortName = response.data.id
    setCodeCountry(countryShortName)
  } catch (e) {
    setCodeCountry('PH')
    console.log(e);
  }
}

function setCodeCountry(countryShortName) {
  let codeCountry = countryPhoneCodes.find(item => item['short_name'] === countryShortName).code
  store.dispatch(changePhoneModel({ codeCountry }))
}
