import React from 'react'

type Data = {
  className: string
}

export default function IconAdd(props: Data) {
  return (
    <svg
      className={props.className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="20.0151" cy="20" r="19.5" fill="#FF5F5F"/>
      <path d="M19.647 12.2737L19.647 26.9907" stroke="#FFF8F8" strokeWidth="2" strokeLinecap="round"/>
      <path d="M12.2886 19.6321L27.0056 19.6321" stroke="#FFF8F8" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  )
}
