import React from 'react'
import MqSmall from '../mq/MqSmall'
import MqAfterSmall from '../mq/MqAfterSmall'


export default function SignupAfterPhoneIllustration() {

  return (
    <div className="signup-illustration">
      <MqSmall>
        <svg width="210" height="144" viewBox="0 0 210 138" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M197.361 134.432C197.361 134.432 218.707 120.54 205.917 107.546C193.128 94.5525 189.304 96.164 192.985 81.6881C196.666 67.2122 211.578 61.8189 205.65 50.8292C198.534 37.6393 182.309 49.6748 172.226 54.2924C153.479 62.8786 153.589 50.0419 160.79 38.0317C167.991 26.0216 168.506 6 151.878 6C133.794 6 94.0493 45.0689 72.1541 72.0698C59.0485 88.2311 49.4992 76.9852 50.8248 66.605C51.6677 60.0042 53.4228 49.3401 49.3168 44.4409C42.1901 35.9215 21.251 62.6108 13.8587 74.0115C-15.0731 118.628 20.0963 134.432 20.0963 134.432H197.361Z" fill="#E3F0FF"/>
          <path d="M35.6946 25.5993C36.5052 23.7408 36.7661 22.314 36.1888 21.1388C34.4799 17.6572 25.7666 17.0985 20.1849 20.4877C12.4 25.2138 10.3423 37.8358 13.9057 40.6386C18.051 43.9009 31.5031 35.1991 35.6946 25.5993Z" fill="#E3F0FF"/>
          <path d="M80.4667 23H79.5333C79.5333 21.2673 78.845 19.6055 77.6198 18.3802C76.3945 17.155 74.7327 16.4667 73 16.4667V15.5333C74.7327 15.5333 76.3945 14.845 77.6198 13.6198C78.845 12.3945 79.5333 10.7327 79.5333 9H80.4667C80.4667 10.7327 81.155 12.3945 82.3802 13.6198C83.6055 14.845 85.2673 15.5333 87 15.5333V16.4667C85.2673 16.4667 83.6055 17.155 82.3802 18.3802C81.155 19.6055 80.4667 21.2673 80.4667 23ZM75.6133 16C76.619 16.376 77.5322 16.9634 78.2914 17.7226C79.0506 18.4818 79.638 19.395 80.014 20.4007C80.39 19.395 80.9774 18.4818 81.7366 17.7226C82.4958 16.9634 83.409 16.376 84.4147 16C83.409 15.624 82.4958 15.0366 81.7366 14.2774C80.9774 13.5182 80.39 12.605 80.014 11.5993C79.638 12.605 79.0506 13.5182 78.2914 14.2774C77.5322 15.0366 76.619 15.624 75.6133 16Z" fill="#A3C5F1"/>
          <path d="M180.503 25.9219H180.041V27.9005H180.503V25.9219Z" fill="#A3C5F1"/>
          <path d="M180.503 37.7959H180.041V39.7745H180.503V37.7959Z" fill="#A3C5F1"/>
          <path d="M177.005 26.733L176.605 26.9639L177.595 28.6774L177.995 28.4465L177.005 26.733Z" fill="#A3C5F1"/>
          <path d="M182.941 37.0064L182.541 37.2373L183.531 38.9508L183.931 38.72L182.941 37.0064Z" fill="#A3C5F1"/>
          <path d="M174.383 29.1801L174.152 29.5801L175.866 30.5694L176.097 30.1695L174.383 29.1801Z" fill="#A3C5F1"/>
          <path d="M184.68 35.1303L184.449 35.5303L186.163 36.5196L186.394 36.1197L184.68 35.1303Z" fill="#A3C5F1"/>
          <path d="M175.323 32.6172H173.344V33.0791H175.323V32.6172Z" fill="#A3C5F1"/>
          <path d="M187.196 32.6172H185.217V33.0791H187.196V32.6172Z" fill="#A3C5F1"/>
          <path d="M175.876 35.1408L174.162 36.1299L174.393 36.5299L176.107 35.5408L175.876 35.1408Z" fill="#A3C5F1"/>
          <path d="M186.16 29.1979L184.445 30.187L184.676 30.587L186.39 29.598L186.16 29.1979Z" fill="#A3C5F1"/>
          <path d="M177.583 37.006L176.594 38.7197L176.994 38.9505L177.983 37.2368L177.583 37.006Z" fill="#A3C5F1"/>
          <path d="M183.521 26.7233L182.531 28.437L182.931 28.6678L183.921 26.9541L183.521 26.7233Z" fill="#A3C5F1"/>
          <path d="M157 139H91V140H157V139Z" fill="#A3C5F1"/>
          <path d="M170 143H141V144H170V143Z" fill="#A3C5F1"/>
          <path d="M132 143H80V144H132V143Z" fill="#A3C5F1"/>
          <path d="M48 143H41V144H48V143Z" fill="#A3C5F1"/>
          <path d="M53 143H51V144H53V143Z" fill="#A3C5F1"/>
          <path d="M178 139H165V140H178V139Z" fill="#A3C5F1"/>
          <path d="M86 139H66V140H86V139Z" fill="#A3C5F1"/>
          <path d="M61 139H26V140H61V139Z" fill="#A3C5F1"/>
          <path d="M22 139H15V140H22V139Z" fill="#A3C5F1"/>
          <path d="M205 134H0V135H205V134Z" fill="#A3C5F1"/>
          <path d="M182 134H170V135H182V134Z" fill="#0958BF"/>
          <path d="M39 134H17V135H39V134Z" fill="#0958BF"/>
          <path d="M115 134H113V135H115V134Z" fill="#0958BF"/>
          <path d="M110 134H44V135H110V134Z" fill="#0958BF"/>
          <path d="M154 134H122V135H154V134Z" fill="#0958BF"/>
          <path d="M13.9868 134.441H12.3125V134.903H13.9868V134.441Z" fill="#0958BF"/>
          <path d="M188 134H184V135H188V134Z" fill="#0958BF"/>
          <path d="M102 29C101.209 29 100.436 28.7654 99.7777 28.3259C99.1199 27.8863 98.6072 27.2616 98.3045 26.5307C98.0017 25.7998 97.9225 24.9956 98.0769 24.2196C98.2312 23.4437 98.6122 22.731 99.1716 22.1716C99.731 21.6122 100.444 21.2312 101.22 21.0769C101.996 20.9225 102.8 21.0017 103.531 21.3045C104.262 21.6072 104.886 22.1199 105.326 22.7777C105.765 23.4355 106 24.2089 106 25C106 26.0609 105.579 27.0783 104.828 27.8284C104.078 28.5786 103.061 29 102 29ZM102 21.8889C101.385 21.8889 100.783 22.0714 100.272 22.4132C99.7599 22.7551 99.3612 23.2409 99.1257 23.8094C98.8902 24.3779 98.8286 25.0035 98.9487 25.607C99.0687 26.2104 99.365 26.7648 99.8001 27.1999C100.235 27.635 100.79 27.9313 101.393 28.0513C101.997 28.1714 102.622 28.1098 103.191 27.8743C103.759 27.6388 104.245 27.2401 104.587 26.7284C104.929 26.2168 105.111 25.6153 105.111 25C105.111 24.1749 104.783 23.3836 104.2 22.8001C103.616 22.2167 102.825 21.8889 102 21.8889Z" fill="#A3C5F1"/>
          <path d="M85.2344 52.0709L89.232 51.3855L94.2708 72.3659L87.527 73.5264L85.2344 52.0709Z" fill="#FFE159"/>
          <path d="M116.023 55.1028L19.9277 79.537L21.2663 84.7987L117.361 60.3644L116.023 55.1028Z" fill="#FF9D45"/>
          <path d="M114.689 49.838L18.5938 74.2723L19.9323 79.5339L116.027 55.0996L114.689 49.838Z" fill="#FFC359"/>
          <path d="M113.345 44.5764L17.25 69.0106L18.5886 74.2723L114.683 49.838L113.345 44.5764Z" fill="#FFC359"/>
          <path d="M9.35136 71.0158L1.45508 73.0236L5.47175 88.812L13.368 86.8042L9.35136 71.0158Z" fill="#FF809F"/>
          <path d="M17.2479 69.0124L9.35156 71.0202L13.3682 86.8086L21.2645 84.8008L17.2479 69.0124Z" fill="#A3C5F1"/>
          <path d="M139.936 46.2135L113.346 44.5706L117.362 60.3576L139.936 46.2135Z" fill="#A3C5F1"/>
          <path d="M131.953 51.218L117.362 60.3609L116.027 55.0949L131.477 49.3575L131.953 51.218Z" fill="#6AACFF"/>
          <path d="M139.938 46.2128L131.954 51.2176L131.479 49.3571L139.938 46.2128Z" fill="#0958BF"/>
          <path d="M139.938 46.213L131.478 49.3573L130.531 45.6291L139.938 46.213Z" fill="#4792F3"/>
          <path d="M19.9275 79.5365L12.0312 81.5443L13.3698 86.8059L21.2661 84.7981L19.9275 79.5365Z" fill="#6AACFF"/>
          <path d="M12.0271 81.5445L4.13086 83.5523L5.46945 88.814L13.3657 86.8062L12.0271 81.5445Z" fill="#E5659B"/>
          <path d="M112.697 61.1005L37.418 80.2557L37.5969 80.9587L112.875 61.8034L112.697 61.1005Z" fill="#0958BF"/>
          <path d="M33.1703 81.3939L17.8633 85.2888L18.0423 85.9919L33.3493 82.097L33.1703 81.3939Z" fill="#0F4C9A"/>
          <path d="M5.21348 89.2537L2.42383 78.2866L3.12759 78.1089L5.73949 88.3688L16.5208 85.6306L16.6986 86.3306L5.21348 89.2537Z" fill="#0F4C9A"/>
          <path d="M1.85389 76.0463L1.01953 72.7605L9.26516 70.6643L9.44654 71.3679L1.90105 73.2864L2.55765 75.8686L1.85389 76.0463Z" fill="#0F4C9A"/>
          <path d="M17.6035 68.9246L16.9004 69.1034L20.3654 82.7232L21.0685 82.5444L17.6035 68.9246Z" fill="white"/>
          <path d="M12.0039 79.955L11.3008 80.1338L11.6818 81.6315L12.385 81.4527L12.0039 79.955Z" fill="white"/>
          <path d="M10.627 74.5568L9.92383 74.7356L10.9566 78.7951L11.6598 78.6163L10.627 74.5568Z" fill="white"/>
          <path d="M45.6379 90.6506C46.7733 89.1165 47.9088 87.6187 47.9523 85.3665C48.0539 79.8141 45.5834 79.5638 45.9099 74.0077C46.2364 68.4517 50.3502 68.7708 50.673 63.2111C50.7178 62.5719 50.6714 61.9296 50.5352 61.3035C50.1159 60.5615 49.7638 59.7835 49.4832 58.9788C49.4324 58.8409 49.3852 58.6959 49.3417 58.5544C48.665 56.3941 48.5116 54.1039 48.8941 51.8726C49.2765 49.6414 50.1839 47.5329 51.5414 45.7211C52.899 43.9092 54.6678 42.4459 56.7021 41.4516C58.7364 40.4573 60.9779 39.9606 63.2419 40.0024C65.5059 40.0442 67.7275 40.6234 69.7237 41.692C71.7199 42.7607 73.4335 44.2884 74.7231 46.1491C76.0128 48.0098 76.8417 50.1504 77.1415 52.3942C77.4413 54.6381 77.2034 56.921 76.4474 59.0549C76.4003 59.1964 76.3458 59.3306 76.2951 59.4684C75.9839 60.2652 75.6014 61.0323 75.1523 61.7604C74.9977 62.3791 74.9294 63.0162 74.9492 63.6536C75.0653 69.2096 79.1863 69.0682 79.3024 74.6207C79.4185 80.1731 76.9408 80.3436 76.8392 85.8924C76.7957 88.1482 77.8767 89.6859 78.9577 91.2635C80.0388 92.8411 81.1126 94.4622 81.069 96.8595C81.0384 98.4952 80.6855 100.109 80.0307 101.608C79.3759 103.107 78.4319 104.463 77.2528 105.597C76.0736 106.732 74.6824 107.623 73.1586 108.219C71.6348 108.816 70.0084 109.106 68.3723 109.074L55.5232 108.835C53.8885 108.805 52.2756 108.454 50.7769 107.8C49.2781 107.147 47.9228 106.205 46.7885 105.028C45.6541 103.851 44.763 102.462 44.1659 100.94C43.5689 99.4182 43.2777 97.7938 43.3089 96.1595C43.367 93.7695 44.506 92.1919 45.6379 90.6506Z" fill="#0958BF"/>
          <path d="M74.1282 108.598C77.2025 105.105 76.8627 99.7821 73.3691 96.7085C69.8755 93.635 64.5511 93.9747 61.4768 97.4674C58.4024 100.96 58.7423 106.283 62.2359 109.357C65.7294 112.43 71.0538 112.09 74.1282 108.598Z" fill="#FFE159"/>
          <path d="M74.6942 78.7625C76.4091 76.8027 76.2101 73.8241 74.2498 72.1097C72.2896 70.3952 69.3102 70.5941 67.5953 72.5539C65.8804 74.5137 66.0794 77.4922 68.0397 79.2067C69.9999 80.9211 72.9793 80.7222 74.6942 78.7625Z" fill="#FFAFAF"/>
          <path d="M90.1404 69.835C90.7998 69.6778 91.4911 69.7197 92.1267 69.9553C92.7624 70.1908 93.3139 70.6095 93.7116 71.1584C94.1092 71.7073 94.3352 72.3617 94.3609 73.039C94.3865 73.7162 94.2108 74.3859 93.8558 74.9633C93.5009 75.5407 92.9827 75.9999 92.3667 76.2829C91.7507 76.5659 91.0646 76.66 90.3952 76.5532C89.7257 76.4465 89.103 76.1437 88.6056 75.6831C88.1083 75.2225 87.7587 74.6249 87.6011 73.9658C87.3905 73.0814 87.5397 72.1496 88.0158 71.3751C88.492 70.6006 89.2561 70.0467 90.1404 69.835Z" fill="#FFE159"/>
          <path d="M70.8672 70.9365L90.7394 69.7469L91.5847 76.5325L72.0317 80.2716L70.8672 70.9365Z" fill="#FFE159"/>
          <path d="M75.8539 75.5456L76.398 101.944L59.5658 103.069L50.747 75.8974C50.6108 75.4775 50.5722 75.032 50.6343 74.5949C50.6963 74.1578 50.8573 73.7406 51.105 73.3751C51.3527 73.0096 51.6805 72.7055 52.0636 72.4858C52.4466 72.2662 52.8747 72.1368 53.3153 72.1075L70.826 70.947C71.4614 70.9047 72.0987 70.9915 72.6996 71.2022C73.3005 71.4128 73.8526 71.743 74.3224 72.1727C74.7922 72.6024 75.1701 73.1228 75.4333 73.7025C75.6966 74.2822 75.8396 74.9092 75.8539 75.5456Z" fill="#FFE159"/>
          <path d="M72.1839 80.2355C74.7247 79.663 76.3203 77.1397 75.7477 74.5996C75.1751 72.0595 72.6511 70.4643 70.1103 71.0368C67.5695 71.6092 65.9739 74.1325 66.5465 76.6726C67.1191 79.2128 69.6431 80.8079 72.1839 80.2355Z" fill="#FFE159"/>
          <path d="M59.5475 102.499H76.4161C77.8474 105.39 78.5911 108.572 78.589 111.798V116.766V134.5H50L53.7832 113.14C53.7834 111.227 54.2208 109.339 55.062 107.62C55.9032 105.902 57.126 104.398 58.637 103.224L59.5475 102.499Z" fill="#0958BF"/>
          <path d="M79.992 84.402C79.9532 83.8333 79.8106 83.2765 79.5712 82.7592L75.4248 73.6924L66.8418 77.6093L70.9882 86.676C71.4475 87.6777 72.2434 88.4869 73.2375 88.963C74.2315 89.4392 75.361 89.5521 76.4297 89.2823C77.4984 89.0125 78.4389 88.377 79.0876 87.4861C79.7364 86.5953 80.0525 85.5053 79.9811 84.4057L79.992 84.402Z" fill="#FFE159"/>
          <path d="M76.7264 75.6445L76.0664 75.9459L76.686 77.302L77.346 77.0007L76.7264 75.6445Z" fill="#FFAFAF"/>
          <path d="M75.2784 89.8056C74.5924 89.8059 73.9135 89.6664 73.2832 89.3958L73.5734 88.7285C74.205 89.0018 74.8929 89.1199 75.5795 89.073C76.15 89.0348 76.7074 88.8845 77.2198 88.6309C77.7322 88.3772 78.1897 88.0251 78.5659 87.5947C78.9422 87.1643 79.23 86.664 79.4129 86.1224C79.5957 85.5808 79.67 85.0084 79.6316 84.4381C79.5975 83.9131 79.4658 83.399 79.2434 82.9221L79.0911 82.5849L77.2772 78.5955L77.9375 78.2945L80.0669 82.9548C80.3412 83.6955 80.439 84.4899 80.3523 85.275C80.2656 86.0601 79.9969 86.8141 79.5675 87.4771C79.1382 88.1401 78.5601 88.6939 77.8791 89.0943C77.1981 89.4948 76.4331 89.7309 75.6448 89.7838L75.2784 89.8056Z" fill="#FFAFAF"/>
          <path d="M49.8726 79.4261C50.3906 80.2026 51.1274 80.8078 51.9897 81.1653C52.852 81.5228 53.801 81.6164 54.7166 81.4342C55.6321 81.252 56.473 80.8023 57.1327 80.142C57.7924 79.4817 58.2412 78.6405 58.4224 77.7249C58.6035 76.8094 58.5087 75.8607 58.1501 74.9991C57.7915 74.1374 57.1852 73.4016 56.4079 72.8847C55.6307 72.3678 54.7175 72.0931 53.7839 72.0955C52.8504 72.0979 51.9386 72.3772 51.164 72.8981C50.1289 73.5942 49.4118 74.672 49.1697 75.8955C48.9277 77.119 49.1804 78.3885 49.8726 79.4261Z" fill="#FFE159"/>
          <path d="M50.6783 73.2606L35.707 86.3783L39.8752 91.8002L56.4172 80.7207L50.6783 73.2606Z" fill="#FFE159"/>
          <path d="M60.8344 71.8752L68.7441 70.8131L67.4359 61.0758L59.5261 62.138L60.8344 71.8752Z" fill="#FFAFAF"/>
          <path d="M60.8326 71.8752C60.938 72.6571 61.273 73.3903 61.7952 73.982C62.3175 74.5736 63.0034 74.9972 63.7664 75.199C64.5294 75.4009 65.3352 75.372 66.0818 75.1161C66.8284 74.8601 67.4823 74.3885 67.9608 73.761C68.4392 73.1335 68.7209 72.3782 68.77 71.5907C68.8191 70.8031 68.6335 70.0187 68.2367 69.3367C67.8398 68.6546 67.2496 68.1055 66.5406 67.7588C65.8316 67.4121 65.0357 67.2834 64.2535 67.3889C63.2049 67.5305 62.2554 68.0826 61.6139 68.9239C60.9723 69.7652 60.6913 70.8268 60.8326 71.8752Z" fill="#FFAFAF"/>
          <path d="M58.6999 58.7978L60.4956 58.5584L60.0131 54.9644L58.1993 55.2037C57.7226 55.2677 57.2909 55.5184 56.999 55.9006C56.7072 56.2829 56.5791 56.7653 56.643 57.2419C56.6747 57.4796 56.7531 57.7087 56.8739 57.916C56.9947 58.1232 57.1554 58.3044 57.3466 58.4491C57.5379 58.5938 57.7561 58.6991 57.9883 58.7589C58.2206 58.8188 58.4625 58.832 58.6999 58.7978Z" fill="#FFAFAF"/>
          <path d="M71.1675 46.5065L57.6582 48.3199L60.0017 65.757L68.3924 64.6291C69.1879 64.522 69.953 64.2533 70.6407 63.8395C71.3284 63.4257 71.924 62.8755 72.391 62.2228C72.8579 61.5701 73.1863 60.8288 73.3558 60.0445C73.5254 59.2601 73.5325 58.4494 73.3768 57.6622L71.1675 46.5065Z" fill="#FFAFAF"/>
          <path d="M58.5761 55.1566L58.2133 55.2038L56.8203 44.8351L67.5219 43.3844C68.8788 43.203 70.2523 43.5674 71.3406 44.3977C72.4289 45.228 73.143 46.4562 73.3261 47.8126L73.3515 48.0157L72.9887 48.0665C69.9262 48.4793 67.082 49.88 64.8882 52.0558C63.1758 53.7512 60.9573 54.8424 58.5688 55.1639L58.5761 55.1566Z" fill="#0958BF"/>
          <path d="M70.5135 79.3683L69.9548 70.9979L57.1855 71.8502L70.5135 79.3683Z" fill="#FFAFAF"/>
          <path d="M55.7964 108.099C55.8091 108.458 55.7152 108.812 55.5267 109.117C55.3382 109.422 55.0634 109.664 54.7372 109.813C54.4109 109.962 54.0479 110.011 53.6938 109.954C53.3398 109.896 53.0107 109.735 52.7481 109.491C52.4856 109.247 52.3014 108.93 52.2187 108.581C52.1361 108.232 52.1588 107.867 52.2839 107.531C52.409 107.195 52.631 106.903 52.9217 106.694C53.2125 106.484 53.5589 106.365 53.9173 106.351C54.1555 106.343 54.3932 106.381 54.6166 106.464C54.84 106.547 55.0448 106.674 55.2193 106.836C55.3939 106.998 55.5347 107.193 55.6337 107.41C55.7327 107.627 55.788 107.861 55.7964 108.099Z" fill="#FFAFAF"/>
          <path d="M58.4506 111.272C58.1708 111.282 57.8912 111.245 57.6235 111.163L53.459 109.901L53.9197 106.351L58.2729 106.198C58.6063 106.187 58.9388 106.241 59.2514 106.358C59.564 106.474 59.8504 106.652 60.0945 106.879C60.3385 107.107 60.5354 107.38 60.6738 107.683C60.8122 107.987 60.8894 108.315 60.9011 108.648C60.9128 108.982 60.8586 109.314 60.7418 109.626C60.625 109.939 60.4477 110.225 60.2202 110.469C59.9926 110.713 59.7192 110.91 59.4156 111.048C59.112 111.187 58.7841 111.264 58.4506 111.276V111.272Z" fill="#FFAFAF"/>
          <path d="M39.9901 91.7218C40.5372 91.3226 40.9537 90.7702 41.1867 90.1345C41.4198 89.4987 41.4591 88.8081 41.2995 88.15C41.14 87.4919 40.7889 86.8959 40.2905 86.4374C39.7922 85.9788 39.169 85.6783 38.4998 85.5738C37.8306 85.4693 37.1454 85.5655 36.531 85.8504C35.9165 86.1352 35.4003 86.5958 35.0476 87.1739C34.695 87.752 34.5218 88.4216 34.5498 89.0982C34.5779 89.7747 34.806 90.4278 35.2053 90.9747C35.7413 91.7073 36.5461 92.1974 37.4432 92.3375C38.3402 92.4775 39.2562 92.2561 39.9901 91.7218Z" fill="#FFE159"/>
          <path d="M35.4863 91.3151L52.6705 109.416L55.4492 107.095L40.7392 86.9341L35.4863 91.3151Z" fill="#FFAFAF"/>
          <path d="M50.3452 106.968L53.4577 104.368L40.7392 86.9341L35.4863 91.3188L50.3452 106.968Z" fill="#FFE159"/>
          <path d="M70.7597 59.1791C70.841 59.7018 70.7112 60.2355 70.3989 60.6626C70.0867 61.0897 69.6175 61.3754 69.0946 61.4566C68.5717 61.5379 68.0379 61.4082 67.6106 61.096C67.1834 60.7838 66.8977 60.3148 66.8164 59.792L70.7597 59.1791Z" fill="white"/>
          <path d="M45.5597 87.5698L44.2695 88.4336L44.6733 89.0364L45.9635 88.1726L45.5597 87.5698Z" fill="#FFAFAF"/>
          <path d="M54.853 81.336L46.7539 86.7585L47.1576 87.3611L55.2567 81.9387L54.853 81.336Z" fill="#FFAFAF"/>
          <path d="M88.3663 69.5274L74.0918 70.3828L74.1352 71.107L88.4098 70.2517L88.3663 69.5274Z" fill="#FFAFAF"/>
          <path d="M92.3296 49.5454L78.123 53.1603L78.3021 53.8634L92.5086 50.2485L92.3296 49.5454Z" fill="#0F4C9A"/>
          <path d="M84.3516 51.1819C84.1889 50.8622 84.1246 50.5015 84.167 50.1453C84.2094 49.7891 84.3564 49.4534 84.5896 49.1808C84.8228 48.9082 85.1317 48.7108 85.4771 48.6137C85.8225 48.5166 86.189 48.5241 86.5301 48.6353C86.8713 48.7464 87.1718 48.9562 87.3937 49.2382C87.6155 49.5201 87.7487 49.8615 87.7765 50.2191C87.8043 50.5767 87.7253 50.9346 87.5496 51.2473C87.3739 51.5601 87.1093 51.8137 86.7894 51.9762C86.3608 52.1938 85.8632 52.2324 85.4061 52.0835C84.949 51.9345 84.5697 51.6103 84.3516 51.1819Z" fill="#FFAFAF"/>
          <path d="M80.6078 49.4198C80.8579 49.2931 81.1278 49.2097 81.4059 49.1732L85.7155 48.5675L86.8038 51.9802L82.9222 53.9495C82.6243 54.1071 82.298 54.2038 81.9624 54.234C81.6267 54.2642 81.2884 54.2273 80.9671 54.1255C80.6459 54.0236 80.3481 53.8589 80.0912 53.6408C79.8343 53.4228 79.6233 53.1558 79.4707 52.8554C79.3181 52.555 79.2268 52.2272 79.2021 51.8912C79.1775 51.5551 79.22 51.2176 79.3272 50.8981C79.4344 50.5787 79.6041 50.2838 79.8264 50.0306C80.0488 49.7774 80.3193 49.571 80.6223 49.4234L80.6078 49.4198Z" fill="#FFAFAF"/>
          <path d="M113.699 44.4715L112.996 44.6504L115.676 55.1767L116.379 54.9978L113.699 44.4715Z" fill="white"/>
          <rect x="147.918" y="11.7358" width="22.9701" height="22.0513" fill="white"/>
          <rect x="147.918" y="56.7572" width="22.9701" height="22.0513" fill="white"/>
          <rect x="147.918" y="100.86" width="22.9701" height="22.0513" fill="white"/>
          <path d="M155.584 32.0238L142 20.8199L145.72 16.3126L154.714 23.7391L173.583 0L178.155 3.63151L155.584 32.0238Z" fill="#FFAFAF"/>
          <path d="M155.584 76.7563L142 65.5465L145.72 61.0451L154.714 68.4657L173.583 44.7325L178.155 48.364L155.584 76.7563Z" fill="#FFAFAF"/>
          <path d="M155.584 121.491L142 110.282L145.72 105.78L154.714 113.201L173.583 89.4677L178.155 93.0992L155.584 121.491Z" fill="#FFAFAF"/>
          <path d="M151.345 35.0062H147.006V28.1635H148.174V33.8385H151.345V35.0062Z" fill="#0F4C9A"/>
          <path d="M171.536 35.0069H154.139V33.8392H170.368V16.0378H171.536V35.0069Z" fill="#0F4C9A"/>
          <path d="M163.468 10.485H161.752V11.6524H163.468V10.485Z" fill="#0F4C9A"/>
          <path d="M148.174 13.7087H147.006V10.485H159.673V11.653H148.174V13.7087Z" fill="#0F4C9A"/>
          <path d="M157.991 79.7423H147.006V76.5829H148.174V78.5743H157.991V79.7423Z" fill="#0F4C9A"/>
          <path d="M171.535 79.7395H160.158V78.5718H170.367V65.3185H171.535V79.7395Z" fill="#0F4C9A"/>
          <path d="M171.535 60.5024H170.367V62.6165H171.535V60.5024Z" fill="#0F4C9A"/>
          <path d="M148.174 74.3636H147.006V55.2193H163.831V56.387H148.174V74.3636Z" fill="#0F4C9A"/>
          <path d="M171.534 124.475H147.006V113.166H148.174V123.307H170.366V119.244H171.534V124.475Z" fill="#0F4C9A"/>
          <path d="M171.535 117.3H170.367V119.245H171.535V117.3Z" fill="#0F4C9A"/>
          <path d="M171.535 107.349H170.367V114.717H171.535V107.349Z" fill="#0F4C9A"/>
          <path d="M171.535 105.897H170.367V107.356H171.535V105.897Z" fill="#0F4C9A"/>
          <path d="M162.443 99.9536H160.738V101.121H162.443V99.9536Z" fill="#0F4C9A"/>
          <path d="M148.174 104.624H147.006V99.9536H158.744V101.121H148.174V104.624Z" fill="#0F4C9A"/>
        </svg>
      </MqSmall>
      <MqAfterSmall>
        <svg width="480" height="300" viewBox="0 0 480 300" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M452.154 283.571C452.154 283.571 499.183 252.956 471.005 224.32C442.828 195.685 434.402 199.236 442.512 167.334C450.623 135.432 483.476 123.546 470.415 99.3273C454.739 70.2593 418.99 96.7833 396.776 106.959C355.471 125.882 355.715 97.5923 371.58 71.1243C387.444 44.6563 388.579 0.532715 351.945 0.532715C312.101 0.532715 224.535 86.6326 176.296 146.137C147.421 181.754 126.382 156.97 129.302 134.094C131.16 119.547 135.026 96.0455 125.98 85.2487C110.278 66.4738 64.1449 125.292 47.858 150.416C-15.885 248.743 61.6008 283.571 61.6008 283.571H452.154Z" fill="#E3F0FF"/>
          <path d="M96.2657 43.7252C98.0516 39.6293 98.6266 36.4849 97.3546 33.8951C93.5894 26.2223 74.3922 24.991 62.0944 32.4602C44.9426 42.8755 40.4092 70.6918 48.26 76.8687C57.3931 84.0581 87.0309 64.8813 96.2657 43.7252Z" fill="#E3F0FF"/>
          <path d="M363.755 291.228H218.705V292.245H363.755V291.228Z" fill="#A3C5F1"/>
          <path d="M391.841 298.982H328.729V300H391.841V298.982Z" fill="#A3C5F1"/>
          <path d="M309.312 298.982H193.875V300H309.312V298.982Z" fill="#A3C5F1"/>
          <path d="M124.164 298.982H107.017V300H124.164V298.982Z" fill="#A3C5F1"/>
          <path d="M134.411 298.982H129.231V300H134.411V298.982Z" fill="#A3C5F1"/>
          <path d="M410.875 291.345H381.379V292.362H410.875V291.345Z" fill="#A3C5F1"/>
          <path d="M207.099 291.228H162.385V292.245H207.099V291.228Z" fill="#A3C5F1"/>
          <path d="M152.784 291.228H74.5907V292.245H152.784V291.228Z" fill="#A3C5F1"/>
          <path d="M65.2747 291.228H51.6133V292.245H65.2747V291.228Z" fill="#A3C5F1"/>
          <path d="M343.601 42.7529H156.62V283.59H343.601V42.7529Z" fill="#6AACFF"/>
          <path d="M343.601 193.028V283.59H156.62V222.157L343.601 193.028Z" fill="#A3C5F1"/>
          <path d="M333.42 273.415V52.9292L166.796 52.9292V273.415H333.42Z" fill="white"/>
          <path d="M300.464 113.498L288.629 103.734L291.87 99.8058L299.706 106.278L316.146 85.5898L320.129 88.7546L300.464 113.498Z" fill="#FFAFAF"/>
          <path d="M277.522 100.162H262.09V101.18H277.522V100.162Z" fill="#0958BF"/>
          <path d="M256.32 100.162H210.106V101.18H256.32V100.162Z" fill="#0958BF"/>
          <path d="M277.522 107.621H240.73V108.638H277.522V107.621Z" fill="#0958BF"/>
          <path d="M238.436 107.621H236.309V108.638H238.436V107.621Z" fill="#0958BF"/>
          <path d="M231.053 107.621H189.163V108.638H231.053V107.621Z" fill="#0958BF"/>
          <path d="M249.034 115.08H189.163V116.098H249.034V115.08Z" fill="#0958BF"/>
          <path d="M300.464 152.482L288.629 142.713L291.87 138.79L299.706 145.257L316.146 124.574L320.129 127.738L300.464 152.482Z" fill="#FFAFAF"/>
          <path d="M277.522 139.146H210.106V140.164H277.522V139.146Z" fill="#0958BF"/>
          <path d="M204.85 139.146H189.163V140.164H204.85V139.146Z" fill="#0958BF"/>
          <path d="M270.827 146.605H189.163V147.623H270.827V146.605Z" fill="#0958BF"/>
          <path d="M220.541 154.064H218.852V155.082H220.541V154.064Z" fill="#0958BF"/>
          <path d="M215.713 154.064H189.163V155.082H215.713V154.064Z" fill="#0958BF"/>
          <path d="M300.464 191.467L288.629 181.697L291.87 177.775L299.706 184.241L316.146 163.559L320.129 166.723L300.464 191.467Z" fill="#FFAFAF"/>
          <path d="M277.528 178.126H189.163V179.144H277.528V178.126Z" fill="#0958BF"/>
          <path d="M277.522 185.59H250.108V186.607H277.522V185.59Z" fill="#0958BF"/>
          <path d="M243.773 185.59H189.163V186.607H243.773V185.59Z" fill="#0958BF"/>
          <path d="M264.975 193.049H189.163V194.066H264.975V193.049Z" fill="#0958BF"/>
          <path d="M296.771 115.589H293.499V110.134L296.771 115.589Z" fill="white"/>
          <path d="M296.771 116.097H292.99V110.134H294.008V115.08H296.771V116.097Z" fill="#0F4C9A"/>
          <path d="M314.36 116.098H299.202V115.08H313.342V99.5669H314.36V116.098Z" fill="#0F4C9A"/>
          <path d="M307.333 94.7275H305.837V95.7451H307.333V94.7275Z" fill="#0F4C9A"/>
          <path d="M293.499 97.5361V95.2363H304.026L293.499 97.5361Z" fill="white"/>
          <path d="M294.008 97.5361H292.99V94.7275H304.026V95.7451H294.008V97.5361Z" fill="#0F4C9A"/>
          <path d="M302.561 155.082H292.99V152.329H294.008V154.064H302.561V155.082Z" fill="#0F4C9A"/>
          <path d="M314.36 155.082H304.448V154.064H313.342V142.514H314.36V155.082Z" fill="#0F4C9A"/>
          <path d="M314.36 138.317H313.342V140.159H314.36V138.317Z" fill="#0F4C9A"/>
          <path d="M294.008 150.396H292.99V133.712H307.649V134.73H294.008V150.396Z" fill="#0F4C9A"/>
          <path d="M314.36 194.066H292.99V184.211H294.008V193.049H313.342V189.508H314.36V194.066Z" fill="#0F4C9A"/>
          <path d="M314.36 187.814H313.342V189.508H314.36V187.814Z" fill="#0F4C9A"/>
          <path d="M314.36 179.143H313.342V185.564H314.36V179.143Z" fill="#0F4C9A"/>
          <path d="M314.36 177.876H313.342V179.148H314.36V177.876Z" fill="#0F4C9A"/>
          <path d="M306.442 172.697H304.957V173.714H306.442V172.697Z" fill="#0F4C9A"/>
          <path d="M294.008 176.767H292.99V172.697H303.217V173.714H294.008V176.767Z" fill="#0F4C9A"/>
          <path d="M294.008 225.765H292.99V227.159H294.008V225.765Z" fill="#0F4C9A"/>
          <path d="M314.36 233.051H292.99V229.113H294.008V232.033H313.342V229.515H314.36V233.051Z" fill="#0F4C9A"/>
          <path d="M314.36 215.838H313.342V227.159H314.36V215.838Z" fill="#0F4C9A"/>
          <path d="M300.464 230.446L288.629 220.682L291.87 216.759L299.706 223.226L316.146 202.538L320.129 205.708L300.464 230.446Z" fill="#FFAFAF"/>
          <path d="M270.827 217.11H189.163V218.128H270.827V217.11Z" fill="#0958BF"/>
          <path d="M277.522 224.569H213.108V225.587H277.522V224.569Z" fill="#0958BF"/>
          <path d="M208.717 224.569H206.971V225.587H208.717V224.569Z" fill="#0958BF"/>
          <path d="M201.034 224.569H189.163V225.587H201.034V224.569Z" fill="#0958BF"/>
          <path d="M220.541 232.034H189.163V233.051H220.541V232.034Z" fill="#0958BF"/>
          <path d="M333.42 272.906H166.796V273.923H333.42V272.906Z" fill="#0958BF"/>
          <path d="M204.321 42.7531V68.1933H295.905V42.7531H267.789L260.559 27.1582H239.672L232.442 42.7531H204.321Z" fill="#A3C5F1"/>
          <path d="M204.321 42.7531V61.299L295.905 47.0321V42.7531H267.789L260.559 27.1582H239.672L232.442 42.7531H204.321Z" fill="#4792F3"/>
          <path d="M468.299 283.591H18.6784V284.608H468.299V283.591Z" fill="#A3C5F1"/>
          <path d="M368.166 117.074C366.67 124.004 364.162 130.68 362.157 137.487C360.122 144.489 358.02 151.617 358.046 158.918C358.12 168.519 361.804 177.741 368.364 184.75C370.908 187.457 373.905 189.838 375.869 192.978C377.833 196.117 378.566 200.477 376.378 203.479C373.208 207.809 366.426 206.873 361.786 209.585C355.95 212.989 355.141 221.674 358.468 227.561C361.796 233.448 376.948 250.274 398.572 246.107C408.239 244.24 421.372 235.03 420.995 225.18C420.608 214.922 410.819 206.614 411.15 196.356C411.369 189.64 415.882 183.957 419.799 178.487C423.717 173.017 427.355 166.357 425.554 159.88C422.903 150.34 410.621 147.394 404.693 139.482C400.867 134.394 400.022 127.688 399.783 121.318C399.544 114.947 399.707 108.419 397.585 102.41C392.395 87.8332 374.099 89.5683 368.166 117.074Z" fill="#A3C5F1"/>
          <path d="M391.403 153.723H390.385V264.261H391.403V153.723Z" fill="#0958BF"/>
          <path d="M391.393 185.661L390.375 185.498C392.019 175.495 403.391 169.277 403.874 169.018L404.383 169.913C404.261 169.974 392.96 176.161 391.393 185.661Z" fill="#0958BF"/>
          <path d="M390.39 229.189C389.276 222.437 381.232 218.026 381.14 217.996L381.624 217.1C381.969 217.288 390.202 221.791 391.393 229.042L390.39 229.189Z" fill="#0958BF"/>
          <path d="M372.486 260.445H409.12V271.003C409.113 274.338 407.784 277.533 405.424 279.889C403.064 282.245 399.867 283.569 396.532 283.57H385.079C381.739 283.57 378.536 282.244 376.174 279.882C373.813 277.52 372.486 274.317 372.486 270.978V260.445Z" fill="#FFC359"/>
          <path d="M378.993 282.604C376.875 281.495 375.101 279.827 373.864 277.781C372.627 275.735 371.975 273.389 371.977 270.998V267.116H372.994V270.998C372.995 273.2 373.599 275.36 374.74 277.243C375.88 279.127 377.515 280.662 379.466 281.683L378.993 282.604Z" fill="#0958BF"/>
          <path d="M409.12 263.752H372.486V264.77H409.12V263.752Z" fill="white"/>
          <path d="M409.12 259.937H385.786V260.954H409.12V259.937Z" fill="#0958BF"/>
          <path d="M417.525 283.591H391.26V284.608H417.525V283.591Z" fill="#0958BF"/>
          <path d="M102.936 283.591H55.821V284.608H102.936V283.591Z" fill="#0958BF"/>
          <path d="M270.827 283.591H266.044V284.608H270.827V283.591Z" fill="#0958BF"/>
          <path d="M260.106 283.591H114.425V284.608H260.106V283.591Z" fill="#0958BF"/>
          <path d="M357.201 283.591H287.327V284.608H357.201V283.591Z" fill="#0958BF"/>
          <path d="M48.4432 283.591H44.7544V284.608H48.4432V283.591Z" fill="#0958BF"/>
          <path d="M432.845 283.591H422.923V284.608H432.845V283.591Z" fill="#0958BF"/>
          <path d="M344.11 252.304H343.092V276.132H344.11V252.304Z" fill="#0F4C9A"/>
          <path d="M344.11 142.514H343.092V241.451H344.11V142.514Z" fill="#0F4C9A"/>
          <path d="M157.129 210.415H156.112V240.856H157.129V210.415Z" fill="#0F4C9A"/>
          <path d="M157.129 203.5H156.112V205.581H157.129V203.5Z" fill="#0F4C9A"/>
          <path d="M157.129 176.497H156.112V201.001H157.129V176.497Z" fill="#0F4C9A"/>
          <path d="M296.414 68.702H247.798V67.6844H295.397V42.7529H296.414V68.702Z" fill="#0F4C9A"/>
          <path d="M242.268 67.6846H212.574V68.7022H242.268V67.6846Z" fill="#0F4C9A"/>
          <path d="M344.109 82.0947H343.092V43.2627H328.362V42.2451H344.109V82.0947Z" fill="#0958BF"/>
          <path d="M323.956 42.2451H316.659V43.2627H323.956V42.2451Z" fill="#0958BF"/>
          <path d="M254.414 37.1024C254.799 34.7336 253.19 32.5017 250.822 32.1172C248.453 31.7326 246.221 33.3412 245.836 35.71C245.452 38.0788 247.06 40.3107 249.429 40.6952C251.798 41.0797 254.03 39.4712 254.414 37.1024Z" fill="white"/>
          <path d="M254.738 37.8682L253.771 37.5629C253.889 37.1875 253.949 36.7963 253.949 36.4029C253.95 35.5356 253.658 34.6936 253.119 34.014C252.58 33.3344 251.827 32.8575 250.982 32.6609C250.138 32.4644 249.251 32.5598 248.468 32.9317C247.684 33.3035 247.05 33.9299 246.668 34.7085L245.752 34.2608C246.233 33.2705 247.037 32.4729 248.03 31.9987C249.024 31.5245 250.149 31.4018 251.222 31.6507C252.294 31.8996 253.25 32.5054 253.933 33.3687C254.617 34.2321 254.986 35.3019 254.982 36.4029C254.978 36.9011 254.895 37.3955 254.738 37.8682Z" fill="#0F4C9A"/>
          <path d="M250.113 41.2577C248.826 41.2563 247.592 40.7445 246.682 39.8345C245.772 38.9245 245.26 37.6906 245.259 36.4037V36.2612L246.277 36.2918V36.4037C246.278 37.4208 246.683 38.3958 247.402 39.1149C248.121 39.8341 249.096 40.2387 250.113 40.2401C250.51 40.241 250.905 40.1792 251.283 40.0569L251.599 41.0236C251.119 41.1801 250.617 41.2591 250.113 41.2577Z" fill="#0F4C9A"/>
          <path d="M294.008 219.573H292.99V211.681H305.7V212.699H294.008V219.573Z" fill="#0F4C9A"/>
          <path d="M4.57924 167.614C3.67355 167.614 2.78821 167.345 2.03515 166.842C1.2821 166.339 0.695166 165.623 0.348574 164.787C0.00198194 163.95 -0.0886994 163.029 0.0879917 162.141C0.264683 161.253 0.700812 160.437 1.34123 159.796C1.98165 159.156 2.79759 158.72 3.68588 158.543C4.57416 158.366 5.4949 158.457 6.33164 158.804C7.16839 159.15 7.88357 159.737 8.38674 160.49C8.88991 161.243 9.15848 162.129 9.15848 163.034C9.15848 164.249 8.67603 165.414 7.81726 166.272C6.95848 167.131 5.79373 167.614 4.57924 167.614ZM4.57924 159.473C3.87482 159.473 3.18621 159.682 2.60051 160.073C2.0148 160.464 1.5583 161.021 1.28872 161.671C1.01915 162.322 0.948622 163.038 1.08605 163.729C1.22347 164.42 1.56269 165.055 2.06079 165.553C2.55889 166.051 3.19351 166.39 3.8844 166.528C4.57529 166.665 5.29142 166.594 5.94222 166.325C6.59302 166.055 7.14928 165.599 7.54063 165.013C7.93199 164.427 8.14087 163.739 8.14087 163.034C8.14087 162.09 7.76563 161.184 7.0977 160.516C6.42976 159.848 5.52385 159.473 4.57924 159.473Z" fill="#A3C5F1"/>
          <path d="M415.312 44.437H414.294V48.7975H415.312V44.437Z" fill="#A3C5F1"/>
          <path d="M415.312 70.6045H414.294V74.9649H415.312V70.6045Z" fill="#A3C5F1"/>
          <path d="M407.606 46.2236L406.725 46.7324L408.905 50.5087L409.787 49.9999L407.606 46.2236Z" fill="#A3C5F1"/>
          <path d="M420.685 68.8647L419.804 69.3735L421.984 73.1498L422.865 72.641L420.685 68.8647Z" fill="#A3C5F1"/>
          <path d="M401.83 51.6182L401.322 52.4995L405.098 54.6797L405.607 53.7985L401.83 51.6182Z" fill="#A3C5F1"/>
          <path d="M424.513 64.7305L424.004 65.6118L427.78 67.792L428.289 66.9108L424.513 64.7305Z" fill="#A3C5F1"/>
          <path d="M403.899 59.1924H399.539V60.21H403.899V59.1924Z" fill="#A3C5F1"/>
          <path d="M430.062 59.1924H425.701V60.21H430.062V59.1924Z" fill="#A3C5F1"/>
          <path d="M405.118 64.753L401.341 66.9326L401.85 67.814L405.626 65.6344L405.118 64.753Z" fill="#A3C5F1"/>
          <path d="M427.775 51.6569L423.999 53.8364L424.507 54.7178L428.284 52.5382L427.775 51.6569Z" fill="#A3C5F1"/>
          <path d="M408.878 68.865L406.698 72.6416L407.58 73.1503L409.759 69.3736L408.878 68.865Z" fill="#A3C5F1"/>
          <path d="M421.959 46.2028L419.78 49.9795L420.661 50.4881L422.84 46.7115L421.959 46.2028Z" fill="#A3C5F1"/>
          <path d="M186.497 21.394H185.48C185.48 19.5048 184.729 17.693 183.393 16.3571C182.057 15.0212 180.246 14.2708 178.356 14.2708V13.2531C180.246 13.2531 182.057 12.5027 183.393 11.1668C184.729 9.83092 185.48 8.01909 185.48 6.12988H186.497C186.497 8.01909 187.248 9.83092 188.584 11.1668C189.919 12.5027 191.731 13.2531 193.62 13.2531V14.2708C191.731 14.2708 189.919 15.0212 188.584 16.3571C187.248 17.693 186.497 19.5048 186.497 21.394ZM181.206 13.762C182.302 14.1719 183.298 14.8124 184.125 15.6401C184.953 16.4678 185.594 17.4635 186.004 18.56C186.414 17.4635 187.054 16.4678 187.882 15.6401C188.71 14.8124 189.705 14.1719 190.802 13.762C189.705 13.352 188.71 12.7115 187.882 11.8838C187.054 11.0561 186.414 10.0604 186.004 8.96392C185.594 10.0604 184.953 11.0561 184.125 11.8838C183.298 12.7115 182.302 13.352 181.206 13.762Z" fill="#A3C5F1"/>
          <path d="M204.83 42.7529H203.812V69.0937H204.83V42.7529Z" fill="white"/>
          <path d="M137.993 86.1291L143.6 85.1675L150.667 114.602L141.209 116.23L137.993 86.1291Z" fill="#FFE159"/>
          <path d="M181.174 90.3826L46.3931 124.663L48.2706 132.044L183.051 97.7644L181.174 90.3826Z" fill="#FF9D45"/>
          <path d="M179.3 82.9983L44.5193 117.278L46.3968 124.66L181.177 90.3801L179.3 82.9983Z" fill="#FFC359"/>
          <path d="M177.416 75.6155L42.6356 109.896L44.5131 117.277L179.294 82.9973L177.416 75.6155Z" fill="#FFC359"/>
          <path d="M31.5606 112.709L20.4855 115.525L26.1192 137.676L37.1943 134.859L31.5606 112.709Z" fill="#FF809F"/>
          <path d="M42.635 109.898L31.5598 112.714L37.1935 134.865L48.2687 132.048L42.635 109.898Z" fill="#A3C5F1"/>
          <path d="M214.716 77.9118L177.42 75.6069L183.053 97.7552L214.716 77.9118Z" fill="#A3C5F1"/>
          <path d="M203.517 84.9334L183.053 97.7604L181.18 90.3725L202.85 82.3232L203.517 84.9334Z" fill="#6AACFF"/>
          <path d="M214.715 77.9116L203.517 84.9331L202.85 82.323L214.715 77.9116Z" fill="#0958BF"/>
          <path d="M214.716 77.9115L202.85 82.3228L201.522 77.0923L214.716 77.9115Z" fill="#4792F3"/>
          <path d="M46.3911 124.663L35.316 127.479L37.1935 134.861L48.2686 132.044L46.3911 124.663Z" fill="#6AACFF"/>
          <path d="M35.3132 127.48L24.238 130.296L26.1155 137.678L37.1907 134.861L35.3132 127.48Z" fill="#E5659B"/>
          <path d="M176.51 98.7976L70.9259 125.671L71.1769 126.658L176.761 99.7838L176.51 98.7976Z" fill="#0958BF"/>
          <path d="M64.9642 127.269L43.4954 132.733L43.7464 133.719L65.2152 128.255L64.9642 127.269Z" fill="#0F4C9A"/>
          <path d="M25.7559 138.296L21.8432 122.909L22.8303 122.66L26.4937 137.054L41.6153 133.213L41.8647 134.195L25.7559 138.296Z" fill="#0F4C9A"/>
          <path d="M21.0442 119.766L19.874 115.156L31.4391 112.215L31.6935 113.202L21.1104 115.894L22.0313 119.517L21.0442 119.766Z" fill="#0F4C9A"/>
          <path d="M43.1315 109.774L42.1453 110.025L47.0051 129.133L47.9913 128.882L43.1315 109.774Z" fill="white"/>
          <path d="M35.2794 125.25L34.2932 125.5L34.8275 127.601L35.8137 127.35L35.2794 125.25Z" fill="white"/>
          <path d="M33.3457 117.676L32.3595 117.927L33.8081 123.623L34.7943 123.372L33.3457 117.676Z" fill="white"/>
          <path d="M177.914 75.4677L176.928 75.7188L180.687 90.4866L181.673 90.2356L177.914 75.4677Z" fill="white"/>
          <path d="M82.4518 140.255C84.0444 138.103 85.637 136.002 85.698 132.842C85.8405 125.052 82.3755 124.701 82.8334 116.906C83.2914 109.111 89.0612 109.559 89.5141 101.759C89.5769 100.863 89.5118 99.9614 89.3207 99.0829C88.7326 98.042 88.2388 96.9505 87.8452 95.8215C87.7739 95.6282 87.7078 95.4246 87.6467 95.2262C86.6977 92.1954 86.4825 88.9823 87.0189 85.852C87.5553 82.7217 88.828 79.7636 90.7321 77.2217C92.6361 74.6798 95.117 72.6267 97.9703 71.2318C100.824 69.8369 103.967 69.1401 107.143 69.1987C110.318 69.2574 113.434 70.0698 116.234 71.5692C119.034 73.0685 121.437 75.2117 123.246 77.8222C125.055 80.4327 126.218 83.4357 126.638 86.5837C127.059 89.7317 126.725 92.9346 125.665 95.9284C125.598 96.1268 125.522 96.3151 125.451 96.5084C125.014 97.6263 124.478 98.7026 123.848 99.724C123.631 100.592 123.535 101.486 123.563 102.38C123.726 110.175 129.506 109.976 129.669 117.766C129.832 125.556 126.357 125.795 126.214 133.58C126.153 136.745 127.669 138.902 129.186 141.115C130.702 143.329 132.208 145.603 132.147 148.966C132.104 151.261 131.609 153.525 130.69 155.628C129.772 157.732 128.448 159.634 126.794 161.225C125.14 162.817 123.189 164.067 121.052 164.903C118.915 165.74 116.633 166.148 114.339 166.103L96.3168 165.767C94.024 165.725 91.7618 165.232 89.6597 164.316C87.5576 163.399 85.6567 162.077 84.0657 160.426C82.4747 158.774 81.2248 156.826 80.3873 154.691C79.5499 152.556 79.1415 150.277 79.1853 147.984C79.2667 144.631 80.8644 142.418 82.4518 140.255Z" fill="#0958BF"/>
          <path d="M122.413 165.433C126.725 160.533 126.248 153.065 121.348 148.753C116.448 144.441 108.98 144.918 104.668 149.818C100.356 154.718 100.833 162.186 105.733 166.498C110.633 170.81 118.101 170.333 122.413 165.433Z" fill="#FFE159"/>
          <path d="M123.208 123.577C125.614 120.827 125.335 116.648 122.585 114.243C119.836 111.838 115.657 112.117 113.252 114.866C110.846 117.616 111.125 121.795 113.875 124.2C116.624 126.605 120.803 126.326 123.208 123.577Z" fill="#FFAFAF"/>
          <path d="M144.872 111.05C145.797 110.83 146.766 110.889 147.658 111.219C148.549 111.55 149.323 112.137 149.88 112.907C150.438 113.677 150.755 114.595 150.791 115.545C150.827 116.496 150.581 117.435 150.083 118.245C149.585 119.055 148.858 119.7 147.994 120.097C147.13 120.494 146.168 120.626 145.229 120.476C144.29 120.326 143.417 119.901 142.719 119.255C142.021 118.609 141.531 117.771 141.31 116.846C141.015 115.605 141.224 114.298 141.892 113.211C142.56 112.125 143.631 111.347 144.872 111.05Z" fill="#FFE159"/>
          <path d="M117.839 112.597L145.711 110.928L146.897 120.448L119.472 125.694L117.839 112.597Z" fill="#FFE159"/>
          <path d="M80.0756 282.319C81.9021 282.319 83.3828 280.838 83.3828 279.011C83.3828 277.185 81.9021 275.704 80.0756 275.704C78.2491 275.704 76.7684 277.185 76.7684 279.011C76.7684 280.838 78.2491 282.319 80.0756 282.319Z" fill="#FFAFAF"/>
          <path d="M117.732 282.319C119.559 282.319 121.04 280.838 121.04 279.011C121.04 277.185 119.559 275.704 117.732 275.704C115.906 275.704 114.425 277.185 114.425 279.011C114.425 280.838 115.906 282.319 117.732 282.319Z" fill="#0958BF"/>
          <path d="M124.835 119.064L125.598 156.1L101.99 157.677L89.6208 119.557C89.4297 118.968 89.3757 118.343 89.4627 117.73C89.5497 117.117 89.7755 116.532 90.1229 116.019C90.4703 115.506 90.9301 115.079 91.4674 114.771C92.0046 114.463 92.6051 114.281 93.2231 114.24L117.783 112.612C118.674 112.553 119.568 112.675 120.411 112.97C121.254 113.266 122.028 113.729 122.687 114.332C123.346 114.935 123.876 115.665 124.245 116.478C124.614 117.291 124.815 118.171 124.835 119.064Z" fill="#FFE159"/>
          <path d="M119.687 125.643C123.251 124.839 125.489 121.299 124.686 117.736C123.882 114.172 120.342 111.934 116.779 112.737C113.215 113.54 110.977 117.08 111.78 120.644C112.583 124.208 116.123 126.446 119.687 125.643Z" fill="#FFE159"/>
          <path d="M125.624 156.878H101.965L100.688 157.896C98.5682 159.543 96.8532 161.652 95.6733 164.063C94.4935 166.474 93.88 169.122 93.8797 171.806V176.894C93.8797 178.784 94.6302 180.595 95.9661 181.931C97.3019 183.267 99.1138 184.018 101.003 184.018H121.548C123.438 184.018 125.25 183.267 126.585 181.931C127.921 180.595 128.672 178.784 128.672 176.894V169.924C128.675 165.398 127.632 160.934 125.624 156.878Z" fill="#0958BF"/>
          <path d="M130.64 131.489C130.586 130.691 130.386 129.91 130.05 129.184L124.234 116.464L112.196 121.959L118.012 134.679C118.656 136.084 119.772 137.22 121.167 137.888C122.561 138.556 124.145 138.714 125.644 138.336C127.143 137.957 128.462 137.066 129.372 135.816C130.282 134.566 130.725 133.037 130.625 131.494L130.64 131.489Z" fill="#FFE159"/>
          <path d="M126.054 119.202L125.129 119.625L125.998 121.527L126.923 121.104L126.054 119.202Z" fill="#FFAFAF"/>
          <path d="M124.026 139.069C123.064 139.07 122.112 138.874 121.228 138.494L121.635 137.558C122.521 137.942 123.485 138.107 124.448 138.042C125.249 137.988 126.03 137.777 126.749 137.421C127.468 137.065 128.109 136.572 128.637 135.968C129.165 135.364 129.568 134.662 129.825 133.902C130.081 133.142 130.186 132.339 130.132 131.539C130.084 130.802 129.899 130.081 129.587 129.412L129.374 128.939L126.83 123.342L127.756 122.92L130.742 129.458C131.127 130.497 131.264 131.612 131.143 132.713C131.021 133.815 130.644 134.872 130.042 135.803C129.44 136.733 128.629 137.51 127.674 138.072C126.719 138.633 125.646 138.965 124.54 139.039L124.026 139.069Z" fill="#FFAFAF"/>
          <path d="M88.3945 124.508C89.1209 125.597 90.1544 126.447 91.3638 126.948C92.5733 127.45 93.9044 127.581 95.1885 127.325C96.4727 127.07 97.6521 126.439 98.5773 125.512C99.5026 124.586 100.132 123.406 100.386 122.121C100.64 120.837 100.507 119.506 100.004 118.297C99.5014 117.088 98.6509 116.056 97.5608 115.331C96.4706 114.606 95.1898 114.22 93.8804 114.224C92.5711 114.227 91.2922 114.619 90.2058 115.35C88.7539 116.326 87.7481 117.838 87.4086 119.555C87.0691 121.271 87.4236 123.052 88.3945 124.508Z" fill="#FFE159"/>
          <path d="M89.5242 115.858L68.5258 134.262L74.372 141.868L97.5735 126.325L89.5242 115.858Z" fill="#FFE159"/>
          <path d="M121.548 184.007C125.482 184.007 128.672 180.818 128.672 176.884C128.672 172.95 125.482 169.761 121.548 169.761C117.614 169.761 114.425 172.95 114.425 176.884C114.425 180.818 117.614 184.007 121.548 184.007Z" fill="#0958BF"/>
          <path d="M101.003 184.007C104.937 184.007 108.126 180.818 108.126 176.884C108.126 172.95 104.937 169.761 101.003 169.761C97.0689 169.761 93.8797 172.95 93.8797 176.884C93.8797 180.818 97.0689 184.007 101.003 184.007Z" fill="#0958BF"/>
          <path d="M120.18 219.471C123.358 219.471 125.934 216.894 125.934 213.716C125.934 210.538 123.358 207.961 120.18 207.961C117.001 207.961 114.425 210.538 114.425 213.716C114.425 216.894 117.001 219.471 120.18 219.471Z" fill="#0958BF"/>
          <path d="M114.425 176.884V213.717L125.919 214.149L128.651 177.424L114.425 176.884Z" fill="#FFAFAF"/>
          <path d="M103.766 113.913L114.86 112.423L113.025 98.7625L101.931 100.253L103.766 113.913Z" fill="#FFAFAF"/>
          <path d="M103.765 113.915C103.913 115.012 104.383 116.04 105.115 116.87C105.848 117.7 106.81 118.295 107.88 118.578C108.95 118.861 110.081 118.82 111.128 118.461C112.175 118.102 113.092 117.441 113.763 116.56C114.434 115.68 114.829 114.62 114.898 113.515C114.967 112.411 114.707 111.31 114.15 110.353C113.594 109.396 112.766 108.626 111.771 108.14C110.777 107.653 109.66 107.473 108.563 107.621C107.093 107.819 105.761 108.594 104.861 109.774C103.961 110.954 103.567 112.444 103.765 113.915Z" fill="#FFAFAF"/>
          <path d="M100.774 95.567L103.292 95.2312L102.616 90.189L100.072 90.5248C99.4029 90.6146 98.7973 90.9663 98.388 91.5025C97.9787 92.0388 97.7991 92.7156 97.8887 93.3843C97.9331 93.7178 98.0431 94.0392 98.2125 94.3299C98.3819 94.6206 98.6073 94.8748 98.8756 95.0778C99.1439 95.2808 99.4498 95.4286 99.7756 95.5126C100.101 95.5965 100.441 95.6151 100.774 95.567Z" fill="#FFAFAF"/>
          <path d="M118.261 78.3237L99.3135 80.8678L102.6 105.331L114.369 103.749C115.485 103.599 116.558 103.222 117.522 102.641C118.487 102.06 119.322 101.289 119.977 100.373C120.632 99.4572 121.093 98.4171 121.331 97.3167C121.568 96.2164 121.578 95.0789 121.36 93.9746L118.261 78.3237Z" fill="#FFAFAF"/>
          <path d="M100.601 90.4587L100.092 90.5248L98.1383 75.9781L113.148 73.9429C115.051 73.6883 116.978 74.1996 118.504 75.3645C120.031 76.5294 121.032 78.2525 121.289 80.1554L121.325 80.4403L120.816 80.5116C116.52 81.0907 112.531 83.0558 109.454 86.1084C107.052 88.487 103.941 90.0178 100.591 90.4689L100.601 90.4587Z" fill="#0958BF"/>
          <path d="M117.345 124.427L116.562 112.684L98.6517 113.879L117.345 124.427Z" fill="#FFAFAF"/>
          <path d="M125.919 214.154L121.034 279.261L114.425 279.012V213.717L125.919 214.154Z" fill="#FFAFAF"/>
          <path d="M96.7034 164.734C96.7212 165.237 96.5895 165.734 96.3251 166.162C96.0606 166.59 95.6753 166.93 95.2177 167.138C94.7601 167.347 94.2509 167.416 93.7543 167.336C93.2578 167.255 92.7962 167.029 92.4279 166.687C92.0597 166.344 91.8013 165.9 91.6854 165.41C91.5695 164.921 91.6013 164.408 91.7768 163.937C91.9523 163.465 92.2636 163.056 92.6714 162.762C93.0792 162.467 93.5651 162.3 94.0678 162.282C94.4019 162.27 94.7352 162.323 95.0486 162.44C95.3619 162.557 95.6492 162.734 95.894 162.962C96.1388 163.19 96.3363 163.463 96.4751 163.768C96.614 164.072 96.6916 164.4 96.7034 164.734Z" fill="#FFAFAF"/>
          <path d="M100.423 169.186C100.03 169.2 99.6383 169.148 99.2628 169.033L93.4218 167.263L94.0679 162.281L100.174 162.068C100.641 162.051 101.108 162.127 101.546 162.291C101.984 162.455 102.386 162.703 102.729 163.023C103.071 163.342 103.347 163.725 103.541 164.151C103.735 164.577 103.844 165.037 103.86 165.505C103.876 165.972 103.8 166.439 103.636 166.877C103.473 167.315 103.224 167.717 102.905 168.06C102.586 168.402 102.202 168.678 101.776 168.872C101.351 169.066 100.891 169.175 100.423 169.191V169.186Z" fill="#FFAFAF"/>
          <path d="M74.5296 141.757C75.297 141.197 75.881 140.422 76.2079 139.53C76.5348 138.638 76.5899 137.669 76.3661 136.746C76.1424 135.822 75.6499 134.986 74.9509 134.343C74.2519 133.699 73.3779 133.278 72.4393 133.131C71.5007 132.985 70.5397 133.12 69.6779 133.519C68.816 133.919 68.092 134.565 67.5974 135.376C67.1028 136.187 66.8598 137.127 66.8991 138.076C66.9385 139.025 67.2584 139.941 67.8185 140.708C68.5703 141.736 69.6991 142.424 70.9573 142.62C72.2155 142.817 73.5002 142.506 74.5296 141.757Z" fill="#FFE159"/>
          <path d="M68.2151 141.187L92.3172 166.581L96.2146 163.325L75.5826 135.041L68.2151 141.187Z" fill="#FFAFAF"/>
          <path d="M89.0557 163.147L93.4213 159.499L75.5826 135.041L68.2151 141.192L89.0557 163.147Z" fill="#FFE159"/>
          <path d="M93.9763 175.704L76.8093 278.462L83.2863 279.795L107.923 178.574L93.9763 175.704Z" fill="#FFAFAF"/>
          <path d="M76.7683 278.991H83.3828V283.57H74.1124C73.9262 283.57 73.7418 283.534 73.5699 283.462C73.3979 283.391 73.2418 283.286 73.1104 283.154C72.979 283.022 72.8749 282.866 72.8041 282.694C72.7333 282.522 72.6972 282.337 72.6979 282.151C72.6982 281.902 72.7636 281.659 72.8878 281.443C73.0119 281.228 73.1903 281.049 73.4051 280.925L76.7683 278.991Z" fill="#0958BF"/>
          <path d="M117.692 96.1016C117.806 96.835 117.624 97.5837 117.186 98.1829C116.748 98.7821 116.09 99.1828 115.356 99.2968C114.623 99.4109 113.874 99.2289 113.275 98.7909C112.676 98.3529 112.275 97.6949 112.161 96.9614L117.692 96.1016Z" fill="white"/>
          <path d="M77.2633 272.685L76.7712 275.625L77.7749 275.793L78.267 272.853L77.2633 272.685Z" fill="#0F4C9A"/>
          <path d="M114.934 198.839H113.916V202.131H114.934V198.839Z" fill="#0F4C9A"/>
          <path d="M82.3426 135.933L80.5332 137.145L81.0994 137.991L82.9089 136.779L82.3426 135.933Z" fill="#FFAFAF"/>
          <path d="M95.3785 127.187L84.019 134.794L84.5852 135.64L95.9448 128.033L95.3785 127.187Z" fill="#FFAFAF"/>
          <path d="M142.382 110.62L122.361 111.82L122.422 112.836L142.443 111.636L142.382 110.62Z" fill="#FFAFAF"/>
          <path d="M147.942 82.5867L128.017 87.6582L128.268 88.6444L148.193 83.5728L147.942 82.5867Z" fill="#0F4C9A"/>
          <path d="M136.751 84.8821C136.523 84.4336 136.433 83.9275 136.492 83.4277C136.552 82.928 136.758 82.4571 137.085 82.0746C137.412 81.6922 137.845 81.4153 138.33 81.2791C138.814 81.1428 139.328 81.1534 139.807 81.3093C140.285 81.4652 140.707 81.7596 141.018 82.1551C141.329 82.5506 141.516 83.0296 141.555 83.5313C141.594 84.0331 141.483 84.5351 141.237 84.9739C140.99 85.4127 140.619 85.7685 140.17 85.9964C139.569 86.3017 138.871 86.3559 138.23 86.1469C137.589 85.938 137.057 85.4831 136.751 84.8821Z" fill="#FFAFAF"/>
          <path d="M131.501 82.41C131.852 82.2323 132.23 82.1153 132.62 82.0641L138.665 81.2144L140.191 86.0022L134.747 88.765C134.329 88.9861 133.871 89.1218 133.401 89.1641C132.93 89.2065 132.455 89.1548 132.005 89.0119C131.554 88.869 131.137 88.6379 130.776 88.3319C130.416 88.026 130.12 87.6514 129.906 87.23C129.692 86.8086 129.564 86.3487 129.529 85.8773C129.495 85.4059 129.554 84.9323 129.705 84.4841C129.855 84.036 130.093 83.6222 130.405 83.267C130.717 82.9118 131.096 82.6222 131.521 82.4151L131.501 82.41Z" fill="#FFAFAF"/>
          <path d="M88.2063 210.262H126.209L128.651 177.424L93.9761 175.704L88.2063 210.262Z" fill="#0958BF"/>
          <path d="M92.9008 179.121L77.5464 270.875L78.5501 271.042L93.9045 179.288L92.9008 179.121Z" fill="#0F4C9A"/>
          <path d="M114.934 215.97H113.916V275.704H114.934V215.97Z" fill="#0F4C9A"/>
          <path d="M121.04 278.991H114.425V283.57H123.711C123.897 283.57 124.081 283.534 124.253 283.462C124.425 283.391 124.581 283.286 124.713 283.154C124.844 283.022 124.948 282.866 125.019 282.694C125.09 282.522 125.126 282.337 125.125 282.151C125.125 281.902 125.059 281.659 124.935 281.443C124.811 281.228 124.633 281.049 124.418 280.925L121.04 278.991Z" fill="#0958BF"/>
        </svg>
      </MqAfterSmall>
    </div>
  )
}
