import React from 'react'


export default function QiwiIcon() {
  return (
    <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.5602 21.1586C26.42 19.71 25.7954 18.3452 24.7812 17.2717C23.767 16.1982 22.4187 15.4746 20.9411 15.2109C21.2809 15.0338 21.3593 14.9578 21.4377 14.9578C24.0512 14.7048 24.0512 14.6541 23.633 12.0726C23.1491 9.87223 21.846 7.92121 19.9721 6.59138C18.0982 5.26155 15.7846 4.64587 13.4721 4.86168C11.1597 5.07748 9.01004 6.10968 7.4329 7.76157C5.85576 9.41346 4.96138 11.5695 4.9202 13.8189C4.92562 16.0749 5.77644 18.2524 7.3146 19.9467C8.85277 21.6411 10.9737 22.7372 13.2835 23.0315C14.2766 23.0315 14.8255 23.0315 14.6948 21.8419C14.6684 21.4205 14.6684 20.9979 14.6948 20.5765C15.3482 20.5765 15.4266 20.9561 15.6357 21.2345C16.3712 22.3036 17.3222 23.2178 18.4314 23.9223C19.5407 24.6268 20.7855 25.1072 22.0911 25.3346C25.3032 25.8423 28.2325 27.4173 30.3759 29.789C30.6634 30.118 31.0555 30.3964 31.0032 30.9026C30.4544 31.2063 30.193 30.7255 29.9055 30.4724C28.3343 29.2096 26.502 28.2875 24.532 27.768C22.5619 27.2485 20.4998 27.1437 18.4844 27.4606C16.0894 27.8657 13.64 27.8657 11.2449 27.4606C8.68246 26.9109 6.32478 25.691 4.42848 23.9338C2.53217 22.1767 1.1699 19.9495 0.489917 17.4946C-0.190069 15.0398 -0.161715 12.4513 0.571892 10.011C1.3055 7.57065 2.71625 5.37193 4.65062 3.6541C6.58499 1.93627 8.96886 0.76515 11.5428 0.268193C14.1167 -0.228764 16.7821 -0.032518 19.2489 0.835573C21.7156 1.70366 23.8893 3.21034 25.5332 5.19163C27.1772 7.17292 28.2284 9.55292 28.5726 12.0726C29.0205 15.236 28.3084 18.4513 26.5602 21.1586Z"
        fill="#859AB6"/>
      <path
        d="M23.2685 23.2094C23.2685 23.9687 22.8765 24.2471 22.3015 24.2471C22.1211 24.2542 21.9411 24.2251 21.7729 24.1615C21.6046 24.0979 21.4518 24.0012 21.3241 23.8775C21.1964 23.7539 21.0966 23.6059 21.0309 23.443C20.9652 23.2801 20.9351 23.1058 20.9425 22.931C20.9425 22.3742 21.2038 21.9693 21.805 22.0199C22.1417 22.0596 22.4592 22.1931 22.7191 22.4043C22.9789 22.6155 23.1698 22.8952 23.2685 23.2094Z"
        fill="#859AB6"/>
      <path
        d="M25.1752 21.7412C24.9092 21.6261 24.6808 21.4425 24.5149 21.2105C24.349 20.9784 24.252 20.7068 24.2344 20.4251C24.2344 20.0961 24.2344 19.6912 24.7048 19.7671C25.1752 19.843 25.4889 20.4504 25.5934 20.9566C25.6979 21.4628 25.6718 21.64 25.1752 21.7412Z"
        fill="#859AB6"/>
    </svg>
  )
}
