import { SET_PAYMENTS_HISTORY, RESET_HISTORY_PAYMENTS, SET_RESPONSE_ID } from './actionTypes'
import { downloadPaymentsHistory } from './api'

export const setPaymentsHistory = () => async dispatch => {
  const history = await downloadPaymentsHistory()
  if (!history) return
  dispatch({
    type: SET_PAYMENTS_HISTORY,
    history
  })
}

export const resetHistoryPayments = () => ({
  type: RESET_HISTORY_PAYMENTS
})

export const updatePaymentsHistory = () => ({
  type: SET_RESPONSE_ID
})
