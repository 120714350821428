import { axios, axiosV3 } from '../../axios'
import store from '../index'
import _ from 'lodash'

export async function downloadType() {
  const locale = store.getState().locale.locale.name
  try {
    const response = await axiosV3.get('types?include=parent')
    const types = response.data.reduce((prev, item) => {
      let transformItemType = item.type
      if (transformItemType.includes('-')) {
        transformItemType = item.type
          .split('-')
          .map((item, index) => {
            if (!!index) {
              return item[0].toUpperCase() + item.slice(1)
            }
            return item
          })
          .join('')
      }
      if (!item.value) {
        item.title = item.meta[locale] ? item.meta[locale].title : item.meta.ru.title
      }
      prev[transformItemType] = prev[transformItemType] ? [...prev[transformItemType], item] : [item]
      return prev
    }, {})
    const needUploadPassport = types.settings.find(setting => setting.name === 'need_upload_passport')
    const needFormatPassportField = needUploadPassport.value !== undefined
    if (needFormatPassportField) {
      types.needUploadPassport = !!Number(needUploadPassport.value)
    }
    const countries = types.countries.filter(item => !['all', 'xa', 'xb'].includes(item.name))
    types.restrictions = transformRestrictions(types.restrictions, response.included)
    return {
      ...types,
      countries
    }
  } catch (e) {
    console.info('Error in the method "downloadType": ', e)
    return false
  }
}

function transformRestrictions(restrictions, included) {
  const unionArr = included.concat(restrictions)
  let result = {}
  let objPaths = []
  let transfromUnion = unionArr.reduce((prev, restrict) => {
    if (restrict.type !== 'restrictions') return prev
    let item = restrict
    let parent_id = item.parent?.id
    let str = parent_id ? `${parent_id}` : item.id
    while (parent_id) {
      item = restrictions.find(rest => rest.id === parent_id)
      parent_id = item.parent?.id
      str = parent_id ? `${parent_id}.children.${str}` : str
    }
    let updStr = restrict.parent ? `${str}.children.${restrict.id}` : `${str}`
    objPaths.push(updStr)
    prev[restrict.id] = restrict
    return prev
  }, {})
  objPaths.sort((a, b) => b.length - a.length)
  objPaths.forEach(item => {
    let restrictObj = _.get(result, item, {});
    let sliceId = item.slice(item.lastIndexOf('.') + 1)
    const restrict = transfromUnion[sliceId]
    const emptyObj = !Object.keys(restrictObj).length ? {children: {}} : {}
    restrictObj = {
      ...restrictObj,
      ...restrict,
      ...emptyObj
    }
    if (!included.find(item1 => item1.id === item)) _.set(result, item, restrictObj)
  })
  for (const [key, value] of Object.entries(result)) {
    if (!value.name) {
      result[key] = {
        ...value,
        ...transfromUnion[key]
      }
    }
  }
  return result
}
