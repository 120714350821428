import { axiosV3 } from '../../axios'
import store from '../index'
import { updateDeletedApp } from './actions'
import Notification from '../../components/notification/Notification'
import i18n from 'i18next'
import FormDataV3 from "../../utils/formDataApiV3";

export async function downloadDeletedApplications() {
  const state = store.getState()
  const userId = state.user.userData?.id || state.user.id
  try {
    const response = await axiosV3.get(`subtasks?include=application,deletion_reason,application.default_locale&filter[needs_deletion_reason_for]=${userId}`)
    return response.data.map(deletion => {
      const {deletion_reason: deletionReason} = deletion
      delete deletion['deletion_reason']
      return {
        ...deletion,
        deletionReason
      }
    })
  } catch (e) {
    if (e.logout) return
    console.info('Error in the method "downloadDeletedApplications": ', e)
  }
}

export async function uploadScreenshotsWithReason(files, taskId, appId, isFirstUploading) {
  let response
  if (!files || !files.length) return
  const initData = store.getState().application.selectedDeletedApp
  const data = new FormDataV3()
  data.addParam('type', 'deletion-reasons')
  data.addAttr('files', files)
  try {
    data.addRelation('subtask', {
      data: {
        id: taskId,
        type: 'subtasks'
      }
    })
    if (isFirstUploading) {
      data.addRelation('subtask', {
        data: {
          id: taskId,
          type: 'subtasks'
        }
      })
      response = await axiosV3.post(`deletion-reasons`, data)
    } else {
      const deletionReasonId = initData.deletionReason?.id
      data.addParam('id', deletionReasonId)
      response = await axiosV3.patch(`deletion-reasons/${deletionReasonId}`, data)
    }
    const updatedData = response.data
    const result = {
      ...initData,
      deletionReason: {
        files: updatedData.files,
        status: updatedData.status,
        id: updatedData.id
      }
    }
    store.dispatch(updateDeletedApp(result))
    Notification({ noticeType: 'success', text: i18n.t('Reason screenshots uploaded') })
    return true
  } catch (e) {
    if (e.logout || e.offline) return
    Notification({ noticeType: 'error', text: i18n.t('Reason screenshots uploaded error') })
    console.info('Error in the method "uploadScreenshotsWithReason": ', e)
  }
}
