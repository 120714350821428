import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import '../../style/sass/languageSwitcher/LanguageSwitcher.scss'
import { configuration } from '../../configuration'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'


export default function LanguageSwitcher() {
  const { i18n } = useTranslation()

  const location = useLocation()

  const history = useHistory()

  const currentLocale = useSelector((state: RootState) => state.locale.locale.name)

  const [showList, setShowList] = useState(false)

  const handleSwitchLocale = locale => {
    const path = location.pathname.split('/').reverse()[0]
    if (['sign-in', 'sign-up'].includes(path)) {
      history.push(`/${locale}/${path}`)
    }
    i18n.changeLanguage(locale)
    setShowList(false)
  }

  const toggleShowList = () => {
    setShowList(!showList)
  }

  const getLocaleList = () => {
    return (
      <div className="select-locale__list">
        {configuration.localeList.map((item: string) => {
          if (item === currentLocale) return
          return (
            <div
              key={item}
              className="select-locale__item"
              onClick={() => handleSwitchLocale(item)}>
              {item}
            </div>
          )
        })}
      </div>
    )
  }

  const activeClass = showList ? ' active' : ''

  return (
    <div className={`select-locale${activeClass}`}>
      <div className="select-locale__current" onClick={toggleShowList}>
        {currentLocale}
      </div>
      {getLocaleList()}
    </div>
  )
}
