export type Configuration = {
  baseUrl?: string
  backendUrl?: string
  localeList: Array<string>
  breakpoints: {
    medium: number,
    large: number
  },
  heightBreakpoints: {
    high: number
  }
}

export const configuration: Configuration = {
  baseUrl: process.env.REACT_APP_CDN_URL,
  backendUrl: process.env.REACT_APP_BACKEND_URL,
  localeList: ['ru', 'en'],
  breakpoints: {
    medium: 576,
    large: 1200
  },
  heightBreakpoints: {
    high: 540
  }
}
