export interface Accrual {
  id: number;
  amount: number,
  confirmed: boolean,
  created_at: string,
  updated_at: string,
  deposit_type: DepositType,
  subtask?: {
    id: string
  }
}

export type DepositType = {
  name: AccrualType,
}

export enum AccrualType {
  replenished_admin = 'Accrual from the administrator',
  replenished_marathon = 'For app life',
  replenished_publication_app = 'For publish app',
  replenished_update_app = 'For update app',
  replenished_verification = 'For verification',
  replenished_compensation_google = 'Compensation for Google console',
  replenished_compensation_google_register = 'Compensation for Google console reg',
  affiliate_program = 'Profit from promo',
  replenished_deletion_reason_description = 'Upload reason for deletion',
  replenished_welcome_bonus_register = 'Welcome bonus upon registration',
  replenished_complete_task = 'For completing the task',
  replenished_promocode = 'For promo code'
}

export interface AccrualsState {
  history: Array<Accrual>,
  meta: {
    [key: string]: any
  }
}

const initialState: AccrualsState = {
  history: [],
  meta: {}
}

export default initialState
