import React from 'react'
import Paragraph from '../../common_kit/text/Paragraph'
import USDTIcon from '../../funds_account_icon/USDTIcon'


type Data = {
  countDetails: string
}

export default function UsdtMoney(props: Data) {
  return (
    <div className="payment-count usdt-money">
      <USDTIcon/>
      <Paragraph text={props.countDetails}/>
    </div>
  )
}
