import React, { useEffect, useState } from 'react'
import { PopupBtnType, PopupType } from './TaskPreviewCard'
import { Button, notification } from 'antd'
import Paragraph, { Align } from '../common_kit/text/Paragraph'
import { RightCircleTwoTone } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'
import _ from 'lodash'
import IconClear from '../icons/IconClear'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { taskPopupCloseHandler } from '../../utils/helpers'


type Data = {
  popupType: PopupType
  taskId: string
  openModalForUploadingReason: () => void
  popupCloseHandler: () => void
}

type PopupOptions = {
  icon: string
  notificationText: string
  text: {
    header: string,
    subheader: string
  },
  btnType?: PopupBtnType
}

export default function TaskCardPopup(props: Data) {

  const { t } = useTranslation()
  const history = useHistory()

  const userId = useSelector((state: RootState) => state.user.userData?.id, _.isEqual)
  const typesCost = useSelector((state: RootState) => state.types.costs, _.isEqual)
  const locale = useSelector((state: RootState) => state.locale, _.isEqual)

  const [notificationKey, setNotificationKey] = useState<string>('')
  const [paymentAmount, setPaymentAmount] = useState<string>('0')
  const [options, setOptions] = useState<PopupOptions>()

  useEffect(() => {
    setNotificationKey(`open${Date.now()}`)
    definePopupOptions()
  }, [])

  useEffect(() => {
    if ([
      PopupType.APP_UNPUBLISHED_AFTER_MARATHON,
      PopupType.FAILURE_POST_MODERATION
    ].includes(props.popupType)) {
      definePopupOptions()
    }
  }, [paymentAmount])

  useEffect(() => {
    let payment = typesCost.find(item => item.name === 'price_deletion_reason_description')
    if (!payment) return
    let paymentEN = Number(payment.value) / locale.rate.en
    setPaymentAmount((locale.locale.name === 'ru') ? payment.value : paymentEN.toFixed(2))
  }, [locale])

  const handleGoToWithdrawalFunds = () => {
    history.push('/withdrawal')
  }

  const definePopupOptions = () => {
    const initOptions = {
      [PopupType.TASK_COMPLETED_SUCCESSFULLY]: {
        icon: '/modal_cash.svg',
        notificationText: t('Modal App passed'),
        text: {
          header: t('Congratulations'),
          subheader: t('Task completed successfully')
        },
        btnType: PopupBtnType.GO_TO_WITHDRAWAL_FUNDS
      },
      [PopupType.APP_UNPUBLISHED_AFTER_MARATHON]: {
        icon: '/modal_work_error.svg',
        notificationText: t('Modal Upload reason for deletion (with bonus)', { paymentAmount }),
        text: {
          header: t('We regret'),
          subheader: t('The app has been removed')
        },
        btnType: PopupBtnType.LOAD_REASON_FOR_DELETION
      },
      [PopupType.FAILURE]: {
        icon: '/modal_time_error.svg',
        notificationText: t('Modal Failed task'),
        text: {
          header: t('We regret'),
          subheader: t('Completion time is up')
        }
      },
      [PopupType.FAILURE_MODERATION]: {
        icon: '/modal_work_error.svg',
        notificationText: t('Modal Failed moderation'),
        text: {
          header: t('We regret'),
          subheader: t('The app has been removed')
        }
      },
      [PopupType.FAILURE_POST_MODERATION]: {
        icon: '/modal_work_error.svg',
        notificationText: t('Modal Upload reason for deletion', { paymentAmount }),
        text: {
          header: t('We regret'),
          subheader: t('The app has been removed')
        },
        btnType: PopupBtnType.LOAD_REASON_FOR_DELETION
      },
      [PopupType.UPLOADED_REASON_CHECKED]: {
        icon: '/modal_cash.svg',
        notificationText: t('Modal Uploaded reason checked'),
        text: {
          header: t('Congratulations'),
          subheader: t('Uploaded reason checked')
        },
        btnType: PopupBtnType.GO_TO_WITHDRAWAL_FUNDS
      }
    }

    const currentOptions = initOptions[props.popupType]
    if (!currentOptions) return
    setOptions(currentOptions)
  }

  const getPopupBtn = () => {
    if (!options?.btnType) return

    const getLoadReasonBtn = () => (
      <Button
        type="primary"
        onClick={props.openModalForUploadingReason}>
        {t('Earn more')}
      </Button>
    )
    const gotoWithdrawalFundsBtn = () => (
      <Button
        type="primary"
        onClick={handleGoToWithdrawalFunds}>
        {t('Withdraw funds')}
      </Button>
    )

    const getter = {
      [PopupBtnType.LOAD_REASON_FOR_DELETION]: getLoadReasonBtn,
      [PopupBtnType.GO_TO_WITHDRAWAL_FUNDS]: gotoWithdrawalFundsBtn
    }[options.btnType]

    if (!getter) return
    return getter()
  }

  const openNotification = () => {

    const getNotificationBtn = () => {
      if (!options?.btnType) return

      const getLoadReasonBtn = () => (
        <Button
          type="primary"
          onClick={() => {
            notification.close(notificationKey)
            props.openModalForUploadingReason()
          }}>
          {t('Load reason for deletion')}
        </Button>
      )
      const gotoWithdrawalFundsBtn = () => (
        <Button
          type="primary"
          onClick={() => {
            notification.close(notificationKey)
            handleGoToWithdrawalFunds()
          }}>
          {t('Withdraw funds')}
        </Button>
      )

      const getter = {
        [PopupBtnType.LOAD_REASON_FOR_DELETION]: getLoadReasonBtn,
        [PopupBtnType.GO_TO_WITHDRAWAL_FUNDS]: gotoWithdrawalFundsBtn
      }[options.btnType]

      if (!getter) return
      return getter()
    }

    const icon = <img src={options?.icon} alt=""/>

    notification.open({
      message: '',
      description:
      options?.notificationText,
      icon,
      btn: getNotificationBtn(),
      key: notificationKey,
      duration: null,
      closeIcon: <IconClear fill="#DFDEFD" width={22} height={22}/>
    })
  }

  return !userId ? null : (
    <div className="task-popup-content">
      <img src={options?.icon} className="task-popup-image" alt=""/>
      <div className="task-popup-main">
        <Paragraph align={Align.CENTER} text={options?.text.header}/>
        <Paragraph align={Align.CENTER} text={options?.text.subheader}/>
        <div className="task-popup-toolbar">
          {getPopupBtn()}
          <Button
            type="text"
            className="task-popup-toolbar__text-btn"
            onClick={() => {
              props.popupCloseHandler()
              taskPopupCloseHandler(userId, props.taskId, props.popupType!)
            }}>
            {t('OK')}
          </Button>
        </div>
      </div>
      <RightCircleTwoTone className="task-popup-icon" style={{ fontSize: '35px' }} onClick={openNotification}/>
    </div>
  )
}
