export interface IntercomState {
  app_id: string;
  user_id?: string;
  language_override?: string;
}

const initialState: IntercomState = {
  app_id: 'rolopgu5',
}

export default initialState
