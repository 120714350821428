import React from 'react'


interface Data {
  masterCard?: boolean
}

export default function BankCardIcon(props: Data) {

  if (props.masterCard) {
    return <div className="icon-bank-card">
      <img src="/mastercard_logo.png" alt=""/>
    </div>
  }

  return (
    <svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.072 0H3.92804C1.76215 0 0 1.77982 0 3.96742V19.0326C0 21.2202 1.76215 23 3.92804 23H26.072C28.2378 23 30 21.2202 30 19.0326V3.96742C30 1.77982 28.2378 0 26.072 0ZM3.92804 2.41495H26.072C26.9194 2.41495 27.609 3.11149 27.609 3.96742V7.47531H2.39098V3.96742C2.39098 3.11149 3.08061 2.41495 3.92804 2.41495ZM26.072 20.585H3.92804C3.08061 20.585 2.39098 19.8885 2.39098 19.0326V13.0814H27.609V19.0326C27.609 19.8885 26.9194 20.585 26.072 20.585Z"
        fill="#859AB6"/>
    </svg>
  )
}
