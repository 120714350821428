import React from 'react'
import '../../style/sass/IconPassword.scss'


type Data = {
  closed: boolean
  onClick: () => void
}

export default function IconPassword(props: Data) {
  return <div className='password-icon' onClick={props.onClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18.566259"
      viewBox="0 0 19 18.566259"
      version="1.1"
      id="svg881">
      {/*Контур глаза*/}
      <g
        style={{ display: 'inline' }}
        transform="translate(0,2.5662588)">
        <path
          style={{ clipRule: 'evenodd', display: 'inline', fill: '#2684fe', fillRule: 'evenodd' }}
          d="M 0,6.69362 C 1.57087,2.66252 5.11155,0 9.5,0 13.8885,0 17.4291,2.66252 19,6.69362 17.421367,10.569438 14.555843,13.333963 9.5501814,13.412883 4.1825931,13.362804 1.4271546,10.086581 0,6.69362 Z M 9.5,12.6407 C 5.53543,12.6407 2.14436,10.1773 0.7979,6.69362 2.14436,3.18507 5.53543,0.721617 9.5,0.721617 c 3.9895,0 7.3806,2.463453 8.727,5.972003 C 16.8806,10.1773 13.4895,12.6407 9.5,12.6407 Z"/>
      </g>
      {/*Ресницы внизу*/}
      <g
        style={{ display: props.closed ? 'inline' : 'none' }}
        transform="translate(0,2.5662588)">
        <path
          style={{
            fill: '#2684fe',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: '1px',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 1
          }}
          d="M 0.822835,12.1182 0.448819,11.4961 2.34383,10.4012 2.89239,10.8989 Z"/>
        <path
          style={{
            fill: '#2684fe',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: '1px',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 1
          }}
          d="M 4.53806,14.5568 5.6601,12.6159 6.35827,12.8647 5.16142,14.93 Z"/>
        <path
          style={{
            fill: '#2684fe',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: '1px',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 1
          }}
          d="m 9.15092,16 v -2.6376 h 0.7231 V 16 Z"/>
        <path
          style={{
            fill: '#2684fe',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: '1px',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 0
          }}
          d="m 12.6667,12.8647 1.1968,2.0653 0.6234,-0.3732 -1.1221,-1.9409 z"/>
        <path
          style={{
            fill: '#2684fe',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: '1px',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 0
          }}
          d="m 16.1076,10.8989 2.0945,1.2193 0.374,-0.6221 -1.895,-1.0949 z"/>
      </g>
      {/*Ресницы вверху*/}
      <g
        style={{ display: props.closed ? 'none' : 'inline' }}
        transform="translate(0,2.5662588)">
        <path
          style={{
            display: 'inline',
            fill: '#2684fe',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: '1px',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 1
          }}
          d="m 0.822835,1.3155412 -0.374016,0.6221 1.895011,1.0949 0.54856,-0.4977 z"/>
        <path
          style={{
            display: 'inline',
            fill: '#2684fe',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: '1px',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 1
          }}
          d="M 4.53806,-1.1230588 5.6601,0.81784114 6.35827,0.56904114 5.16142,-1.4962588 Z"/>
        <path
          style={{
            display: 'inline',
            fill: '#2684fe',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: '1px',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 1
          }}
          d="m 9.15092,-2.5662588 v 2.63759994 h 0.7231 V -2.5662588 Z"/>
        <path
          style={{
            display: 'inline',
            fill: '#2684fe',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: '1px',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 0
          }}
          d="m 12.6667,0.56904114 1.1968,-2.06529994 0.6234,0.3732 -1.1221,1.94089994 z"/>
        <path
          style={{
            display: 'inline',
            fill: '#2684fe',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: '1px',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 0
          }}
          d="m 16.1076,2.5348412 2.0945,-1.2193 0.374,0.6221 -1.895,1.0949 z"/>
      </g>
      {/*Зрачок*/}
      <g
        style={{ display: props.closed ? 'none' : 'inline' }}
        transform="translate(0,2.5662588)">
        <circle
          style={{
            opacity: 1,
            fill: '#2684fe',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: 3.77952766,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 4,
            strokeDasharray: 'none',
            strokeDashoffset: 0,
            strokeOpacity: 0,
            paintOrder: 'normal'
          }}
          id="path1124"
          cx="9.5017471"
          cy="7.116971"
          r="3.9111843"/>
      </g>
    </svg>
  </div>
}
