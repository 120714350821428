import React from 'react'

export default function SignupSocialVK() {
  return (
    <div className="social-icon">
      <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.4493 0.965101C23.6163 0.408846 23.4494 0 22.6553 0H20.0297C19.362 0 19.0544 0.353145
          18.8875 0.742673C18.8875 0.742673 17.5522 3.99712 15.6607 6.11111C15.0487 6.72307 14.7706
          6.91755 14.4367 6.91755C14.2698 6.91755 14.0283 6.72307 14.0283 6.16682V0.965101C14.0283
          0.297445 13.8345 0 13.2781 0H9.15214C8.73504 0 8.48411 0.309823 8.48411 0.603516C8.48411
          1.23648 9.4297 1.38239 9.52722 3.16255V7.02914C9.52722 7.87684 9.37419 8.03062 9.04036
          8.03062C8.15028 8.03062 5.98527 4.76136 4.70097 1.0208C4.44929 0.293694 4.19685 0 3.52582
          0H0.90021C0.150035 0 0 0.353145 0 0.742673C0 1.43809 0.89027 4.88739 4.14472 9.44921C6.3146
          12.5643 9.37119 14.2533 12.1528 14.2533C13.822 14.2533 14.0283 13.8782 14.0283 13.2322V10.8775C14.0283
          10.1274 14.1864 9.97771 14.7147 9.97771C15.1044 9.97771 15.7719 10.1724 17.3298 11.6744C19.11 13.4544
          19.4033 14.2533 20.4048 14.2533H23.0304C23.7806 14.2533 24.1556 13.8782 23.9392 13.138C23.7025 12.4002
          22.8526 11.3301 21.7247 10.0612C21.1127 9.33781 20.1947 8.55894 19.9168 8.16959C19.5272 7.66885 19.6385
          7.44643 19.9168 7.00157C19.9168 7.00157 23.1155 2.49527 23.4493 0.965288V0.965101Z"
          fill="#859AB6"/>
      </svg>
    </div>
  )
}
