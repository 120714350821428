import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import TaskPreviewCard from './TaskPreviewCard'
import taskCardWrap, { CardType } from './taskCardWrap'
import AnnouncementCard from './AnnouncementCard'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'
import NotAvailableTaskCardWrap from './NotAvailableTaskCardWrap'
import TaskDetailsCard from './TaskDetailsCard'
import { useHistory } from 'react-router-dom'
import { PublisherTaskType, TaskListItem } from '../../store/taskList/initialState'
import { setDeletedApplications } from '../../store/application/actions'


type Data = {
  loading: boolean
  signal: AbortSignal
}

export default function TaskList({ loading, signal }: Data) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const tasks = useSelector((state: RootState) => state.taskList.publicationTasks.data, _.isEqual)
  const metaData = useSelector((state: RootState) => state.taskList.publicationTasks.meta?.page)
  const selectedTask = useSelector((state: RootState) => state.taskList.selectedTask as TaskListItem, _.isEqual)

  const [hasActiveTasks, setHasActiveTasks] = useState<boolean>(false)
  const [allTasksLoaded, setAllTasksLoaded] = useState<boolean>(false)
  const [adtStartTime, setAdtStartTime] = useState<string>()

  useEffect(() => {
    dispatch(setDeletedApplications())
    history.push({ search: 'type=publication' })
  }, [])

  useEffect(() => {
    if (loading) return
    setAdtStartTime(calcAdtStartTime())
    setHasActiveTasks(!!tasks?.length)
  }, [tasks, loading])

  useEffect(() => {
    if (!metaData || !metaData.current_page || !metaData.last_page) {
      setAllTasksLoaded(true)
      return
    }
    setAllTasksLoaded(metaData.current_page === metaData.last_page)
  }, [metaData])

  const calcAdtStartTime = () => {
    let lastTask: TaskListItem | undefined
    const hasUpdateTasks = tasks?.some(task => task.type === PublisherTaskType.UPDATE)
    if (hasUpdateTasks && !allTasksLoaded) return
    const allTasksCompleted = tasks?.every(task => task.status.name === 'completed')
    if (!allTasksCompleted) return
    tasks?.map(task => {
      const currentTaskIsBefore = lastTask && moment(lastTask.completed_at).isAfter(task.completed_at)
      if (currentTaskIsBefore) return
      lastTask = task
    })
    if (!lastTask) return
    return lastTask.completed_at
  }

  const getContent = () => {
    if (loading) return (
      <div className="tasks-loading-dummy">
        {t('Loading the task list')}
      </div>
    )

    if (selectedTask) {
      const DetailsCard = taskCardWrap(TaskDetailsCard, {
        task: selectedTask,
        cardType: CardType.DETAILS
      })
      return <DetailsCard/>
    }

    function getCards(type: PublisherTaskType) {
      const list = tasks?.filter(task => task.type === type)
      if (!list?.length) return
      return list?.map(task => {
        const Card = taskCardWrap(TaskPreviewCard, {
          task,
          cardType: CardType.PREVIEW
        })
        return <Card key={task.id}/>
      })
    }

    return (
      <div className={`task-cards-pane ${(tasks?.length === 1 && !adtStartTime) ? 'centered-card' : ''}`}>
        {getCards(PublisherTaskType.VERIFICATION)}
        {!hasActiveTasks && (
          <NotAvailableTaskCardWrap/>
        )}
        {getCards(PublisherTaskType.UPDATE)}
        {getCards(PublisherTaskType.PUBLICATION)}
        {!!adtStartTime && (
          <AnnouncementCard startTime={adtStartTime}/>
        )}
      </div>
    )
  }

  const getKey = () => {
    if (loading) return 1
    if (!tasks?.length) return 2
    if (selectedTask) return 3
    return 0
  }

  return (
    <Spin spinning={loading}>
      <div className='task-list'>
        <SwitchTransition>
          <CSSTransition
            key={getKey()}
            timeout={200}
            classNames="fade">
            {getContent()}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </Spin>
  )
}
