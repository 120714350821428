import React from 'react'
import IconMobile from '../../icons/IconMobile'
import Paragraph from '../../common_kit/text/Paragraph'


type Data = {
  countDetails: string
}

export default function MobileCount(props: Data) {

  const hiddenDetails = props.countDetails.slice(-5)

  return <div className={'payment-count'}>
    <IconMobile fillBack={'none'} fill={'#2A3E58'}/>
    <Paragraph text={`* *** *** ${hiddenDetails}`}/>
  </div>
}
