import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import '../style/sass/SignupPage.scss'
import PhoneVerification from './signupPage/PhoneVerification'
import SignupForm from './signupPage/SignupForm'
import {startIntercomChat} from '../utils/helpers'
import {setSidebarAvailability} from '../store/sidebar/actions'
import { setPhoneVerified } from '../store/phone/actions'
import {CSSTransition} from 'react-transition-group'
import {RootState} from '../store/rootState'
import SignupBeforeIllustration from './illustrations/SignupBeforePhoneIllustration'
import SignupAfterIllustration from './illustrations/SignupAfterPhoneIllustration'
import Paragraph, {TextSize} from './common_kit/text/Paragraph'
import {Link} from 'react-router-dom'
import i18n from 'i18next'
import H3 from './common_kit/text/H3'
import {useTranslation} from 'react-i18next'


export default function SignupPage() {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const phoneVerified = useSelector((state: RootState) => state.phone.phoneVerified)

  useEffect(() => {
    dispatch(setSidebarAvailability(false))
    startIntercomChat()

    return () => {
      dispatch(setPhoneVerified(false))
    }
  }, [])

  return (
    <div className="signup-page">
      <div className="signup-page-title-block">
        <H3 className="signup-page-title" text={t('Sign up')}/>
        <div className="invitation-login">
          <Paragraph
            text={t('Already registered')}
            size={TextSize.P_18}
          />
          <Link to={`/${i18n.language}/sign-in`}>
            {t('Lets log in')}
          </Link>
        </div>
      </div>
      <PhoneVerification/>
      <div className="signup-page-form-block">
        <CSSTransition
          in={phoneVerified}
          timeout={300}
          mountOnEnter
          unmountOnExit>
          <SignupForm/>
        </CSSTransition>
        {phoneVerified ? (<SignupAfterIllustration/>) : (<SignupBeforeIllustration/>)}
      </div>
    </div>
  )
}
