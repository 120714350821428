import React, { useEffect, useState } from 'react'
import RadioButton from './RadioButton'
import Span, { TextSize } from '../common_kit/text/Span'
import { CardStatusColor } from '../common_kit/Tooltip'


type RadioButtonGroupData = {
  label: string,
  items: Array<{
    value: string,
    label: string,
  }>,
  handler: (value: string) => void,

  value?: string,
  isDisabled?: boolean,
}

export default function RadioButtonGroup(props: RadioButtonGroupData) {

  const [value, setValue] = useState('')
  const handler = option => setValue(option)

  const getButtons = () => {
    return props.items.map(item => <RadioButton
      key={item.value}
      isActive={item.value === value}
      value={item.value}
      label={item.label}
      handler={handler}
    />)
  }

  useEffect(() => {
    if (props.value) {
      setValue(props.value)
    }
  }, [])

  useEffect(() => {
    props.handler(value)
  }, [value])

  return <div className="radio-button-group">
    <label>
      <Span color={CardStatusColor.GRAY} text={props.label} size={TextSize.P18}/>
    </label>
    <div className="radio-button-list">
      {getButtons()}
    </div>
  </div>
}

