import _axios from 'axios'
import store from './store'
import { logout } from './store/auth/api'
import Notification from './components/notification/Notification'
import i18n from 'i18next'
import { configuration } from './configuration'
import Sentry from './sentry'
import { deserialize } from 'jsonapi-fractal'


const createAxiosInstance = (options = {}, interceptors) => {
  const defaultOptions = {
    baseURL: configuration.backendUrl,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }

  const defaultInterceptors = {
    request: {
      onFulfilled: config => {
        const isOnline = navigator.onLine
        Error.prototype.offline = !isOnline
        if (!isOnline) {
          Notification({
            noticeType: 'disconnect',
            text: i18n.t('Connection error'),
            key: 'disconnect',
            notClose: true
          })
          const error = new Error
          return Promise.reject(error)
        }
        const authSession = store.getState().auth.access_token
        const authHeader = authSession
          ? { 'Authorization': `Bearer ${authSession}` }
          : {}
        const acceptLanguage = { 'Accept-Language': store.getState().locale.locale.name }
        config.headers = {
          ...config.headers,
          ...authHeader,
          ...acceptLanguage
        }
        if (config.method === 'put' && config.data) {
          config.data.append('_method', 'PUT')
          config.method = 'post'
        }
        return config
      },
      onRejected: error => Promise.reject(error)
    },
    response: {
      onFulfilled: response => response,
      onRejected: error => {
        if (error.__CANCEL__) return Promise.reject(error)

        const url = error.response.config.url
        const code = error.response.status
        Sentry.captureMessage(`Error AJAX: '${url}' with code ${code}`)
        if (error.response && code === 401) {
          const authSession = store.getState().auth.access_token
          if (!authSession) {
            error.logout = true // TODO: Убрать свойство logout. Вместо этого возвращать Promise.resolve('cancel')
            return Promise.reject(error)
          }
          logout()
          Notification({ noticeType: 'warn', text: i18n.t('Authorization error') })
        }
        return Promise.reject(error)
      }
    }
  }

  const axiosInstance = _axios.create({
    ...defaultOptions,
    ...options
  })

  axiosInstance.interceptors.request.use(
    interceptors?.request?.onFulfilled ?? defaultInterceptors.request.onFulfilled,
    interceptors?.request?.onRejected ?? defaultInterceptors.request.onRejected
  )

  axiosInstance.interceptors.response.use(
    interceptors?.response?.onFulfilled ?? defaultInterceptors.response.onFulfilled,
    interceptors?.response?.onRejected ?? defaultInterceptors.response.onRejected
  )

  return axiosInstance
}

export const axios = createAxiosInstance()
export const axiosV3 = createAxiosInstance({
  baseURL: configuration.backendUrl + '/v3',
  headers: {
    'Content-Type': 'application/vnd.api+json',
    'Accept': 'application/vnd.api+json'
  }
}, {
  response: {
    onFulfilled: response => {
      const getData = () => {
        const deserializedData = deserialize(response.data)
        if (!Array.isArray(deserializedData)) return deserializedData
        return deserializedData.map(item => {
          const itemMeta = response.data.data.find(_item => _item.id === item.id).meta
          const type = response.config.url.includes('types') ? response.data.data.find(_item => _item.id === item.id).type : undefined
          if (itemMeta) return { ...item, ...itemMeta }
          if (type) return { ...item, type }
          return item
        })
      }
      /* TODO: разобраться в правильном получении meta.
      preMeta - данные полученные Лёней
      meta - новые данные
      Надо проверить все роуты в правильном получении meta*/
      const preMeta = Array.isArray(response.data.data) ? response.data.meta : response.data.data.meta
      const meta = preMeta ? {...preMeta, ...response.data.meta || {}} : response.data.meta
      return {
        data: getData(),
        included: response.data.included,
        meta: meta || ''
      }
    }
  }
})
