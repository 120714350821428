import React, { useEffect, useState } from 'react'
import H3 from './common_kit/text/H3'
import H5 from './common_kit/text/H5'
import { copyToClipboard, getAmountWithSign } from '../utils/helpers'
import ReferralCard from './referrals/ReferralCard'
import '../style/sass/referrals/Referrals.scss'
import SubmitButton from './inputs/SubmitButton'
import Paragraph, { Align, TextSize } from './common_kit/text/Paragraph'
import H4 from './common_kit/text/H4'
import Tooltip, { CardStatusColor } from './common_kit/Tooltip'
import ReferralsIllustration from './illustrations/ReferralsIllustration'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserData } from '../store/user/actions'
import { RootState } from '../store/rootState'
import { setSidebarAvailability } from '../store/sidebar/actions'
import Notification from './notification/Notification'


type Unit = {
  name: string,
  title: string,
  unit: string,
}

type adaptiveSizeModel = {
  wrapper: string,
  titlePage: string,
  illustration: string,
  cardList: string,
  card: string,
  descriptionSize: string
}

type CssSizeModel = {
  s: adaptiveSizeModel,
  m: adaptiveSizeModel,
  l: adaptiveSizeModel
}

export default function Referrals() {
  const { t, i18n } = useTranslation()

  const dispatch = useDispatch()

  const userData = useSelector((state: RootState) => state.user.userData)
  const size = useSelector((state: RootState) => state.mq.size)

  const STATS = [
    {
      name: 'total_referrals',
      title: `${t('Referral stats total')}:`,
      unit: 'man'
    },
    {
      name: 'total_referrals_with_task',
      title: `${t('Referral stats completed')}:`,
      unit: 'man'
    },
    {
      name: 'total_referral_transactions_amount',
      title: `${t('Referral stats rewards')}:`,
      unit: 'rub'
    }
  ]

  let CssSize: CssSizeModel = {
    s: {
      wrapper: '',
      titlePage: 'text-center',
      illustration: 'referral__image_mobile',
      cardList: '',
      card: '',
      descriptionSize: 'P_14'
    },
    m: {
      wrapper: 'referral_tablet',
      titlePage: 'referral__header_tablet',
      illustration: '',
      cardList: '',
      card: '',
      descriptionSize: 'P_16'
    },
    l: {
      wrapper: 'referral_desktop',
      titlePage: 'text-left referral__header_desktop',
      illustration: 'referral__image_desktop',
      cardList: 'referrals__card-list_desktop',
      card: 'referral-card_desktop',
      descriptionSize: 'P_18'
    }
  }

  const [zeroIndicators, setZeroIndicators] = useState<boolean>()

  useEffect(() => {
    dispatch(setSidebarAvailability(true))
    dispatch(updateUserData())
  }, [])

  useEffect(() => {
    setZeroIndicators(!userData?.total_referrals && !userData?.total_referrals_with_task && !userData?.total_referral_transactions_amount)
  }, [userData])

  const getUnits = (units: string, num: number) => {
    const lang = i18n.language
    switch (units) {
      case 'man':
        let result
        const str: string = `${num}`
        const lastChar: string = str[str.length - 1]
        const number: number = parseInt(lastChar)
        const lastTwoSigns: string = str.slice(-2)
        if (lang === 'ru') {
          if (['12', '13', '14'].includes(lastTwoSigns)) {
            result = 'человек'
          } else if (number >= 2 && number <= 4) {
            result = 'человекa'
          } else {
            result = 'человек'
          }
        } else {
          result = number === 1 ? 'user' : 'users'
        }
        return <Paragraph text={result} className="referrals__stats-item-unit"/>
      case 'rub':
        return <H4 text={t('currency sign')} className="referrals__stats-item-currency"/>
    }
  }

  const handlerClickCopyBtn = () => {
    if (!userData?.referral_code) return
    copyToClipboard(userData?.referral_code)
    Notification({ noticeType: 'info', text: t('Referral copy msg') })
  }

  const getStat = (stat: number, unit: Unit) => {
    if (unit.name === 'total_referral_transactions_amount') {
      return <H4 text={getAmountWithSign(stat)}/>
    }
    return (
      <div className="referrals-unit">
        <H4 text={`${stat}`}/>
        {getUnits(unit.unit, stat)}
      </div>
    )
  }

  const getHint = (name: string) => {
    if (name !== 'total_referral_transactions_amount') return
    return (
      <Tooltip
        className="referrals__stats-card-hint"
        svg={CardStatusColor.BLUE}
        text={t('Referral hint')}
        color={CardStatusColor.GRAY}
      />
    )
  }

  if (!userData) return null

  let isZeroRef = zeroIndicators ? 'outReferrals' : ''

  return (
    <div className={`referrals ${CssSize[size]?.wrapper} ${isZeroRef}`}>
      <H3 className={`referrals__title ${CssSize[size]?.titlePage}`} text={t('Referral code')}/>
      <div className={`referrals__card-list ${CssSize[size]?.cardList}`}>
        <ReferralCard className={`${CssSize[size]?.card} referrals__main-card`}>
          <H5 text={t('Referral header')}/>
          <div className="referrals__main-card-code">
            <H3 text={userData.referral_code}/>
          </div>
          <div className="referrals__main-card-description">
            <Paragraph
              text={t('Referral description', { percentage: userData.referral_percentage })}
              size={TextSize[CssSize[size]?.descriptionSize]}
            />
          </div>
          <SubmitButton
            text={t('Copy')}
            className="referrals__main-card-btn"
            onClick={handlerClickCopyBtn}
          />
        </ReferralCard>
        {zeroIndicators ? (
            <div className={`illustration ${CssSize[size]?.illustration}`}>
              <ReferralsIllustration/>
              <Paragraph
                className="illustration-description"
                text={t('Referral dummy description')}
                size={TextSize.P_18}
                align={Align.CENTER}
              />
            </div>
          ) : (
            <ReferralCard className={`${CssSize[size]?.card} referrals__stats-card`}>
              {STATS.map(item => {
                const field = item.name
                return <div key={field} className="referrals__stats-item">
                  <H5 text={item.title} className="referrals__stats-item-title"/>
                  <div className="referrals__stats-item-number">
                    {getStat(userData[field], item)}
                    {getHint(item.name)}
                  </div>
                </div>
              })}
            </ReferralCard>
          )
        }
      </div>
    </div>
  )
}
