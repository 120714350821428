import React from 'react'
import { Accrual, AccrualType, DepositType } from '../../../store/accruals/initialState'
import Paragraph from '../../common_kit/text/Paragraph'
import H4 from '../../common_kit/text/H4'
import H5 from '../../common_kit/text/H5'
import IconWallet from '../../icons/IconWallet'
import H3 from '../../common_kit/text/H3'
import { useTranslation } from 'react-i18next'
import { getAmountWithSign } from '../../../utils/helpers'
import IconAccepted from '../../icons/IconAccepted'


type Data = {
  data: Accrual
}

export default function AccrualHistoryItem(props: Data) {

  const { t } = useTranslation()

  const getStatus = () => {
    if (props.data.confirmed) {
      return ( <IconAccepted/> )
    }
    return <div>
      <Paragraph text="Статус:"/>
      <H5 text="в обработке" color="#FF5F5F"/>
    </div>
  }

  const getDate = () => {
    const date = props.data.updated_at ?? props.data.created_at
    return new Date(date).toLocaleDateString()
  }

  const getIcon = () => {
    const defaultIcon = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9998 2.66675C8.63984 2.66675 2.6665 8.64008 2.6665 16.0001C2.6665 23.3601 8.63984 29.3334 15.9998 29.3334C23.3598 29.3334 29.3332 23.3601 29.3332 16.0001C29.3332 8.64008 23.3598 2.66675 15.9998 2.66675ZM17.8798 24.1201V26.6667H14.3198V24.0934C12.0398 23.6134 10.1065 22.1467 9.95984 19.5601H12.5732C12.7065 20.9601 13.6665 22.0534 16.1065 22.0534C18.7198 22.0534 19.3065 20.7467 19.3065 19.9334C19.3065 18.8267 18.7198 17.7867 15.7465 17.0801C12.4398 16.2801 10.1732 14.9201 10.1732 12.1867C10.1732 9.89341 12.0265 8.40008 14.3198 7.90675V5.33341H17.8798V7.93341C20.3598 8.53341 21.5998 10.4134 21.6798 12.4534H19.0665C18.9998 10.9734 18.2132 9.96008 16.1065 9.96008C14.1065 9.96008 12.9065 10.8667 12.9065 12.1467C12.9065 13.2667 13.7732 14.0001 16.4665 14.6934C19.1598 15.3867 22.0398 16.5467 22.0398 19.9067C22.0265 22.3467 20.1998 23.6801 17.8798 24.1201Z"
        fill="#2A3E58"/>
    </svg>

    if (props.data.deposit_type) {
      switch (AccrualType[props.data.deposit_type.name]) {
        case AccrualType.replenished_publication_app:
        case AccrualType.replenished_update_app:
          return <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25 22.5C26.375 22.5 27.4875 21.375 27.4875 20L27.5 6.25C27.5 4.875 26.375 3.75 25 3.75H5C3.625 3.75 2.5 4.875 2.5 6.25V20C2.5 21.375 3.625 22.5 5 22.5H0C0 23.875 1.125 25 2.5 25H27.5C28.875 25 30 23.875 30 22.5H25ZM5 6.25H25V20H5V6.25ZM15 23.75C14.3125 23.75 13.75 23.1875 13.75 22.5C13.75 21.8125 14.3125 21.25 15 21.25C15.6875 21.25 16.25 21.8125 16.25 22.5C16.25 23.1875 15.6875 23.75 15 23.75Z"
              fill="#2A3E58"/>
          </svg>
        default:
          return defaultIcon
      }
    } else {
      return defaultIcon
    }
  }

  const getDisplayedType = () => {
    const type: DepositType = props.data.deposit_type
    if (!type) return t('Default accrual type')
    const taskId = props.data.subtask?.id ? t('Task id', { id: props.data.subtask?.id }) : ''
    return t(AccrualType[type.name]) + taskId
  }

  return <div className="accruals-history-item">
    <div className="history-item-head">
      <div className="accruals-history-item__status">
        {getStatus()}
      </div>
      <div className="accruals-history-item__meta">
        <Paragraph text={getDate()}/>
        <H5 text={`id${props.data.id}`}/>
      </div>
    </div>
    <div className="accruals-history-item__body">
      <div className="accruals-history-item__arrow">
        <svg width="25" height="67" viewBox="0 0 25 67" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M24.7071 59.7071C25.0976 59.3166 25.0976 58.6834 24.7071 58.2929L18.3431 51.9289C17.9526 51.5384 17.3195 51.5384 16.9289 51.9289C16.5384 52.3195 16.5384 52.9526 16.9289 53.3431L22.5858 59L16.9289 64.6569C16.5384 65.0474 16.5384 65.6805 16.9289 66.0711C17.3195 66.4616 17.9526 66.4616 18.3431 66.0711L24.7071 59.7071ZM9 2H24V0H9V2ZM24 58H9V60H24V58ZM2 51V9H0V51H2ZM9 58C5.13401 58 2 54.866 2 51H0C0 55.9706 4.02944 60 9 60V58ZM9 0C4.02944 0 0 4.02944 0 9H2C2 5.13401 5.13401 2 9 2V0Z"
            fill="#859AB6"/>
        </svg>
      </div>
      <div className="accruals-history-item__type">
        {getIcon()}
        <H4 text={getDisplayedType()}/>
      </div>
      <div className="accruals-history-item__amount">
        <IconWallet/>
        <H3 text={getAmountWithSign(props.data.amount)}/>
      </div>
    </div>
  </div>
}
