import React from "react";
import Span from "../../common_kit/text/Span";
import '../../../style/sass/instructions/stepNumber/StepNumber.scss';


type Data = {
  className?: string,
  text?: string,
  colorText?: string
}

export default function GuideNumber(props: Data) {
  const colorText = props.colorText ?? "#fff";

  return <div className={`${props.className} guide-number`}>
    <Span color={colorText} text={props.text}/>
  </div>
}
