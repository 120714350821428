import { Modal } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'

type Data = {
  showModal: boolean,
  children: ReactElement

  closable?: boolean
  closeByOutsideClick?: boolean
  bodyBackground?: string
  maskBackground?: string
  wrapClassName?: string
  width?: string
  afterClose?: () => void
}

InfoModal.defaultProps = {
  closable: false,
  closeByOutsideClick: false,
  bodyBackground: '#FFF',
  maskBackground: 'rgba(0, 0, 0, 0.5)',
  wrapClassName: '',
  width: '680px'
}

export default function InfoModal(props: Data): ReactElement {

  const [open, setOpen] = useState<boolean>()

  useEffect(() => {
    setOpen(props.showModal)
  }, [props.showModal])

  return (
    <Modal
      open={open}
      closable={props.closable}
      maskClosable={props.closeByOutsideClick}
      onCancel={() => setOpen(false)}
      afterClose={props.afterClose}
      zIndex={10003}
      footer={false}
      centered
      bodyStyle={{
        position: 'absolute',
        width: props.width,
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px 0',
        background: props.bodyBackground,
        borderRadius: '30px',
        outline: 'none',
        textAlign: 'center'
      }}
      maskStyle={{
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        background: props.maskBackground,
        backdropFilter: 'blur(8px)'
      }}
      wrapClassName={props.wrapClassName}>
      {props.children}
    </Modal>
  )
}
