import { axiosV3 } from "../../axios";
import store from '../index'

export async function userAction(typeEvent){
  let cnv_id = store.getState().binom.cnv_id
  if (!cnv_id) return
  const data = {
    data: {
      type: "binom-events",
      id: cnv_id,
      attributes: {
        event: typeEvent,
        value: true
      }
    }
  }
  try {
    await axiosV3.post(`events/binom`, data)
    console.log(`event: ${typeEvent}, ${cnv_id}`,  )
  } catch (e) {
    console.log(e)
  }
}
