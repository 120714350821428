import { RESET_ACCRUALS_PAYMENTS, SET_ACCRUALS_HISTORY } from './actionTypes'
import { downloadAccrualsHistory } from './api'

export const setAccrualsHistory = () => async dispatch => {
  const history = await downloadAccrualsHistory()
  if (!history) return
  dispatch({
    type: SET_ACCRUALS_HISTORY,
    history
  })
}

export const resetAccrualsHistory = () => ({
  type: RESET_ACCRUALS_PAYMENTS
})
