import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Paragraph, { TextSize, TextStyle, Weight } from '../common_kit/text/Paragraph'
import NewTextInput from '../inputs/NewTextInput'
import HintList from '../text/HintList'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'
import { checkValidationSteps, getPasswordValidationHints, isEmail } from '../../utils/helpers'
import { useForm } from 'react-hook-form'
import IconPassword from '../icons/IconPassword'
import { resetForm, setUnsavedUserData } from '../../store/userProfile/actions'


interface ProfileFormModel {
  last_name: string,
  first_name: string,
  email: string,
  password: string,
}

type Data = {
  onChange: (value: boolean, valid: boolean) => void
}

export default function Form(props: Data) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const initFirstName = useSelector((state: RootState) => state.user.userData?.first_name)
  const initLastName = useSelector((state: RootState) => state.user.userData?.last_name)
  const initEmail = useSelector((state: RootState) => state.user.userData?.email)
  const initSocialAccount = useSelector((state: RootState) => state.user.userData?.social_account)
  const initPhone = useSelector((state: RootState) => state.user.userData?.phone)
  const size = useSelector((state: RootState) => state.mq.size)
  const locale = useSelector((state: RootState) => state.locale.locale.name)
  const dataId = useSelector((state: RootState) => state.userProfile.form.dataId)

  const {
    register,
    watch,
    setValue,
    reset,
    resetField,
    trigger,
    formState: { errors, isValid, isDirty, dirtyFields }
  } = useForm<ProfileFormModel>({
    mode: 'onChange',
    defaultValues: {
      last_name: initLastName ?? '',
      first_name: initFirstName,
      email: initEmail,
      password: ''
    }
  })

  const [lastNameHint, setLastNameHint] = useState<string>('')
  const [firstNameHint, setFirstNameHint] = useState<string>('')
  const [emailHint, setEmailHint] = useState<string>('')
  const [hiddenPassword, setHiddenPassword] = useState<boolean>(true)
  const [passwordDisabled, setPasswordDisabled] = useState<boolean>(true)
  const [passwordValidation, setPasswordValidation] = useState(() => {
    return getPasswordValidationHints().slice()
  })

  const lastName = watch('last_name')
  const firstName = watch('first_name')
  const email = watch('email')
  const password = watch('password')
  const watchAllFields = useMemo(() => watch(), [
    lastName,
    firstName,
    email,
    password
  ])

  let formCss = {
    s: {
      titleSize: TextSize.P_14
    },
    m: {
      titleSize: TextSize.P_16
    },
    l: {
      titleSize: TextSize.P_16
    }
  }

  useEffect(() => {
    reset({
      last_name: initLastName ?? '',
      first_name: initFirstName,
      email: initEmail,
      password: ''
    })
    setPasswordDisabled(true)
  }, [dataId])

  useEffect(() => {
    if (passwordDisabled) {
      resetField('password')
    }
  }, [passwordDisabled])

  useEffect(() => {
    trigger('last_name')
    if ((!!lastName || !!initLastName) && lastName !== initLastName) {
      const before = initLastName || t('empty value')
      const after = lastName || t('empty value')
      setLastNameHint(t('Field changed hint', { before, after }))
    } else {
      setLastNameHint('')
    }
  }, [locale, lastName, initLastName])

  useEffect(() => {
    trigger('first_name')
    if (firstName !== initFirstName) {
      const before = initFirstName || t('empty value')
      const after = firstName || t('empty value')
      setFirstNameHint(t('Field changed hint', { before, after }))
    } else {
      setFirstNameHint('')
    }
  }, [locale, firstName, initFirstName])

  useEffect(() => {
    trigger('email')
    if (email !== initEmail) {
      const before = initEmail || t('empty value')
      const after = email || t('empty value')
      setEmailHint(t('Field changed hint', { before, after }))
    } else {
      setEmailHint('')
    }
  }, [locale, email, initEmail])

  useEffect(() => {
    trigger('password')
    let passwordValidation = checkValidationSteps(password)
    setPasswordValidation(passwordValidation)
  }, [locale, password])

  useEffect(() => {
    props.onChange(isDirty, isValid)
  }, [isDirty, isValid])

  useEffect(() => {
    dispatch(setUnsavedUserData(getChangedValues()))
  }, [watchAllFields])

  const getChangedValues = () => {
    const result = {}
    Object.keys(dirtyFields).forEach(key => {
      result[key] = watchAllFields[key]
    })
    return result
  }

  const trimValue = (value: string): string => {
    // Не позволяет ставить пробел нигде

    return value.replace(/\s+/g, '')
  }

  const transformName = (value: string): string => {
    // Позволяет ставить пробел, но не в начале и не больше одного подряд

    const trimmedValue = value.trimStart()
    return trimmedValue.replace(/\s+/g, ' ')
  }

  const validatePassword = (value: string): boolean => {
    if (passwordDisabled) return true
    return [
      value.length >= 8,
      value.match(/[A-Za-z]+/),
      value.match(/\d+/)
    ].every(item => item)
  }

  const handlePasswordIconClick = useCallback(() => {
    setHiddenPassword(prev => !prev)
  }, [])

  const handleChangePasswordClick = useCallback(() => {
    setPasswordDisabled(prev => !prev)
  }, [])

  return (
    <div className="user-profile__form">
      <div className="user-profile__input">
        <Paragraph
          className="user-profile__form-label"
          text={t('Surname')}
          size={formCss[size].titleSize}
          fontWeight={Weight.BOLD}
          color={'#2A3E58'}
        />
        <div className="form-group">
          <input
            type="text"
            id="profile-last-name-input"
            className={'form-control' + (errors.last_name ? ' invalid' : '')}
            autoComplete="off"
            {...register('last_name', {
              setValueAs: transformName,
              onChange: event => {
                event.preventDefault()
                event.target.value = transformName(event.target.value)
              },
              onBlur: () => {
                setValue('last_name', lastName.trim())
              }
            })}
          />
        </div>
        <Paragraph
          className="user-profile__form-hint"
          text={lastNameHint}
          textStyle={TextStyle.ITALIC}
        />
      </div>
      <div className="user-profile__input">
        <Paragraph
          className="user-profile__form-label"
          text={t('Name')}
          size={TextSize.P_16}
          fontWeight={Weight.BOLD}
          color={'#2A3E58'}
        />
        <div className="form-group">
          <input
            type="text"
            id="profile-first-name-input"
            className={'form-control' + (errors.first_name ? ' invalid' : '')}
            autoComplete="off"
            {...register('first_name', {
              required: true,
              setValueAs: transformName,
              onChange: event => {
                event.preventDefault()
                event.target.value = transformName(event.target.value)
              },
              onBlur: () => {
                setValue('first_name', firstName.trim())
              }
            })}
          />
          {errors.first_name && errors.first_name.type === 'required' && (
            <div className="error-message">{t('Profile name error')}</div>
          )}
        </div>
        <Paragraph
          className="user-profile__form-hint"
          text={firstNameHint}
          textStyle={TextStyle.ITALIC}
        />
      </div>
      <div className="user-profile__input">
        <Paragraph
          className="user-profile__form-label"
          text={t('Phone number')}
          size={TextSize.P_16}
          fontWeight={Weight.BOLD}
          color={'#2A3E58'}
        />
        <NewTextInput
          idKey="phone"
          value={initPhone}
          disabled
        />
      </div>
      <div className="user-profile__input">
        <Paragraph
          className="user-profile__form-label"
          text={t('Email')}
          size={TextSize.P_16}
          fontWeight={Weight.BOLD}
          color={'#2A3E58'}
        />
        <div className="form-group">
          <input
            id="profile-email-input"
            type="text"
            autoComplete="off"
            className={'form-control' + (errors.email ? ' invalid' : '')}
            {...register('email', {
              required: true,
              validate: {
                email: isEmail
              },
              setValueAs: trimValue,
              onChange: event => {
                event.preventDefault()
                event.target.value = trimValue(event.target.value)
              }
            })}
          />
          {errors.email?.type === 'required' && (
            <div className="error-message">{t('Profile email error 1')}</div>
          )}
          {errors.email?.type === 'email' && (
            <div className="error-message">{t('Profile email error 2')}</div>
          )}
        </div>
        <Paragraph
          className="user-profile__form-hint"
          text={emailHint}
          textStyle={TextStyle.ITALIC}
        />
      </div>
      <div className="user-profile__input user-profile__password-input">
        <Paragraph
          className="user-profile__form-label"
          text={t('Password')}
          size={TextSize.P_16}
          fontWeight={Weight.BOLD}
          color={'#2A3E58'}
        />
        <div className="form-group">
          <input
            id="profile-password-input"
            type={hiddenPassword ? 'password' : 'text'}
            className={'form-control' + (errors.password ? ' invalid' : '') + (passwordDisabled ? ' disabled' : '')}
            disabled={passwordDisabled}
            {...register('password', {
              required: !passwordDisabled,
              validate: {
                format: validatePassword
              },
              setValueAs: trimValue,
              onChange: event => {
                event.preventDefault()
                event.target.value = trimValue(event.target.value)
              }
            })}
          />
          {errors.password?.type === 'required' && (
            <div className="error-message">{t('Profile password error 1')}</div>
          )}
          {errors.password?.type === 'format' && (
            <div className="error-message">{t('Profile password error 2')}</div>
          )}
          {password && (
            <IconPassword
              closed={hiddenPassword}
              onClick={handlePasswordIconClick}
            />
          )}
        </div>
        {!password && (
          <button
            className="user-profile__password-input-btn"
            onClick={handleChangePasswordClick}>
            {t(passwordDisabled ? 'Change password' : 'Cancel')}
          </button>
        )}
        {!!password && <div className="user-profile__password-input-hints">
          <HintList
            label={t('Password should contain')}
            items={passwordValidation}
          />
        </div>}
      </div>
      <div className="user-profile__input">
        <Paragraph
          className="user-profile__form-label"
          text={t('Social media')}
          size={TextSize.P_16}
          fontWeight={Weight.BOLD}
          color={'#2A3E58'}
        />
        {initSocialAccount && (
          <a href={initSocialAccount} target="_blank" rel="noopener noreferrer">
            <Paragraph size={TextSize.P_16} text={initSocialAccount ?? ''}/>
          </a>
        )}
      </div>
    </div>
  )
}
