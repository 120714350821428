export interface AgeLimit {
  id: number;
  name: string;
  title: string;
  type: string;
  meta: {
    en: LocaleMeta,
    ru: LocaleMeta,
  };
}

export interface Country {
  id: number;
  name: string;
  title: string;
  type: string;
  meta: {
    en: LocaleMeta,
    ru: LocaleMeta,
  };
}

export enum RestrictionType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox'
}

export interface Restriction {
  id: string;
  name: string;
  title: string;
  children?: Array<Restriction>;
  type: string;
  meta: {
    en: LocaleMeta,
    ru: LocaleMeta,
    type?: RestrictionType
  };
}

export interface LangType {
  id: number;
  name: string;
  title: string;
  type: string;
  meta: {
    en: LocaleMeta,
    ru: LocaleMeta,
  };
}

export interface Currency {
  id: string;
  name: string;
  value: string;
  type: string;
}

export interface LocaleMeta {
  description: string,
  title: string
}

export interface PayoutLimit {
  name: string;
  value: string;
  type: string;
}

export interface Cost {
  id: number;
  description: string;
  name: string;
  title: string;
  value: string;
  type: string;
}

export interface Status {
  id: number
  name: string
  title: string
  type: string;
  meta: {
    en: LocaleMeta,
    ru: LocaleMeta,
  }
}

export interface MarathonStatus {
  id: number;
  name: string;
  title: string;
  type: string;
}

export interface Setting {
  id: number
  name: string
  value: string
  type: string;
  meta: {
    en: LocaleMeta,
    ru: LocaleMeta,
  }
}

export interface TypesState {
  ageLimits: Array<AgeLimit>;
  countries: Array<Country>;
  restrictions: Array<Restriction>;
  language: Array<LangType>;
  currencies: Array<Currency>;
  payoutLimits: Array<PayoutLimit>;
  needUploadPassport: Boolean;
  costs: Array<Cost>;
  statuses: Array<Status>;
  marathonStatuses: Array<MarathonStatus>;
  settings: Array<Setting>
}

const initialState: TypesState = {
  ageLimits: [],
  countries: [],
  restrictions: [],
  language: [],
  currencies: [],
  payoutLimits: [],
  needUploadPassport: true,
  costs: [],
  statuses: [],
  marathonStatuses: [],
  settings: [],
}

export default initialState
