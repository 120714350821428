import React, { useEffect, useState } from 'react'
import '../../../style/sass/instructions/guides/Guides.scss'
import { useTranslation } from 'react-i18next'
import Img from '../../common_kit/Img'
import GuideComponent from '../GuideComponent'
import GuideNumber from '../stepNumber/GuideNumber'
import { useSelector } from 'react-redux'
import MultiScreenshoter from '../../fileUploader/MultiScreenshoter'
import { RootState } from '../../../store/rootState'
import { Button, Spin } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { uploadScreenshotsWithReason } from '../../../store/application/api'
import _ from 'lodash'


type Data = {}

export default function UploadReasonGuide(props: Data) {
  const { t } = useTranslation()

  const deletedAppData = useSelector((state: RootState) => {
    return state.application.selectedDeletedApp
  }, _.isEqual)

  const [uploadedFiles, setUploadedFiles] = useState<Array<string>>([])
  const [showConfirmBtn, setShowConfirmBtn] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [uploading, setUploading] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(false)

  useEffect(() => {
    const files = deletedAppData?.deletionReason?.files ?? []
    setChecked(!!deletedAppData?.deletionReason?.status)
    setShowConfirmBtn(files.length < 5)
  }, [deletedAppData])

  const handleUploadBtnClick = async () => {
    if (!deletedAppData?.id || !deletedAppData?.application.id) return
    setLoading(true)
    const isFirstUploading = !deletedAppData?.deletionReason?.files?.length
    const success = await uploadScreenshotsWithReason(
      uploadedFiles,
      deletedAppData.id,
      deletedAppData.application.id,
      isFirstUploading
    )
    setLoading(false)
    if (success) {
      setUploadedFiles([])
    }
  }

  return (
    <div className="upload-reason-guide guide-content">
      <div className="guide-description">
        <p>
          <span>{t('UploadReasonGuide Regular text part 1')}</span>
        </p>
        <p>
          <span>{t('UploadReasonGuide Regular text part 2')}</span>
        </p>
      </div>
      <div className="guide-picture">
        <GuideComponent>
          <Img src="/guides/Upload_Reason_Guide.png"/>
        </GuideComponent>
        <GuideNumber className="number-1" text="1"/>
        <div className="arrow arrow-1">
          <svg width="89" height="62" viewBox="0 0 89 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.292893 53.2929C-0.0976311 53.6834 -0.0976311 54.3166 0.292893 54.7071L6.65685 61.0711C7.04738 61.4616 7.68054 61.4616 8.07107 61.0711C8.46159 60.6805 8.46159 60.0474 8.07107 59.6569L2.41421 54L8.07107 48.3431C8.46159 47.9526 8.46159 47.3195 8.07107 46.9289C7.68054 46.5384 7.04738 46.5384 6.65685 46.9289L0.292893 53.2929ZM87.0674 0.639006C69.3073 46.5195 23.9146 53 1 53V55C24.0854 55 70.6927 48.4805 88.9326 1.36099L87.0674 0.639006Z"
              fill="#FF5F5F"/>
          </svg>
        </div>
      </div>
      <div className="guide-picture">
        <GuideComponent className="screenshoter">
          <Spin spinning={loading}>
            <MultiScreenshoter
              imagesUpdateHandler={setUploadedFiles}
              imageUrls={deletedAppData?.deletionReason?.files ?? []}
              noMoreUpload={checked}
              onUploading={setUploading}
            />
            <div className="guide-toolbar">
              {(!checked && showConfirmBtn) && (
                <Button
                  type="primary"
                  className="upload-btn"
                  shape="round"
                  icon={<DownloadOutlined/>}
                  disabled={!uploadedFiles.length || uploading}
                  size="large"
                  onClick={handleUploadBtnClick}>
                  {t('Ready')}
                </Button>
              )}
            </div>
          </Spin>
        </GuideComponent>
        <GuideNumber className="number-2" text="2"/>
        <div className="arrow arrow-2">
          <svg width="65" height="88" viewBox="0 0 65 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.292893 8.70711C-0.0976311 8.31658 -0.0976311 7.68342 0.292893 7.29289L6.65685 0.928932C7.04738 0.538406 7.68054 0.538406 8.07107 0.928932C8.46159 1.31946 8.46159 1.95262 8.07107 2.34315L2.41421 8L8.07107 13.6569C8.46159 14.0474 8.46159 14.6805 8.07107 15.0711C7.68054 15.4616 7.04738 15.4616 6.65685 15.0711L0.292893 8.70711ZM63.0172 87.1848C56.5255 52.6651 45.0121 33.1393 33.1804 22.2439C21.3607 11.3596 9.12784 9 1 9V7C9.52733 7 22.2772 9.48476 34.5352 20.7726C46.781 32.0494 58.44 52.0236 64.9828 86.8152L63.0172 87.1848Z"
              fill="#FF5F5F"/>
          </svg>
        </div>
      </div>
    </div>
  )
}
