import React from 'react'
import H5 from '../../common_kit/text/H5'
import { useTranslation } from 'react-i18next'
import { getAmountWithSign } from '../../../utils/helpers'
import { useSelector } from "react-redux";
import { RootState } from "../../../store/rootState";
import MqSmall from '../../mq/MqSmall';
import MqAfterSmall from '../../mq/MqAfterSmall';


type Data = {
  amountMoney: number,
  lockedMoney: number,
}

export default function BalanceInfo(props: Data) {
  const { t } = useTranslation()

  const locale = useSelector((state: RootState) => state.locale.locale.name)

  return (
    <div className="balance-info">
      <MqAfterSmall>
        <div className="active-balance">
          <H5 text={t('Active funds')}/>
          <div className="amount">
            <div className="wallet-icon">
              <img src="/wallet_icon.svg" alt=""/>
            </div>
            <div className={'amount-money'}>
              {getAmountWithSign(props.amountMoney)}
            </div>
          </div>
        </div>
        <div className="hold-balance">
          <div className={locale === 'en' ? 'hold-balance-title tooltipEn' : 'hold-balance-title'}>
            <H5 text={t('Hold')} color="#859AB6"/>
          </div>
          <div className="amount">
            <div className={'amount-money'}>
              / {getAmountWithSign(props.lockedMoney)}
            </div>
          </div>
        </div>
      </MqAfterSmall>
      <MqSmall>
        <div className="active-balance">
          <H5 text={t('Active funds')}/>
        </div>
        <div className="hold-balance">
          <div className={locale === 'en' ? 'hold-balance-title tooltipEn' : 'hold-balance-title'}>
            <H5 text={t('Hold')} color="#859AB6"/>
          </div>
        </div>
        <div className="balance_amount">
          <div className="amount active-money">
            <div className="wallet-icon">
              <img src="/wallet_icon.svg" alt=""/>
            </div>
            <div className={'amount-money'}>
              {getAmountWithSign(props.amountMoney)}
            </div>
          </div>
          <div className="amount">
            <div className={'amount-money'}>
              / {getAmountWithSign(props.lockedMoney)}
            </div>
          </div>
        </div>
      </MqSmall>
    </div>
  )
}
