import initialState from './initialState'
import { RESET_FORM, SET_AVATAR_IMAGE, SET_IMG_PATH, SET_IMG_STATUS, SET_UNSAVED_DATA } from './actionTypes'

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case SET_AVATAR_IMAGE:
      return {
        ...state,
        image: action.image
      }
    case SET_IMG_STATUS:
      return {
        ...state,
        status: action.status,
      }
    case SET_IMG_PATH:
      return {
        ...state,
        path: action.path,
        status: action.status,
      }
    case SET_UNSAVED_DATA:
      return {
        ...state,
        form: {
          ...state.form,
          unsavedData: action.data
        }
      }
    case RESET_FORM:
      return {
        ...state,
        form: {
          unsavedData: {},
          dataId: Date.now()
        }
      }
    default:
      return state
  }
}
