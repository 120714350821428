import initialState from './initialState'
import { SET_CNV_ID, SET_SEO_DATA, UPDATE_PHONE_CODE_STATUS, DELETE_SEO_DATA } from './actionTypes'
import { set, setMany, delMany } from 'idb-keyval'

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SEO_DATA:
      setMany([['cnvId', action.data.cnvId], ['utmSource', action.data.utmSource]])
      return {
        ...state,
        cnv_id: action.data.cnvId,
        utm_source: action.data.utmSource
      }
    case SET_CNV_ID:
      set('cnvId', action.data)
      return {
        ...state,
        cnv_id: action.data,
      }
    case UPDATE_PHONE_CODE_STATUS:
      return {
        ...state,
        alreadyReqCode: action.data
      }
    case DELETE_SEO_DATA:
      delMany(['cnvId', 'utmSource'])
      return {
        ...state,
        cnv_id: '',
        utm_source: ''
      }
    default:
      return state
  }
}
