import React from 'react'


type Data = {
  className?: string
  fill?: string,
  width?: number,
  height?: number
}

IconWarning.defaultProps = {
  fill: '#FF5F5F',
  width: 24,
  height: 24
}

export default function IconWarning(props: Data) {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill={props.fill} fillRule="evenodd" clipRule="evenodd"
            d="M13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13V7ZM13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V16Z"/>
      <circle stroke={props.fill} cx="12" cy="12" r="10" strokeWidth="2"/>
    </svg>
  )
}
