export default class FormDataV3 {
  constructor () {
    this.data = {}
  }

  addParam(title, value) {
    this.data[title] = value
  }

  addAttr(title, value) {
    if (!this.data.hasOwnProperty('attributes')) this.data.attributes = {}
    this.data.attributes[title] = value
  }

  addMeta(title, value) {
    if (!this.data.hasOwnProperty('meta')) this.data.meta = {}
    this.data.meta[title] = value
  }

  addRelation(title, value) {
    if (!this.data.hasOwnProperty('relationships')) this.data.relationships = {}
    this.data.relationships[title] = value
  }
}
