import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'
import _ from 'lodash'
import {goToStepByLinkId, setBtnDisabled} from '../../store/task/actions'
import { ScreenshotType } from '../../store/taskScreenshots/initialState'


interface Data {
  children: ReactElement,
  typeTask: string
}

export default function WithStepCheck (props: Data) {
  const dispatch = useDispatch()
  const currentStep = useSelector((state: RootState) => props.typeTask === 'task' ? state.task.currentStep : state.taskList.simpleTaskGmail.currentStep, _.isEqual)
  const needUploadPassport = useSelector((state: RootState) => state.types.needUploadPassport)
  const selfie = useSelector((state: RootState) => state.task.selectedTask?.verification?.selfie_pic)
  const passport = useSelector((state: RootState) => state.task.selectedTask?.verification?.passport_pic)
  const compensationScreen = useSelector((state: RootState) => state.task.selectedTask?.compensation?.screen)
  const taskId = useSelector((state: RootState) => state.task.selectedTask?.id)

  const uploadedSelfie = useSelector((state: RootState) => {
    return state.taskScreenshots.screenshots[`${taskId}-${ScreenshotType.SELFIE}`]?.image
  }, _.isEqual)
  const uploadedPassport = useSelector((state: RootState) => {
    return state.taskScreenshots.screenshots[`${taskId}-${ScreenshotType.PASSPORT}`]?.image
  }, _.isEqual)
  const uploadedCompensationScreen = useSelector((state: RootState) => {
    return state.taskScreenshots.screenshots[`${taskId}-${ScreenshotType.COMPENSATION}`]?.image
  }, _.isEqual)

  const stepConditions = {
    compensation: () => compensationScreen || uploadedCompensationScreen,
    passport: () => {
      if (!needUploadPassport) return true
      return [
        selfie || uploadedSelfie,
        passport || uploadedPassport
      ].every(i => !!i)
    }
  }

  for (const linkId of currentStep?.stepOptions?.stepsToBeCompleted ?? []) {
    const condition = stepConditions[linkId]

    if (!condition()) {
      dispatch(goToStepByLinkId(linkId))
      return null
    }
  }

  const stepWithoutBlocking = !currentStep?.stepOptions.blockExecution
  if (stepWithoutBlocking) {
    dispatch(setBtnDisabled(false))
  }

  return props.children
}
