import i18n from 'i18next'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import {
  addressFormatter,
  cardholderFormatter,
  FAILED_FINGERPRINT_ID,
  LOCAL_STORAGE_FIELDS,
  ME_REGEX,
  META_TAGS,
  MT_REGEX,
  MU_REGEX,
  WEB_GL_1,
  WEB_GL_2
} from './constants'
import store from '../store'
import { PaymentType } from '../store/payments/initialState'
import { Language, OrderedTaskStep, Task, TaskStep } from '../store/task/initialState'
import Notification from '../components/notification/Notification'
import { isBrowser } from 'react-device-detect'
import { get, set } from 'idb-keyval'
import { setAccessToken, setLocalToken } from '../store/auth/actions'
import { setLocalTypes } from '../store/types/actions'
import { setLocalLocale } from '../store/locale/actions'
import { clearUserData, setLocalUserData, setUserId } from '../store/user/actions'
import { setLocalCnvId } from '../store/binom/actions'
import { configuration } from '../configuration'
import { PopupType } from '../components/tasksPage/TaskPreviewCard'
import moment from 'moment'
import { axios, axiosV3 } from '../axios'
import _ from 'lodash'
import { PublisherTaskType } from '../store/taskList/initialState'
import DetectRTC from 'detectrtc'
import FormDataV3 from "./formDataApiV3";


export enum FileUploadStatus {
  PROGRESS = 1,
  SUCCESS = 2,
  FAIL = 3
}


export async function getUrlFromFile(image: File | string | null | undefined): Promise<string> {
  //Возвращает url файла или пустую строку

  if (image === null || image === undefined) return ''
  if (typeof image === 'string') return image.toString()
  let result = ''
  if (image instanceof File) {
    result = await new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onloadend = () => {
        resolve(reader.result?.toString() ?? '')
      }
    })
  }
  return result
}

export async function getTextFromFile(text: File): Promise<string> {
  //Возвращает содержимое файла или пустую строку

  return await new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsText(text)
    reader.onloadend = () => {
      resolve(reader.result?.toString() ?? '')
    }
  })
}


export function setDefaultLangFirst(language: Array<Language>, langDefault: Language): Array<Language> {
  // Пересобирает массив с локализациями, помещая основную локализацию в начало массива,
  // возвращает новый массив

  if (!language || !language.length) return []
  if (language.length === 1) return language
  const otherLangs: Array<Language> = language.filter(item => item.name !== langDefault.name)
  return [langDefault, ...otherLangs]
}


export function numberTaskSteps(sections: any, steps: Array<TaskStep>) {
  // Добавляет нумерацию шагов задачи в рамках каждого этапа в формате 1/5, 2/5,...

  const result: Array<OrderedTaskStep> = []

  for (const section in sections) {
    const num = parseInt(section)
    if (isNaN(num)) continue
    const stepList = steps.filter(step => step.stepOptions.section === num)
    const totalStepsInSection = stepList.length
    stepList.map((item, index) => {
      const orderedItem: OrderedTaskStep = {
        ...item,
        stepOptions: {
          ...item.stepOptions,
          stepNumInSection: index + 1,
          totalStepsInSection: totalStepsInSection
        }
      }
      result.push(orderedItem)
    })
  }

  return result
}

export function isUrl(search?: string): boolean {
  if (!search) return false
  const regexp = new RegExp(/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w\.-]*)*(\?.+)?\/?$/)
  return regexp.test(search)
}


export function isEmail(search?: string): boolean {
  if (!search) return false
  const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"а-яА-ЯёЁ]+(\.[^<>()\[\]\\.,;:\s@"а-яА-ЯёЁ]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  return regexp.test(search)
}


export function eraseFirstSlash(string) {
  if (!string) return ''
  if (string[0] === '/') return string.slice(1)
  return string
}


export function getFullFileUrl(path) {
  if (!path) return ''
  if (path.startsWith('http') || path.startsWith('data')) return path
  return configuration.baseUrl + eraseFirstSlash(path)
}


export function getDownloadUrl(path) {
  if (!path) return ''
  return '/cdn/' + eraseFirstSlash(path)
}


export function getDownloadName(path: string | undefined, name: string | null = null): string {
  if (!path) return ''

  if (!name) {
    return path.split('/').pop() ?? ''
  }
  const ext = getFileExtension(path)
  return `${name}.${ext}`
}

function getFileExtension(url: string): string {
    url = url.substr(1 + url.lastIndexOf("/"));
    url = url.split('?')[0];
    url = url.split('#')[0];
    url = url.split('.')[1];
    return url;
}


export enum PASSWORD_VALIDATION_RULES {
  AT_LEAST_8_SYMBOLS = '8 symbols',
  CHARS = 'chars',
  DIGITS = 'digits',
}


export type PasswordValidationItem = {
  id: string,
  options: {
    text: string,
    value: boolean
  }
}


export const getPasswordValidationHints = (): Array<PasswordValidationItem> => [
  {
    id: PASSWORD_VALIDATION_RULES.AT_LEAST_8_SYMBOLS,
    options: {
      text: `${i18n.t('Non less than 8 characters')};`,
      value: false
    }
  },
  {
    id: PASSWORD_VALIDATION_RULES.CHARS,
    options: {
      text: `${i18n.t('Letters')};`,
      value: false
    }
  },
  {
    id: PASSWORD_VALIDATION_RULES.DIGITS,
    options: {
      text: `${i18n.t('Numbers')}.`,
      value: false
    }
  }
]


export function checkValidationSteps(password: string) {
  return getPasswordValidationHints().map(item => {
    switch (item.id) {
      case PASSWORD_VALIDATION_RULES.AT_LEAST_8_SYMBOLS:
        item.options.value = password.length >= 8
        break
      case PASSWORD_VALIDATION_RULES.CHARS:
        const charsRegex = new RegExp(/[A-Za-z]/)
        item.options.value = charsRegex.test(password)
        break
      case PASSWORD_VALIDATION_RULES.DIGITS:
        const digitsRegex = new RegExp(/[0-9]/)
        item.options.value = digitsRegex.test(password)
        break
    }
    return item
  })
}


export function checkValidateErrors(e: any) {
  if (e.response?.status === 422) {
    const error = e.response.data.error
    if (_.isString(error)) {
      Notification({ noticeType: 'error', text: error, notClose: true })
      return
    }
    for (const field of Object.keys(error)) {
      for (const message of error[field]) {
        Notification({ noticeType: 'error', text: message, notClose: true })
      }
    }
  }
}


export function parseTaskTimeToString(taskTime: number) {
  const hours: number = Math.floor(taskTime / 3600)
  const minutes: number = Math.floor((taskTime % 3600) / 60)
  const hoursText = hours ? `${hours} ${getCorrectWordVariant('hour', hours)}` : ''
  const minutesText = minutes ? `${minutes} ${getCorrectWordVariant('minute', minutes)}` : ''
  return `${hoursText} ${minutesText}`
}


export async function getFPVisitorId(options?) {
  try {
    const fp = await FingerprintJS.load({
      apiKey: process.env.REACT_APP_FP_API_KEY as string,
      endpoint: process.env.REACT_APP_FP_ENDPOINT
    })
    const fingOpt = {
      ...options,
      extendedResult: true
    }
    const result: any = await fp.get(fingOpt)
    return result.visitorId
  } catch (e) {
    console.info('Не удалось получить FPVisitorId:', e)
    return FAILED_FINGERPRINT_ID
  }
}

export const collectBrowserData = (ip, id) => {
  const data = new FormDataV3()
  const newObj: any = navigator;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const cpu = navigator.hardwareConcurrency;
  const memory = newObj.deviceMemory;
  const webgl = detectWebGLContext()
  const webrtc = detectWebRTC()
  data.addParam('type', 'user-browsers')
  data.addAttr('timezone', timezone)
  data.addAttr('cpu', cpu)
  data.addAttr('memory', memory)
  data.addAttr('memory', memory)
  data.addAttr('canvas', webgl.canvas)
  data.addAttr('webrtc', {mode: webrtc.mode, ip: ip})
  data.addAttr('webgl', webgl.webgl)
  data.addAttr('webgl_info', webgl.webgl_info)
  data.addRelation('entity', {
    data: {
      id: String(id),
      type: 'subtasks'
    }
  })

  function detectWebGLContext() {
    const canvas = document.createElement("canvas");

    // Get WebGLRenderingContext from canvas element.
    const webGl1 = canvas.getContext("webgl")
    const webGl2 = canvas.getContext("webgl2")
    if (!webGl1 && !webGl2) return {
      canvas: !!window.HTMLCanvasElement ? 'real' : 'off',
      webgl: 'off',
      webgl_info: {
        mode: 'off'
      }
    }
    const preferVerWebGl = webGl2 ? 2 : 1
    const webGlref = {
      1: {
        listMaximums: WEB_GL_1,
        gl: webGl1
      },
      2: {
        listMaximums: WEB_GL_2,
        gl: webGl2
      }
    }[preferVerWebGl]
    const webgl = webGlref.gl instanceof WebGLRenderingContext ? "noise" : "off";

    const vendor = webGlref.gl?.getParameter(webGlref.gl.VENDOR);
    const renderer = webGlref.gl?.getParameter(webGlref.gl.RENDERER);
    let maximums = {}
    webGlref.listMaximums.forEach(param => {
      maximums[param] = webGlref.gl?.getParameter(webGlref.gl[param])
    })

    return {
      canvas: !!window.HTMLCanvasElement ? 'real' : 'off',
      webgl,
      webgl_info: {
        mode: vendor ? 'manual' : 'off',
        vendor,
        renderer,
        maximums
      }
    }
  }

  function detectWebRTC() {
    return {mode: DetectRTC.isWebRTCSupported ? 'manual' : 'off'}
  }

  return {data}
}


export const formatCardholderData = (field, value) => {
  const rules = cardholderFormatter[field]
  if (!rules) return value
  const callback = (value, rule) => rule(value)
  return rules.reduce(callback, value)
}


export const formatAddressData = (field, value) => {
  const rules = addressFormatter[field]
  if (!rules) return value
  const callback = (value, rule) => rule(value)
  return rules.reduce(callback, value)
}


export const getLanguageTitle = (name: string, locale: string): string => {
  const langList = store.getState().types.languages
  return langList.find(item => item.name === name)?.meta[locale].title ?? ''
}


export const startIntercomChat = () => {
  window.Intercom('boot', store.getState().intercom)
}


export const getRate = () => {
  const currencyName = store.getState().locale.locale.name
  return store.getState().locale.rate[currencyName] ?? 1
}


export const getAmountWithSign = (rubAmount: number | string): string => {
  const currentLocaleName = store.getState().locale.locale.name
  if (currentLocaleName === 'ru') {
    return `${rubAmount} ₽`
  }
  if (currentLocaleName === 'en') {
    const rate = store.getState().locale.rate[currentLocaleName]
    const amount = (Number(rubAmount) / rate).toFixed(2)
    return `$ ${amount}`
  }
  return String(rubAmount)
}


export const getPaymentLimits = (currency: string, paymentType: PaymentType, countryCode: string | null, isDevastated: boolean = false): { min?: number, max?: number } => {
  let min, max
  if (!currency) return { min, max }
  const limits = store.getState().types.payoutLimits
  if (!limits || !limits.length) return { min, max }
  const getLimitValueByName = name => Number(limits.find(item => item.name === name).value)
  const getRubLimits = () => {
    if (!paymentType) return
    const getPhoneRubLimits = () => {
      min = getLimitValueByName('payout_limit_qiwi_mc_ru_rub_min')
      max = getLimitValueByName('payout_limit_qiwi_mc_ru_rub_max')
    }
    const getBankCardRubLimits = () => {
      if (!countryCode || countryCode === 'all') return
      const getRusRubLimits = () => {
        min = getLimitValueByName('payout_limit_qiwi_card_ru_rub_min')
        max = getLimitValueByName('payout_limit_qiwi_card_ru_rub_max')
      }
      const getUkrRubLimits = () => {
        min = getLimitValueByName('payout_limit_capitalist_card_ua_rub_min')
        max = getLimitValueByName('payout_limit_capitalist_card_ua_rub_max')
      }
      const getKazRubLimits = () => {
        min = getLimitValueByName('payout_limit_capitalist_card_kz_rub_min')
        max = getLimitValueByName('payout_limit_capitalist_card_kz_rub_max')
      }
      const getRubLimitsByCountryCode = {
        ru: getRusRubLimits,
        ua: getUkrRubLimits,
        kz: getKazRubLimits
      }[countryCode]
      getRubLimitsByCountryCode!()
    }
    const getQiwiRubLimits = () => {
      min = getLimitValueByName('payout_limit_qiwi_qiwi_ru_rub_min')
      max = getLimitValueByName('payout_limit_qiwi_qiwi_ru_rub_max')
    }
    const getRubLimitsForPaymentType = {
      mc: getPhoneRubLimits,
      card: getBankCardRubLimits,
      qiwi: getQiwiRubLimits
    }[paymentType]
    getRubLimitsForPaymentType!()
  }
  const getUsdLimits = () => {
    if (!countryCode) return
    const getRusUsdLimits = () => {
      min = getLimitValueByName('payout_limit_capitalist_card_ru_usd_min')
      max = getLimitValueByName('payout_limit_capitalist_card_ru_usd_max')
    }
    const getUkrUsdLimits = () => {
      min = getLimitValueByName('payout_limit_capitalist_card_ua_usd_min')
      max = getLimitValueByName('payout_limit_capitalist_card_ua_usd_max')
    }
    const getKazUsdLimits = () => {
      min = getLimitValueByName('payout_limit_capitalist_card_kz_usd_min')
      max = getLimitValueByName('payout_limit_capitalist_card_kz_usd_max')
    }
    const getDefaultUsdLimits = () => {
      min = getLimitValueByName('payout_limit_capitalist_card_all_usd_min')
      max = getLimitValueByName('payout_limit_capitalist_card_all_usd_max')
    }
    const getUsdLimitsForCountryCode = {
      ru: getRusUsdLimits,
      ua: getUkrUsdLimits,
      kz: getKazUsdLimits
    }[countryCode] || getDefaultUsdLimits
    const getUsdLimitsPayTM = () => {
      min = getLimitValueByName('payout_limit_capitalist_paytm_all_usd_min')
      max = getLimitValueByName('payout_limit_capitalist_paytm_all_usd_max')
    }
    const getUsdLimitsForPaymentType = {
      card: getUsdLimitsForCountryCode,
      paytm: getUsdLimitsPayTM
    }[paymentType] || getDefaultUsdLimits
    getUsdLimitsForPaymentType()
  }
  const getUsdtLimits = () => {
    const specialLimit = isDevastated ? 'special' : ''
    const getUsdtTrc20UsdLimits = () => {
      min = getLimitValueByName(`payout_limit_capitalist_usdttrc20${specialLimit}_all_usdt_min`)
      max = getLimitValueByName(`payout_limit_capitalist_usdttrc20${specialLimit}_all_usdt_max`)
    }
    const getUsdtLimitsForPaymentType = {
      usdttrc20: getUsdtTrc20UsdLimits
    }[paymentType]
    if (!getUsdtLimitsForPaymentType) return
    getUsdtLimitsForPaymentType()
  }
  const getLimitsByCurrency = {
    rub: getRubLimits,
    usd: getUsdLimits,
    usdt: getUsdtLimits
  }[currency]
  getLimitsByCurrency!()
  return { min, max }
}


export const checkFileFormat = (file, types): boolean => {
  // Проверка, что файл соответствует одному из типов

  const format = file.name.split('.').reverse()[0]
  const isValid = types.includes(format.toLowerCase())
  if (!isValid) {
    const typesString = types.join(', ').toUpperCase()
    Notification({
      noticeType: 'error',
      text: i18n.t('File type error', { typesString })
    })
  }
  return isValid
}


export const checkFileSize = (file, max): boolean => {
  // Проверка, что размер файла не превышает max МБ

  const limit: number = max * 1000 * 1000
  const isValid = file.size <= limit
  if (!isValid) {
    const sizeString = (file.size / 1000 / 1000).toFixed(2)
    Notification({
      noticeType: 'error',
      text: i18n.t('File size error', { sizeString, max })
    })
  }
  return isValid
}


export const checkDevice = () => {
  const landscape = window.screen.width > window.screen.height
  return isBrowser && landscape
}


export const setProgressToIDB = (taskId: number | string, userId: number | string, progress: number, locale: string, type: string | undefined = undefined) => {
  const key = `${userId}_${taskId}_${locale}`
  const keyType = `${userId}_${taskId}_type`
  get('advancement')
    .then(data => {
      if (!data) {
        set('advancement', { [key]: progress, [keyType]: type })
        return
      }
      data[key] = progress
      set('advancement', data)
    })
}


export const removeProgressFromIDB = (taskId: number | string, userId: number | string, locale: string) => {
  const key = `${userId}_${taskId}_${locale}`
  get('advancement')
    .then(data => {
      if (!data) return
      delete data[key]
      set('advancement', data)
    })
}


export const deleteFinishedTaskFromIDB = (taskId: number | undefined, storageField: string): void => {
  const userId = store.getState().user.userData.id
  get(storageField)
    .then(data => {
      if (!data) return
      const foundUser = data.find(item => item.userId === userId)
      if (!foundUser || !foundUser.taskId.includes(taskId)) return
      foundUser.taskId = foundUser.taskId.filter(item => item !== taskId)
      set(storageField, data)
    })
}

export const checkTranslate = (data, lang, field = 'title', key = '') => {
  const addMeta = () => {
    const list = store.getState().types[key]
    const dataObject = list.find(i => i.id === data.id)
    data.meta = { ...dataObject.meta }
  }
  if (key && !data.meta) {
    try {
      addMeta()
    } catch (e) {
      console.log(`Не удалось найти переводы в справочнике (${key} -> id${data.id}). Ошибка: ${e}`)
      return data[field]
    }
  }
  try {
    return data.meta[lang][field]
  } catch {
    console.error(`Отсутствует перевод поля ${field} - ${data[field]}`)
    return data[field]
  }
}

export const getCorrectWordVariant = (word: string, value: number) => {
  const dictionary = {
    hour: {
      en: {
        one: 'hour',
        several: 'hours'
      },
      ru: {
        endsWithOne: 'час',
        endsWithFew: 'часа',
        endsWithMore: 'часов'
      }
    },
    minute: {
      en: {
        one: 'minute',
        several: 'minutes'
      },
      ru: {
        endsWithOne: 'минута',
        endsWithFew: 'минуты',
        endsWithMore: 'минут'
      }
    },
    dayGenitive: {
      en: {
        one: 'day',
        several: 'days'
      },
      ru: {
        endsWithOne: 'день',
        endsWithFew: 'дня',
        endsWithMore: 'дней'
      }
    },
    hourGenitive: {
      en: {
        one: 'hour',
        several: 'hours'
      },
      ru: {
        endsWithOne: 'час',
        endsWithFew: 'часа',
        endsWithMore: 'часов'
      }
    },
    minuteGenitive: {
      en: {
        one: 'minute',
        several: 'minutes'
      },
      ru: {
        endsWithOne: 'минуту',
        endsWithFew: 'минуты',
        endsWithMore: 'минут'
      }
    }
  }
  if (!dictionary[word]) return i18n.t(word)
  const variants = dictionary[word][i18n.language]
  if (i18n.language === 'en') {
    return value === 1 ? variants.one : variants.several
  }
  if (i18n.language === 'ru') {
    const isException = value >= 11 && value <= 20
    const lastChar = Number(String(value).slice(-1))
    if (isException) return variants.endsWithMore
    if (lastChar === 1) return variants.endsWithOne
    if (lastChar >= 2 && lastChar <= 4) return variants.endsWithFew
    return variants.endsWithMore
  }
}


export const dataTransferFromLsToIdb = () => {
  for (const key of LOCAL_STORAGE_FIELDS) {
    const rawData = localStorage.getItem(key)
    if (!rawData) continue
    let parsedData
    try {
      parsedData = JSON.parse(rawData)
    } catch {
      parsedData = rawData
    }
    set(key, parsedData)
  }
  localStorage.clear()
}

export const setGeneralLocalData = async () => Promise.allSettled([
  store.dispatch(setLocalToken()),
  store.dispatch(setLocalTypes()),
  store.dispatch(setLocalLocale()),
  store.dispatch(setLocalUserData()),
  store.dispatch(setLocalCnvId())
])

export const copyToClipboard = (text: string): void => {
  navigator.clipboard.writeText(text)
}

export function calculateCompleteAt(task: Partial<Task>): Task {
  if ('calcCompleteAt' in task) return task as Task
  if (!task.complete_in || task.type === PublisherTaskType.VERIFICATION) return {
    ...task,
    calcCompleteAt: ''
  } as Task
  const process = ['process', 'process_assigned'].includes(task.status?.name ?? '')
  const calcCompleteAt = process ? moment().add(task.complete_in, 'seconds').format('YYYY-MM-DD HH:mm:ss') : ''
  return {
    ...task,
    calcCompleteAt
  } as Task
}

export async function checkModalOpenStatus(userId: string, taskId: number | string, storageField: PopupType): Promise<boolean> {
  const data = await get(storageField)
  if (!data) {
    return true
  }
  const foundUser = data.find(item => item.userId === userId)
  if (!foundUser) {
    return true
  }
  const foundTaskId = foundUser.taskId.includes(taskId)
  return !foundTaskId
}

export async function taskPopupCloseHandler(userId: string, taskId: string, storageField: PopupType): Promise<void> {
  const userRecord = { userId, taskId: [taskId] }
  const data = await get(storageField)
  if (!data) {
    set(storageField, [userRecord])
    return
  }
  const foundUser = data.find(item => item.userId === userId)
  if (!foundUser) {
    data.push(userRecord)
  } else if (!foundUser.taskId.includes(taskId)) {
    foundUser.taskId.push(taskId)
  }
  set(storageField, data)
}

export async function uploadFile(file: File, isPrivate: boolean = false): Promise<{ path: string, status: FileUploadStatus }> {
  const config = {
    baseURL: process.env.REACT_APP_UPLOAD_URL
  }
  const data = new FormData()
  data.set('file', file)
  isPrivate && data.set('private', 'true')
  try {
    const response = await axios.post('/', data, config)
    return {
      path: response.data.response.file,
      status: FileUploadStatus.SUCCESS
    }
  } catch (e) {
    console.info('Error in the method "uploadFile": ', e)
    Notification({ noticeType: 'error', text: i18n.t('Upload file error') })
    return {
      path: '',
      status: FileUploadStatus.FAIL
    }
  }
}

export function shortenFileName(fileName: string, max: number): string {
  if (fileName.length <= max) return fileName
  const parts = fileName.split('.')
  const name = parts[0]
  const format = parts[1]
  const shortName = name.slice(0, max - format.length - 3)
  return `${shortName}...${format}`
}

export function setMetaTags(locale: string): void {
  if (!['ru', 'en'].includes(locale)) return
  const oldTags = document.head.querySelectorAll('meta[data-type="metadata"]')
  for (const tag of oldTags) {
    document.head.removeChild(tag)
  }
  for (const data of META_TAGS) {
    const tag = document.createElement('meta')
    tag.setAttribute('property', data.property)
    tag.setAttribute('content', data.content[locale])
    tag.setAttribute('data-type', 'metadata') // Атрибут, по которому будем искать теги, чтобы удалить
    document.head.appendChild(tag)
  }
}

export function parseParameters(search: string): Array<{ key: string, value: string }> {
  return search.replace('?', '').split('&').map(item => {
    const parts = item.split('=')
    return {
      key: parts[0],
      value: parts[1]
    }
  })
}

export function checkAgeLimits(targetAge: number, type: string, ageLimits: string[]): boolean {
  const ageLimitsServer = store.getState().types.ageLimits
  const transformAgeLimits = ageLimits.map(age => {
    return +ageLimitsServer.find(ageServer => ageServer.id === age).name
  })
  if (type === 'less') return transformAgeLimits.some(age => age < targetAge)
  return transformAgeLimits.some(age => age > targetAge)
}

export function checkSearchAndLogIn(search: string): string {
  if (!search.includes('token=') || !search.includes('uid=')) return search

  const paramString = search.replace('?', '')
  const params = Object.fromEntries(paramString.split('&').map(str => str.split('=')))
  store.dispatch(clearUserData())
  store.dispatch(setAccessToken(params.token))
  store.dispatch(setUserId(params.uid))
  const newParamString = Object.entries(params)
    .filter(item => !['token', 'uid'].includes(item[0]))
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
  return '?' + newParamString
}

export function checkSearchAndTrack(search: string): string {
  const hasTrackingParams = search.match(ME_REGEX) && search.match(MU_REGEX) && search.match(MT_REGEX)
  if (hasTrackingParams) {
    const trackingParams = getTrackingParams(search)
    sendTrackingData(trackingParams)
    return search.replace(ME_REGEX, '').replace(MU_REGEX, '').replace(MT_REGEX, '')
  }
  return search
}

export function getTrackingParams(search: string): { event: string, user: string, task: string } {
  const pairs = search.replace('?', '').split('&')
  const allParams = Object.fromEntries(pairs.map(item => item.split('=')))
  return {
    event: allParams.me,
    user: allParams.mu,
    task: allParams.mt
  }
}

export async function sendTrackingData(params: { event: string, user: string, task: string }) {
  const { event, user, task } = params
  const data = new FormDataV3()
  data.addParam('type', 'track-events')
  data.addAttr('event', event)
  data.addAttr('context', {sub_task_id: task})
  data.addRelation('user', {data: {id: user, type: 'users'}})
  try {
    await axiosV3.post('events/tracker', data)
  } catch (e) {
    console.log(e)
  }
}

export async function FileCheckStatus(file: File): Promise<{ status: FileUploadStatus }> {
  const contentFile = await getTextFromFile(file)
  const email = contentFile.match(/\(\w+@gmail\.com\)/gm)
  const codes = contentFile.match(/\d.\s\d{4}\s\d{4}/gm)
  const isCorrectFile = [email, codes].some(item => item)
  if (isCorrectFile) return {status: FileUploadStatus.SUCCESS}
  Notification({ noticeType: 'error', text: i18n.t('Upload gmail code error') })
  return {status: FileUploadStatus.FAIL}
}

export function updateDataIndexedDB(data, id, field, newData) {
  if (!data) {
    data = {
      [id]: {[field]: newData}
    }
    return data
  }
  if (data.hasOwnProperty(id)) {
    data[id][field] = newData
  } else {
    data[id] = {[field]: newData}
  }
  return data
}

export function allowGoogleAccountInVerification(): boolean {
  const state = store.getState()
  const allowToUser: boolean = state.user.userData?.allow_google_account_verification ?? false
  const allowGlobally: boolean = state.types.settings.find(i => i.name === 'allow_google_account_verification')?.value === "1" ?? false
  const availablePhone: boolean = state.task['availablePhone']
  return allowGlobally && allowToUser && availablePhone
}

export function getUserId() {
  const userData = store.getState().user.userData
  const userId = store.getState().user.id
  return userData?.id || userId
}
