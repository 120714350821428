import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootState";

type adaptiveModel = {
  width: number,
  height: number
}

type svgCssModel = {
  s: adaptiveModel,
  m: adaptiveModel,
  l: adaptiveModel
}

export default function NotAvailableTasksIllustration() {

  const size = useSelector((state: RootState) => state.mq.size);
  const svgCss: svgCssModel = {
    s:{
      width: 245,
      height: 141
    },
    m:{
      width: 245,
      height: 141
    },
    l:{
      width: 258,
      height: 148
    }
  }

  return <div className='not-available-task-illustration'>
    <svg width={svgCss[size].width} height={svgCss[size].height} viewBox="0 0 258 148" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M199.745 95.5427C199.745 88.7923 179.88 71.6092 179.879 58.2779C179.879 50.9031 185.54 45.0957 195.165 48.5521C195.165 48.5521 181.859 7.17326 207.337 0.443134C218.162 -2.41492 231.276 8.91735 218.002 23.9102C208.919 34.1676 207.143 44.6539 212.671 41.99C225.731 35.6973 227.735 51.3203 213.47 71.263C209.325 77.0573 199.745 90.2698 199.745 95.5412V95.5427Z" fill="white"/>
      <path d="M191.558 106.118L192.584 111.346L194.389 120.555H213.072L215.393 108.719L215.902 106.118H191.558Z" fill="#FFE159"/>
      <path d="M191.558 106.118L192.584 111.346L215.393 108.719L215.902 106.118H191.558Z" fill="#FFC359"/>
      <path d="M200.053 104.135H199.43V79.314C199.43 40.3268 204.574 19.909 204.625 19.7076L205.229 19.8626C205.177 20.064 200.053 40.4166 200.053 79.314V104.135Z" fill="#0958BF"/>
      <path d="M200.053 75.2658H199.43C199.43 64.8983 203.5 56.918 211.868 50.8673L212.232 51.3729C204.04 57.2976 200.053 65.1141 200.053 75.2658Z" fill="#0958BF"/>
      <path d="M199.462 105.804H217.578V106.429H199.462V105.804Z" fill="#0958BF"/>
      <path d="M196.052 105.804H197.656V106.429H196.052V105.804Z" fill="#0958BF"/>
      <path d="M189.883 105.804H194.492V106.429H189.883V105.804Z" fill="#0958BF"/>
      <path d="M140.533 94.7306L142.142 94.6405C142.286 94.6324 142.427 94.596 142.556 94.5333C142.686 94.4706 142.802 94.3829 142.898 94.2752C142.994 94.1676 143.068 94.042 143.115 93.9058C143.163 93.7695 143.183 93.6253 143.175 93.4812L143.03 90.9496C143.013 90.6591 142.883 90.3869 142.666 90.193C142.449 89.9991 142.165 89.8993 141.875 89.9154L140.371 90.0011L140.138 90.0142C137.005 90.1952 133.897 89.3472 131.288 87.599C128.496 85.7259 125.247 84.6503 121.89 84.4877L121.836 95.2026C120.301 94.7042 118.952 93.7528 117.966 92.4731C116.98 91.1935 116.403 89.6453 116.311 88.0316C116.18 85.7288 116.537 83.4243 117.358 81.2694C118.18 79.1146 119.447 77.1581 121.077 75.5286C122.706 73.899 124.662 72.6333 126.815 71.8145C128.968 70.9957 131.269 70.6424 133.568 70.7777C135.867 70.913 138.111 71.5339 140.153 72.5997C142.196 73.6654 143.99 75.1519 145.418 76.9614C146.846 78.771 147.875 80.8626 148.439 83.099C149.003 85.3354 149.087 87.6659 148.688 89.9374C150.201 90.9335 151.297 92.4506 151.768 94.2015C152.24 95.9524 152.054 97.8156 151.246 99.4386C150.438 101.062 149.065 102.332 147.385 103.008C145.705 103.685 143.836 103.722 142.131 103.111L140.533 94.7306Z" fill="#145FC0"/>
      <path d="M121.834 95.1938L121.889 84.4789C125.245 84.6408 128.494 85.7157 131.287 87.588C133.663 89.1849 136.46 90.0372 139.321 90.0361C139.593 90.0361 139.867 90.0361 140.139 90.012L140.371 89.9988L141.875 89.9132C142.165 89.897 142.45 89.9968 142.666 90.1908C142.883 90.3847 143.014 90.6568 143.03 90.9473L143.175 93.479C143.183 93.623 143.163 93.7673 143.116 93.9035C143.068 94.0398 142.994 94.1653 142.899 94.273C142.803 94.3807 142.687 94.4683 142.557 94.531C142.427 94.5937 142.286 94.6301 142.142 94.6383L140.533 94.7283L142.134 103.107L143.01 107.676L143.111 108.208C143.344 109.553 143.04 110.935 142.264 112.058C141.489 113.18 140.304 113.953 138.965 114.209C137.627 114.465 136.241 114.185 135.107 113.428C133.973 112.671 133.181 111.498 132.902 110.162L132.427 107.676L131.815 104.468L129.923 104.576C128.88 104.635 127.837 104.48 126.856 104.12C125.875 103.76 124.979 103.203 124.221 102.483C123.463 101.763 122.86 100.896 122.449 99.9344C122.038 98.9727 121.828 97.9371 121.832 96.891L121.834 95.1938Z" fill="#FFAFAF"/>
      <path d="M130.188 98.1732C130.37 99.3677 129.132 100.014 128.813 100.15C128.286 100.372 127.369 100.41 126.79 100.146C126.211 99.8825 125.619 99.4614 125.277 98.9241L130.188 98.1732Z" fill="white"/>
      <path d="M122.958 129.588C123.271 130.57 123.838 131.452 124.602 132.143C125.365 132.834 126.298 133.31 127.305 133.523C127.581 133.581 127.86 133.619 128.14 133.637C128.263 133.637 128.388 133.648 128.511 133.648C128.825 133.649 129.139 133.624 129.449 133.574L129.298 132.638C128.936 132.697 128.568 132.715 128.202 132.693C126.911 132.611 125.705 132.019 124.85 131.047C123.995 130.075 123.56 128.802 123.642 127.509C123.73 126.888 123.876 126.276 124.081 125.682L126.904 119.436L126.045 119.045L123.195 125.335C123.16 125.432 122.732 126.701 122.684 127.45C122.643 128.174 122.736 128.899 122.958 129.588Z" fill="white"/>
      <path d="M134.329 131.67C134.833 132.246 135.447 132.716 136.134 133.054C136.821 133.391 137.568 133.588 138.332 133.635C138.455 133.635 138.577 133.646 138.702 133.646C139.017 133.646 139.33 133.621 139.641 133.571L139.487 132.636C139.125 132.695 138.757 132.713 138.391 132.691C137.751 132.652 137.125 132.486 136.549 132.204C135.974 131.921 135.46 131.527 135.037 131.044L134.329 131.67Z" fill="white"/>
      <path d="M123.922 110.321C124.489 109.508 125.243 108.843 126.121 108.385C126.999 107.926 127.974 107.686 128.964 107.686H132.419L132.895 110.171C133.174 111.507 133.966 112.68 135.1 113.437C136.234 114.194 137.619 114.475 138.958 114.219C140.296 113.962 141.481 113.19 142.257 112.067C143.032 110.945 143.336 109.562 143.104 108.217L143.003 107.686H148.187C149.221 107.686 150.238 107.948 151.143 108.447C152.049 108.946 152.814 109.666 153.368 110.54L158.342 118.383L169.465 135.924L162.215 141.119L155.345 132.569L155.713 132.273L150.426 125.686L149.689 126.279L153.729 131.309L152.942 147.481H133.079C133.009 147.624 130.665 147.338 130.597 147.481L127.311 133.535C127.586 133.593 127.866 133.632 128.146 133.65C128.269 133.65 128.394 133.661 128.517 133.661C128.831 133.661 129.145 133.636 129.455 133.586L129.304 132.651C128.942 132.709 128.574 132.728 128.208 132.705C126.917 132.624 125.711 132.032 124.856 131.059C124.001 130.087 123.566 128.815 123.648 127.522C123.735 126.9 123.882 126.288 124.086 125.695L126.91 119.448L126.051 119.057L123.201 125.348C123.166 125.444 122.738 126.714 122.69 127.462C122.644 128.185 122.733 128.91 122.951 129.601L116.056 137.196L115.361 136.642L109.09 131.636L123.922 110.321ZM148.593 124.904L149.33 124.311L148.558 123.352L147.821 123.945L148.593 124.904ZM138.333 133.639C138.456 133.639 138.579 133.65 138.704 133.65C139.018 133.65 139.332 133.625 139.642 133.575L139.489 132.64C139.127 132.699 138.759 132.717 138.393 132.695C137.753 132.656 137.127 132.49 136.551 132.207C135.975 131.925 135.461 131.531 135.039 131.048L134.331 131.674C134.835 132.25 135.449 132.72 136.136 133.057C136.823 133.395 137.57 133.592 138.333 133.639Z" fill="#FFC359"/>
      <path d="M147.82 123.94L148.592 124.899L149.328 124.307L148.556 123.347L147.82 123.94Z" fill="white"/>
      <path d="M149.691 126.271L153.731 131.302L154.978 132.854L155.347 132.558L155.715 132.261L150.427 125.679L149.691 126.271Z" fill="white"/>
      <path d="M6.67981 142.802H246.918V147.534H6.67981V142.802Z" fill="#84B7FB"/>
      <path d="M6.68106 147.054H69.9609V148.001H6.68106V147.054Z" fill="#145FC0"/>
      <path d="M100.6 147.054H205.418V148.001H100.6V147.054Z" fill="#145FC0"/>
      <path d="M219.746 147.054H246.918V148.001H219.746V147.054Z" fill="#145FC0"/>
      <path d="M210.605 147.054H214.965V148.001H210.605V147.054Z" fill="#145FC0"/>
      <path d="M257.224 122.47H234.648V120.292H257.224V122.47Z" fill="white"/>
      <path d="M257.224 131.544H234.648V129.366H257.224V131.544Z" fill="white"/>
      <path d="M236.82 120.287V131.544H234.646V120.287H236.82Z" fill="white"/>
      <path d="M234.645 131.544H191.57V120.287H234.645V131.544Z" fill="#6AACFF"/>
      <path d="M226.575 129.138C224.796 128.849 223.589 127.17 223.878 125.389C224.166 123.608 225.842 122.399 227.62 122.688C229.398 122.977 230.606 124.655 230.317 126.436C230.029 128.217 228.353 129.427 226.575 129.138Z" fill="white"/>
      <path d="M225.57 127.459C224.721 126.609 224.721 125.23 225.57 124.379C226.42 123.528 227.797 123.528 228.646 124.379C229.495 125.23 229.495 126.609 228.646 127.459C227.797 128.31 226.42 128.31 225.57 127.459Z" fill="#145FC0"/>
      <path d="M254.796 129.368H236.82V122.469H254.796V129.368Z" fill="#CDE5FF"/>
      <path d="M216.388 128.685H197.445V123.148H216.388V128.685Z" fill="white"/>
      <path d="M187.312 131.544H209.887V133.722H187.312V131.544Z" fill="#FFC359"/>
      <path d="M187.312 140.622H209.887V142.8H187.312V140.622Z" fill="#FFC359"/>
      <path d="M209.887 131.549V142.806H207.712V131.549H209.887Z" fill="#FFC359"/>
      <path d="M209.883 131.544H252.957V142.802H209.883V131.544Z" fill="#FFD259"/>
      <path d="M214.194 137.711C213.905 135.93 215.113 134.252 216.891 133.963C218.669 133.673 220.345 134.883 220.634 136.664C220.922 138.445 219.715 140.123 217.936 140.413C216.158 140.702 214.483 139.492 214.194 137.711Z" fill="white"/>
      <path d="M215.31 137.664C215.035 136.493 215.758 135.32 216.927 135.044C218.096 134.768 219.268 135.493 219.543 136.664C219.819 137.834 219.095 139.007 217.926 139.284C216.758 139.56 215.586 138.835 215.31 137.664Z" fill="#145FC0"/>
      <path d="M189.731 133.725H207.707V140.623H189.731V133.725Z" fill="white"/>
      <path d="M228.139 134.407H247.082V139.945H228.139V134.407Z" fill="white"/>
      <path d="M191.572 119.821H248.113V120.767H191.572V119.821Z" fill="#0958BF"/>
      <path d="M187.31 142.333H218.281V143.279H187.31V142.333Z" fill="#0958BF"/>
      <path d="M221.871 142.333H224.453V143.279H221.871V142.333Z" fill="#0958BF"/>
      <path d="M187.309 133.725H201.957V134.671H187.309V133.725Z" fill="#145FC0"/>
      <path d="M114.691 139.963H140.535V142.802H114.691V139.963Z" fill="#145FC0"/>
      <path d="M67.0808 110.39L72.2434 135.489L72.2807 135.673L73.7473 142.805H119.102L112.435 110.39H67.0808Z" fill="#73B1FF"/>
      <path d="M91.0937 128.809C89.5908 127.451 89.2673 125.356 90.3712 124.131C91.475 122.905 93.5882 123.013 95.0912 124.371C96.5941 125.73 96.9176 127.824 95.8138 129.049C94.7099 130.275 92.5967 130.167 91.0937 128.809Z" fill="white"/>
      <path d="M72.2434 135.49L72.2807 135.674L73.7473 142.806H119.102L72.2434 135.49Z" fill="#67AAFF"/>
      <path d="M112.738 59.3392C124.811 59.3392 134.598 49.2028 134.598 36.699C134.598 24.1952 124.811 14.0588 112.738 14.0588C100.666 14.0588 90.8789 24.1952 90.8789 36.699C90.8789 49.2028 100.666 59.3392 112.738 59.3392Z" fill="#FF95A9"/>
      <path d="M116.643 57.7757C112.165 57.7762 107.787 56.4487 104.064 53.9612C100.34 51.4736 97.4377 47.9377 95.7239 43.8007C94.01 39.6636 93.5614 35.1113 94.4349 30.7193C95.3084 26.3273 97.4647 22.2929 100.631 19.1265C103.798 15.9601 107.832 13.8038 112.224 12.9303C116.616 12.0568 121.168 12.5053 125.305 14.2192C129.442 15.9331 132.978 18.8353 135.466 22.5589C137.953 26.2824 139.281 30.66 139.28 35.138C139.273 41.1397 136.886 46.8935 132.642 51.1374C128.398 55.3812 122.644 57.7686 116.643 57.7757ZM116.643 13.5554C112.372 13.5554 108.197 14.8217 104.647 17.1943C101.096 19.5669 98.3281 22.9392 96.6938 26.8847C95.0595 30.8302 94.6319 35.1718 95.4651 39.3603C96.2982 43.5488 98.3547 47.3962 101.374 50.416C104.394 53.4357 108.242 55.4922 112.43 56.3254C116.619 57.1585 120.96 56.7309 124.906 55.0966C128.851 53.4624 132.223 50.6948 134.596 47.1439C136.969 43.5931 138.235 39.4184 138.235 35.1478C138.229 29.4236 135.952 23.9356 131.904 19.8887C127.856 15.8418 122.367 13.5666 116.643 13.5627V13.5554Z" fill="#145FC0"/>
      <path d="M117.2 42.5717L111.84 41.0643L115.761 23.835L122.77 25.8075L117.2 42.5717Z" fill="white"/>
      <path d="M116.887 45.4536L111.5 43.9421L109.991 49.3374L115.378 50.8489L116.887 45.4536Z" fill="white"/>
      <path d="M75.0109 100.758L75.8834 100.394L71.1746 89.0884L76.0391 86.9652L66.1194 58.9637L65.2294 59.2821L74.8553 86.4492L69.9448 88.5944L75.0109 100.758Z" fill="#6AACFF"/>
      <path d="M77.5311 104.322L78.2852 106.134L77.4131 106.498L76.659 104.686L77.5311 104.322Z" fill="#6AACFF"/>
      <path d="M87.2166 104.57L89.3846 81.1646L96.8776 80.7935L96.9258 59.1134L95.981 59.1112L95.9349 79.8933L88.5187 80.2621L86.2761 104.483L87.2166 104.57Z" fill="#6AACFF"/>
      <path d="M110.051 78.1985L110.777 78.8032L107.173 83.1487L106.446 82.5439L110.051 78.1985Z" fill="#6AACFF"/>
      <path d="M93.4021 106.706L105.788 92.8933L101.145 90.421L105.852 84.7474L105.124 84.1414L99.6762 90.7108L104.278 93.1612L92.6984 106.076L93.4021 106.706Z" fill="#6AACFF"/>
      <path d="M70.8477 131.059L71.7728 130.868L67.658 110.859H112.434V109.913H66.4984L70.8477 131.059Z" fill="#6AACFF"/>
      <path d="M72.248 133.057L73.6719 139.986L72.7464 140.177L71.3225 133.248L72.248 133.057Z" fill="#6AACFF"/>
      <path d="M9.15843 102.738H64.3359L62.3016 101.833C60.85 101.186 59.6166 100.132 58.7506 98.7977C57.8846 97.4635 57.423 95.9066 57.4217 94.3152V74.7496C57.4188 72.3181 56.4532 69.987 54.7366 68.2677C53.02 66.5484 50.6926 65.5812 48.265 65.5782H9.15843C6.73079 65.5812 4.4034 66.5484 2.6868 68.2677C0.970196 69.987 0.00453186 72.3181 0.00163269 74.7496V93.5665C0.00453186 95.998 0.970196 98.3291 2.6868 100.048C4.4034 101.768 6.73079 102.735 9.15843 102.738ZM60.3308 101.792H9.15843C6.98119 101.789 4.89379 100.922 3.35425 99.3799C1.81472 97.8379 0.948772 95.7472 0.946453 93.5665V74.7496C0.949932 72.5697 1.81639 70.4801 3.3558 68.939C4.89522 67.398 6.98195 66.5313 9.15843 66.529H48.265C50.4422 66.5313 52.5296 67.3986 54.0691 68.9406C55.6087 70.4826 56.4746 72.5733 56.4769 74.754V94.3196C56.4786 95.7825 56.8292 97.2238 57.4996 98.5235C58.1699 99.8232 59.1406 100.944 60.3308 101.792Z" fill="#145FC0"/>
      <path d="M8.58558 75.661H49.2617V76.6073H8.58558V75.661Z" fill="#145FC0"/>
      <path d="M34.414 79.8973H49.2617V80.8437H34.414V79.8973Z" fill="#145FC0"/>
      <path d="M8.58558 84.3665H49.2617V85.3128H8.58558V84.3665Z" fill="#145FC0"/>
      <path d="M34.414 88.8395H49.2617V89.7858H34.414V88.8395Z" fill="#145FC0"/>
      <path d="M9.15628 102.738H62.1065C62.2151 102.739 62.3208 102.702 62.4055 102.634C62.4903 102.566 62.5489 102.471 62.5716 102.365C62.5943 102.258 62.5796 102.147 62.53 102.05C62.4804 101.954 62.399 101.877 62.2994 101.833C60.8478 101.186 59.6144 100.132 58.7484 98.7978C57.8824 97.4636 57.4209 95.9067 57.4196 94.3154V74.7497C57.4167 72.3182 56.4511 69.9871 54.7345 68.2678C53.0179 66.5485 50.6905 65.5813 48.2628 65.5784H9.15628C6.72864 65.5813 4.40126 66.5485 2.68465 68.2678C0.968052 69.9871 0.002388 72.3182 -0.000514984 74.7497V93.5666C0.002388 95.9981 0.968052 98.3292 2.68465 100.049C4.40126 101.768 6.72864 102.735 9.15628 102.738ZM60.3287 101.792H9.15628C6.97905 101.789 4.89165 100.922 3.35211 99.38C1.81257 97.838 0.946629 95.7473 0.944309 93.5666V74.7497C0.947788 72.5698 1.81425 70.4802 3.35366 68.9392C4.89307 67.3981 6.97981 66.5314 9.15628 66.5291H48.2628C50.4401 66.5314 52.5274 67.3987 54.067 68.9407C55.6065 70.4827 56.4725 72.5734 56.4748 74.7541V94.3198C56.4765 95.7826 56.8271 97.2239 57.4975 98.5236C58.1678 99.8233 59.1385 100.944 60.3287 101.792Z" fill="#145FC0"/>
      <path d="M15.4765 79.8973H30.3242V80.8437H15.4765V79.8973Z" fill="#145FC0"/>
      <path d="M113.693 114.068L118.656 138.221L117.731 138.412L112.768 114.258L113.693 114.068Z" fill="white"/>
      <path d="M108.81 98.8892L109.391 99.6359L101.507 105.785L100.927 105.038L108.81 98.8892Z" fill="#6AACFF"/>
      <path d="M161.248 138.67C161.048 136.209 162.877 134.052 165.334 133.851C167.791 133.65 169.945 135.483 170.145 137.943C170.345 140.404 168.516 142.562 166.059 142.762C163.602 142.963 161.448 141.131 161.248 138.67Z" fill="#FFC359"/>
      <path d="M165.123 133.876L165.695 142.777L138.694 142.79L137.995 137.415L165.123 133.876Z" fill="#FFC359"/>
      <path d="M124.399 138.369C124.686 137.538 125.291 136.857 126.081 136.473C126.87 136.09 127.779 136.036 128.608 136.324L133.431 138.004L137.988 137.409L138.688 142.784H133.183L127.514 142.764C127.149 142.764 126.787 142.704 126.442 142.584C126.031 142.442 125.653 142.22 125.328 141.931C125.003 141.642 124.738 141.292 124.549 140.9C124.359 140.509 124.248 140.084 124.222 139.649C124.197 139.215 124.257 138.78 124.399 138.369Z" fill="#FFAFAF"/>
    </svg>
  </div>
}
