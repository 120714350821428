import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { Spin } from 'antd'
import { RootState } from '../../store/rootState'
import NotAvailableTaskCardWrap from './NotAvailableTaskCardWrap'
import SimpleTaskPreviewCard from './SimpleTaskPreviewCard'
import { useHistory } from 'react-router-dom'
import SimpleTaskDetailsCard from './SimpleTaskDetailsCard'
import DownloadElseCard from '../common_kit/DownloadElseCard'
import { clearTasks, setMoreSimpleTasks, updateTasks } from '../../store/taskList/actions'


type Data = {
  loading: boolean,
  signal: AbortSignal
}

export default function SimpleTasksList({ loading, signal }: Data) {

  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const tasks = useSelector((state: RootState) => state.taskList.simpleTasks.data)
  const metaData = useSelector((state: RootState) => state.taskList.simpleTasks.meta?.page)
  const responseId = useSelector((state: RootState) => state.taskList.responseId.simpleTasks)
  const selectedTask = useSelector((state: RootState) => state.taskList.selectedSimpleTask)
  const googleTask = useSelector((state: RootState) => state.taskList.selectedSimpleTask?.settings?.google_account)
  const size = useSelector((state: RootState) => state.mq.size)

  const [hasActiveTasks, setHasActiveTasks] = useState<boolean>(false)
  const [allTasksLoaded, setAllTasksLoaded] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingAddTask, setIsLoadingAddTask] = useState<boolean>(false)

  useEffect(() => {
    history.push({ search: 'type=other' })

    return () => {
      dispatch(updateTasks('simpleTasks', true))
    }
  }, [])

  useEffect(() => {
    if (loading) return
    setHasActiveTasks(!!tasks.length)
  }, [loading, tasks])

  useEffect(() => {
    if (!metaData || !metaData.current_page || !metaData.last_page) {
      setAllTasksLoaded(true)
      return
    }
    setAllTasksLoaded(metaData.current_page === metaData.last_page)
  }, [metaData])

  useEffect(() => {
    if (!responseId) return
    const asyncEffect = async () => {
      await dispatch(setMoreSimpleTasks(signal))
    }
    setIsLoading(true)
    dispatch(clearTasks('simpleTasks'))
    asyncEffect()
      .finally(() => {setIsLoading(false)})
  }, [responseId])

  const onMoreLoadClickHandler = async () => {
    setIsLoadingAddTask(true)
    await dispatch(setMoreSimpleTasks(signal))
    setIsLoadingAddTask(false)
  }

  const getContent = () => {
    if (selectedTask && !googleTask) return (
      <SimpleTaskDetailsCard/>
    )

    if (loading || isLoading) return (
      <div className="tasks-loading-dummy">
        {t('Loading the task list')}
      </div>
    )

    return (
      <div className="task-cards-pane">
        {/*TODO: Пересмотреть условие для отображения карточки "Нет новых задач", т.к. теперь в списке есть пагинация*/}
        {!hasActiveTasks && (
          <NotAvailableTaskCardWrap/>
        )}
        {!!tasks.length && tasks.map(task => (
          <SimpleTaskPreviewCard key={task.id} task={task}/>
        ))}
        {!allTasksLoaded && (
          <DownloadElseCard
            className='task-card'
            onMoreLoadClickHandler={onMoreLoadClickHandler}
            isLoading={isLoadingAddTask}/>
        )}
      </div>
    )
  }

  const getKey = () => {
    if (selectedTask) return 1
    if (loading || isLoading) return 2
    if (!tasks.length) return 3
    return 0
  }

  const getClass = () => {
    const list = ['task-list']
    if (size === 'm') list.push('custom-scroll')
    return list.join(' ')
  }

  return (
    <Spin spinning={(loading || isLoading) && !selectedTask}>
      <div className={getClass()}>
        <SwitchTransition>
          <CSSTransition
            key={getKey()}
            timeout={200}
            classNames="fade">
            {getContent()}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </Spin>
  )
}
