import {FC} from 'react'
import VKLogo from '../../components/signupPage/social/vk'
import FacebookLogo from '../../components/signupPage/social/facebook'
import InstagramLogo from '../../components/signupPage/social/instagram'
import InstagramBack from '../../components/signupPage/social/instagram-back'
import TelegramLogo from '../../components/signupPage/social/telegram'
import LinkedInLogo from '../../components/signupPage/social/linkedin'

export const SOCIAL_NETWORKS:Array<SocialAccount> = [
  {
    name: 'vk',
    component: VKLogo
  },
  {
    name: 'facebook',
    component: FacebookLogo
  },
  {
    name: 'instagram',
    component: InstagramLogo,
    backgroundComponent: InstagramBack
  },
  {
    name: 'telegram',
    component: TelegramLogo
  },
  {
    name: 'linkedin',
    component: LinkedInLogo
  }
]

export interface SocialAccount {
  name: string,
  component: FC,
  backgroundComponent?: FC
}

export interface PhoneState {
  model: PhoneModel,
  phoneVerified: boolean
}

interface PhoneModel {
  phone: string,
  codeCountry: string
}

const model = {
  phone: '',
  codeCountry: ''
}

const initialStore: PhoneState = {
  model,
  phoneVerified: false,
}

export default initialStore
