import { SET_HEIGHT, SET_ORIENTATION, SET_SIZE } from './actionTypes'

export const setScreenSize = size => ({
  type: SET_SIZE,
  size
})

export const setScreenHeight = height => ({
  type: SET_HEIGHT,
  height
})

export const setScreenOrientation = orientation => ({
  type: SET_ORIENTATION,
  orientation
})
