import React, { useCallback, useEffect, useState } from 'react'
import '../../style/sass/signupPage/RegistrationData.scss'
import Paragraph, { TextSize, TextStyle } from '../common_kit/text/Paragraph'
import HintList from '../text/HintList'
import Tooltip, { CardStatusColor } from '../common_kit/Tooltip'
import SubmitButton from '../inputs/SubmitButton'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'
import {
  checkValidationSteps,
  getFPVisitorId,
  getPasswordValidationHints,
  isEmail,
  isUrl,
  PasswordValidationItem
} from '../../utils/helpers'
import { checkPromoCode, uploadDataForRegister } from '../../store/auth/api'
import { del } from 'idb-keyval'
import { useForm } from 'react-hook-form'
import IconPassword from '../icons/IconPassword'
import IconClear from '../icons/IconClear'
import Notification from '../notification/Notification'
import { SOCIAL_NETWORKS, SocialAccount } from '../../store/phone/initialState'
import { FAILED_FINGERPRINT_ID, FINGERPRINT_EXTRA_TIME } from '../../utils/constants'
import { Spin } from 'antd'
import { CheckOutlined, CloseOutlined, SyncOutlined, WarningFilled } from '@ant-design/icons'

type ValidationError = {
  name: string,
  errorMessage: string,
}

type RegistrationResult = {
  success: boolean,
  validationErrors?: Array<ValidationError>,
}

export default function SignupForm() {
  const { t } = useTranslation()
  const {
    register,
    resetField,
    watch,
    handleSubmit,
    setValue,
    setError,
    setFocus,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onTouched'
  })

  const promoLength = {
    min: 6,
    max: 11
  }
  const loadIcon = <SyncOutlined spin/>

  const locale = useSelector((state: RootState) => state.locale.locale.name)

  const [hiddenPassword, setHiddenPassword] = useState<boolean>(true)
  const [hiddenConfirm, setHiddenConfirm] = useState<boolean>(true)
  const [passwordValidation, setPasswordValidation] = useState<Array<PasswordValidationItem>>(getPasswordValidationHints())
  const [showPasswordHints, setShowPasswordHints] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [backendValidationErrors, setBackendValidationErrors] = useState<Array<ValidationError>>([])
  const [activeSocial, setActiveSocial] = useState<SocialAccount>()
  const [visitorId, setVisitorId] = useState<string>()
  const [waitingForFP, setWaitingForFP] = useState<boolean>(false)
  const [fpTimer, setFpTimer] = useState<NodeJS.Timeout>()
  const [promoCodeTimer, setPromoCodeTimer] = useState<NodeJS.Timeout>()
  const [checkPromoResult, setCheckPromoResult] = useState<string | undefined>('')
  const [checkPromoStatus, setCheckPromoStatus] = useState<string | undefined>('')
  const [checkPromoIsAvailable, setCheckPromoIsAvailable] = useState<boolean>(false)

  const name = watch('name')
  const email = watch('email')
  const social = watch('social')
  const password = watch('password')
  const confirm = watch('confirm')
  const promo = watch('promo')

  useEffect(() => {
    getFPVisitorId()
      .then(id => { setVisitorId(id) })
  }, [])

  useEffect(() => {
    if (password === undefined) return
    setPasswordValidation(checkValidationSteps(password))
  }, [password])

  useEffect(() => {
    resetField('source')
    setPasswordValidation(getPasswordValidationHints())
  }, [locale])

  useEffect(() => {
    for (const error of backendValidationErrors) {
      if (Object.keys(getValues()).includes(error.name)) {
        setError(error.name, {
          type: 'backend',
          message: error.errorMessage
        })
      } else {
        Notification({ noticeType: 'error', text: error.errorMessage })
      }
    }
  }, [backendValidationErrors])

  useEffect(() => {
    if (!waitingForFP) return
    fpTimer && clearTimeout(fpTimer)
    signup(visitorId)
  }, [visitorId])

  useEffect(() => {
    const value = !!promo && promo.length >= promoLength.min && promo.length <= promoLength.max
    setCheckPromoIsAvailable(value)
  }, [promo])

  useEffect(() => {
    const controller = new AbortController()
    if (checkPromoIsAvailable) {
      setCheckPromoResult('loading')
      if (promoCodeTimer) clearTimeout(promoCodeTimer)
      const timer = setTimeout(async () => {
        let result = await checkPromoCode(promo, controller.signal)
        setCheckPromoResult(result || 'loading')
      }, 700)
      setPromoCodeTimer(timer)
    } else {
      setCheckPromoResult('')
    }
    return () => { controller.abort() }
  }, [promo, checkPromoIsAvailable])

  useEffect(() => {
    let value
    if (checkPromoIsAvailable) {
      value = checkPromoResult
    } else {
      value = ''
    }
    setCheckPromoStatus(value)
  }, [checkPromoResult, checkPromoIsAvailable])

  const signup = async fingerprintId => {
    setIsSubmitting(true)
    const result: RegistrationResult | undefined = await uploadDataForRegister({
      ...getValues(),
      fingerprintId
    })
    if (result?.success) {
      del('verified_phone')
    } else {
      setIsSubmitting(false)
      if (!!result?.validationErrors?.length) {
        setBackendValidationErrors(result.validationErrors)
      }
    }
  }

  const handleClickSubmitBtn = () => {
    if (checkPromoStatus && checkPromoStatus !== 'valid') return
    if (!visitorId) {
      setWaitingForFP(true)
      const timer: NodeJS.Timeout = setTimeout(async () => {
        setWaitingForFP(false)
        signup(FAILED_FINGERPRINT_ID)
      }, FINGERPRINT_EXTRA_TIME)
      setFpTimer(timer)
      return
    }
    signup(visitorId)
  }

  const handlePasswordIconClick = useCallback(() => {
    setHiddenPassword(prev => !prev)
  }, [])

  const handleConfirmIconClick = useCallback(() => {
    setHiddenConfirm(prev => !prev)
  }, [])

  //TODO: Вынести вспомогательные функции для форматирования в отдельный файл
  const transformPromoCode = (value: string): string => {
    // Позволяет вводить решетку вначале (но решетка - не обязательный символ),
    // а после нее - только цифры и латинские буквы

    const transformedValue = value.replace(/[^a-zA-Z\d]/g, '').toUpperCase()
    return value[0] === '#' ? '#' + transformedValue : transformedValue
  }

  const transformName = (value: string): string => {
    // Позволяет ставить пробел, но не в начале и не больше одного подряд

    const trimmedValue = value.trimStart()
    return trimmedValue.replace(/\s+/g, ' ')
  }

  const trimValue = (value: string): string => {
    // Не позволяет ставить пробел нигде

    return value.replace(/\s+/g, '')
  }

  const transformUrl = (value: string): string => {
    // Позволяет указывать ссылку и с протоколом, и без него

    return (!value || value.startsWith('http')) ? value : `https://${value}`
  }

  const validatePassword = (value: string): boolean => {
    return [
      value.length >= 8,
      value.match(/[A-Za-z]+/),
      value.match(/\d+/)
    ].every(item => item)
  }

  const validateConfirm = (value: string): boolean => {
    return value === password
  }

  const socialButtons = SOCIAL_NETWORKS.map((social) =>
    <button
      key={social.name}
      type="button"
      className={`social-${social.name}
      ${activeSocial?.name === social.name ? 'active' : ''}
      ${(errors.social?.type === 'required' && !activeSocial) ? 'invalid' : ''}
      ${social.backgroundComponent ? 'social-background' : ''}`}
      onClick={() => { setActiveSocial(social) }}>
      {social.component({})}
      {social.backgroundComponent && social.backgroundComponent({})}
    </button>
  )

  return (
    <div className="signup-form">

      {/*Имя*/}
      <div className="signup-input-block">
        <div className="labeled-input">
          <label htmlFor="signup-name-input">{t('Name') + ' *'}</label>
          <input
            id="signup-name-input"
            type="text"
            autoComplete="off"
            className={errors.name ? 'invalid' : ''}
            {...register('name', {
              required: true,
              setValueAs: transformName,
              onChange: event => {
                event.preventDefault()
                event.target.value = transformName(event.target.value)
              },
              onBlur: () => {
                setValue('name', name.trim())
              }
            })}
          />
          {name && (
            <div className="form-control__icon">
              <IconClear
                width={10}
                height={10}
                fill={'#FF4848'}
                onClick={() => {
                  setValue('name', '')
                  setError('name', { type: 'required' })
                  setFocus('name')
                }}
              />
            </div>
          )}
          {errors.name && errors.name.type !== 'backend' && (
            <div className="error-message">{t('Validator name error')}</div>
          )}
          {errors.name?.type === 'backend' && (
            <div className="error-message">{errors.name?.message}</div>
          )}
        </div>
      </div>

      {/*Email*/}
      <div className="signup-input-block">
        <div className="labeled-input">
          <label htmlFor="signup-email-input">{t('Your email') + ' *'}</label>
          <input
            id="signup-email-input"
            type="text"
            autoComplete="off"
            className={errors.email ? 'invalid' : ''}
            {...register('email', {
              required: true,
              validate: {
                email: isEmail
              },
              setValueAs: trimValue,
              onChange: event => {
                event.preventDefault()
                event.target.value = trimValue(event.target.value)
              }
            })}
          />
          {email && (
            <div className="form-control__icon">
              <IconClear
                width={10}
                height={10}
                fill={'#FF4848'}
                onClick={() => {
                  setValue('email', '')
                  setError('email', { type: 'required' })
                  setFocus('email')
                }}
              />
            </div>
          )}
          {errors.email?.type === 'required' && (
            <div className="error-message">{t('Validator email error 1')}</div>
          )}
          {errors.email?.type === 'email' && (
            <div className="error-message">{t('Validator email error 2')}</div>
          )}
          {errors.email?.type === 'backend' && (
            <div className="error-message">{t(errors.email?.message)}</div>
          )}
        </div>
      </div>

      {/*Ссылка на соц. сеть*/}
      <div className="signup-input-block">
        <div>
          <div className="social-block">
            <span>{t('Social link') + ' *'}</span>
            {socialButtons}
          </div>
          <div className="labeled-input">
            <div className="social-placeholder">
              {activeSocial && activeSocial.component({})}
            </div>
            <input
              id="signup-social-input"
              type="text"
              autoComplete="off"
              className={`${errors.social ? 'invalid' : ''} ${activeSocial ? '' : 'hidden'}`}
              placeholder={t('Social link placeholder')}
              {...register('social', {
                required: true,
                validate: {
                  url: isUrl
                },
                setValueAs: trimValue,
                onChange: event => {
                  event.preventDefault()
                  event.target.value = trimValue(event.target.value)
                },
                onBlur: () => {
                  setValue('social', transformUrl(social))
                }
              })}
            />
            {activeSocial && (
              <div className="form-control__icon">
                <IconClear
                  width={10}
                  height={10}
                  fill={'#FF4848'}
                  onClick={() => {
                    setValue('social', '')
                    setError('social', { type: 'required' })
                    setFocus('social')
                  }}
                />
              </div>
            )}
            {errors.social?.type === 'required' && !activeSocial && (
              <div className="error-message">{t('Validator social account error')}</div>
            )}
            {errors.social?.type === 'required' && activeSocial && (
              <div className="error-message">{t('Validator social error 1')}</div>
            )}
            {errors.social?.type === 'url' && (
              <div className="error-message">{t('Invalid link format')}</div>
            )}
          </div>
        </div>
        <Paragraph
          className="signup-input-hint"
          text={t('Social link description')}
          textStyle={TextStyle.ITALIC}
          size={TextSize.P_12}
        />
      </div>

      {/*Пароль и подтверждение пароля*/}
      <div className="signup-input-block">
        <div className="password-inputs">
          <div className="labeled-input">
            <label htmlFor="signup-password-input">{t('Create password') + ' *'}</label>
            <input
              id="signup-password-input"
              type={hiddenPassword ? 'password' : 'text'}
              className={errors.password ? 'invalid' : ''}
              onFocus={() => setShowPasswordHints(true)}
              {...register('password', {
                required: true,
                validate: {
                  format: validatePassword
                },
                setValueAs: trimValue,
                onChange: event => {
                  event.preventDefault()
                  event.target.value = trimValue(event.target.value)
                },
                onBlur: () => {
                  setShowPasswordHints(false)
                }
              })}
            />
            {password && (
              <IconPassword
                closed={hiddenPassword}
                onClick={handlePasswordIconClick}
              />
            )}
            {errors.password?.type === 'required' && (
              <div className="error-message">{t('Validator password error 1')}</div>
            )}
            {errors.password?.type === 'format' && (
              <div className="error-message">{t('Validator password error 2')}</div>
            )}
          </div>
          <div className="labeled-input">
            <label htmlFor="signup-confirm-input">{t('Confirm password') + ' *'}</label>
            <input
              id="signup-confirm-input"
              type={hiddenConfirm ? 'password' : 'text'}
              className={errors.confirm ? 'invalid' : ''}
              {...register('confirm', {
                required: true,
                validate: {
                  equal: validateConfirm
                },
                setValueAs: trimValue,
                onChange: event => {
                  event.preventDefault()
                  event.target.value = trimValue(event.target.value)
                }
              })}
            />
            {errors.confirm?.type === 'required' && (
              <div className="error-message">{t('Validator password_confirmation error 1')}</div>
            )}
            {errors.confirm?.type === 'equal' && (
              <div className="error-message">{t('Validator password_confirmation error 2')}</div>
            )}
            {confirm && (
              <IconPassword
                closed={hiddenConfirm}
                onClick={handleConfirmIconClick}
              />
            )}
          </div>
        </div>
        <HintList
          className={showPasswordHints || errors.password?.type === 'format' ? 'shown' : ''}
          label={t('Password should contain')}
          items={passwordValidation}
        />
      </div>

      {/*Подсказка*/}
      <div className="signup-prompt">
        <Paragraph text="*" size={TextSize.P_18}/>
        <Paragraph text={`${t('Required to fill')}`} textStyle={TextStyle.ITALIC}/>
      </div>

      {/*Промокод и кнопка*/}
      <div className="signup-input-block">
        <div className="labeled-input">
          <label htmlFor="signup-promo-input">{t('Referral code 2')}</label>
          <input
            id="signup-promo-input"
            type="text"
            autoComplete="off"
            className={errors.promo || ['error', 'invalid'].includes(checkPromoStatus as string) ? 'invalid' : ''}
            {...register('promo', {
              minLength: promoLength.min,
              maxLength: promoLength.max,
              setValueAs: transformPromoCode,
              onChange: event => {
                event.preventDefault()
                event.target.value = transformPromoCode(event.target.value)
              }
            })}
          />
          <Spin indicator={loadIcon} spinning={checkPromoStatus === 'loading'}/>
          {checkPromoStatus === 'valid' && <CheckOutlined/>}
          {checkPromoStatus === 'invalid' && <WarningFilled/>}
          {checkPromoStatus === 'error' && <CloseOutlined/>}
          {errors.promo && (
            <div className="error-message promo-error">
              {errors.promo?.type === 'minLength' && t('Min length error', { min: promoLength.min })}
              {errors.promo?.type === 'maxLength' && t('Max length error', { max: promoLength.max })}
            </div>
          )}
          {checkPromoStatus === 'invalid' && (
            <div className="error-message promo-error">
              {t('Invalid promo code')}
            </div>
          )}
          <Tooltip
            svg={CardStatusColor.BLUE}
            color={CardStatusColor.BLUE}
            text={t('Promo code tooltip', { min: promoLength.min, max: promoLength.max })}
          />
        </div>
        <div className="signup-submit-btn">
          {/*
            !!! ВНИМАНИЕ !!!
            Изменять значение id допускается только по согласованию с руководством
          */}
          <SubmitButton
            id="uploader__registration-btn"
            text={isSubmitting || waitingForFP ? t('Loading') : t('Lets sign up')}
            isDisabled={isSubmitting || waitingForFP}
            onClick={handleSubmit(handleClickSubmitBtn)}
          />
          <Paragraph
            text={t('Consent to the processing of personal data')}
            textStyle={TextStyle.ITALIC}
            size={TextSize.P_12}
          />
          {!!Object.keys(errors).length && (
            <Paragraph
              text={t('Form has errors')}
              textStyle={TextStyle.ITALIC}
              size={TextSize.P_12}
              color={'#FF4848'}
            />
          )}
        </div>
      </div>

    </div>
  )
}
