import { SET_DELETED_APPLICATIONS, SET_SELECTED_DELETED_APP, UPDATE_DELETED_APPLICATION } from './actionTypes'
import { downloadDeletedApplications } from './api'

export const setDeletedApplications = () => async dispatch => {
  const deletedApplications = await downloadDeletedApplications()
  if (!deletedApplications) return
  dispatch({
    type: SET_DELETED_APPLICATIONS,
    deletedApplications
  })
}

export const setSelectedDeletedApp = app => ({
  type: SET_SELECTED_DELETED_APP,
  app
})

export const updateDeletedApp = app => ({
  type: UPDATE_DELETED_APPLICATION,
  app
})
