import React from "react";
import {STORY_TABS} from "./WithdrawalStory";
import H3 from "../../common_kit/text/H3";
import {useTranslation} from "react-i18next";


type Data = {
  activeTab: string,
  handlerTabClick: (tabName: string) => void,
}

export default function WithdrawalStoryTabs(props: Data) {

  const {t} = useTranslation()

  return <div className='transactions-history__tabs'>
    {STORY_TABS.map(tab => {
      const isActive: boolean = props.activeTab === tab.name
      const activeClass: string = isActive ? ' active' : ''
      return <div
        key={tab.name}
        onClick={e => props.handlerTabClick(tab.name)}
        className={`transactions-history__tab${activeClass}`}>
        <H3 text={t(tab.title)} color={isActive ? '#2A3E58' : '#859AB6'}/>
      </div>
    })}
  </div>

}
