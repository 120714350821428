import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { Spin } from 'antd'
import { RootState } from '../../store/rootState'
import NotAvailableTaskCardWrap from './NotAvailableTaskCardWrap'
import SimpleTaskPreviewCard from './SimpleTaskPreviewCard'
import { useHistory } from 'react-router-dom'
import {
  PublishedSimpleTask,
  PublisherTaskType,
  TakenSimpleTask,
  TaskListItem
} from '../../store/taskList/initialState'
import TaskPreviewCard from './TaskPreviewCard'
import taskCardWrap, { CardType } from './taskCardWrap'
import DownloadElseCard from '../common_kit/DownloadElseCard'
import { setMoreCompletedTasks } from '../../store/taskList/actions'
import {setDeletedApplications} from "../../store/application/actions";


type Data = {
  loading: boolean
  signal: AbortSignal
}

export default function CompletedTasksList({ loading, signal }: Data) {

  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const tasks = useSelector((state: RootState) => state.taskList.completedTasks.data)
  const metaData = useSelector((state: RootState) => state.taskList.completedTasks.meta?.page)
  const size = useSelector((state: RootState) => state.mq.size)
  const statuses = useSelector((state: RootState) => state.types.statuses)

  const [hasActiveTasks, setHasActiveTasks] = useState<boolean>(true)
  const [allTasksLoaded, setAllTasksLoaded] = useState<boolean>(false)
  const [isLoadingAddTask, setIsLoadingAddTask] = useState<boolean>(false)

  useEffect(() => {
    dispatch(setDeletedApplications())
    history.push({ search: 'type=completed' })
  }, [])

  useEffect(() => {
    setHasActiveTasks(findActiveTasks())
  }, [tasks])

  useEffect(() => {
    if (!metaData || !metaData.current_page || !metaData.last_page) {
      setAllTasksLoaded(true)
      return
    }
    setAllTasksLoaded(metaData.current_page === metaData.last_page)
  }, [metaData])

  const onMoreLoadClickHandler = async () => {
    setIsLoadingAddTask(true)
    await dispatch(setMoreCompletedTasks(signal))
    setIsLoadingAddTask(false)
  }

  const findActiveTasks = () => {
    const finishStatus = ['completed', 'verified']
    const idFinishStatus = statuses.filter(status => finishStatus.includes(status.name))?.map(status => status.id)
    return tasks.some(task => {
      return !finishStatus.includes(task.status.name) || !idFinishStatus.includes(task['status_id'])
    })
  }

  const getContent = () => {
    if (loading) return (
      <div className='tasks-loading-dummy'>
        {t('Loading the task list')}
      </div>
    )

    return (
      <div className='task-cards-pane'>
        {!hasActiveTasks && (
          <NotAvailableTaskCardWrap message={'No completed tasks'}/>
        )}
        {!!tasks.length && tasks.map(task => {
          if ([PublisherTaskType.SIMPLE, PublisherTaskType.USER_SIMPLE].includes(task.type)) {
            return (
              <SimpleTaskPreviewCard key={task.id} task={task as PublishedSimpleTask | TakenSimpleTask}/>
            )
          }
          const Card = taskCardWrap(TaskPreviewCard, {
            task: task as TaskListItem,
            cardType: CardType.PREVIEW
          })
          return (
            <Card key={task.id}/>
          )
        })}
        {!allTasksLoaded && (
          <DownloadElseCard
            className='task-card'
            onMoreLoadClickHandler={onMoreLoadClickHandler}
            isLoading={isLoadingAddTask}/>
        )}
      </div>
    )
  }

  const getKey = () => {
    if (loading) return 1
    if (!tasks.length) return 2
    return 0
  }

  const getClass = () => {
    const list = ['task-list']
    if (size === 'm') list.push('custom-scroll')
    return list.join(' ')
  }

  return (
    <Spin spinning={loading}>
      <div className={getClass()}>
        <SwitchTransition>
          <CSSTransition
            key={getKey()}
            timeout={200}
            classNames='fade'>
            {getContent()}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </Spin>
  )
}
