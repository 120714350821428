import React from 'react'
import IconClear from '../icons/IconClear'
import Paragraph, { Align, TextStyle } from '../common_kit/text/Paragraph'
import IconWarning from '../icons/IconWarning'

type InputData = {
  value: string,
  changeInputCallback: (value: string) => void,

  idKey?: string,
  label?: string,
  placeholder?: string,
  required?: boolean
  error?: string,
  showWarningIcon?: boolean,
  showWarningMsg?: boolean,
  onPressEnterKey?: () => void,
  onInputMouseEnter?: () => void,
  onInputMouseLeave?: () => void,
  onInputFocus?: () => void,
  onInputBlur?: () => void,
  transparent?: boolean,
  disabled?: boolean,
  onClickClearBtn?: () => void,
  outline?: boolean,
  digitsOnly?: boolean,
  className?: string,
  labelClassName?: string,
  validationMethod?: () => void
}

NewTextInput.defaultProps = {
  value: '',
  outline: false,
  digitsOnly: false,
  className: '',
  showWarningIcon: true,
  showWarningMsg: true,
  idKey: '',
  changeInputCallback: () => {}
}

export default function NewTextInput(props: InputData) {
  const id = `id-${Date.now()}${props.idKey}`

  const validate = () => {
    if (!props.validationMethod) return
    props.validationMethod()
  }

  const handlerInputChange = (event: any) => {
    const value = event.target.value
    if (props.digitsOnly && value.match(/.*\D/)) return

    props.changeInputCallback(value)
    validate()
  }

  const handlerInputKeyUp = (event: any) => {
    if (props.onPressEnterKey && event.keyCode === 13) {
      props.onPressEnterKey()
      validate()
    }
  }

  const handlerInputMouseEnter = () => {
    if (props.onInputMouseEnter) {
      props.onInputMouseEnter()
    }
  }

  const handlerInputMouseLeave = () => {
    if (props.onInputMouseLeave) {
      props.onInputMouseLeave()
      validate()
    }
  }

  const handlerInputFocus = () => {
    if (props.onInputFocus) {
      props.onInputFocus()
    }
  }

  const handlerInputBlur = () => {
    if (props.onInputBlur) {
      props.onInputBlur()
    }
    validate()
  }

  const handlerClickClearBtn = () => {
    props.changeInputCallback('')
    validate()
  }

  const transparentClass = props.transparent ? ' transparent' : ''
  const disableClass = props.disabled ? ' disabled' : ''
  const invalidClass = props.error ? ' invalid' : ''
  const outlineClass = props.outline ? ' outline' : ''

  const getIcon = () => {
    if (props.error && !props.value) {
      if (!props.showWarningIcon) return
      return <IconWarning
        className="form-control__warn"
      />
    }
    return <IconClear
      className={`form-control__close ${props.error ? '' : 'hidden'}`}
      width={10}
      height={10}
      fill={'#FF4848'}
      onClick={handlerClickClearBtn}
    />
  }

  return <div className="form-group">
    {props.label !== undefined ? <label htmlFor={id} className={props.labelClassName}>{props.label}</label> : null}
    <div className="form-control__container">
      <input
        id={id}
        type="text"
        value={props.value}
        disabled={props.disabled || false}
        required={props.required}
        className={`${props.className} form-control${transparentClass}${disableClass}${invalidClass}${outlineClass}`}
        placeholder={props.placeholder}
        onChange={handlerInputChange}
        onKeyUp={handlerInputKeyUp}
        onMouseEnter={handlerInputMouseEnter}
        onMouseLeave={handlerInputMouseLeave}
        onFocus={handlerInputFocus}
        onBlur={handlerInputBlur}
      />
      {getIcon()}
    </div>
    {props.showWarningMsg && (
      <Paragraph
        className={`form-control__error-text ${props.error ? '' : 'hidden'}`}
        textStyle={TextStyle.ITALIC}
        align={Align.CENTER}
        text={props.error}
        color={'#FF4848'}
      />
    )}
  </div>

}
