import React, { ReactElement } from 'react'
import '../../style/sass/common_kit/Borders.scss'

type Data = {
  className?: string
  children: ReactElement
}

export default function EncircleBorder(props: Data) {
  return (
    <div className={`${props.className ?? ''} borders__encircle-border`}>
      {props.children}
    </div>
  )
}

