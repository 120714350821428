import React, { useCallback, useState } from 'react'
import { parseTaskTimeToString } from '../../utils/helpers'
import InfoModal from '../InfoModal/InfoModal'
import { WarningFilled } from '@ant-design/icons'
import { Trans, useTranslation } from 'react-i18next'
import { Checkbox, Spin } from 'antd'
import SubmitButton from '../inputs/SubmitButton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'
import { takeSimpleTask } from '../../store/taskList/actions'
import {set} from "idb-keyval"
import {useHistory} from "react-router-dom"
import {takeSimpleTaskToProcess} from '../../store/taskList/api'
import WarningGmailPhone from './WarningGmailPhone'


interface Data {
  open: boolean
  taskId: string
  taskTime: number
  googleTask: boolean
  onCloseHandler: () => void
}

export default function TakeSimpleTaskModal(props: Data) {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const size = useSelector((state: RootState) => state.mq.size)

  const [timeLimitConfirmed, setTimeLimitConfirmed] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const needConfirmTimeLimit = props.taskTime > 0
  const startBtnDisabled = needConfirmTimeLimit && !timeLimitConfirmed
  const time: string = parseTaskTimeToString(props.taskTime)

  const toggleTimeLimitConfirmed = useCallback(() => {
    setTimeLimitConfirmed(!timeLimitConfirmed)
  }, [timeLimitConfirmed])

  const navigateToTask = (id) => {
    set('activeSimpleTask', id)
      .then(() => {
        const path = `/google-account/task/${id}`
        history.push(path)
      })
  }

  const handleStartBtnClick = async () => {
    setIsLoading(true)
    const task = await takeSimpleTaskToProcess(props.taskId)
    if (!task) {
      setIsLoading(false)
      props.onCloseHandler()
      return
    }
    await dispatch(takeSimpleTask(task, props.taskId))
    if (props.googleTask) navigateToTask(task?.id)
    setIsLoading(false)
    props.onCloseHandler()
  }

  const getWidth = () => {
    return {
      l: '630px',
      m: '500px',
      s: '300px'
    }[size]
  }

  return (
    <InfoModal
      showModal={props.open}
      closeByOutsideClick
      width={ getWidth() }
      afterClose={props.onCloseHandler}>
      <div className="simple-task-take-modal">
        <div className="modal-image">
          <img src="/programmer.svg" alt=""/>
        </div>
        <div className="modal-text">
          {t('Simple task take modal')}
        </div>
        {needConfirmTimeLimit && (
          <div className="time-limit-warning">
            <div className="warning-title">
              <WarningFilled/>
              {t('Simple task time limit warning part 1')}
            </div>
            <div className="warning-subtitle">
              <Trans i18nKey="Simple task time limit warning part 2">
                To receive a reward, you need to complete the execution in
                <span className="time-value">{{ time }}</span>
              </Trans>
            </div>
            <div className="warning-checkbox" onClick={toggleTimeLimitConfirmed}>
              <Checkbox
                checked={timeLimitConfirmed}
                onChange={toggleTimeLimitConfirmed}
              />
              <div className="warning-checkbox-label">
                {t('Clear')}
              </div>
            </div>
          </div>
        )}
        {props.googleTask && <WarningGmailPhone/>}
        <Spin spinning={isLoading}>
          <div className="modal-toolbar">
            <SubmitButton
              isDisabled={startBtnDisabled}
              text={t('Start')}
              onClick={handleStartBtnClick}
            />
          </div>
        </Spin>
      </div>
    </InfoModal>
  )
}
