import React from 'react'
import Span from '../common_kit/text/Span'
import { CardStatusColor } from '../common_kit/Tooltip'


type CheckboxData = {
  value: boolean,
  label: string,
  handler: Function,
  isDisabled: boolean,
}

export default function Checkbox (props: CheckboxData) {

  const getSrc = (): string => props.value ? '/checkboxIcons/checkboxChecked.svg' : '/checkboxIcons/checkboxNotChecked.svg'

  const handleClick = () => {
    if (props.isDisabled) return
    props.handler(!props.value)
  }

  const disabledClass = props.isDisabled ? 'disabled' : ''

  return <div className={`checkbox form-group ${disabledClass}`} onClick={handleClick}>
    <div className="img-wrapper">
      <img src={getSrc()} alt=''/>
    </div>
    <label>
      <Span color={CardStatusColor.GRAY} text={props.label}/>
    </label>
  </div>
}
