import React, { ReactNode, Suspense, useEffect } from 'react'
import '../../style/sass/instructions/guides/GuideSteps.scss'
import IconClear from '../icons/IconClear'
import PrevNext from './PrevNext'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'
import { setTaskCompleteModal } from '../../store/modal/actions'
import InfoModal from '../InfoModal/InfoModal'
import { ScreenshotType } from '../../store/taskScreenshots/initialState'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'
import WithStepCheck from './withStepСheck'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import store from '../../store'
import { resetTaskData } from '../../store/task/actions'
import {resetSimpleTaskData, resetPage, resetTaskList} from "../../store/taskList/actions";



type Data = {
  moderationScreenshotType?: ScreenshotType,
  typeTask: string
  text?: string
}

export default function GuideContent(props: Data) {
  const { t } = useTranslation()

  const history = useHistory()
  const location = useLocation()

  const dispatch = useDispatch()

  const modalCompleteTask = useSelector((state: RootState) => state.modal.taskCompleteModal, _.isEqual)
  const currentIndex = useSelector((state: RootState) => props.typeTask === 'task' ? state.task.currentIndex : state[props.typeTask].simpleTaskGmail.currentIndex)
  const currentStep = useSelector((state: RootState) => props.typeTask === 'task' ? state.task.currentStep : state[props.typeTask].simpleTaskGmail.currentStep, _.isEqual)
  const locale = useSelector((state: RootState) => state.locale.locale.name)
  const typeSelectTask = props.typeTask === 'task' ? 'selectedTask' : 'selectedSimpleTask'
  const taskId = useSelector((state: RootState) => state[props.typeTask][typeSelectTask]?.id)

  useEffect(() => {
    if (!currentStep) return
    const stepId = currentStep.componentPath.split('/').reverse()[0].toLowerCase()
    history.push({ search: `?step=${stepId}` })
  }, [currentStep])

  const handleOnCloseClick = (resetAdvancement = false) => {
    const queryParam = location.pathname.includes('google-account') ? '?type=other' : ''
    const getSetter = () => {
      const mapper = {
        task: resetTaskData,
        taskList: resetSimpleTaskData
      }
      return mapper[props.typeTask]
    }
    const setter = getSetter()
    store.dispatch(setter(resetAdvancement))
    store.dispatch(resetPage())
    store.dispatch(resetTaskList())
    history.push(`/available-tasks${queryParam}`)
  }

  const getTaskCompleteModal = (): ReactNode => {
    return (
      <InfoModal showModal={modalCompleteTask.show}>
        <div>
          <IconClear
            className={'modal-close'}
            fill={'#dfdefd'}
            onClick={() => {
              dispatch(setTaskCompleteModal({ show: false, iconType: '', text: '' }))
              modalCompleteTask.iconType === 'success' && handleOnCloseClick(true)
            }}/>
          {modalCompleteTask.iconType === 'success'
            ? <img src="/modal_work_done.svg" className="modal__icon" alt=""/>
            : <img src="/modal_work_error.svg" className="modal__icon" alt=""/>}
          <p className="ui-kit-regular-14 modal__text-small">
            {t(modalCompleteTask.text)}
          </p>
        </div>
      </InfoModal>
    )
  }

  const CurrentStep = React.lazy(() => {
    const componentPath = currentStep?.componentPath
    return import((`./guides/${componentPath}`))
  })

  return (
    <div className={`guide-content guide-${locale}`}>
      <IconClear className="guide-content__ic-close" fill={'#DFDEFD'} onClick={() => handleOnCloseClick()}/>
      {currentStep?.componentPath && (
        <WithStepCheck typeTask={props.typeTask}>
          <SwitchTransition>
            <CSSTransition
              key={currentIndex}
              timeout={200}
              classNames="fade">
              <div>
                <Suspense fallback={<div className="guide-content-dummy"/>}>
                  <CurrentStep {...currentStep?.stepOptions} typeTask={props.typeTask} taskId={taskId}/>
                </Suspense>
              </div>
            </CSSTransition>
          </SwitchTransition>
        </WithStepCheck>
      )}
      <PrevNext moderationScreenshotType={props.moderationScreenshotType} typeTask={props.typeTask} text={props.text}/>
      {getTaskCompleteModal()}
    </div>
  )
}
