import { CHANGE_PHONE_MODEL, SET_PHONE_VERIFIED } from './actionTypes'

export const setPhoneVerified = status => ({
  type: SET_PHONE_VERIFIED,
  status
})

export const changePhoneModel = object => ({
  type: CHANGE_PHONE_MODEL,
  object
})
