import React, {ReactNode, useCallback, useState} from 'react'
import '../../style/sass/tasksPage/Cards.scss'
import { useTranslation } from 'react-i18next'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import {checkDevice, checkTranslate, getAmountWithSign} from '../../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'
import { Spin } from 'antd'
import SubmitButton from '../inputs/SubmitButton'
import RejectReason from './RejectReason'
import Timer from '../common_kit/Timer'
import TakeSimpleTaskModal from './TakeSimpleTaskModal'
import { PublishedSimpleTask, TakenSimpleTask } from '../../store/taskList/initialState'
import {
  restartSimpleTask,
  setSelectedTaskForDetails,
  setSelectedSimpleGmailTask,
  setCurrentSimpleIndex
} from '../../store/taskList/actions'
import {useHistory} from "react-router-dom"
import {set} from "idb-keyval"
import InfoModal from "../InfoModal/InfoModal";


type Data = {
  task: PublishedSimpleTask | TakenSimpleTask
}

export default function SimpleTaskPreviewCard(props: Data) {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const locale = useSelector((state: RootState) => state.locale.locale.name)
  const size = useSelector((state: RootState) => state.mq.size)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showTakeTaskModal, setShowTakeTaskModal] = useState<boolean>(false)
  const [showDeviceModal, setShowDeviceModal] = useState<boolean>(false)

  const status = props.task.status
  const showTip = status.name === 'moderation'
  const isGoogleAccount = props.task.type === 'user_simple'
    ? props.task.settings.google_account
    : !!props.task.result.google_account
  const showDetails = !['process', 'moderation_rejected'].includes(status.name) && !isGoogleAccount
  const showBtn = !['moderation', 'completed', 'failed'].includes(status.name)
  const notActiveDescript = ['completed', 'failed'].includes(status.name)
  const showTimer = status.name === 'process' && props.task.calcCompleteAt

  const openTakeTaskModal = useCallback(() => {
    if (isGoogleAccount) {
      const correctDevice = checkDevice()
      if (!correctDevice) {
        setShowDeviceModal(true)
        return
      }
    }
    setShowTakeTaskModal(true)
  }, [])

  const handleModalClose = useCallback(() => {
    setShowTakeTaskModal(false)
  }, [])

  const navigateToTask = (id) => {
    set('activeSimpleTask', id)
      .then(() => {
        const path = `/google-account/task/${id}`
        history.push(path)
      })
  }

  const handleDetailsBtnClick = useCallback(async () => {
    if (isGoogleAccount) {
      const correctDevice = checkDevice()
      if (!correctDevice) {
        setShowDeviceModal(true)
        return
      }
      set('activeSimpleTask', props.task.id)
        .then(() => {
          history.push(`/google-account/task/${props.task.id}`)
        })
      dispatch(setSelectedTaskForDetails(props.task))
      dispatch(setSelectedSimpleGmailTask())
      return
    }
    if (isLoading || notActiveDescript) return
    if (status.name === 'published') {
      dispatch(setSelectedTaskForDetails(props.task))
      return
    }
    setIsLoading(true)
    dispatch(setSelectedTaskForDetails(props.task))
    setIsLoading(false)
  }, [isLoading])

  const handleRestartBtnClick = async () => {
    setIsLoading(true)
    await dispatch(restartSimpleTask(props.task.id))
    if (isGoogleAccount) navigateToTask(props.task.id)
    setIsLoading(false)
  }

  const getStatus = () => {
    if (showTimer) {
      return (
        <div className="task-status">
          <div className="status-title">
            {t('Task in progress')}
          </div>
          <Timer endMoment={props.task.calcCompleteAt}/>
        </div>
      )
    } else {
      return (
        <div className="task-status">
          <div className="status-title">
            {t('Task status')}
          </div>
          <div className="status-value">
            {checkTranslate(status, locale)}
          </div>
        </div>
      )
    }
  }

  const getBtn = () => {
    if (isLoading) return <Spin tip={t('Download instructions')}/>
    if (status.name === 'published') return (
      <SubmitButton
        text={t('Start task')}
        onClick={openTakeTaskModal}
      />
    )
    if (status.name === 'process') return (
      <SubmitButton
        text={t('Continue task')}
        onClick={handleDetailsBtnClick}
      />
    )
    if ('comment' in props.task && status.name === 'moderation_rejected') return (
      <RejectReason
        reason={props.task.comment}
        clickBtnHandler={handleRestartBtnClick}
        btnTitleText={t('Try it again')}
      />
    )
  }

  const getClass = () => {
    const list = ['card-box', 'simple-task-preview-card']
    list.push(status.name)
    return list.join(' ')
  }

  const getDeviceModal = (): ReactNode => {
    if (!isGoogleAccount) return
    const width = {
      s: '280px',
      m: '500px',
      l: '680px'
    }[size]
    return (
      <InfoModal showModal={showDeviceModal} width={width}>
        <div className="device-modal">
          <div className="message">
            <p className="ui-kit-regular-18 modal__text title">
              {t('Attention')}
            </p>
            <p className="ui-kit-regular-18 modal__text">
              {t('Incorrect device modal')}
            </p>
          </div>
          <img src="/deviceImage.svg" alt="" className="modal__icon"/>
          <button
            className="btn btn-primary modal__button"
            onClick={() => setShowDeviceModal(false)}>
            {t('Clear')}
          </button>
        </div>
      </InfoModal>
    )
  }

  return (
    <div className="task-card-wrap">
      <div className={getClass()}>
        <div className="task-card-header">
          {getStatus()}
          <div className="task-cost">
            + {getAmountWithSign(props.task.cost)}
          </div>
        </div>
        <div className="card-image">
          <img src="simpleTaskImage.svg" alt=""/>
        </div>
        <div className="task-title">
          {props.task.title}
        </div>
        {showTip && (
          <div className="task-tip">
            {t('Moderation tip')}
          </div>
        )}
        {showDetails && (
          <Spin spinning={!showBtn && isLoading}>
            <div className="task-card__details">
              <div className="task-card__details-link" onClick={handleDetailsBtnClick}>
                {t('Task details')}
              </div>
            </div>
          </Spin>
        )}
        {showBtn && (
          <div className="card-action-block">
            <SwitchTransition>
              <CSSTransition
                key={Number(isLoading)}
                timeout={200}
                classNames="fade">
                {getBtn()}
              </CSSTransition>
            </SwitchTransition>
          </div>
        )}
      </div>
      <TakeSimpleTaskModal
        open={showTakeTaskModal}
        taskId={props.task.id}
        taskTime={props.task.task_time}
        googleTask={isGoogleAccount}
        onCloseHandler={handleModalClose}
      />
      {getDeviceModal()}
    </div>
  )
}
