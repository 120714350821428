import {
  CLEAR_SIDEBAR_OPTIONS,
  HIDE_MENU,
  HIDE_SIDEBAR,
  SET_SIDEBAR_AVAILABILITY,
  SHOW_MENU,
  SHOW_SIDEBAR
} from './actionTypes'

export const setSidebarAvailability = data => ({
  type: SET_SIDEBAR_AVAILABILITY,
  data
})

export const showSidebar = () => {
  // setSidebarClassToBody()
  return {
    type: SHOW_SIDEBAR
  }
}

export const hideSidebar = () => {
  return {
    type: HIDE_SIDEBAR
  }
}

export const showMenu = () => {
  document.body.classList.add('out-scroll')
  document.getElementsByTagName('html')[0].classList.add('out-scroll');
  return {
    type: SHOW_MENU
  }
}

export const hideMenu = () => {
  document.body.classList.remove('out-scroll')
  document.getElementsByTagName('html')[0].classList.remove('out-scroll');
  return {
    type: HIDE_MENU
  }
}

export const clearSidebarOptions = () => ({
  type: CLEAR_SIDEBAR_OPTIONS
})
