import React from 'react'
import { useTranslation } from 'react-i18next'
import { Space, Spin } from 'antd'
import '../../style/sass/tasksPage/Cards.scss'
import { PlusOutlined } from '@ant-design/icons'

type Data = {
  className?: string
  onMoreLoadClickHandler: () => void
  isLoading: boolean
}

export default function DownloadElseCard(props: Data) {
  const { t } = useTranslation()

  const getContent = () => {
    if (props.isLoading) return (
      <Spin size='large'/>
    )
    return (
      <div className='load-more-btn'>
        <PlusOutlined/>
        <div className='load-more-btn__text'>
          {t('Load more')}
        </div>
      </div>
    )
  }

  const classList = [
    'card-box',
    'download-else-card',
    props.className ?? ''
  ].join(' ')

  return (
    <div className={classList} onClick={props.onMoreLoadClickHandler}>
      {getContent()}
    </div>
  )
}
