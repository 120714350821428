import React, {Component} from "react";
import "../../../style/sass/common_kit/Text.scss"

type Data = {
    className?: string
    text: string
    color?: string
}

class H5 extends Component<Data> {

    render() {
        return <h5 className={`ui-kit ${this.props.className ?? ""}`} style={{color: this.props.color}}>{this.props.text}</h5>
    }

}

export default H5;
