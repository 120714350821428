export interface ModalState {
  verificationModal: boolean
  taskCompleteModal: {
    show: boolean,
    iconType: string,
    text: string
  }
}

const initialStore: ModalState = {
  verificationModal: false,
  taskCompleteModal: {
    show: false,
    iconType: '',
    text: ''
  }
}

export default initialStore
