import React from "react";
import "../../../style/sass/common_kit/Text.scss"


type Data = {
    className?: string
    color?: string
    text: string
    fontSize?: number
}

export default function H3 (props: Data) {

    const style = {
        color: props.color,
        fontSize: props.fontSize,
    }

    return <h3 className={`ui-kit ${props.className ?? ""}`} style={style}>
        {props.text}
    </h3>

}
