import React, { useEffect } from 'react'
import PaymentForm from './paymentsPage/paymentForm/PaymentForm'
import WithdrawalStory from './paymentsPage/transactionsHistrory/WithdrawalStory'
import '../style/sass/PaymentsPage.scss'
import { useDispatch } from 'react-redux'
import { updateUserData } from '../store/user/actions'
import { setSidebarAvailability } from '../store/sidebar/actions'


export default function PaymentsPage() {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setSidebarAvailability(true))
    dispatch(updateUserData())
  }, [])

  return (
    <div className="payments-page">
      <PaymentForm/>
      <WithdrawalStory/>
    </div>
  )

}
