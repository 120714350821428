import React from 'react'
import './../style/sass/Banner.scss'

export default function AdBanner() {

  return (
    <div className="ad-banner">
      <a
        target="_blank" rel="noopener noreferrer"
        href="https://www.facebook.com/freelance.appwork">
        <img src={`/banners/fb_subscribe.png`} alt="Facebook"/>
      </a>
    </div>
  )
}
