import React, { useEffect, useState } from 'react'
import BalanceInfo from './BalanceInfo'
import FundsAccount from './FundsAccount'
import WithdrawalAmountForm from './WithdrawalAmountForm'
import { useTranslation, Trans } from 'react-i18next'
import WithdrawalAccountForm, { purseDetails } from './WithdrawalAccountForm'
import SubmitButton from '../../inputs/SubmitButton'
import { PaymentType } from '../../../store/payments/initialState'
import { requestPayout } from '../../../store/payments/api'
import { getPaymentLimits } from '../../../utils/helpers'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/rootState'
import HoldMoneyIllustration from '../../illustrations/HoldMoneyIllustration'
import MqMedium from '../../mq/MqMedium'
import MqLarge from '../../mq/MqLarge'
import {PaymentCurrency} from "../../../utils/constants";

export default function PaymentForm() {

  const { t, i18n } = useTranslation()

  const locale = useSelector((state: RootState) => state.locale.locale.name)
  const balance = useSelector((state: RootState) => {
    const walletRub = state.user.userData?.wallets?.find(item => item.name === PaymentCurrency.RUB.toUpperCase())
    return Number(walletRub?.balance) ?? 0
  })
  const hold = useSelector((state: RootState) => state.user.userData?.holdBalanceRub)
  const isDevastated = useSelector((state: RootState) => state.user.userData?.is_devastated)

  const [amount, setAmount] = useState<string>('')
  const [upperLimit, setUpperLimit] = useState<number>()
  const [lowerLimit, setLowerLimit] = useState<number>()
  const [amountValid, setAmountValid] = useState<boolean>(false)
  const [currency, setCurrency] = useState<string>(i18n.language === 'ru' ? PaymentCurrency.RUB : PaymentCurrency.USD)
  const [disableSubmitBtn, setDisableSubmitBtn] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeType, setActiveType] = useState<PaymentType>(locale === 'ru' ? PaymentType.MOBILE : PaymentType.PAYTM)
  const [purseDetails, setPurseDetails] = useState<purseDetails>()
  const [purseDetailsValid, setPurseDetailsValid] = useState<boolean>(false)

  const submitBtnText = loading ? t('Withdrawing') : t('Withdraw')

  useEffect(() => {
    updateLimits()
  }, [])

  useEffect(() => {
    const isUsdt = activeType === PaymentType.USDTTRC20
    const currency = isUsdt ? PaymentCurrency.USDT : (locale === 'ru' ? PaymentCurrency.RUB : PaymentCurrency.USD)
    setCurrency(currency)
  }, [locale, activeType])

  useEffect(() => {
    updateLimits()
  }, [
    currency,
    activeType,
    purseDetails?.bankCountry,
    purseDetails?.bankCardUsdData?.countryCode
  ])

  useEffect(() => {
    setDisableSubmitBtn(!amount || !amountValid || !purseDetailsValid || loading)
  }, [
    amount,
    amountValid,
    purseDetailsValid,
    loading
  ])

  const updateLimits = () => {
    const defaultCountry = currency === PaymentCurrency.USD ? 'default' : null
    const selectedCountry = currency === PaymentCurrency.USD ? purseDetails?.bankCardUsdData?.countryCode : purseDetails?.bankCountry
    const { min, max } = getPaymentLimits(currency, activeType, selectedCountry || defaultCountry, isDevastated)
    if (!min || !max) return
    setLowerLimit(min)
    setUpperLimit(max)
  }

  const handleAmountChange = (amount: string, isAmountValid: boolean) => {
    setAmountValid(isAmountValid)
    setAmount(amount)
  }

  const handlePurseDataChange = (valid: boolean, model: purseDetails) => {
    setPurseDetailsValid(valid)
    setPurseDetails(model)
  }

  const handleSubmitBtnClick = async () => {
    setLoading(true)
    const result = await requestPayout(activeType, amount, currency, purseDetails, isDevastated)
    if (result) {
      setAmount('')
    }
    setLoading(false)
  }

  const localeClass = (locale === 'en') ? 'en-locale' : ''

  return (
    <div className={`payment-form-wrap ${localeClass}`}>
      <div className="payment-form">
        <WithdrawalAmountForm
          amount={amount}
          upperLimit={upperLimit}
          lowerLimit={lowerLimit}
          balance={balance}
          activeType={activeType}
          handlerAmountChange={handleAmountChange}
        />
        <BalanceInfo amountMoney={balance} lockedMoney={hold || 0}/>
        <FundsAccount
          activeType={activeType}
          handlerTypeChanged={setActiveType}
        />
        <WithdrawalAccountForm
          paymentType={activeType}
          handlerDataChange={handlePurseDataChange}
        />
      </div>
      <div className="payment-form-toolbar">
        <SubmitButton
          className="payment-form-btn"
          text={submitBtnText}
          isDisabled={disableSubmitBtn}
          onClick={handleSubmitBtnClick}
        />
      </div>
      <MqMedium>
        <div className="payment-form-arrow">
          <img src="arrow.svg" alt=""/>
        </div>
      </MqMedium>
      <MqLarge>
        <div className="payment-form-arrow">
          <svg width="26" height="317" viewBox="0 0 26 317" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.292893 309.945C-0.0976312 309.555 -0.0976313 308.922 0.292893 308.531L6.65685 302.167C7.04738 301.777 7.68054 301.777 8.07107 302.167C8.46159 302.558 8.46159 303.191 8.07107 303.581L2.41421 309.238L8.07107 314.895C8.46159 315.286 8.46159 315.919 8.07107 316.309C7.68054 316.7 7.04738 316.7 6.65686 316.309L0.292893 309.945ZM17 2.23828L0.999973 2.23828L0.999973 0.238283L17 0.238282L17 2.23828ZM1 308.238L17 308.238L17 310.238L1 310.238L1 308.238ZM24 301.238L24 9.23828L26 9.23828L26 301.238L24 301.238ZM17 308.238C20.866 308.238 24 305.104 24 301.238L26 301.238C26 306.209 21.9706 310.238 17 310.238L17 308.238ZM17 0.238282C21.9706 0.238282 26 4.26772 26 9.23828L24 9.23828C24 5.37229 20.866 2.23828 17 2.23828L17 0.238282Z" fill="#2684FE"/>
          </svg>
        </div>
      </MqLarge>
      <div className="payment-form-info_description">
        <p>
          <Trans i18nKey="Info about hold">
            <span>Холд</span> - это сумма, которая будет доступна для снятия после выполнения задания.
          </Trans>
        </p>
        <HoldMoneyIllustration/>
      </div>
    </div>
  )

}
