import {
  CheckCircleOutlined,
  DisconnectOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  WarningOutlined
} from '@ant-design/icons'
import { notification } from 'antd'
import IconClear from '../icons/IconClear'
import React from 'react'
import './Notification.css'

type Data = {
  noticeType: string,
  text: string,
  notClose?: boolean,
  key?: string
}

export default function Notification(props: Data) {
  const iconMapper = {
    info: <InfoCircleOutlined style={{ fontSize: '30px', color: '#2684FE' }}/>,
    error: <WarningOutlined style={{ fontSize: '30px', color: '#e74c3c' }}/>,
    warn: <ExclamationCircleOutlined style={{ fontSize: '30px', color: '#f1c40f' }}/>,
    success: <CheckCircleOutlined style={{ fontSize: '30px', color: '#07bc0c' }}/>,
    disconnect: <DisconnectOutlined style={{ fontSize: '30px', color: '#e74c3c' }}/>
  }

  notification.open({
    message: props.text,
    className: `notifications ${props.noticeType}`,
    duration: props.notClose ? null : 5,
    icon: iconMapper[props.noticeType],
    closeIcon: <IconClear fill="#DFDEFD" className="icon-close-notification"/>,
    key: props.key
  })
}
