import React, { useEffect, useState } from 'react'
import GuideContent from '../instructions/GuideContent'
import './../../style/sass/TaskPage.scss'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserData } from '../../store/user/actions'
import { setSidebarAvailability } from '../../store/sidebar/actions'
import { setLocalScreenshots } from '../../store/taskScreenshots/actions'
import { RootState } from '../../store/rootState'
import { ScreenshotType } from '../../store/taskScreenshots/initialState'
import { downloadAndSetSelectedTask, resetTaskData, setStepIndexFromProgress } from '../../store/task/actions'
import { Spin } from 'antd'
import TaskSidebar from './TaskSidebar'
import { useTranslation } from 'react-i18next'
import {getMany, get} from 'idb-keyval'
import { PublisherTaskType } from '../../store/taskList/initialState'
import {resetSimpleTaskData} from "../../store/taskList/actions";

export default function TaskPage() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const taskStatus = useSelector((state: RootState) => state.task.selectedTask?.status.name)
  const taskType = useSelector((state: RootState) => state.task.selectedTask?.type)
  const isLoading = useSelector((state: RootState) => !state.task.steps?.length)
  const locale = useSelector((state: RootState) => state.locale.locale.name)
  const textData = useSelector((state: RootState) => state.taskList?.simpleTaskGmail?.textFile)

  const [isFirstChangeLocale, setIsFirstChangeLocale] = useState<boolean>(true)
  const [id, setId] = useState<string>()

  useEffect(() => {
    getMany(['activeTask'])
      .then(([activeTask]) => {
        setId(activeTask)
        dispatch(setSidebarAvailability(false))
        dispatch(updateUserData())
        dispatch(setLocalScreenshots())
      })
    return () => {
      dispatch(resetTaskData())
      dispatch(resetSimpleTaskData())
    }
  }, [])

  useEffect(() => {
    if (isFirstChangeLocale) {
      setIsFirstChangeLocale(false)
      return
    }
    dispatch(resetTaskData(true))
    dispatch(downloadAndSetSelectedTask(id))
  }, [locale, id])

  useEffect(() => {
    if (isLoading || !id) return
    dispatch(setStepIndexFromProgress(id))
  }, [isLoading, id])

  const getContent = () => {
    if (isLoading) return (
      <div className="task-loading-dummy">
        {t('Download instructions')}
      </div>
    )
    const screenshotType = taskType === PublisherTaskType.VERIFICATION ? ScreenshotType.VERIFICATION : ScreenshotType.PUBLISH_MODERATION
    return (<GuideContent moderationScreenshotType={screenshotType} typeTask={'task'} text={textData}/>)
  }

  const countingDown = ['process', 'process_assigned', 'verified'].includes(taskStatus ?? '')
  const loadingClass = isLoading ? 'loading' : ''

  return (
    <div className={`task-page ${loadingClass}`}>
      <TaskSidebar countingDown={countingDown} loading={isLoading} typeTask={'task'}/>
      <div className="instruction col col-sm-9">
        <Spin spinning={isLoading}>
          {getContent()}
        </Spin>
      </div>
    </div>
  )
}
