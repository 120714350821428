import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Select } from 'antd'
import countryPhoneCodes from '../../tempDictionary/countryPhoneCodes.json'
import { RootState } from '../../store/rootState'
import { useTranslation } from 'react-i18next'
import { GlobalOutlined, StarFilled } from '@ant-design/icons'
import IconClear from '../icons/IconClear'

type selectData = {
  value: string,
  isDisabled: boolean | undefined,
  onChange: (value: string) => void
  onFocus: () => void
  onBlur: () => void
}

type Country = {
  code: string
  en: string
  ru: string
  short_name: string
  flag: string
}

export default function CountrySelect(props: selectData) {

  const { t } = useTranslation()

  const { Option, OptGroup } = Select

  const locale = useSelector((state: RootState) => state.locale.locale.name)

  const [allCountries, setAllCountries] = useState<Array<Country>>([])
  const [favoriteCountries, setFavoriteCountries] = useState<Array<Country>>([])
  const [search, setSearch] = useState<string>('')

  const favoriteCountryNames = ['Армения', 'Индия', 'Пакистан', 'Россия', 'Украина']

  const sort = countries => countries.sort((a, b) => {
    if (a[locale] > b[locale]) return 1
    if (a[locale] < b[locale]) return -1
    return 0
  })

  const initCountries = sort([...countryPhoneCodes])
  const initFavoriteCountries = sort([...countryPhoneCodes].filter(item => {
    return favoriteCountryNames.includes(item.ru)
  }))

  useEffect(() => {
    const foundResults = list => {
      return list.filter(item => {
        const nameFound = item[locale].toLowerCase().includes(search.toLowerCase())
        return nameFound || item.code.includes(search)
      })
    }
    setAllCountries(() => foundResults(initCountries))
    setFavoriteCountries(() => foundResults(initFavoriteCountries))
  }, [locale, search])

  const searchChangeHandler = useCallback(event => {
    event.preventDefault()
    const value = event.target.value.trim().replace('+', '')
    setSearch(value)
  }, [])

  const optionSelectHandler = useCallback((value: string) => {
    setSearch('')
    props.onChange(value)
  }, [])

  const handlerClickClearBtn = useCallback(event => {
    event.preventDefault()
    setSearch('')
  }, [])

  const getLabel = type => {
    const key = {
      favorite: 'Frequently used',
      world: 'The whole world'
    }[type]
    const text = key ? t(key) : ''
    const icon = {
      favorite: <StarFilled/>,
      world: <GlobalOutlined/>
    }[type] || null
    return (
      <div className="country-group-label">
        {icon}
        <span>{text}</span>
      </div>
    )
  }

  const getOptions = (countries, key) => {
    if (!countries.length) return (
      <Option key={key} disabled>
        {t('No results found')}
      </Option>
    )
    return countries.map((country) => {
      return (
        <Option key={`${country.en}_${country.code}_${key}`} value={country.code}>
          <span className="country">
            {country[locale]}
          </span>
          <span className="code">
            +{country.code}
          </span>
        </Option>
      )
    })
  }

  return (
    <Select
      value={props.value}
      listHeight={150}
      className={props.isDisabled ? 'disabled' : ''}
      popupClassName="country-select-dropdown"
      disabled={props.isDisabled}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onChange={optionSelectHandler}
      dropdownRender={menu => {
        return (
          <div className="country-dropdown-wrap">
            <div className="country-search">
              <input
                type="text"
                placeholder={t('Country search')}
                value={search}
                onChange={searchChangeHandler}/>
              {search && (
                <IconClear
                  width={10}
                  height={10}
                  fill={'#FF4848'}
                  onClick={handlerClickClearBtn}/>
              )}
            </div>
            {menu}
          </div>
        )
      }}>
      <OptGroup label={getLabel('favorite')}>
        {getOptions(favoriteCountries, 'favorite')}
      </OptGroup>
      <OptGroup label={getLabel('world')}>
        {getOptions(allCountries, 'world')}
      </OptGroup>
    </Select>
  )
}
