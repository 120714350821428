import React, { useEffect } from 'react'
import IconClear from '../../icons/IconClear'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isUrl } from '../../../utils/helpers'


export interface LoginFormModel {
  link: string
}

interface Data {
  value: string | null
  disabled: boolean
  onChange: (value: string, valid: boolean) => void
}

export default function ResultLinkInput(props: Data) {

  const { t } = useTranslation()
  const {
    register,
    watch,
    setValue,
    setFocus,
    setError,
    formState: {
      errors,
      isValid
    }
  } = useForm<LoginFormModel>({
    mode: 'onChange',
    defaultValues: {
      link: props.value || ''
    }
  })

  const link = watch('link')

  useEffect(() => {
    props.onChange(link, isValid)
  }, [link, isValid])

  const trimValue = (value: string): string => {
    // Не позволяет ставить пробел нигде

    return value.replaceAll(/\s+/g, '')
  }

  const transformUrl = (value: string): string => {
    // Позволяет указывать ссылку и с протоколом, и без него

    return (!value || value.startsWith('http')) ? value : `https://${value}`
  }

  return (
    <div className="input-type-link">
      <input
        type="text"
        disabled={props.disabled}
        className={errors.link ? 'invalid' : ''}
        autoComplete="off"
        {...register('link', {
          required: true,
          validate: {
            url: isUrl
          },
          setValueAs: trimValue,
          onChange: event => {
            event.preventDefault()
            event.target.value = trimValue(event.target.value)
          },
          onBlur: () => {
            setValue('link', transformUrl(link))
          }
        })}
      />
      {!props.disabled && link && (
        <div className="form-control__icon">
          <IconClear
            width={10}
            height={10}
            fill={'#FF4848'}
            onClick={() => {
              setValue('link', '')
              setError('link', { type: 'required' })
              setFocus('link')
            }}
          />
        </div>
      )}
      {!props.disabled && errors.link?.type === 'required' && (
        <div className="error-message">{t('This field is required')}</div>
      )}
      {!props.disabled && errors.link?.type === 'url' && (
        <div className="error-message">{t('Invalid link format')}</div>
      )}
    </div>
  )
}
