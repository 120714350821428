import React from 'react'


type Data = {
  className?: string,
  src: string,

  baseUrl?: boolean,
  onError?: () => void,
}

export default function Img(props: Data) {
  return (
    <img
      alt=""
      src={props.src}
      className={props.className}
      onError={props.onError}
    />
  )
}
