import initialState from './initialState'
import { RESET_HISTORY_PAYMENTS, SET_PAYMENTS_HISTORY, SET_RESPONSE_ID } from './actionTypes'

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case SET_PAYMENTS_HISTORY:
      const { data, meta } = action.history
      const history = [
        ...state.history,
        ...data.filter(payment => payment.purse)
      ]
      return {
        ...state,
        history,
        meta
      }
    case SET_RESPONSE_ID:
      return {
        ...state,
        responseId: Date.now
      }
    case RESET_HISTORY_PAYMENTS:
      return {
        history: [],
        meta: {},
        responseId: undefined
      }
    default:
      return state
  }
}
