import React, { useEffect } from 'react'
import Span, { TextSize, TextStyle } from '../../common_kit/text/Span'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import IconClear from '../../icons/IconClear'
import IconWarning from '../../icons/IconWarning'
import { PaymentType } from '../../../store/payments/initialState'
import { getRate } from '../../../utils/helpers'


type Data = {
  amount: string,
  lowerLimit: number | undefined,
  upperLimit: number | undefined,
  balance: number,
  activeType: PaymentType,
  handlerAmountChange: (amount: string, isAmountValid: boolean) => void
}

export default function WithdrawalAmountForm(props: Data) {

  const { t, i18n } = useTranslation()

  const {
    register,
    watch,
    formState: {
      errors,
      isValid
    },
    setValue,
    setError,
    setFocus,
    trigger,
    reset
  } = useForm<{ amount: string }>({
    mode: 'onChange',
    defaultValues: {
      amount: props.amount
    }
  })

  const amount = watch('amount')

  const minHint = getHint('Min', props.lowerLimit)
  const maxHint = getHint('Max', props.upperLimit)

  const errMsg = {
    required: t('Withdraw error 1'),
    min: `${t('Withdraw error 4')} ${props.lowerLimit}`,
    max: `${t('Withdraw error 3')} ${props.upperLimit}`,
    payTM: t('Withdraw error 5'),
    notEnough: t('Withdraw error 2')
  }

  useEffect(() => {
    if (props.amount) return
    reset()
  }, [props.amount])

  useEffect(() => {
    props.handlerAmountChange(amount, isValid)
  }, [amount, isValid])

  useEffect(() => {
    if (!amount) return
    trigger()
  }, [props.lowerLimit, props.upperLimit])

  function getHint(key: string, amount?: number): string {
    if (!amount) return ''
    if (i18n.language === 'ru') {
      return `${t(key)} ${amount} ${t('currency sign')}`
    } else {
      return `${t(key)} ${t('currency sign')} ${amount}`
    }
  }

  const transformAmount = value => {
    return value.replaceAll(/[^0-9]/g, '')
  }

  const payTM = value => {
    if (props.activeType === PaymentType.PAYTM) {
      return !(parseFloat(value) % 10)
    }
    return true
  }

  const notEnough = value => {
    const convertedBalance = props.balance / getRate()
    return Number(value) <= Number(convertedBalance.toFixed(2))
  }

  return (
    <div className="amount-form">
      <div className="form-group">
        <label htmlFor="withdrawal-input" className="amount-form-label">{t('Withdraw funds amount')}</label>
        <div className="form-control__container">
          <input
            type="text"
            id="withdrawal-input"
            className={`amount-form-input form-control outline ${errors.amount ? 'invalid' : ''}`}
            {...register('amount', {
              required: true,
              min: props.lowerLimit,
              max: props.upperLimit,
              validate: { payTM, notEnough },
              setValueAs: transformAmount,
              onChange: event => {
                event.preventDefault()
                event.target.value = transformAmount(event.target.value)
              }
            })} />
          {amount && (
            <div className="form-control__icon">
              <IconClear
                width={10}
                height={10}
                fill={'#FF4848'}
                onClick={() => {
                  setValue('amount', '')
                  setError('amount', { type: 'required' })
                  setFocus('amount')
                  props.handlerAmountChange('', false)
                }}
              />
            </div>
          )}
          {errors.amount && !amount && (
            <IconWarning
              className="form-control__warn"
            />
          )}
        </div>
        <div className="amount-form-hint">
          <Span
            text={minHint}
            textStyle={TextStyle.ITALIC}
            size={TextSize.P12}
          />
          <Span
            text={maxHint}
            textStyle={TextStyle.ITALIC}
            size={TextSize.P12}
          />
        </div>
        {errors.amount && <div className="error-message">{errMsg[errors.amount.type]}</div>}
      </div>
    </div>
  )
}
