import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import '../../style/sass/tasksPage/Cards.scss'
import '../../style/sass/tasksPage/TaskCard.scss'
import SubmitButton from '../inputs/SubmitButton'
import H3 from '../common_kit/text/H3'
import { useTranslation } from 'react-i18next'
import { checkModalOpenStatus, getAmountWithSign, getFullFileUrl, taskPopupCloseHandler } from '../../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'
import { MARATHON_STATUS_STOP } from '../../utils/constants'
import { Popconfirm, Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import IconClear from '../icons/IconClear'
import InfoModal from '../InfoModal/InfoModal'
import UploadReasonGuide from '../instructions/uploadingReason/UploadReasonGuide'
import { setSelectedDeletedApp } from '../../store/application/actions'
import { DeletedApplication } from '../../store/application/initialState'
import _ from 'lodash'
import { del, update } from 'idb-keyval'
import { setSelectedPublicTaskForDetails } from '../../store/taskList/actions'
import TaskCardPopup from './TaskCardPopup'
import { Cost } from '../../store/types/initialState'
import { PublisherTaskType, TaskListItem } from '../../store/taskList/initialState'


export enum PopupType {
  TASK_COMPLETED_SUCCESSFULLY = 'viewedTaskCompleteNotice',
  APP_UNPUBLISHED_AFTER_MARATHON = 'viewedAppUnpublishedAfterMarathonNotice',
  FAILURE = 'viewedFailureNotice',
  FAILURE_MODERATION = 'viewedFailureModerationNotice',
  FAILURE_POST_MODERATION = 'viewedFailurePostModerationNotice',
  UPLOADED_REASON_CHECKED = 'viewedDeletedAppCheckedNotice'
}

export enum PopupBtnType {
  LOAD_REASON_FOR_DELETION = 'load_reason_for_deletion',
  GO_TO_WITHDRAWAL_FUNDS = 'go_to_withdrawal_funds'
}

export type TaskCardData = {
  task: TaskListItem,
  cost: string,
  btnBlock: ReactNode,
  statusBlock: ReactNode,
  cardTitle: string,
  isExpired: boolean,
  isLoading: boolean,
  disabled: boolean
}


export default function TaskPreviewCard(props: TaskCardData) {

  const task = props.task
  const statusName = task.status.name
  const appStatusName = task.application?.status.name
  const isVerificationTask = task.type === PublisherTaskType.VERIFICATION
  const isPublishTask = task.type === PublisherTaskType.PUBLICATION

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const DEFAULT_SHORT_DESCRIPTION = t('Task short description')

  const userId = useSelector((state: RootState) => state.user.userData?.id)
  const deletionReasonPrice = useSelector((state: RootState) => state.types.costs.find(item => item.name === 'price_deletion_reason_description'))
  const locale = useSelector((state: RootState) => state.locale)
  const deletedApplications = useSelector((state: RootState) => {
    return state.application.deletedApplications
  }, _.isEqual)
  const marathonStatuses = useSelector((state:RootState) => state.types.marathonStatuses.filter(status => MARATHON_STATUS_STOP.includes(status.name)))
  const marathonPrice = useSelector((state:RootState) => state.taskList.completedTasks.meta.marathon_price)

  const [popupType, setPopupType] = useState<PopupType>()
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [showUploadingReasonModal, setShowUploadingReasonModal] = useState<boolean>(false)
  const [iconUrl, setIconUrl] = useState((): string => {
    if (isVerificationTask) {
      switch (statusName) {
        case 'verification_check':
          return 'verificationIcons/faintIcon.svg'
        case 'verified':
          return 'verificationIcons/colorlessIcon.svg'
        default:
          return 'verificationIcons/coloredIcon.svg'
      }
    }
    const defaultLangIcon = task.application.default_locale?.icon
    return getFullFileUrl(defaultLangIcon)
  })
  const [deletedApplicationData, setDeletedApplicationData] = useState<DeletedApplication>()
  const [deletedApplicationChecked, setDeletedApplicationChecked] = useState<boolean>()
  const [paymentAmount, setPaymentAmount] = useState<string>()

  useEffect(() => {
    definePopupType()
    if (['process', 'process_assigned'].includes(statusName)) cleanIndexedDB('viewedFailureNotice')
  }, [])

  useEffect(() => {
    definePopupType()
  }, [props.isExpired])

  useEffect(() => {
    setDeletedApplicationData(deletedApplications.find(item => item.id === props.task.id))
  }, [deletedApplications])

  useEffect(() => {
    setDeletedApplicationChecked(
      !!deletedApplicationData?.deletionReason?.files?.length
      && deletedApplicationData?.deletionReason?.status
    )
    definePopupType()
  }, [deletedApplicationData])

  useEffect(() => {
    definePopupType()
  }, [deletedApplicationChecked])

  useEffect(() => {
    if (!userId || !popupType) return
    checkModalOpenStatus(userId, task.id, popupType)
      .then((result: boolean) => {
        setShowPopup(result)
      })
  }, [userId, task.id, popupType])

  useEffect(() => {
    const getPrice = (priceObject:Cost|undefined):string|undefined => {
      if (!priceObject) return
      let paymentEN = Number(priceObject.value) / locale.rate.en
      return (locale.locale.name === 'ru') ? priceObject.value : paymentEN.toFixed(2)
    }
    setPaymentAmount(getPrice(deletionReasonPrice))
  }, [locale])

  const isCompleted = statusName === 'completed'
  const hideButton = [
    isVerificationTask && ['verified'].includes(statusName),
    isPublishTask && ['completed', 'failed_post_moderation'].includes(statusName)
  ].some(i => i)
  const showIcon = isVerificationTask || [
    'published',
    'assigned',
    'process',
    'process_assigned',
    'verification',
    'confirmation',
    'verified'
  ].includes(statusName)
  const appDeleted = [
    'failed_post_moderation_application',
    'application_unpublished_after_marathon',
    'application_unpublished_stuck_on_update'
  ].includes(appStatusName) && [
    'completed',
    'failed_post_moderation'
  ].includes(statusName)
  const appUnpublished: boolean = [
    'application_unpublished_after_marathon',
    'application_unpublished_stuck_on_update'
  ].includes(appStatusName)

  const DEFAULT_ICON = 'defaultTaskIcon.svg'

  const cleanIndexedDB = async (storageField: string) => {
    let isEmptyList = false
    await update(storageField, (val) => {
      if (!val) return
      let listTasks = val.concat()
      let indexTask
      let indexListTasks = listTasks.findIndex(tasks => {
        if (tasks.taskId.includes(task.id)) indexTask = tasks.taskId.findIndex(targetTask => targetTask === task.id)
        return tasks.taskId.includes(task.id)
      })
      if (indexListTasks >= 0) {
        listTasks[indexListTasks].taskId.splice(indexTask, 1)
        if (!listTasks[indexListTasks].taskId.length) {
          listTasks.splice(indexListTasks, 1)
        }
      }
      if (!listTasks.length) isEmptyList = true
      return listTasks
    })
    if (isEmptyList) del(storageField)
  }

  const handleMoreLinkClick = () => {
    if (props.disabled) return
    dispatch(setSelectedPublicTaskForDetails(task))
  }

  const handleErrorImg = () => {
    setIconUrl(DEFAULT_ICON)
  }

  const getBottomCard = () => {
    const bonus = marathonPrice
    const count = task.days_count
    const total = bonus * count

    if (task.status.name !== 'completed' || appDeleted || !isPublishTask) return null

    const receivingStoppedClass: string = appUnpublished ? 'receiving-stopped' : ''
    const stopMarathon = marathonStatuses.find(status => status.id === task.application.marathon?.id)

    if (stopMarathon) {
      return (
        <div className="task-card__bottom-marathon">
          <Tooltip title={t(`Marathon ${stopMarathon.name.replace(/_/g, ' ')}`)} overlayClassName="reason-stop-marathon" color="white">
            <div className={'task-card__bottom-marathon-icon'}>
              <ExclamationCircleOutlined />
            </div>
          </Tooltip>
          <div className={'task-card__bottom-marathon-info'}>
            <p>{t('Marathon stop')}</p>
          </div>
        </div>
      )
    }

    return (
      <div className={`task-card__bottom-bonus ${receivingStoppedClass}`}>
        <div className={'task-card__bottom-bonus-count'}>
          <p>{count}<br/>{t('days')}</p>
          <b>{'+ ' + getAmountWithSign(total)}</b>
        </div>
        <div className={'task-card__bottom-bonus-daily'}>
          <p>{t('Daily bonus')}<br/><b>{'+ ' + getAmountWithSign(bonus)}</b></p>
        </div>
      </div>
    )
  }

  const getDeletionReasonBlock = () => {
    const data = deletedApplicationData
    const filesFlag = !!data?.deletionReason?.files?.length ?? false
    const status = data?.deletionReason?.status ?? false
    if ([
      !data,
      filesFlag === undefined,
      status === undefined
    ].some(item => item)) return null
    const getTextBlock = () => {
      switch (true) {
        case !filesFlag && !status:
          return (
            <div className="deletion-reason__text">
              <div className="deletion-reason__text-warning">
                {`${data!.application.default_locale.store_name} ${t('deletedAppCard No images text part 1')}`}
              </div>
              <div className="deletion-reason__text-regular">
                {t('deletedAppCard No images text part 2', {paymentAmount})}
              </div>
            </div>
          )
        case filesFlag && !status:
          return (
            <div className="deletion-reason__text">
              <div className="deletion-reason__text-warning">
                <p>{data!.application.default_locale.store_name}</p>
                <p>{t('deletedAppCard Checking images text')}</p>
              </div>
            </div>
          )
        case filesFlag && status:
          return (
            <div className="deletion-reason__text">
              <div className="deletion-reason__text-warning">
                {t('deletedAppCard Checked images text', {paymentAmount})}
              </div>
            </div>
          )
      }
    }
    return <div>
      {paymentAmount && getTextBlock()}
      { !status && <SubmitButton
        isDisabled={props.disabled}
        text={t('Load reason for deletion')}
        onClick={openModalForUploadingReason}
      /> }
    </div>
  }

  const openModalForUploadingReason = useCallback(() => {
    if (!userId || !popupType || props.disabled) return
    dispatch(setSelectedDeletedApp(deletedApplicationData))
    setShowUploadingReasonModal(true)
    if (!deletedApplicationChecked) {
      setShowPopup(false)
      taskPopupCloseHandler(userId, task.id, popupType)
    }
  }, [popupType, deletedApplicationData])

  const popupCloseHandler = useCallback(() => {
    setShowPopup(false)
  }, [])

  const definePopupType = () => {
    const taskCompletedAppActive = statusName === 'completed' && !appUnpublished
    const popupCanBeShown = [
      isPublishTask,
      taskCompletedAppActive,
      props.isExpired
    ].some(item => item)
    if (!userId || !popupCanBeShown) {
      setPopupType(undefined)
      return
    }

    const taskStatus = props.isExpired ? 'expired' : statusName

    const getTypeForFailed = () => PopupType.FAILURE
    const getTypeForFailedPreModeration = () => PopupType.FAILURE_MODERATION
    const getTypeForFailedPostModeration = () => deletedApplicationChecked ? PopupType.UPLOADED_REASON_CHECKED : PopupType.FAILURE_POST_MODERATION
    const getTypeForCompleted = () => {
      if (appUnpublished) {
        return deletedApplicationChecked ? PopupType.UPLOADED_REASON_CHECKED : PopupType.APP_UNPUBLISHED_AFTER_MARATHON
      }
      return PopupType.TASK_COMPLETED_SUCCESSFULLY
    }
    const typeGetter = {
      expired: getTypeForFailed,
      failed: getTypeForFailed,
      failed_assigned: getTypeForFailed,
      failed_pre_moderation: getTypeForFailedPreModeration,
      failed_post_moderation: getTypeForFailedPostModeration,
      completed: getTypeForCompleted
    }[taskStatus]

    if (!typeGetter) return

    const result = task['deletion_reason'] && taskStatus === 'failed_post_moderation'
      ? PopupType.UPLOADED_REASON_CHECKED
      : typeGetter()
    setPopupType(result)
  }

  const getModalForUploadingReason = (): ReactNode => (
    <InfoModal
      showModal={showUploadingReasonModal}
      bodyBackground="transparent"
      maskBackground="rgba(0, 0, 0, 0.8)"
      wrapClassName="upload-reason-modal custom-scroll"
      width="auto">
      <div>
        <IconClear
          className="modal-close"
          fill="#dfdefd"
          onClick={() => setShowUploadingReasonModal(false)}/>
        <UploadReasonGuide/>
      </div>
    </InfoModal>
  )

  const getPopupContainer = useCallback(trigger => trigger.parentElement, [])

  const getShortDescription = (): string => {
    if (isVerificationTask) return t(
      statusName === 'verified'
        ? 'Verification confirmed task short description'
        : 'Verification task short description'
    )
    return DEFAULT_SHORT_DESCRIPTION
  }

  const getCardClass = () => {
    const list = ['card-box', 'task-card', 'task-preview-card']
    if (isCompleted) list.push('completed-task')
    if (appDeleted) list.push('deleted-app')
    if (isVerificationTask) list.push('verification-task')
    if (props.disabled) list.push('disabled-task')
    return list.join(' ')
  }

  return (
    <Popconfirm
      autoAdjustOverflow={false}
      placement="top"
      title={popupType && (
        <TaskCardPopup
          taskId={task.id}
          popupType={popupType}
          openModalForUploadingReason={openModalForUploadingReason}
          popupCloseHandler={popupCloseHandler}
        />
      )}
      zIndex={1000}
      open={showPopup}
      getPopupContainer={getPopupContainer}
      icon={''}>
      <div
        className={getCardClass()}>
        <div className="task-card__top">
          <div className="task-card__status-block">
            {props.statusBlock}
          </div>
          {props.cost && (
            <div className="task-card__price">
              <H3 text={'+ ' + getAmountWithSign(props.cost)}/>
            </div>
          )}
        </div>
        <div className="task-card__bottom-action">
          {showIcon && (
            <div className="task-card__icon-box">
              <div className="task-card__icon-wrap">
                <img src={iconUrl} alt="" onError={handleErrorImg}/>
              </div>
            </div>
          )}
          <h5>{props.cardTitle}</h5>
          <p>{getShortDescription()}</p>
          {!hideButton && (
            <div className="task-card__details">
              <div onClick={handleMoreLinkClick} className="task-card__details-link">
                {t(isVerificationTask ? 'Verification details' : 'Task details')}
              </div>
            </div>
          )}
          {!hideButton && props.btnBlock}
          {(appDeleted && isPublishTask) && (
            <div className="task-card__deletion-reason">
              {getDeletionReasonBlock()}
            </div>
          )}
        </div>
        {getBottomCard()}
      </div>
      {getModalForUploadingReason()}
    </Popconfirm>
  )
}
