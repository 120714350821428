import React from "react";
import Paragraph, {Align, TextSize} from "../../common_kit/text/Paragraph";
import {useTranslation} from "react-i18next";


export default function CleanPayoutsStory() {

  const {t} = useTranslation()

  return <div className='clean-story'>
    <svg width="667" height="430" viewBox="0 0 667 430" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M118.586 403.226C93.124 403.226 55.2085 380.619 55.2085 337.731C55.2085 316.644 70.4511 291.04 113.323 276.08C145.834 264.731 97.4078 249.156 97.4078 192.553C97.4078 162.267 128.233 133.268 207.9 133.268C236.5 133.268 248.58 153.127 328.661 128.71C373.675 114.979 375.336 103.655 422.026 103.655C462.216 103.655 510.775 123.181 500.389 172.263C490.169 220.622 503.411 236.089 516.67 249.92C525.129 258.745 537.973 261.468 562.696 252.411C581.658 245.479 618.428 254.378 587.005 316.287C555.581 378.195 634.326 403.209 634.326 403.209L118.586 403.226Z"
        fill="#E3F0FF"/>
      <path
        d="M460.979 177.07C460.979 177.07 459.053 185.654 439.876 188.875C420.698 192.097 447.04 213.474 439.477 218.48C431.914 223.487 426.742 207.746 427.962 199.294C427.962 199.294 413.973 197.725 418.747 208.601C410.553 210.261 404.633 226.625 416.705 235.666C429.05 244.881 426.733 248.285 425.646 248.949C419.536 252.56 408.403 239.294 405.621 227.87C405.621 227.87 396.414 235.691 402.301 245.695C398.673 253.258 406.078 269.173 432.836 276.736C462.175 285.038 469.555 272.535 471.656 270.393C479.601 266.035 486.251 261.062 478.605 248.152C474.18 257.948 465.878 264.69 460.73 260.605C458.829 259.086 460.166 255.325 462.59 254.902C466.741 254.188 475.251 258.513 481.469 252.726C487.687 246.94 485.686 236.67 485.28 234.537C484.599 240.307 479.02 246.085 471.839 248.177C461.411 251.224 448.593 248.501 450.726 243.27C452.287 239.427 460.971 245.313 470.245 244.101C478.347 243.055 483.943 236.903 484.906 228.003C485.479 222.714 483.345 216.505 483.345 216.505C483.345 216.505 476.031 236.064 459.801 231.855C456.173 230.917 455.65 226.924 463.586 226.616C467.53 226.459 472.644 223.918 475.209 221.295C481.959 214.305 478.356 204.782 478.015 203.944C475.923 207.962 465.247 220.93 461.901 216.33C458.555 211.731 476.745 209.506 472.76 199.062C471.307 195.243 467.912 193.749 465.571 191.3C461.519 187.082 460.465 184.351 460.979 177.07Z"
        fill="#4792F3"/>
      <path
        d="M448.178 198.854C448.692 202.084 447.588 204.94 445.687 205.239C443.786 205.537 441.876 203.163 441.37 199.942C440.864 196.721 441.951 193.856 443.861 193.558C445.77 193.259 447.688 195.633 448.178 198.854Z"
        fill="white"/>
      <path
        d="M421.171 223.03C421.786 224.69 421.171 226.409 419.801 226.924C418.432 227.438 416.821 226.533 416.207 224.898C415.592 223.262 416.207 221.519 417.576 221.004C418.946 220.49 420.557 221.394 421.171 223.03Z"
        fill="white"/>
      <path
        d="M416.016 249.588C418.506 252.137 419.336 255.4 417.825 256.811C416.315 258.222 413.135 257.359 410.653 254.81C408.17 252.261 407.332 248.999 408.843 247.587C410.354 246.176 413.533 247.039 416.016 249.588Z"
        fill="white"/>
      <path
        d="M452.686 234.669C454.404 234.827 455.716 235.765 455.625 236.762C455.533 237.758 454.064 238.422 452.304 238.281C450.544 238.14 449.274 237.177 449.373 236.18C449.473 235.184 450.976 234.503 452.686 234.669Z"
        fill="white"/>
      <path
        d="M428.07 287.969L426.783 286.923C426.783 286.873 431.134 281.252 438.248 256.072C439.834 250.443 441.378 243.802 443.014 236.762C446.268 222.706 449.963 206.783 455.807 190.486L457.368 191.051C451.557 207.248 447.879 223.13 444.624 237.135C442.964 244.192 441.445 250.859 439.843 256.521C432.587 282.224 428.253 287.745 428.07 287.969Z"
        fill="white"/>
      <path
        d="M448.634 220.365L447.024 219.975C449.381 210.278 457.202 204.525 459.535 202.989L460.448 204.384C458.24 205.828 450.851 211.258 448.634 220.365Z"
        fill="white"/>
      <path
        d="M441.694 248.982L440.083 248.592C442.773 237.476 436.762 232.818 430.345 227.953C426.103 224.707 421.719 221.311 420.117 215.982L421.702 215.508C423.155 220.348 427.14 223.404 431.358 226.633C437.883 231.639 444.641 236.82 441.694 248.982Z"
        fill="white"/>
      <path
        d="M434.745 272.867L433.217 272.236C436.306 264.764 440.241 257.716 454.205 253.382L454.695 254.968C441.42 259.086 437.684 265.786 434.745 272.867Z"
        fill="white"/>
      <path
        d="M318.475 0.867127C331.509 4.61965 362.077 39.8287 355.469 61.6713C346.254 92.1399 294.963 111.99 271.875 93.2938C257.546 81.6876 281.381 -9.82591 318.475 0.867127Z"
        fill="#E3F0FF"/>
      <path
        d="M44.6982 277.475C55.9391 294.245 92.2938 257.334 81.2272 242.988C70.1605 228.642 30.3024 255.989 44.6982 277.475Z"
        fill="#E3F0FF"/>
      <path
        d="M577.989 293.141C580.222 295.947 582.015 313.331 564.523 317.897C542.771 323.576 527.371 295.482 523.17 306.333C518.969 317.183 553.888 328.798 564.822 322.107C565.876 328.333 558.703 338.08 551.879 338.47C537.35 339.3 524.731 322.787 521.717 329.446C517.566 338.578 542.771 345.402 551.057 341.5C548.79 346.764 536.354 358.279 520.406 353.754C511.655 358.461 500.481 355.539 489.763 348.582C484.215 344.911 479.109 340.612 474.545 335.772C469.414 330.441 465.589 323.993 463.369 316.935C461.149 309.876 460.596 302.4 461.752 295.092C457.551 283.859 460.598 273.822 466.276 266.433C471.075 260.181 477.758 255.831 483.528 254.262C487.297 242.39 503.32 241.261 505.205 241.527C487.455 261.169 503.129 294.278 512.975 287.553C519.077 283.402 505.437 276.429 503.453 258.206C500.78 233.623 529.521 233.299 529.521 233.299C517.799 243.162 517.641 274.278 526.018 274.768C537.408 275.416 523.311 245.91 536.661 233.158C550.011 220.406 579.242 238.14 579.242 238.14C566.856 248.783 583.327 258.895 579.633 267.745C574.303 280.555 554.162 272.493 555.507 280.106C556.852 287.719 576.461 281.061 581.309 274.162C581.799 276.47 581.451 282.722 574.46 288.757C560.911 300.463 540.023 283.859 538.28 294.245C536.869 302.63 568.308 305.552 577.989 293.141Z"
        fill="#6AACFF"/>
      <path
        d="M543.602 274.494C545.91 274.544 547.753 275.673 547.753 276.985C547.753 278.297 545.818 279.384 543.502 279.326C541.186 279.268 539.351 278.147 539.351 276.836C539.351 275.524 541.285 274.436 543.602 274.494Z"
        fill="white"/>
      <path
        d="M544.34 246.5C543.336 249.937 540.903 252.245 538.911 251.664C536.918 251.083 536.105 247.82 537.109 244.383C538.114 240.946 540.546 238.629 542.547 239.211C544.548 239.792 545.312 243.063 544.34 246.5Z"
        fill="white"/>
      <path
        d="M490.858 288.583C490.703 289.013 490.428 289.389 490.066 289.668C489.705 289.946 489.271 290.116 488.816 290.157C488.361 290.197 487.904 290.107 487.498 289.897C487.093 289.687 486.756 289.365 486.526 288.97C486.297 288.575 486.186 288.123 486.205 287.666C486.224 287.21 486.373 286.769 486.635 286.394C486.896 286.019 487.259 285.727 487.681 285.552C488.102 285.376 488.565 285.325 489.015 285.404C489.678 285.586 490.242 286.022 490.587 286.617C490.931 287.211 491.029 287.917 490.858 288.583Z"
        fill="white"/>
      <path
        d="M511.963 324.456C514.835 325.585 516.645 327.86 515.989 329.529C515.333 331.197 512.469 331.646 509.588 330.517C506.707 329.388 504.906 327.121 505.562 325.444C506.217 323.767 509.082 323.335 511.963 324.456Z"
        fill="white"/>
      <path
        d="M489.763 348.574C484.215 344.902 479.109 340.604 474.545 335.764C469.414 330.432 465.589 323.985 463.369 316.927C461.149 309.868 460.596 302.392 461.752 295.083C457.551 283.851 460.598 273.814 466.276 266.425C466.633 266.765 466.974 267.106 467.322 267.454C477.746 277.889 485.291 290.844 489.223 305.059C493.156 319.274 493.342 334.265 489.763 348.574Z"
        fill="#3F84DE"/>
      <path
        d="M486.749 276.205C486.093 281.186 483.196 284.988 480.282 284.615C477.368 284.241 475.525 279.849 476.181 274.835C476.836 269.821 479.726 266.043 482.64 266.425C485.554 266.807 487.397 271.182 486.749 276.205Z"
        fill="white"/>
      <path
        d="M462.125 350.002L461.187 348.632C483.038 333.688 513.116 308.475 525.993 286.366C549.562 245.886 570.6 240.116 571.48 239.875L571.895 241.486C571.679 241.544 550.6 247.397 527.429 287.147C510.651 316.013 470.087 344.572 462.125 350.002Z"
        fill="#4792F3"/>
      <path
        d="M522.473 294.785L521.07 293.896C528.982 281.402 538.255 283.477 545.702 285.138C547.607 285.629 549.552 285.954 551.513 286.109L551.455 287.769C549.384 287.617 547.331 287.278 545.32 286.757C538.031 285.129 529.754 283.278 522.473 294.785Z"
        fill="#4792F3"/>
      <path
        d="M489.755 328.615L488.7 327.337C504.881 313.995 500.597 300.953 496.811 289.438C495.574 285.652 494.395 282.074 494.038 278.72L495.699 278.546C496.047 281.725 497.193 285.187 498.405 288.923C502.141 300.339 506.807 314.552 489.755 328.615Z"
        fill="#4792F3"/>
      <path
        d="M536.18 273.589L534.826 272.593C543.809 260.97 559.541 258.28 569.778 261.61L569.263 263.187C559.425 259.966 544.349 262.996 536.18 273.589Z"
        fill="#4792F3"/>
      <path
        d="M515.881 340.014C512.447 338.672 509.083 337.156 505.802 335.473C497.5 331.38 490.925 328.142 485.379 332.75L484.317 331.471C490.693 326.175 498.032 329.811 506.533 333.962C509.775 335.62 513.1 337.111 516.495 338.428L515.881 340.014Z"
        fill="#4792F3"/>
      <path d="M352.771 388.158H232.192V403.234H352.771V388.158Z" fill="#FAB4A8"/>
      <path d="M352.771 388.158H322.626V403.234H352.771V388.158Z" fill="#F5A098"/>
      <path d="M292.481 388.158H238.219V403.234H292.481V388.158Z" fill="#FFD4CC"/>
      <path d="M238.219 388.158H232.192V403.234H238.219V388.158Z" fill="#FAB4A8"/>
      <path d="M250.274 388.158H244.246V403.234H250.274V388.158Z" fill="#FAB4A8"/>
      <path d="M262.336 388.158H256.309V403.234H262.336V388.158Z" fill="#F5A098"/>
      <path d="M274.391 388.158H268.364V403.234H274.391V388.158Z" fill="#F5A098"/>
      <path d="M286.454 388.158H280.427V403.234H286.454V388.158Z" fill="#F5A098"/>
      <path d="M298.509 388.158H292.481V403.234H298.509V388.158Z" fill="#F5A098"/>
      <path d="M310.563 388.158H304.536V403.234H310.563V388.158Z" fill="#E88983"/>
      <path d="M322.626 388.158H316.599V403.234H322.626V388.158Z" fill="#E88983"/>
      <path d="M334.681 388.158H328.653V403.234H334.681V388.158Z" fill="#E88983"/>
      <path d="M346.743 388.158H340.716V403.234H346.743V388.158Z" fill="#DB7071"/>
      <path d="M358.798 388.158H352.771V403.234H358.798V388.158Z" fill="#DB7071"/>
      <path d="M358.798 327.868H238.219V342.945H358.798V327.868Z" fill="#FAB4A8"/>
      <path d="M358.798 327.868H328.653V342.945H358.798V327.868Z" fill="#F5A098"/>
      <path d="M298.508 327.868H244.246V342.945H298.508V327.868Z" fill="#FFD4CC"/>
      <path d="M244.246 327.868H238.219V342.945H244.246V327.868Z" fill="#FAB4A8"/>
      <path d="M256.309 327.868H250.282V342.945H256.309V327.868Z" fill="#FAB4A8"/>
      <path d="M268.364 327.868H262.336V342.945H268.364V327.868Z" fill="#F5A098"/>
      <path d="M280.418 327.868H274.391V342.945H280.418V327.868Z" fill="#F5A098"/>
      <path d="M292.481 327.868H286.454V342.945H292.481V327.868Z" fill="#F5A098"/>
      <path d="M304.536 327.868H298.509V342.945H304.536V327.868Z" fill="#F5A098"/>
      <path d="M316.599 327.868H310.571V342.945H316.599V327.868Z" fill="#E88983"/>
      <path d="M328.653 327.868H322.626V342.945H328.653V327.868Z" fill="#E88983"/>
      <path d="M340.708 327.868H334.68V342.945H340.708V327.868Z" fill="#E88983"/>
      <path d="M352.771 327.868H346.743V342.945H352.771V327.868Z" fill="#DB7071"/>
      <path d="M364.825 327.868H358.798V342.945H364.825V327.868Z" fill="#DB7071"/>
      <path d="M358.798 373.081H238.219V388.158H358.798V373.081Z" fill="#FAB4A8"/>
      <path d="M358.798 373.081H328.653V388.158H358.798V373.081Z" fill="#F5A098"/>
      <path d="M298.508 373.081H244.246V388.158H298.508V373.081Z" fill="#FFD4CC"/>
      <path d="M244.246 373.081H238.219V388.158H244.246V373.081Z" fill="#FAB4A8"/>
      <path d="M256.309 373.081H250.282V388.158H256.309V373.081Z" fill="#FAB4A8"/>
      <path d="M268.364 373.081H262.336V388.158H268.364V373.081Z" fill="#F5A098"/>
      <path d="M280.418 373.081H274.391V388.158H280.418V373.081Z" fill="#F5A098"/>
      <path d="M292.481 373.081H286.454V388.158H292.481V373.081Z" fill="#F5A098"/>
      <path d="M304.536 373.081H298.509V388.158H304.536V373.081Z" fill="#F5A098"/>
      <path d="M316.599 373.081H310.571V388.158H316.599V373.081Z" fill="#E88983"/>
      <path d="M328.653 373.081H322.626V388.158H328.653V373.081Z" fill="#E88983"/>
      <path d="M340.708 373.081H334.68V388.158H340.708V373.081Z" fill="#E88983"/>
      <path d="M352.771 373.081H346.743V388.158H352.771V373.081Z" fill="#DB7071"/>
      <path d="M364.825 373.081H358.798V388.158H364.825V373.081Z" fill="#DB7071"/>
      <path d="M358.798 358.013H238.219V373.089H358.798V358.013Z" fill="#FAB4A8"/>
      <path d="M358.798 358.013H328.653V373.089H358.798V358.013Z" fill="#F5A098"/>
      <path d="M298.508 358.013H244.246V373.089H298.508V358.013Z" fill="#FFD4CC"/>
      <path d="M244.246 358.013H238.219V373.089H244.246V358.013Z" fill="#FAB4A8"/>
      <path d="M256.309 358.013H250.282V373.089H256.309V358.013Z" fill="#FAB4A8"/>
      <path d="M268.364 358.013H262.336V373.089H268.364V358.013Z" fill="#F5A098"/>
      <path d="M280.418 358.013H274.391V373.089H280.418V358.013Z" fill="#F5A098"/>
      <path d="M292.481 358.013H286.454V373.089H292.481V358.013Z" fill="#F5A098"/>
      <path d="M304.536 358.013H298.509V373.089H304.536V358.013Z" fill="#F5A098"/>
      <path d="M316.599 358.013H310.571V373.089H316.599V358.013Z" fill="#E88983"/>
      <path d="M328.653 358.013H322.626V373.089H328.653V358.013Z" fill="#E88983"/>
      <path d="M340.708 358.013H334.68V373.089H340.708V358.013Z" fill="#E88983"/>
      <path d="M352.771 358.013H346.743V373.089H352.771V358.013Z" fill="#DB7071"/>
      <path d="M364.825 358.013H358.798V373.089H364.825V358.013Z" fill="#DB7071"/>
      <path d="M352.762 312.792H232.183V327.868H352.762V312.792Z" fill="#FAB4A8"/>
      <path d="M352.762 312.792H322.618V327.868H352.762V312.792Z" fill="#F5A098"/>
      <path d="M292.473 312.792H238.211V327.868H292.473V312.792Z" fill="#FFD4CC"/>
      <path d="M238.211 312.792H232.183V327.868H238.211V312.792Z" fill="#FAB4A8"/>
      <path d="M250.265 312.792H244.238V327.868H250.265V312.792Z" fill="#FAB4A8"/>
      <path d="M262.328 312.792H256.301V327.868H262.328V312.792Z" fill="#F5A098"/>
      <path d="M274.383 312.792H268.355V327.868H274.383V312.792Z" fill="#F5A098"/>
      <path d="M286.446 312.792H280.418V327.868H286.446V312.792Z" fill="#F5A098"/>
      <path d="M298.5 312.792H292.473V327.868H298.5V312.792Z" fill="#F5A098"/>
      <path d="M310.555 312.792H304.528V327.868H310.555V312.792Z" fill="#E88983"/>
      <path d="M322.618 312.792H316.59V327.868H322.618V312.792Z" fill="#E88983"/>
      <path d="M334.672 312.792H328.645V327.868H334.672V312.792Z" fill="#E88983"/>
      <path d="M346.735 312.792H340.708V327.868H346.735V312.792Z" fill="#DB7071"/>
      <path d="M358.79 312.792H352.762V327.868H358.79V312.792Z" fill="#DB7071"/>
      <path d="M352.762 297.715H232.183V312.792H352.762V297.715Z" fill="#FAB4A8"/>
      <path d="M352.762 297.715H322.618V312.792H352.762V297.715Z" fill="#F5A098"/>
      <path d="M292.473 297.715H238.211V312.792H292.473V297.715Z" fill="#FFD4CC"/>
      <path d="M238.211 297.715H232.183V312.792H238.211V297.715Z" fill="#FAB4A8"/>
      <path d="M250.265 297.715H244.238V312.792H250.265V297.715Z" fill="#FAB4A8"/>
      <path d="M262.328 297.715H256.301V312.792H262.328V297.715Z" fill="#F5A098"/>
      <path d="M274.383 297.715H268.355V312.792H274.383V297.715Z" fill="#F5A098"/>
      <path d="M286.446 297.715H280.418V312.792H286.446V297.715Z" fill="#F5A098"/>
      <path d="M298.5 297.715H292.473V312.792H298.5V297.715Z" fill="#F5A098"/>
      <path d="M310.555 297.715H304.528V312.792H310.555V297.715Z" fill="#E88983"/>
      <path d="M322.618 297.715H316.59V312.792H322.618V297.715Z" fill="#E88983"/>
      <path d="M334.672 297.715H328.645V312.792H334.672V297.715Z" fill="#E88983"/>
      <path d="M346.735 297.715H340.708V312.792H346.735V297.715Z" fill="#DB7071"/>
      <path d="M358.79 297.715H352.762V312.792H358.79V297.715Z" fill="#DB7071"/>
      <path d="M352.762 342.937H232.183V358.013H352.762V342.937Z" fill="#FAB4A8"/>
      <path d="M352.762 342.937H322.618V358.013H352.762V342.937Z" fill="#F5A098"/>
      <path d="M292.473 342.937H238.211V358.013H292.473V342.937Z" fill="#FFD4CC"/>
      <path d="M238.211 342.937H232.183V358.013H238.211V342.937Z" fill="#FAB4A8"/>
      <path d="M250.265 342.937H244.238V358.013H250.265V342.937Z" fill="#FAB4A8"/>
      <path d="M262.328 342.937H256.301V358.013H262.328V342.937Z" fill="#F5A098"/>
      <path d="M274.383 342.937H268.355V358.013H274.383V342.937Z" fill="#F5A098"/>
      <path d="M286.446 342.937H280.418V358.013H286.446V342.937Z" fill="#F5A098"/>
      <path d="M298.5 342.937H292.473V358.013H298.5V342.937Z" fill="#F5A098"/>
      <path d="M310.555 342.937H304.528V358.013H310.555V342.937Z" fill="#E88983"/>
      <path d="M322.618 342.937H316.59V358.013H322.618V342.937Z" fill="#E88983"/>
      <path d="M334.672 342.937H328.645V358.013H334.672V342.937Z" fill="#E88983"/>
      <path d="M346.735 342.937H340.708V358.013H346.735V342.937Z" fill="#DB7071"/>
      <path d="M358.79 342.937H352.762V358.013H358.79V342.937Z" fill="#DB7071"/>
      <path d="M364.825 387.328H238.219V388.988H364.825V387.328Z" fill="#DB7071"/>
      <path d="M364.825 372.251H238.219V373.911H364.825V372.251Z" fill="#DB7071"/>
      <path d="M358.79 357.183H232.183V358.843H358.79V357.183Z" fill="#DB7071"/>
      <path d="M364.817 342.106H238.219V343.767H364.817V342.106Z" fill="#DB7071"/>
      <path d="M364.817 327.038H232.183V328.698H364.817V327.038Z" fill="#DB7071"/>
      <path d="M358.781 311.962H232.183V313.622H358.781V311.962Z" fill="#DB7071"/>
      <path d="M358.806 282.988H238.227V298.064H358.806V282.988Z" fill="#FAB4A8"/>
      <path d="M358.806 282.988H328.661V298.064H358.806V282.988Z" fill="#F5A098"/>
      <path d="M298.517 282.988H244.255V298.064H298.517V282.988Z" fill="#FFD4CC"/>
      <path d="M244.255 282.988H238.227V298.064H244.255V282.988Z" fill="#FAB4A8"/>
      <path d="M256.309 282.988H250.282V298.064H256.309V282.988Z" fill="#FAB4A8"/>
      <path d="M268.364 282.988H262.336V298.064H268.364V282.988Z" fill="#F5A098"/>
      <path d="M280.427 282.988H274.399V298.064H280.427V282.988Z" fill="#F5A098"/>
      <path d="M292.481 282.988H286.454V298.064H292.481V282.988Z" fill="#F5A098"/>
      <path d="M304.544 282.988H298.517V298.064H304.544V282.988Z" fill="#F5A098"/>
      <path d="M316.599 282.988H310.571V298.064H316.599V282.988Z" fill="#E88983"/>
      <path d="M328.653 282.988H322.626V298.064H328.653V282.988Z" fill="#E88983"/>
      <path d="M340.716 282.988H334.689V298.064H340.716V282.988Z" fill="#E88983"/>
      <path d="M352.771 282.988H346.743V298.064H352.771V282.988Z" fill="#DB7071"/>
      <path d="M364.834 282.988H358.806V298.064H364.834V282.988Z" fill="#DB7071"/>
      <path d="M358.798 222.698H238.219V237.775H358.798V222.698Z" fill="#FAB4A8"/>
      <path d="M358.798 222.698H328.653V237.775H358.798V222.698Z" fill="#F5A098"/>
      <path d="M298.517 222.698H244.255V237.775H298.517V222.698Z" fill="#FFD4CC"/>
      <path d="M244.246 222.698H238.219V237.775H244.246V222.698Z" fill="#FAB4A8"/>
      <path d="M256.309 222.698H250.282V237.775H256.309V222.698Z" fill="#FAB4A8"/>
      <path d="M268.364 222.698H262.336V237.775H268.364V222.698Z" fill="#F5A098"/>
      <path d="M280.427 222.698H274.399V237.775H280.427V222.698Z" fill="#F5A098"/>
      <path d="M292.481 222.698H286.454V237.775H292.481V222.698Z" fill="#F5A098"/>
      <path d="M304.544 222.698H298.517V237.775H304.544V222.698Z" fill="#F5A098"/>
      <path d="M316.599 222.698H310.571V237.775H316.599V222.698Z" fill="#E88983"/>
      <path d="M328.653 222.698H322.626V237.775H328.653V222.698Z" fill="#E88983"/>
      <path d="M340.716 222.698H334.689V237.775H340.716V222.698Z" fill="#E88983"/>
      <path d="M352.771 222.698H346.743V237.775H352.771V222.698Z" fill="#DB7071"/>
      <path d="M364.825 222.698H358.798V237.775H364.825V222.698Z" fill="#DB7071"/>
      <path d="M364.834 267.911H244.255V282.987H364.834V267.911Z" fill="#FAB4A8"/>
      <path d="M364.833 267.911H334.689V282.987H364.833V267.911Z" fill="#F5A098"/>
      <path d="M304.544 267.911H250.282V282.987H304.544V267.911Z" fill="#FFD4CC"/>
      <path d="M250.282 267.911H244.255V282.987H250.282V267.911Z" fill="#FAB4A8"/>
      <path d="M262.336 267.911H256.309V282.987H262.336V267.911Z" fill="#FAB4A8"/>
      <path d="M274.399 267.911H268.372V282.987H274.399V267.911Z" fill="#F5A098"/>
      <path d="M286.454 267.911H280.427V282.987H286.454V267.911Z" fill="#F5A098"/>
      <path d="M298.509 267.911H292.481V282.987H298.509V267.911Z" fill="#F5A098"/>
      <path d="M310.571 267.911H304.544V282.987H310.571V267.911Z" fill="#F5A098"/>
      <path d="M322.626 267.911H316.599V282.987H322.626V267.911Z" fill="#E88983"/>
      <path d="M334.689 267.911H328.661V282.987H334.689V267.911Z" fill="#E88983"/>
      <path d="M346.743 267.911H340.716V282.987H346.743V267.911Z" fill="#E88983"/>
      <path d="M358.798 267.911H352.771V282.987H358.798V267.911Z" fill="#DB7071"/>
      <path d="M370.861 267.911H364.834V282.987H370.861V267.911Z" fill="#DB7071"/>
      <path d="M358.798 252.843H238.219V267.919H358.798V252.843Z" fill="#FAB4A8"/>
      <path d="M358.798 252.843H328.653V267.919H358.798V252.843Z" fill="#F5A098"/>
      <path d="M298.517 252.843H244.255V267.919H298.517V252.843Z" fill="#FFD4CC"/>
      <path d="M244.246 252.843H238.219V267.919H244.246V252.843Z" fill="#FAB4A8"/>
      <path d="M256.309 252.843H250.282V267.919H256.309V252.843Z" fill="#FAB4A8"/>
      <path d="M268.364 252.843H262.336V267.919H268.364V252.843Z" fill="#F5A098"/>
      <path d="M280.427 252.843H274.399V267.919H280.427V252.843Z" fill="#F5A098"/>
      <path d="M292.481 252.843H286.454V267.919H292.481V252.843Z" fill="#F5A098"/>
      <path d="M304.544 252.843H298.517V267.919H304.544V252.843Z" fill="#F5A098"/>
      <path d="M316.599 252.843H310.571V267.919H316.599V252.843Z" fill="#E88983"/>
      <path d="M328.653 252.843H322.626V267.919H328.653V252.843Z" fill="#E88983"/>
      <path d="M340.716 252.843H334.689V267.919H340.716V252.843Z" fill="#E88983"/>
      <path d="M352.771 252.843H346.743V267.919H352.771V252.843Z" fill="#DB7071"/>
      <path d="M364.825 252.843H358.798V267.919H364.825V252.843Z" fill="#DB7071"/>
      <path d="M352.762 207.622H232.183V222.698H352.762V207.622Z" fill="#FAB4A8"/>
      <path d="M352.762 207.622H322.618V222.698H352.762V207.622Z" fill="#F5A098"/>
      <path d="M292.481 207.622H238.219V222.698H292.481V207.622Z" fill="#FFD4CC"/>
      <path d="M238.211 207.622H232.183V222.698H238.211V207.622Z" fill="#FAB4A8"/>
      <path d="M250.274 207.622H244.246V222.698H250.274V207.622Z" fill="#FAB4A8"/>
      <path d="M262.328 207.622H256.301V222.698H262.328V207.622Z" fill="#F5A098"/>
      <path d="M274.391 207.622H268.364V222.698H274.391V207.622Z" fill="#F5A098"/>
      <path d="M286.446 207.622H280.418V222.698H286.446V207.622Z" fill="#F5A098"/>
      <path d="M298.5 207.622H292.473V222.698H298.5V207.622Z" fill="#F5A098"/>
      <path d="M310.563 207.622H304.536V222.698H310.563V207.622Z" fill="#E88983"/>
      <path d="M322.618 207.622H316.59V222.698H322.618V207.622Z" fill="#E88983"/>
      <path d="M334.681 207.622H328.653V222.698H334.681V207.622Z" fill="#E88983"/>
      <path d="M346.735 207.622H340.708V222.698H346.735V207.622Z" fill="#DB7071"/>
      <path d="M358.79 207.622H352.762V222.698H358.79V207.622Z" fill="#DB7071"/>
      <path d="M364.825 192.553H244.246V207.63H364.825V192.553Z" fill="#FAB4A8"/>
      <path d="M364.825 192.553H334.68V207.63H364.825V192.553Z" fill="#F5A098"/>
      <path d="M304.536 192.553H250.274V207.63H304.536V192.553Z" fill="#FFD4CC"/>
      <path d="M250.274 192.553H244.246V207.63H250.274V192.553Z" fill="#FAB4A8"/>
      <path d="M262.328 192.553H256.301V207.63H262.328V192.553Z" fill="#FAB4A8"/>
      <path d="M274.391 192.553H268.364V207.63H274.391V192.553Z" fill="#F5A098"/>
      <path d="M286.446 192.553H280.418V207.63H286.446V192.553Z" fill="#F5A098"/>
      <path d="M298.5 192.553H292.473V207.63H298.5V192.553Z" fill="#F5A098"/>
      <path d="M310.563 192.553H304.536V207.63H310.563V192.553Z" fill="#F5A098"/>
      <path d="M322.618 192.553H316.59V207.63H322.618V192.553Z" fill="#E88983"/>
      <path d="M334.681 192.553H328.653V207.63H334.681V192.553Z" fill="#E88983"/>
      <path d="M346.735 192.553H340.708V207.63H346.735V192.553Z" fill="#E88983"/>
      <path d="M358.79 192.553H352.762V207.63H358.79V192.553Z" fill="#DB7071"/>
      <path d="M370.852 192.553H364.825V207.63H370.852V192.553Z" fill="#DB7071"/>
      <path d="M352.762 237.766H232.183V252.843H352.762V237.766Z" fill="#FAB4A8"/>
      <path d="M352.762 237.766H322.618V252.843H352.762V237.766Z" fill="#F5A098"/>
      <path d="M292.481 237.766H238.219V252.843H292.481V237.766Z" fill="#FFD4CC"/>
      <path d="M238.211 237.766H232.183V252.843H238.211V237.766Z" fill="#FAB4A8"/>
      <path d="M250.274 237.766H244.246V252.843H250.274V237.766Z" fill="#FAB4A8"/>
      <path d="M262.328 237.766H256.301V252.843H262.328V237.766Z" fill="#F5A098"/>
      <path d="M274.391 237.766H268.364V252.843H274.391V237.766Z" fill="#F5A098"/>
      <path d="M286.446 237.766H280.418V252.843H286.446V237.766Z" fill="#F5A098"/>
      <path d="M298.5 237.766H292.473V252.843H298.5V237.766Z" fill="#F5A098"/>
      <path d="M310.563 237.766H304.536V252.843H310.563V237.766Z" fill="#E88983"/>
      <path d="M322.618 237.766H316.59V252.843H322.618V237.766Z" fill="#E88983"/>
      <path d="M334.681 237.766H328.653V252.843H334.681V237.766Z" fill="#E88983"/>
      <path d="M346.735 237.766H340.708V252.843H346.735V237.766Z" fill="#DB7071"/>
      <path d="M358.79 237.766H352.762V252.843H358.79V237.766Z" fill="#DB7071"/>
      <path d="M370.861 282.157H244.255V283.818H370.861V282.157Z" fill="#DB7071"/>
      <path d="M370.861 267.081H238.236V268.741H370.861V267.081Z" fill="#DB7071"/>
      <path d="M358.79 252.012H232.183V253.673H358.79V252.012Z" fill="#DB7071"/>
      <path d="M364.817 236.936H238.219V238.596H364.817V236.936Z" fill="#DB7071"/>
      <path d="M364.817 221.868H232.183V223.528H364.817V221.868Z" fill="#DB7071"/>
      <path d="M370.836 206.791H232.183V208.452H370.836V206.791Z" fill="#DB7071"/>
      <path d="M364.825 297.226H238.227V298.886H364.825V297.226Z" fill="#DB7071"/>
      <path
        d="M285.69 229.107C285.69 229.396 285.576 229.674 285.372 229.879C285.168 230.084 284.892 230.201 284.603 230.203H283.515C281.817 230.202 280.135 230.536 278.566 231.185C276.997 231.834 275.571 232.786 274.369 233.986C273.168 235.187 272.215 236.612 271.565 238.181C270.915 239.75 270.58 241.431 270.58 243.129V244.217C270.58 244.505 270.466 244.782 270.262 244.986C270.058 245.19 269.781 245.305 269.493 245.305C269.204 245.305 268.928 245.19 268.724 244.986C268.52 244.782 268.405 244.505 268.405 244.217V243.129C268.403 239.7 267.039 236.413 264.614 233.989C262.188 231.565 258.9 230.203 255.471 230.203H254.383C254.11 230.179 253.856 230.054 253.671 229.852C253.485 229.65 253.383 229.386 253.383 229.111C253.383 228.837 253.485 228.573 253.671 228.371C253.856 228.169 254.11 228.043 254.383 228.02H255.471C258.9 228.02 262.188 226.658 264.614 224.234C267.039 221.81 268.403 218.522 268.405 215.093V214.006C268.405 213.717 268.52 213.441 268.724 213.237C268.928 213.033 269.204 212.918 269.493 212.918C269.781 212.918 270.058 213.033 270.262 213.237C270.466 213.441 270.58 213.717 270.58 214.006V215.093C270.58 216.792 270.915 218.473 271.565 220.042C272.215 221.611 273.168 223.036 274.369 224.237C275.571 225.437 276.997 226.389 278.566 227.038C280.135 227.687 281.817 228.021 283.515 228.02H284.628C284.912 228.026 285.182 228.144 285.38 228.347C285.579 228.55 285.69 228.823 285.69 229.107Z"
        fill="white"/>
      <path
        d="M522.738 146.568C522.736 146.673 522.694 146.773 522.619 146.848C522.545 146.922 522.445 146.965 522.34 146.967H521.941C521.321 146.967 520.707 147.089 520.134 147.326C519.561 147.564 519.04 147.912 518.601 148.35C518.162 148.789 517.815 149.31 517.577 149.883C517.34 150.456 517.218 151.07 517.218 151.691V152.089C517.218 152.195 517.176 152.296 517.101 152.371C517.026 152.446 516.925 152.488 516.819 152.488C516.714 152.486 516.614 152.443 516.54 152.369C516.465 152.294 516.423 152.194 516.421 152.089V151.691C516.421 150.438 515.923 149.236 515.037 148.35C514.151 147.464 512.95 146.967 511.697 146.967H511.273C511.163 146.967 511.058 146.923 510.98 146.845C510.902 146.767 510.858 146.662 510.858 146.552C510.858 146.442 510.902 146.336 510.98 146.258C511.058 146.18 511.163 146.137 511.273 146.137H511.672C512.293 146.14 512.91 146.02 513.485 145.785C514.061 145.55 514.584 145.203 515.025 144.765C515.466 144.327 515.817 143.806 516.056 143.232C516.296 142.658 516.419 142.043 516.421 141.421V141.023C516.423 140.917 516.465 140.817 516.54 140.743C516.614 140.669 516.714 140.626 516.819 140.624C516.925 140.624 517.026 140.666 517.101 140.741C517.176 140.815 517.218 140.917 517.218 141.023V141.421C517.22 142.673 517.718 143.873 518.604 144.759C519.489 145.644 520.689 146.143 521.941 146.145H522.34C522.394 146.146 522.447 146.158 522.497 146.18C522.546 146.202 522.591 146.233 522.628 146.273C522.665 146.312 522.694 146.359 522.713 146.409C522.732 146.46 522.741 146.514 522.738 146.568Z"
        fill="white"/>
      <path
        d="M110.608 380.603C110.606 380.681 110.574 380.755 110.518 380.809C110.462 380.863 110.387 380.894 110.309 380.894H110.019C109.089 380.894 108.198 381.263 107.541 381.92C106.884 382.577 106.515 383.468 106.515 384.397V384.671C106.522 384.714 106.52 384.758 106.509 384.8C106.497 384.842 106.477 384.881 106.449 384.914C106.421 384.947 106.386 384.974 106.346 384.992C106.307 385.01 106.264 385.02 106.22 385.02C106.177 385.02 106.134 385.01 106.095 384.992C106.055 384.974 106.02 384.947 105.992 384.914C105.964 384.881 105.944 384.842 105.932 384.8C105.921 384.758 105.919 384.714 105.926 384.671V384.381C105.926 383.451 105.557 382.56 104.9 381.903C104.243 381.246 103.351 380.877 102.422 380.877H102.132C102.089 380.884 102.045 380.882 102.003 380.871C101.961 380.859 101.922 380.839 101.889 380.811C101.856 380.783 101.829 380.748 101.811 380.708C101.793 380.669 101.783 380.626 101.783 380.582C101.783 380.539 101.793 380.496 101.811 380.457C101.829 380.417 101.856 380.382 101.889 380.354C101.922 380.326 101.961 380.306 102.003 380.294C102.045 380.283 102.089 380.28 102.132 380.288H102.422C102.882 380.289 103.337 380.199 103.762 380.024C104.187 379.849 104.574 379.592 104.899 379.267C105.225 378.942 105.483 378.557 105.659 378.132C105.835 377.707 105.926 377.252 105.926 376.793V376.494C105.937 376.424 105.974 376.361 106.027 376.315C106.081 376.269 106.15 376.244 106.22 376.244C106.291 376.244 106.36 376.269 106.413 376.315C106.467 376.361 106.503 376.424 106.515 376.494V376.793C106.514 377.253 106.604 377.708 106.78 378.133C106.956 378.558 107.214 378.944 107.539 379.269C107.865 379.594 108.252 379.851 108.677 380.026C109.103 380.201 109.559 380.29 110.019 380.288H110.284C110.327 380.284 110.37 380.29 110.41 380.304C110.451 380.318 110.488 380.341 110.518 380.371C110.549 380.401 110.573 380.438 110.588 380.477C110.604 380.517 110.611 380.56 110.608 380.603Z"
        fill="white"/>
      <path
        d="M171.421 358.013C171.421 358.116 171.401 358.217 171.361 358.312C171.322 358.406 171.265 358.492 171.192 358.565C171.12 358.637 171.034 358.695 170.939 358.734C170.844 358.773 170.743 358.793 170.64 358.793H169.852C167.375 358.793 164.999 359.777 163.247 361.529C161.496 363.281 160.512 365.656 160.512 368.133V368.897C160.492 369.091 160.401 369.271 160.256 369.402C160.111 369.532 159.922 369.605 159.727 369.605C159.532 369.605 159.344 369.532 159.199 369.402C159.054 369.271 158.963 369.091 158.943 368.897V368.108C158.943 365.631 157.959 363.256 156.207 361.504C154.456 359.752 152.08 358.768 149.603 358.768H148.823C148.713 358.78 148.602 358.768 148.497 358.734C148.392 358.699 148.295 358.643 148.213 358.57C148.131 358.496 148.066 358.405 148.021 358.304C147.976 358.204 147.953 358.094 147.953 357.984C147.953 357.874 147.976 357.764 148.021 357.664C148.066 357.563 148.131 357.472 148.213 357.398C148.295 357.324 148.392 357.269 148.497 357.234C148.602 357.2 148.713 357.188 148.823 357.199H149.603C152.08 357.199 154.456 356.215 156.207 354.464C157.959 352.712 158.943 350.337 158.943 347.86V347.079C158.931 346.969 158.943 346.858 158.978 346.754C159.012 346.649 159.068 346.552 159.142 346.47C159.216 346.388 159.306 346.323 159.407 346.278C159.508 346.233 159.617 346.21 159.727 346.21C159.838 346.21 159.947 346.233 160.048 346.278C160.149 346.323 160.239 346.388 160.313 346.47C160.387 346.552 160.443 346.649 160.477 346.754C160.511 346.858 160.523 346.969 160.512 347.079V347.86C160.512 350.337 161.496 352.712 163.247 354.464C164.999 356.215 167.375 357.199 169.852 357.199H170.64C170.746 357.199 170.85 357.221 170.947 357.262C171.044 357.303 171.131 357.364 171.204 357.44C171.277 357.516 171.334 357.606 171.371 357.704C171.408 357.803 171.425 357.908 171.421 358.013Z"
        fill="white"/>
      <path
        d="M469.547 156.29C469.548 156.428 469.522 156.565 469.47 156.693C469.418 156.821 469.341 156.937 469.244 157.035C469.147 157.133 469.031 157.211 468.903 157.264C468.776 157.317 468.639 157.344 468.501 157.344H467.447C464.144 157.344 460.977 158.656 458.641 160.992C456.306 163.327 454.994 166.495 454.994 169.797V170.843C455.007 170.989 454.989 171.136 454.942 171.275C454.895 171.413 454.82 171.541 454.721 171.649C454.623 171.757 454.502 171.843 454.369 171.902C454.235 171.961 454.09 171.991 453.944 171.991C453.797 171.991 453.653 171.961 453.519 171.902C453.385 171.843 453.264 171.757 453.166 171.649C453.067 171.541 452.992 171.413 452.945 171.275C452.898 171.136 452.88 170.989 452.893 170.843V169.797C452.893 166.495 451.581 163.327 449.246 160.992C446.911 158.656 443.743 157.344 440.44 157.344H439.386C439.123 157.321 438.879 157.2 438.701 157.006C438.524 156.811 438.425 156.558 438.425 156.294C438.425 156.031 438.524 155.777 438.701 155.582C438.879 155.388 439.123 155.267 439.386 155.244H440.44C443.743 155.244 446.911 153.932 449.246 151.596C451.581 149.261 452.893 146.094 452.893 142.791V141.736C452.88 141.591 452.898 141.444 452.945 141.305C452.992 141.167 453.067 141.039 453.166 140.931C453.264 140.823 453.385 140.737 453.519 140.678C453.653 140.619 453.797 140.589 453.944 140.589C454.09 140.589 454.235 140.619 454.369 140.678C454.502 140.737 454.623 140.823 454.721 140.931C454.82 141.039 454.895 141.167 454.942 141.305C454.989 141.444 455.007 141.591 454.994 141.736V142.791C455.007 146.09 456.323 149.249 458.656 151.582C460.988 153.915 464.148 155.231 467.447 155.244H468.501C468.639 155.243 468.775 155.269 468.903 155.321C469.03 155.373 469.146 155.45 469.243 155.548C469.341 155.645 469.418 155.761 469.47 155.888C469.522 156.016 469.548 156.152 469.547 156.29Z"
        fill="white"/>
      <path d="M509.447 373.081H388.868V388.158H509.447V373.081Z" fill="#FAB4A8"/>
      <path d="M509.447 373.081H479.302V388.158H509.447V373.081Z" fill="#F5A098"/>
      <path d="M449.157 373.081H394.895V388.158H449.157V373.081Z" fill="#FFD4CC"/>
      <path d="M394.895 373.081H388.868V388.158H394.895V373.081Z" fill="#FAB4A8"/>
      <path d="M406.95 373.081H400.923V388.158H406.95V373.081Z" fill="#FAB4A8"/>
      <path d="M419.004 373.081H412.977V388.158H419.004V373.081Z" fill="#F5A098"/>
      <path d="M431.067 373.081H425.04V388.158H431.067V373.081Z" fill="#F5A098"/>
      <path d="M443.122 373.081H437.095V388.158H443.122V373.081Z" fill="#F5A098"/>
      <path d="M455.185 373.081H449.158V388.158H455.185V373.081Z" fill="#F5A098"/>
      <path d="M467.239 373.081H461.212V388.158H467.239V373.081Z" fill="#E88983"/>
      <path d="M479.294 373.081H473.267V388.158H479.294V373.081Z" fill="#E88983"/>
      <path d="M491.357 373.081H485.329V388.158H491.357V373.081Z" fill="#E88983"/>
      <path d="M503.411 373.081H497.384V388.158H503.411V373.081Z" fill="#DB7071"/>
      <path d="M515.474 373.081H509.447V388.158H515.474V373.081Z" fill="#DB7071"/>
      <path d="M503.403 358.013H382.824V373.089H503.403V358.013Z" fill="#FAB4A8"/>
      <path d="M503.403 358.013H473.258V373.089H503.403V358.013Z" fill="#F5A098"/>
      <path d="M443.122 358.013H388.86V373.089H443.122V358.013Z" fill="#FFD4CC"/>
      <path d="M388.851 358.013H382.824V373.089H388.851V358.013Z" fill="#FAB4A8"/>
      <path d="M400.914 358.013H394.887V373.089H400.914V358.013Z" fill="#FAB4A8"/>
      <path d="M412.969 358.013H406.941V373.089H412.969V358.013Z" fill="#F5A098"/>
      <path d="M425.032 358.013H419.004V373.089H425.032V358.013Z" fill="#F5A098"/>
      <path d="M437.086 358.013H431.059V373.089H437.086V358.013Z" fill="#F5A098"/>
      <path d="M449.141 358.013H443.114V373.089H449.141V358.013Z" fill="#F5A098"/>
      <path d="M461.204 358.013H455.176V373.089H461.204V358.013Z" fill="#E88983"/>
      <path d="M473.258 358.013H467.231V373.089H473.258V358.013Z" fill="#E88983"/>
      <path d="M485.321 358.013H479.294V373.089H485.321V358.013Z" fill="#E88983"/>
      <path d="M497.376 358.013H491.348V373.089H497.376V358.013Z" fill="#DB7071"/>
      <path d="M509.43 358.013H503.403V373.089H509.43V358.013Z" fill="#DB7071"/>
      <path d="M515.466 342.937H394.887V358.013H515.466V342.937Z" fill="#FAB4A8"/>
      <path d="M515.466 342.937H485.321V358.013H515.466V342.937Z" fill="#F5A098"/>
      <path d="M455.176 342.937H400.914V358.013H455.176V342.937Z" fill="#FFD4CC"/>
      <path d="M400.914 342.937H394.887V358.013H400.914V342.937Z" fill="#FAB4A8"/>
      <path d="M412.969 342.937H406.941V358.013H412.969V342.937Z" fill="#FAB4A8"/>
      <path d="M425.032 342.937H419.004V358.013H425.032V342.937Z" fill="#F5A098"/>
      <path d="M437.086 342.937H431.059V358.013H437.086V342.937Z" fill="#F5A098"/>
      <path d="M449.141 342.937H443.114V358.013H449.141V342.937Z" fill="#F5A098"/>
      <path d="M461.204 342.937H455.176V358.013H461.204V342.937Z" fill="#F5A098"/>
      <path d="M473.258 342.937H467.231V358.013H473.258V342.937Z" fill="#E88983"/>
      <path d="M485.321 342.937H479.294V358.013H485.321V342.937Z" fill="#E88983"/>
      <path d="M497.376 342.937H491.348V358.013H497.376V342.937Z" fill="#E88983"/>
      <path d="M509.43 342.937H503.403V358.013H509.43V342.937Z" fill="#DB7071"/>
      <path d="M521.493 342.937H515.466V358.013H521.493V342.937Z" fill="#DB7071"/>
      <path d="M503.403 388.158H382.824V403.234H503.403V388.158Z" fill="#FAB4A8"/>
      <path d="M503.403 388.158H473.258V403.234H503.403V388.158Z" fill="#F5A098"/>
      <path d="M443.122 388.158H388.86V403.234H443.122V388.158Z" fill="#FFD4CC"/>
      <path d="M388.851 388.158H382.824V403.234H388.851V388.158Z" fill="#FAB4A8"/>
      <path d="M400.914 388.158H394.887V403.234H400.914V388.158Z" fill="#FAB4A8"/>
      <path d="M412.969 388.158H406.941V403.234H412.969V388.158Z" fill="#F5A098"/>
      <path d="M425.032 388.158H419.004V403.234H425.032V388.158Z" fill="#F5A098"/>
      <path d="M437.086 388.158H431.059V403.234H437.086V388.158Z" fill="#F5A098"/>
      <path d="M449.141 388.158H443.114V403.234H449.141V388.158Z" fill="#F5A098"/>
      <path d="M461.204 388.158H455.176V403.234H461.204V388.158Z" fill="#E88983"/>
      <path d="M473.258 388.158H467.231V403.234H473.258V388.158Z" fill="#E88983"/>
      <path d="M485.321 388.158H479.294V403.234H485.321V388.158Z" fill="#E88983"/>
      <path d="M497.376 388.158H491.348V403.234H497.376V388.158Z" fill="#DB7071"/>
      <path d="M509.43 388.158H503.403V403.234H509.43V388.158Z" fill="#DB7071"/>
      <path d="M515.466 387.328H388.868V388.988H515.466V387.328Z" fill="#DB7071"/>
      <path d="M515.466 372.251H382.832V373.911H515.466V372.251Z" fill="#DB7071"/>
      <path d="M509.43 357.183H382.832V358.843H509.43V357.183Z" fill="#DB7071"/>
      <path
        d="M302.726 299.766C302.726 299.949 302.653 300.124 302.524 300.253C302.395 300.382 302.22 300.455 302.037 300.455H301.348C299.179 300.455 297.099 301.317 295.565 302.85C294.032 304.384 293.17 306.464 293.17 308.633V309.322C293.17 309.504 293.098 309.68 292.968 309.809C292.839 309.938 292.664 310.011 292.481 310.011C292.298 310.011 292.123 309.938 291.994 309.809C291.865 309.68 291.792 309.504 291.792 309.322V308.633C291.793 307.559 291.583 306.496 291.173 305.504C290.763 304.512 290.161 303.61 289.402 302.851C288.644 302.091 287.743 301.489 286.751 301.078C285.759 300.667 284.696 300.455 283.623 300.455H282.967C282.784 300.455 282.609 300.382 282.48 300.253C282.351 300.124 282.278 299.949 282.278 299.766C282.278 299.583 282.351 299.408 282.48 299.279C282.609 299.149 282.784 299.077 282.967 299.077H283.656C285.823 299.077 287.901 298.216 289.433 296.684C290.965 295.152 291.825 293.074 291.825 290.908V290.219C291.825 290.036 291.898 289.861 292.027 289.731C292.156 289.602 292.332 289.53 292.514 289.53C292.697 289.53 292.872 289.602 293.002 289.731C293.131 289.861 293.203 290.036 293.203 290.219V290.908C293.206 293.075 294.068 295.153 295.602 296.685C297.135 298.216 299.214 299.077 301.381 299.077H302.062C302.24 299.083 302.409 299.159 302.533 299.287C302.657 299.416 302.726 299.587 302.726 299.766Z"
        fill="white"/>
      <path
        d="M342.028 254.113C342.028 254.245 341.975 254.372 341.882 254.465C341.789 254.559 341.662 254.611 341.53 254.611H341.081C340.31 254.61 339.547 254.761 338.834 255.056C338.121 255.35 337.474 255.783 336.929 256.328C336.383 256.873 335.951 257.521 335.656 258.233C335.362 258.946 335.211 259.71 335.212 260.481V260.97C335.221 261.041 335.215 261.112 335.194 261.18C335.174 261.248 335.139 261.31 335.092 261.363C335.045 261.417 334.988 261.459 334.923 261.488C334.859 261.518 334.789 261.533 334.718 261.533C334.647 261.533 334.577 261.518 334.513 261.488C334.448 261.459 334.39 261.417 334.344 261.363C334.297 261.31 334.262 261.248 334.241 261.18C334.221 261.112 334.215 261.041 334.224 260.97V260.481C334.224 258.924 333.606 257.431 332.505 256.33C331.404 255.229 329.911 254.611 328.354 254.611H327.798C327.678 254.596 327.568 254.537 327.488 254.446C327.408 254.355 327.364 254.238 327.364 254.117C327.364 253.996 327.408 253.879 327.488 253.788C327.568 253.697 327.678 253.639 327.798 253.623H328.296C329.852 253.621 331.344 253.002 332.444 251.902C333.545 250.801 334.164 249.31 334.166 247.754V247.255C334.181 247.135 334.24 247.025 334.331 246.945C334.422 246.866 334.539 246.822 334.66 246.822C334.781 246.822 334.898 246.866 334.989 246.945C335.079 247.025 335.138 247.135 335.154 247.255V247.754C335.154 249.31 335.772 250.803 336.873 251.904C337.974 253.005 339.467 253.623 341.023 253.623H341.513C341.579 253.621 341.645 253.632 341.707 253.655C341.769 253.679 341.825 253.715 341.873 253.76C341.921 253.806 341.959 253.86 341.986 253.921C342.013 253.982 342.027 254.047 342.028 254.113Z"
        fill="white"/>
      <path d="M370.836 206.791H316.59V208.452H370.836V206.791Z" fill="#145FC0"/>
      <path d="M364.833 221.868H328.661V223.528H364.833V221.868Z" fill="#145FC0"/>
      <path d="M364.825 236.936H346.752V238.596H364.825V236.936Z" fill="#145FC0"/>
      <path d="M312.024 206.791H301.514V208.452H312.024V206.791Z" fill="#145FC0"/>
      <path d="M330.081 236.936H323.406V238.596H330.081V236.936Z" fill="#145FC0"/>
      <path d="M313.801 311.962H308.994V313.622H313.801V311.962Z" fill="#145FC0"/>
      <path d="M342.924 297.226H326.777V298.886H342.924V297.226Z" fill="#145FC0"/>
      <path d="M337.703 282.157H323.44V283.818H337.703V282.157Z" fill="#145FC0"/>
      <path d="M364.825 252.145H355.452V253.806H364.825V252.145Z" fill="#145FC0"/>
      <path d="M364.834 267.081H337.694V268.741H364.834V267.081Z" fill="#145FC0"/>
      <path d="M515.474 372.251H504.756V373.911H515.474V372.251Z" fill="#145FC0"/>
      <path d="M515.474 387.328H509.447V388.988H515.474V387.328Z" fill="#145FC0"/>
      <path d="M355.776 387.328H331.667V388.988H355.776V387.328Z" fill="#145FC0"/>
      <path d="M337.686 372.251H320.152V373.911H337.686V372.251Z" fill="#145FC0"/>
      <path d="M318.832 357.183H289.742V358.843H318.832V357.183Z" fill="#145FC0"/>
      <path d="M340.708 357.183H325.64V358.843H340.708V357.183Z" fill="#145FC0"/>
      <path d="M335.851 327.038H317.694V328.698H335.851V327.038Z" fill="#145FC0"/>
      <path d="M500.389 372.251H494.362V373.911H500.389V372.251Z" fill="#145FC0"/>
      <path d="M521.493 357.183H487.098V358.843H521.493V357.183Z" fill="#145FC0"/>
      <path
        d="M406.933 403.234C448.557 403.234 482.299 369.492 482.299 327.868C482.299 286.245 448.557 252.502 406.933 252.502C365.31 252.502 331.567 286.245 331.567 327.868C331.567 369.492 365.31 403.234 406.933 403.234Z"
        fill="#FFC7BD"/>
      <path
        d="M339.23 362.853C333.652 352.035 330.74 340.04 330.737 327.868C330.704 317.706 332.719 307.641 336.664 298.275C340.608 288.909 346.399 280.434 353.692 273.357L354.854 274.544C343.442 285.704 335.911 300.232 333.371 315.991C330.83 331.75 333.414 347.908 340.741 362.089L339.23 362.853Z"
        fill="white"/>
      <path
        d="M342.783 368.997C342.393 368.391 342.011 367.768 341.629 367.145L343.057 366.315C343.423 366.921 343.796 367.527 344.178 368.125L342.783 368.997Z"
        fill="white"/>
      <path
        d="M482.64 329.338H480.979C480.979 328.856 480.979 328.375 480.979 327.902C480.998 323.234 480.579 318.576 479.725 313.987L481.386 313.672C482.277 318.352 482.724 323.105 482.723 327.868C482.656 328.358 482.648 328.848 482.64 329.338Z"
        fill="#145FC0"/>
      <path
        d="M479.07 311.048C478.945 310.516 478.812 309.994 478.68 309.471L480.289 309.056L480.688 310.674L479.07 311.048Z"
        fill="#145FC0"/>
      <path
        d="M478.68 309.471C475.604 297.555 469.613 286.591 461.245 277.566L462.466 276.437C471.019 285.665 477.144 296.874 480.29 309.056L478.68 309.471Z"
        fill="#145FC0"/>
      <path
        d="M453.848 270.601C440.617 259.755 424.041 253.821 406.933 253.806C395.333 253.758 383.888 256.47 373.542 261.718L372.787 260.231C383.37 254.874 395.072 252.103 406.933 252.145C424.425 252.161 441.373 258.227 454.903 269.314L453.848 270.601Z"
        fill="#145FC0"/>
      <path
        d="M374.995 396.517C374.538 396.309 374.09 396.094 373.65 395.878L374.381 394.384L375.692 395.015L374.995 396.517Z"
        fill="#145FC0"/>
      <path
        d="M406.933 403.591C396.676 403.612 386.523 401.526 377.104 397.465L377.76 395.945C387.425 400.08 397.859 402.111 408.37 401.905C418.88 401.699 429.227 399.259 438.722 394.749C448.218 390.238 456.645 383.759 463.446 375.743C470.246 367.726 475.263 358.354 478.165 348.25L479.767 348.698C475.223 364.51 465.662 378.415 452.524 388.317C439.386 398.218 423.385 403.579 406.933 403.591Z"
        fill="#145FC0"/>
      <path
        d="M455.243 376.178C444.066 387.357 429.359 394.314 413.627 395.865C397.896 397.415 382.113 393.463 368.969 384.682C355.825 375.9 346.132 362.833 341.542 347.706C336.953 332.579 337.75 316.329 343.799 301.724C349.848 287.119 360.773 275.064 374.714 267.612C388.655 260.159 404.749 257.772 420.253 260.855C435.757 263.939 449.713 272.303 459.741 284.522C469.77 296.742 475.251 312.06 475.251 327.868C475.275 336.843 473.519 345.734 470.084 354.026C466.65 362.319 461.606 369.848 455.243 376.178Z"
        fill="#F5A098"/>
      <path
        d="M406.933 389.154C392.752 389.158 379.008 384.245 368.044 375.252C357.079 366.258 349.572 353.741 346.802 339.833C344.032 325.925 346.17 311.487 352.853 298.978C359.535 286.47 370.347 276.666 383.448 271.237C396.549 265.807 411.127 265.088 424.698 269.202C438.269 273.317 449.994 282.009 457.874 293.799C465.755 305.589 469.303 319.747 467.915 333.86C466.527 347.973 460.288 361.168 450.262 371.197C444.586 376.907 437.834 381.435 430.397 384.517C422.959 387.599 414.984 389.176 406.933 389.154Z"
        fill="#FFC7BD"/>
      <path
        d="M406.933 389.154C390.679 389.154 375.091 382.697 363.598 371.204C352.104 359.711 345.648 344.122 345.648 327.868C345.648 311.614 352.104 296.026 363.598 284.533C375.091 273.04 390.679 266.583 406.933 266.583V389.154Z"
        fill="#F5A098"/>
      <path
        d="M409.582 361.018C406.997 361.016 404.418 360.777 401.877 360.305C398.295 359.64 395.058 357.743 392.728 354.942C390.397 352.142 389.12 348.614 389.117 344.971V342.164H390.777V344.971C390.779 348.226 391.92 351.377 394.002 353.879C396.084 356.381 398.976 358.076 402.176 358.669C407.071 359.582 412.092 359.582 416.987 358.669C420.041 358.1 422.818 356.526 424.876 354.199C426.934 351.871 428.155 348.923 428.346 345.822C428.537 342.721 427.686 339.645 425.929 337.082C424.172 334.52 421.609 332.618 418.647 331.679L400.026 325.726C396.713 324.672 393.847 322.541 391.883 319.672C389.919 316.803 388.97 313.36 389.187 309.89C389.403 306.42 390.773 303.122 393.078 300.519C395.383 297.916 398.492 296.158 401.911 295.524C407.006 294.577 412.232 294.577 417.327 295.524C420.909 296.189 424.145 298.086 426.474 300.887C428.803 303.687 430.079 307.215 430.079 310.857V313.664H428.419V310.857C428.419 307.602 427.279 304.45 425.196 301.947C423.114 299.445 420.221 297.751 417.02 297.159C412.125 296.246 407.104 296.246 402.209 297.159C399.154 297.725 396.375 299.296 394.315 301.623C392.255 303.95 391.032 306.899 390.841 310.001C390.65 313.103 391.502 316.18 393.261 318.742C395.02 321.304 397.586 323.205 400.549 324.141L419.22 330.077C422.533 331.13 425.4 333.26 427.364 336.129C429.328 338.998 430.278 342.44 430.063 345.91C429.847 349.38 428.478 352.678 426.174 355.282C423.87 357.885 420.762 359.644 417.344 360.28C414.785 360.764 412.186 361.011 409.582 361.018Z"
        fill="#DB7071"/>
      <path d="M410.412 283.386H408.751V295.673H410.412V283.386Z" fill="#DB7071"/>
      <path d="M410.412 360.064H408.751V372.351H410.412V360.064Z" fill="#DB7071"/>
      <path
        d="M406.933 361.018C404.346 361.017 401.764 360.778 399.221 360.305C395.638 359.64 392.402 357.743 390.071 354.942C387.741 352.142 386.463 348.614 386.46 344.971V342.165H388.121V344.971C388.123 348.227 389.265 351.379 391.348 353.882C393.432 356.384 396.326 358.077 399.528 358.669C404.423 359.582 409.444 359.582 414.339 358.669C417.393 358.1 420.17 356.526 422.228 354.199C424.285 351.871 425.507 348.923 425.698 345.822C425.888 342.721 425.038 339.645 423.281 337.082C421.523 334.52 418.961 332.618 415.999 331.679L397.344 325.726C394.043 324.667 391.188 322.537 389.232 319.675C387.276 316.812 386.329 313.379 386.541 309.918C386.754 306.457 388.113 303.165 390.405 300.563C392.696 297.961 395.789 296.197 399.196 295.548C404.291 294.602 409.517 294.602 414.613 295.548C418.204 296.203 421.453 298.095 423.793 300.897C426.133 303.699 427.418 307.232 427.423 310.882V313.688H425.762V310.882C425.761 307.627 424.62 304.476 422.538 301.974C420.456 299.472 417.564 297.777 414.364 297.184C409.469 296.271 404.447 296.271 399.553 297.184C396.497 297.75 393.718 299.321 391.658 301.648C389.598 303.975 388.375 306.924 388.185 310.026C387.994 313.128 388.846 316.205 390.605 318.767C392.364 321.329 394.929 323.229 397.892 324.166L416.572 330.102C419.885 331.156 422.751 333.287 424.715 336.156C426.679 339.025 427.627 342.468 427.411 345.938C427.194 349.408 425.825 352.707 423.52 355.309C421.215 357.912 418.106 359.67 414.687 360.305C412.13 360.78 409.534 361.019 406.933 361.018Z"
        fill="white"/>
      <path d="M407.763 283.386H406.103V295.673H407.763V283.386Z" fill="white"/>
      <path d="M407.763 360.064H406.103V372.351H407.763V360.064Z" fill="white"/>
      <path
        d="M420.225 269.347C412.826 267.7 405.186 267.441 397.693 268.583L397.436 266.923C405.136 265.751 412.987 266.019 420.59 267.712L420.225 269.347Z"
        fill="white"/>
      <path
        d="M387.839 270.933L387.316 269.356C388.005 269.123 388.694 268.907 389.391 268.7L389.864 270.294C389.183 270.526 388.511 270.742 387.839 270.933Z"
        fill="white"/>
      <path
        d="M406.933 389.511C392.623 389.497 378.763 384.508 367.727 375.398C356.692 366.288 349.167 353.624 346.444 339.575C343.72 325.527 345.966 310.968 352.797 298.394C359.628 285.819 370.619 276.012 383.887 270.651L384.501 272.195C376.366 275.476 369.05 280.501 363.067 286.916C357.084 293.33 352.58 300.979 349.874 309.322C347.167 317.666 346.322 326.501 347.399 335.207C348.476 343.912 351.449 352.275 356.109 359.707C360.768 367.139 367 373.459 374.366 378.222C381.732 382.985 390.053 386.075 398.742 387.274C407.431 388.473 416.278 387.752 424.659 385.162C433.039 382.573 440.75 378.177 447.248 372.284L448.36 373.513C437.029 383.825 422.254 389.531 406.933 389.511Z"
        fill="white"/>
      <path
        d="M439.751 274.329C439.751 274.534 439.67 274.731 439.526 274.878C439.381 275.024 439.185 275.107 438.979 275.109H438.215C437.004 275.108 435.804 275.346 434.684 275.809C433.565 276.272 432.548 276.951 431.691 277.808C430.834 278.665 430.155 279.682 429.692 280.802C429.229 281.921 428.991 283.121 428.992 284.333V285.105C429.003 285.213 428.991 285.323 428.957 285.427C428.924 285.531 428.868 285.627 428.795 285.708C428.722 285.789 428.633 285.854 428.533 285.898C428.433 285.943 428.325 285.965 428.216 285.965C428.106 285.965 427.998 285.943 427.898 285.898C427.799 285.854 427.709 285.789 427.636 285.708C427.563 285.627 427.508 285.531 427.474 285.427C427.44 285.323 427.428 285.213 427.439 285.105V284.333C427.444 283.118 427.208 281.914 426.745 280.79C426.282 279.667 425.602 278.646 424.743 277.786C423.884 276.927 422.864 276.245 421.741 275.782C420.618 275.318 419.414 275.081 418.199 275.084H417.46C417.268 275.064 417.09 274.974 416.961 274.83C416.832 274.687 416.76 274.501 416.76 274.308C416.76 274.115 416.832 273.929 416.961 273.785C417.09 273.642 417.268 273.552 417.46 273.532H418.241C420.675 273.521 423.007 272.547 424.727 270.824C426.447 269.101 427.416 266.768 427.423 264.333V263.553C427.443 263.361 427.533 263.183 427.676 263.053C427.82 262.924 428.006 262.853 428.199 262.853C428.392 262.853 428.578 262.924 428.722 263.053C428.865 263.183 428.955 263.361 428.975 263.553V264.333C428.974 265.546 429.212 266.747 429.677 267.868C430.141 268.988 430.822 270.006 431.68 270.863C432.539 271.72 433.558 272.399 434.679 272.861C435.801 273.324 437.002 273.56 438.215 273.557H438.996C439.198 273.561 439.39 273.644 439.531 273.788C439.672 273.933 439.751 274.127 439.751 274.329Z"
        fill="white"/>
      <path
        d="M463.304 362.255C463.304 362.372 463.258 362.484 463.175 362.567C463.093 362.649 462.981 362.696 462.864 362.696H462.433C461.053 362.696 459.731 363.243 458.755 364.217C457.779 365.192 457.229 366.513 457.227 367.893V368.333C457.227 368.447 457.182 368.557 457.101 368.638C457.02 368.719 456.91 368.764 456.795 368.764C456.738 368.765 456.681 368.755 456.628 368.734C456.574 368.713 456.526 368.681 456.485 368.641C456.444 368.601 456.411 368.553 456.389 368.5C456.367 368.447 456.355 368.39 456.355 368.333V367.893C456.355 366.514 455.808 365.192 454.833 364.218C453.859 363.243 452.537 362.696 451.158 362.696H450.718C450.602 362.696 450.49 362.649 450.407 362.567C450.325 362.484 450.278 362.372 450.278 362.255C450.278 362.139 450.325 362.027 450.407 361.944C450.49 361.862 450.602 361.815 450.718 361.815H451.158C452.537 361.815 453.859 361.268 454.833 360.293C455.808 359.319 456.355 357.997 456.355 356.618V356.178C456.355 356.121 456.367 356.064 456.389 356.011C456.411 355.958 456.444 355.91 456.485 355.87C456.526 355.83 456.574 355.798 456.628 355.777C456.681 355.756 456.738 355.746 456.795 355.747C456.91 355.747 457.02 355.792 457.101 355.873C457.182 355.954 457.227 356.064 457.227 356.178V356.618C457.229 357.998 457.779 359.319 458.755 360.294C459.731 361.268 461.053 361.815 462.433 361.815H462.864C462.981 361.815 463.093 361.862 463.175 361.944C463.258 362.027 463.304 362.139 463.304 362.255Z"
        fill="white"/>
      <path d="M479.12 415.687H172.176V417.348H479.12V415.687Z" fill="#6AACFF"/>
      <path d="M411.076 428.34H391.06V430H411.076V428.34Z" fill="#6AACFF"/>
      <path d="M570.243 428.34H563.701V430H570.243V428.34Z" fill="#6AACFF"/>
      <path d="M536.744 428.34H463.536V430H536.744V428.34Z" fill="#6AACFF"/>
      <path d="M381.014 428.34H68.1266V430H381.014V428.34Z" fill="#6AACFF"/>
      <path d="M609.96 415.878H556.96V417.539H609.96V415.878Z" fill="#6AACFF"/>
      <path d="M666.298 403.226H657.854V404.886H666.298V403.226Z" fill="#6AACFF"/>
      <path d="M648.216 403.226H0V404.886H648.216V403.226Z" fill="#6AACFF"/>
      <path d="M563.062 403.226H553.929V404.886H563.062V403.226Z" fill="#0958BF"/>
      <path d="M344.784 403.226H328.661V404.886H344.784V403.226Z" fill="#0958BF"/>
      <path d="M540.331 403.226H437.692V404.886H540.331V403.226Z" fill="#0958BF"/>
      <path d="M299.82 403.226H124.531V404.886H299.82V403.226Z" fill="#0958BF"/>
      <path d="M425.04 403.226H370.861V404.886H425.04V403.226Z" fill="#0958BF"/>
      <path d="M358.856 403.226H349.757V404.886H358.856V403.226Z" fill="#0958BF"/>
      <path
        d="M107.304 52.2653C106.172 52.9501 105.268 53.9552 104.707 55.1536C104.146 56.352 103.952 57.6899 104.151 58.9981C104.351 60.3064 104.933 61.5262 105.825 62.5034C106.718 63.4806 107.88 64.1712 109.164 64.4881C110.449 64.8049 111.799 64.7337 113.043 64.2834C114.288 63.8332 115.371 63.0241 116.155 61.9584C116.94 60.8928 117.391 59.6185 117.451 58.2966C117.512 56.9747 117.179 55.6645 116.494 54.5318C116.04 53.7794 115.442 53.1238 114.734 52.6027C114.026 52.0815 113.222 51.7049 112.369 51.4945C111.515 51.284 110.629 51.2437 109.76 51.376C108.891 51.5083 108.056 51.8105 107.304 52.2653Z"
        fill="#FFAFAF"/>
      <path
        d="M112.775 64.5772C113.616 64.0697 114.589 63.8224 115.571 63.8663C116.552 63.9103 117.499 64.2435 118.291 64.8242C119.084 65.4048 119.687 66.2069 120.025 67.1296C120.363 68.0522 120.421 69.0542 120.19 70.0094C119.96 70.9647 119.453 71.8305 118.732 72.498C118.011 73.1655 117.108 73.6048 116.138 73.7607C115.168 73.9166 114.173 73.7821 113.28 73.3741C112.386 72.9661 111.632 72.3029 111.115 71.4679C110.767 70.9076 110.534 70.2839 110.43 69.6329C110.325 68.9819 110.351 68.3166 110.505 67.6757C110.66 67.0347 110.94 66.4307 111.329 65.8989C111.719 65.367 112.21 64.9177 112.775 64.5772Z"
        fill="#FFAFAF"/>
      <path d="M117.233 56.2834L120.23 67.5908L111.081 71.4679L105.037 61.4473L117.233 56.2834Z" fill="#FFAFAF"/>
      <path
        d="M141.366 139.586C145.08 134.497 143.965 127.361 138.876 123.647C133.787 119.934 126.651 121.049 122.938 126.138C119.224 131.227 120.339 138.363 125.428 142.076C130.517 145.79 137.653 144.675 141.366 139.586Z"
        fill="#145FC0"/>
      <path d="M187.493 141.446L132.741 144.243L129.379 121.778L182.57 108.511L187.493 141.446Z" fill="#145FC0"/>
      <path
        d="M120.089 67.1343L110.426 69.6747L120.894 134.671L142.862 128.893L122.995 75.0212L121.26 70.3223L120.089 67.1343Z"
        fill="#145FC0"/>
      <path d="M120.089 67.1343L110.426 69.6747L111.945 79.0892L122.995 75.0212L121.26 70.3223L120.089 67.1343Z"
            fill="white"/>
      <path d="M120.089 67.1343L110.426 69.6747L111.123 74.0499L121.26 70.314L120.089 67.1343Z" fill="#FFAFAF"/>
      <path
        d="M194.894 139.284C202.932 134.727 205.753 124.518 201.197 116.481C196.64 108.443 186.431 105.622 178.394 110.178C170.357 114.735 167.535 124.944 172.092 132.981C176.648 141.018 186.857 143.84 194.894 139.284Z"
        fill="#145FC0"/>
      <path
        d="M247.717 141.114C245.26 143.33 242.202 144.769 238.928 145.247C235.654 145.725 232.312 145.222 229.324 143.801C226.336 142.38 223.837 140.104 222.142 137.263C220.448 134.421 219.633 131.141 219.803 127.837C219.972 124.533 221.118 121.353 223.095 118.7C225.071 116.047 227.79 114.039 230.908 112.931C234.025 111.823 237.401 111.665 240.609 112.475C243.817 113.286 246.712 115.03 248.929 117.486C250.401 119.117 251.537 121.022 252.272 123.092C253.008 125.162 253.329 127.357 253.216 129.551C253.104 131.745 252.56 133.895 251.616 135.879C250.673 137.863 249.348 139.642 247.717 141.114Z"
        fill="#145FC0"/>
      <path d="M165.609 210.079L141.085 276.661L158.893 284.233L186.622 219.012L165.609 210.079Z" fill="#145FC0"/>
      <path
        d="M223.62 223.77C225.414 217.726 221.969 211.373 215.925 209.579C209.881 207.785 203.527 211.231 201.733 217.274C199.94 223.318 203.385 229.672 209.429 231.466C215.473 233.26 221.827 229.814 223.62 223.77Z"
        fill="#145FC0"/>
      <path
        d="M175.564 118.424L166.141 201.345L226.322 211.158L243.823 129.557C244.155 128.007 244.169 126.405 243.864 124.85C243.559 123.294 242.94 121.816 242.047 120.507C241.153 119.197 240.003 118.082 238.666 117.231C237.328 116.379 235.832 115.808 234.267 115.552L189.088 108.213C187.524 107.957 185.925 108.022 184.388 108.402C182.85 108.782 181.405 109.471 180.141 110.425C178.877 111.38 177.819 112.581 177.033 113.956C176.246 115.331 175.746 116.851 175.564 118.424Z"
        fill="#145FC0"/>
      <path
        d="M166.141 201.345L164.845 212.702L164.787 213.259C164.455 216.17 165.256 219.099 167.024 221.436C168.792 223.773 171.392 225.34 174.285 225.811L210.814 231.789C213.705 232.265 216.669 231.61 219.09 229.959C221.511 228.308 223.203 225.788 223.815 222.922L223.939 222.374L226.322 211.158L166.141 201.345Z"
        fill="#145FC0"/>
      <path d="M201.408 218.688L189.959 288.625L209.054 291.755L223.931 222.374L201.408 218.688Z" fill="#145FC0"/>
      <path
        d="M172.683 394.542C172.618 395.936 172.969 397.318 173.69 398.512C174.411 399.707 175.47 400.661 176.733 401.254C177.996 401.847 179.407 402.052 180.786 401.844C182.166 401.635 183.453 401.022 184.485 400.083C185.516 399.143 186.246 397.918 186.581 396.564C186.917 395.209 186.844 393.786 186.37 392.473C185.897 391.161 185.045 390.018 183.923 389.189C182.8 388.36 181.457 387.883 180.063 387.817C179.137 387.774 178.211 387.913 177.339 388.228C176.466 388.542 175.665 389.026 174.979 389.65C174.294 390.275 173.738 391.028 173.344 391.867C172.95 392.707 172.725 393.616 172.683 394.542Z"
        fill="#145FC0"/>
      <path
        d="M191.155 378.768C191.748 380.031 192.702 381.09 193.897 381.811C195.091 382.532 196.473 382.883 197.867 382.819C199.261 382.755 200.604 382.279 201.728 381.451C202.851 380.624 203.704 379.481 204.179 378.169C204.653 376.857 204.728 375.434 204.394 374.079C204.06 372.724 203.331 371.499 202.301 370.558C201.27 369.617 199.984 369.003 198.604 368.793C197.225 368.583 195.814 368.787 194.55 369.379C192.856 370.174 191.546 371.61 190.91 373.371C190.273 375.131 190.361 377.073 191.155 378.768Z"
        fill="#145FC0"/>
      <path d="M220.76 113.377L196.169 170.428L192.541 126.602L195.389 109.217L220.76 113.377Z" fill="white"/>
      <path
        d="M195.389 109.217L192.541 126.602L194.052 144.858L196.169 170.428L206.322 146.867L220.76 113.377L195.389 109.217Z"
        fill="white"/>
      <path
        d="M154.203 289.162C159.009 286.832 161.016 281.046 158.685 276.24C156.354 271.433 150.569 269.426 145.762 271.757C140.956 274.088 138.949 279.874 141.28 284.68C143.61 289.486 149.396 291.493 154.203 289.162Z"
        fill="#145FC0"/>
      <path
        d="M202.429 299.403C207.522 297.791 210.344 292.356 208.732 287.264C207.121 282.171 201.686 279.349 196.593 280.96C191.5 282.572 188.678 288.007 190.29 293.1C191.901 298.192 197.336 301.014 202.429 299.403Z"
        fill="#145FC0"/>
      <path d="M186.688 396.003L209.054 291.754L189.959 288.625L172.757 393.728L186.688 396.003Z" fill="#145FC0"/>
      <path
        d="M217.793 227.837C229.702 215.929 229.702 196.621 217.793 184.713C205.885 172.804 186.578 172.804 174.669 184.713C162.761 196.621 162.761 215.929 174.669 227.837C186.578 239.745 205.885 239.745 217.793 227.837Z"
        fill="#145FC0"/>
      <path d="M194.052 144.858L196.169 170.428L206.323 146.867L206.082 115.02L204.43 114.755L194.052 144.858Z"
            fill="#0958BF"/>
      <path
        d="M165.568 403.226H186.796V394.866H172.683L164.58 399.283C164.25 399.464 163.974 399.731 163.781 400.055C163.589 400.379 163.486 400.749 163.484 401.126C163.484 401.68 163.703 402.212 164.093 402.605C164.484 402.998 165.014 403.222 165.568 403.226Z"
        fill="#0958BF"/>
      <path d="M200.108 110.019L220.746 113.395L224.404 91.0354L203.765 87.6594L200.108 110.019Z" fill="#FFAFAF"/>
      <path
        d="M197.53 68.8229L194.524 68.079C193.226 67.7577 192.003 67.1837 190.927 66.3898C189.85 65.596 188.94 64.5979 188.249 63.4524C187.558 62.307 187.1 61.0366 186.9 59.714C186.699 58.3913 186.762 57.0421 187.083 55.7436L187.119 55.5985C187.768 52.976 189.432 50.7187 191.746 49.3232C194.059 47.9277 196.832 47.5083 199.455 48.1573L202.477 48.9052L197.547 68.8269L197.53 68.8229Z"
        fill="#0958BF"/>
      <path
        d="M221.624 94.7587L214.194 92.9198L218.021 77.4547L232.769 81.1045L230.76 89.2198C230.525 90.184 230.102 91.0923 229.515 91.8927C228.929 92.6931 228.19 93.3699 227.341 93.8844C226.493 94.3989 225.551 94.7409 224.57 94.891C223.589 95.041 222.588 94.996 221.624 94.7587Z"
        fill="#0958BF"/>
      <path
        d="M204.69 70.0506L196.631 68.0562C194.523 67.5347 192.709 66.1973 191.588 64.3383C190.467 62.4794 190.13 60.2511 190.651 58.1436L192.845 49.2788C193.422 46.9469 194.902 44.9398 196.959 43.699C199.016 42.4582 201.481 42.0853 203.813 42.6624L211.066 44.4573L204.73 70.0605L204.69 70.0506Z"
        fill="#0958BF"/>
      <path
        d="M192.774 95.5854L218.967 102.069L226.272 72.5638C227.339 68.2571 226.651 63.7033 224.361 59.9032C222.071 56.1032 218.366 53.3679 214.06 52.2985C209.887 51.2645 205.477 51.8758 201.742 54.0057C198.008 56.1356 195.237 59.6207 194.002 63.7387L187.361 86.0214C187.066 87.0047 186.973 88.0376 187.088 89.0577C187.202 90.0779 187.522 91.0644 188.028 91.9578C188.533 92.8512 189.214 93.6331 190.03 94.2565C190.846 94.88 191.779 95.3319 192.774 95.5854Z"
        fill="#FFAFAF"/>
      <path
        d="M232.22 83.3213L215.248 79.1211L222.123 51.342L224.444 51.9163C228.329 52.878 231.674 55.3438 233.741 58.7714C235.809 62.199 236.431 66.3075 235.469 70.1933L232.22 83.3213Z"
        fill="#0958BF"/>
      <path
        d="M223.46 84.093L219.004 82.9901L220.107 78.5335C220.395 77.3686 221.134 76.366 222.162 75.7462C223.189 75.1263 224.421 74.9401 225.586 75.2283L225.65 75.2443C226.815 75.5326 227.818 76.2718 228.437 77.2993C229.057 78.3268 229.244 79.5585 228.955 80.7233L228.939 80.7878C228.651 81.9527 227.912 82.9553 226.884 83.5752C225.857 84.195 224.625 84.3813 223.46 84.093Z"
        fill="#FFAFAF"/>
      <path
        d="M192.774 58.6911L209.585 62.8421C212.88 63.6549 216.363 63.127 219.269 61.3743C222.175 59.6216 224.267 56.7875 225.085 53.494C225.473 51.9285 225.222 50.2731 224.389 48.8921C223.556 47.5111 222.209 46.5176 220.644 46.1301C219.868 45.9382 219.063 45.9009 218.273 46.0202C217.484 46.1396 216.725 46.4133 216.042 46.8257C214.661 47.6587 213.667 49.0062 213.28 50.5717C213.238 50.7354 213.134 50.8762 212.989 50.9633C212.845 51.0504 212.672 51.0768 212.508 51.0366L195.696 46.8856L192.774 58.6911Z"
        fill="#0958BF"/>
      <path
        d="M200.511 54.3406L208.871 56.4161C210.43 56.7815 212.07 56.5179 213.436 55.6823C214.801 54.8468 215.783 53.5068 216.167 51.9526C216.552 50.3984 216.308 48.7553 215.49 47.3795C214.671 46.0036 213.343 45.0058 211.794 44.6023L203.433 42.5351C201.868 42.1476 200.213 42.3978 198.832 43.2308C197.451 44.0638 196.457 45.4112 196.069 46.9767C195.682 48.5422 195.932 50.1975 196.765 51.5785C197.598 52.9596 198.946 53.9531 200.511 54.3406Z"
        fill="#0958BF"/>
      <path
        d="M185.542 91.9241C185.307 92.7134 185.234 93.5423 185.328 94.3606C185.422 95.1789 185.681 95.9697 186.089 96.6851C186.497 97.4005 187.046 98.0258 187.703 98.5231C188.359 99.0204 189.11 99.3795 189.909 99.5786L201.648 102.476C203.198 102.861 204.809 102.936 206.388 102.698C207.967 102.459 209.483 101.912 210.85 101.087C212.217 100.262 213.409 99.1751 214.355 97.8892C215.302 96.6033 215.986 95.1433 216.368 93.5928L219.788 79.7782L215.363 78.6823C215.04 79.9861 214.212 81.1082 213.061 81.8024C211.911 82.4965 210.533 82.7061 209.228 82.385L189.843 77.5864L185.542 91.9241Z"
        fill="#0958BF"/>
      <path
        d="M191.155 82.1609C190.983 82.7529 190.932 83.3734 191.005 83.9855C191.078 84.5975 191.273 85.1887 191.579 85.7238C191.885 86.2589 192.296 86.727 192.786 87.1004C193.277 87.4738 193.837 87.7448 194.434 87.8973C195.031 88.0498 195.653 88.0807 196.263 87.9882C196.872 87.8957 197.457 87.6816 197.982 87.3587C198.507 87.0358 198.962 86.6106 199.319 86.1085C199.677 85.6063 199.929 85.0375 200.063 84.4357L191.155 82.1609Z"
        fill="white"/>
      <path
        d="M288.123 189.573C289.797 188.062 290.986 186.088 291.539 183.9C292.092 181.713 291.984 179.412 291.229 177.286C290.474 175.16 289.106 173.306 287.297 171.958C285.488 170.61 283.32 169.828 281.067 169.712C278.814 169.596 276.578 170.151 274.64 171.306C272.702 172.461 271.15 174.165 270.181 176.201C269.211 178.238 268.867 180.517 269.193 182.749C269.518 184.981 270.498 187.067 272.008 188.743C273.012 189.856 274.224 190.76 275.577 191.404C276.93 192.048 278.397 192.419 279.893 192.496C281.39 192.573 282.887 192.355 284.299 191.853C285.711 191.352 287.01 190.577 288.123 189.573Z"
        fill="#145FC0"/>
      <path d="M224.57 140.433L272.332 189.1L289.766 174.48L250.124 118.997L224.57 140.433Z" fill="#145FC0"/>
      <path
        d="M218.182 192.852C218.839 189.215 216.423 185.735 212.786 185.079C209.149 184.422 205.669 186.838 205.012 190.475C204.356 194.112 206.772 197.592 210.409 198.249C214.046 198.905 217.526 196.489 218.182 192.852Z"
        fill="#FFAFAF"/>
      <path
        d="M218.261 189.913C217.792 189.05 217.589 188.067 217.678 187.089C217.766 186.111 218.142 185.181 218.757 184.415C219.373 183.65 220.201 183.084 221.137 182.787C222.074 182.491 223.077 182.479 224.02 182.751C224.964 183.023 225.806 183.569 226.441 184.318C227.075 185.068 227.474 185.989 227.587 186.964C227.7 187.94 227.522 188.927 227.075 189.802C226.629 190.677 225.933 191.4 225.077 191.881C224.501 192.204 223.867 192.409 223.211 192.485C222.556 192.561 221.891 192.506 221.257 192.323C220.623 192.14 220.032 191.832 219.517 191.419C219.003 191.005 218.576 190.493 218.261 189.913Z"
        fill="#FFAFAF"/>
      <path d="M210.142 185.14L221.565 182.591L225.077 191.881L214.832 197.535L210.142 185.14Z" fill="#FFAFAF"/>
      <path d="M224.687 181.893L225.874 192.503H280.476L277.969 169.963L229.618 180.789L224.687 181.893Z"
            fill="#145FC0"/>
      <path d="M224.687 181.893L225.874 192.503H230.913L229.618 180.789L224.687 181.893Z" fill="white"/>
      <path d="M221.565 182.591L222.661 192.504H225.874L224.687 181.894L221.565 182.591Z" fill="#FFAFAF"/>
      <path
        d="M195.306 389.279L200.627 399.615C201.508 401.26 202.993 402.5 204.77 403.071C206.547 403.642 208.476 403.501 210.151 402.677C211.826 401.853 213.115 400.411 213.747 398.655C214.378 396.899 214.303 394.966 213.537 393.264L208.73 383.011L195.306 389.279Z"
        fill="#0958BF"/>
      <path
        d="M141.234 284.548L187.535 374.227L195.306 389.279L208.73 382.986L201.3 367.129L158.752 276.346L141.234 284.548Z"
        fill="#145FC0"/>
      <path d="M177.388 314.192L175.877 314.9L198.533 363.245L200.044 362.537L177.388 314.192Z" fill="#0958BF"/>
      <path d="M204.132 371.26L202.621 371.968L205.334 377.756L206.845 377.048L204.132 371.26Z" fill="#0958BF"/>
      <path d="M172.868 249.234L159.725 280.137L161.26 280.79L174.403 249.887L172.868 249.234Z" fill="#0958BF"/>
      <path d="M178.22 236.679L174.74 244.861L176.276 245.514L179.755 237.332L178.22 236.679Z" fill="#0958BF"/>
      <path d="M263.861 172.262L253.531 174.575L253.898 176.211L264.227 173.899L263.861 172.262Z" fill="#0958BF"/>
      <path d="M251.154 175.107L224.516 181.071L224.882 182.708L251.52 176.744L251.154 175.107Z" fill="#0958BF"/>
      <path d="M169.767 141.508L142.829 142.887L142.914 144.553L169.852 143.174L169.767 141.508Z" fill="#0958BF"/>
      <path d="M319.612 191.723H244.246V193.383H319.612V191.723Z" fill="#0958BF"/>
      <path d="M337.702 191.723H331.567V193.383H337.702V191.723Z" fill="#0958BF"/>
      <path d="M108.375 403.226H101.833V404.886H108.375V403.226Z" fill="#0958BF"/>
      <path d="M232.183 237.766H230.523V253.673H232.183V237.766Z" fill="white"/>
      <path d="M238.211 253.673H236.55V268.741H238.211V253.673Z" fill="white"/>
      <path d="M232.183 297.715H230.523V312.792H232.183V297.715Z" fill="white"/>
      <path d="M238.211 282.688H236.55V298.545H238.211V282.688Z" fill="white"/>
      <path d="M244.238 268.808H242.578V283.884H244.238V268.808Z" fill="white"/>
    </svg>

    <Paragraph
      className='clean-story__text'
      text={t('Empty withdrawal history')}
      size={TextSize.P_18}
      color='#859AB6'
      align={Align.CENTER}
    />
  </div>
}
