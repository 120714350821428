import { set } from 'idb-keyval'
import initialState from './initialState'
import { SET_TYPES_LIST } from './actionTypes'

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TYPES_LIST:
      set('types', action.types)
      return {
        ...state,
        ...action.types
      }
    default:
      return state
  }
}
