import React from 'react'


type SubmitButtonData = {
  text: string,

  onClick?: () => void,
  isDisabled?: boolean,
  className?: string,
  primary?: boolean,
  outline?: boolean,
  id?: string
}

SubmitButton.defaultProps = {
  primary: true,
  isDisabled: false,
  outline: false
}

export default function SubmitButton(props: SubmitButtonData) {

  const getClass = () => {
    const list = ['btn', 'custom-button']
    props.primary && list.push('btn-primary')
    props.outline && list.push('btn-outline')
    props.isDisabled && list.push('btn-disabled')
    props.className && list.push(props.className)
    return list.join(' ')
  }

  return <input
    id={props.id}
    disabled={props.isDisabled}
    onClick={props.onClick}
    type="submit"
    className={getClass()}
    value={props.text}
  />
}
