import { getFPVisitorId } from '../../utils/helpers'
import { axiosV3 } from '../../axios'
import Axios from 'axios'
import { clearUserData, setUserId } from '../user/actions'
import { setTypes } from '../types/actions'
import store from '../index'
import { resetAuthData, setAccessToken } from './actions'
import { clearSidebarOptions, hideSidebar } from '../sidebar/actions'
import { stopIntercomChat } from '../intercom/actions'
import { resetTaskList } from '../taskList/actions'
import ErrorHandler from '../../utils/ErrorHandler'
import Notification from '../../components/notification/Notification'
import { changePhoneModel, setPhoneVerified } from '../phone/actions'
import { userAction } from '../binom/api'
import { deleteSeoData } from '../binom/actions'
import { del, set } from 'idb-keyval'
import moment from 'moment'
import i18n from 'i18next'
import { FAILED_FINGERPRINT_ID, RESEND_SMS_TIMEOUT } from '../../utils/constants'
import FormDataV3 from "../../utils/formDataApiV3"

export async function uploadDataForLogin(phone, password, visitorId) {
  const data = new FormDataV3()
  data.addParam('type', 'users')
  data.addAttr('phone', phone.replace(/\+/, ''))
  data.addAttr('password', password)
  data.addAttr('password_confirmation', password)
  data.addMeta('role_name', 'uploader')
  if (visitorId !== FAILED_FINGERPRINT_ID) {
    data.addMeta('fingerprint_id', visitorId)
  }
  try {
    const result = await axiosV3.post('/users/login?include=roles', data)
    if (result.included[0].attributes?.name !== 'uploader') {
      Notification({ noticeType: 'error', text: i18n.t('Insufficient rights to login') })
      return false
    }
    store.dispatch(setAccessToken(result.meta.access_token))
    store.dispatch(setUserId(result.data.id))
    getFPVisitorId({ linkedId: result.data.id })
    store.dispatch(setTypes())
    return true
  } catch (e) {
    if (e.offline) return
    const errorMessage = e.response?.data?.errors[0]?.detail
    if (!errorMessage) {
      Notification({ noticeType: 'error', text: i18n.t('Default login error') })
      return
    }
    if (e.response?.status === 401) {
      Notification({ noticeType: 'error', text: errorMessage })
    } else {
      Notification({ noticeType: 'error', text: errorMessage })
    }
  }
}

export function logout() {
  store.dispatch(hideSidebar())
  store.dispatch(stopIntercomChat())
  store.dispatch(clearSidebarOptions())
  store.dispatch(resetTaskList())
  store.dispatch(resetAuthData())
  store.dispatch(clearUserData())
  store.dispatch(setUserId())
  del('whatsapp_phone')
}

export async function sendSms(model) {
  const { phone, codeCountry, resolution, alreadyReqCode, typePhone } = model
  const time = moment().add(RESEND_SMS_TIMEOUT, 'minutes').valueOf()
  const data = new FormDataV3()
  data.addParam('type', 'phone-verifications')
  data.addAttr('resolution', resolution)
    data.addAttr('phone', codeCountry + phone)
  if (typePhone) {
    data.addAttr('provider', typePhone)
    data.addAttr('resolution', true)
  }
  try {
    const response = await axiosV3.post('/phone-verifications', data)
    const message = response?.meta?.message
    Notification({ noticeType: 'info', text: message })
    if (!alreadyReqCode) userAction('event_2')
    set('confirmationRequest', { codeCountry, phone, time })
    return true
  } catch (reason) {
    if (reason.offline) return
    let errorMessage
    errorMessage = reason.response?.data?.error?.message
    if (!errorMessage) {
      errorMessage = ErrorHandler(reason, 'phone') ?? i18n.t('Send SMS error')
    } else if (errorMessage.includes('can\'t to deliver')) {
      errorMessage = i18n.t('This number cannot be used')
    }
    Notification({ noticeType: 'error', text: errorMessage })
    return false
  }
}

export async function verifyUser(codeCountry, phone, code, typePhone) {
  const data = new FormDataV3()
  data.addParam('type', 'phone-verifications')
  data.addAttr('code', code)
  data.addAttr('phone', codeCountry + phone)
  if (typePhone) {
    data.addAttr('provider', typePhone)
  }
  try {
    const result = await axiosV3.post('/phone-verifications/verify', data)
    const message = result?.meta?.message
    if (!message) return
    Notification({ noticeType: 'info', text: message })
    store.dispatch(setPhoneVerified(true))
    set(typePhone ? `${typePhone}_phone` : 'verified_phone', { codeCountry, phone })
    store.dispatch(changePhoneModel({ codeCountry, phone }))
  } catch (reason) {
    if (reason.offline) return
    const errorMessage = reason.response?.data?.error?.message ?? i18n.t('Verify user error')
    reason.response?.status === 401
      ? Notification({ noticeType: 'error', text: i18n.t('Verify user error') })
      : Notification({ noticeType: 'error', text: errorMessage })
  }
}

export async function uploadDataForRegister(model) {
  const state = store.getState()
  const data = new FormDataV3()
  const cnv_id = state.binom.cnv_id
  const utm_source = state.binom.utm_source
  const phoneModel = state.phone.model
  const phone = phoneModel.codeCountry + phoneModel.phone
  data.addParam('type', 'users')
  data.addAttr('phone', phone)
  data.addMeta('promo_code', model.promo)
  data.addAttr('name', model.name)
  data.addAttr('first_name', model.name)
  data.addAttr('last_name', '')
  data.addAttr('social_account', model.social)
  data.addAttr('email', model.email)
  data.addAttr('password', model.password)
  data.addAttr('password_confirmation', model.confirm)
  data.addMeta('role_name', 'uploader')
  if (cnv_id) {
    data.addMeta('binom_id', cnv_id)
    data.addMeta('utm_source', utm_source)
  }
  if (model.fingerprintId !== FAILED_FINGERPRINT_ID) {
    data.addMeta('fingerprint_id', model.fingerprintId)
  }
  try {
    const result = await axiosV3.post('/users/register', data)
    if (process.env.REACT_APP_ENV === 'prod') {
      window.dataLayer.push({event: 'registered'})
    }

    store.dispatch(setAccessToken(result.meta.access_token))
    store.dispatch(setUserId(result.data.id))
    getFPVisitorId({ linkedId: result.data.id })
    store.dispatch(setTypes())
    store.dispatch(deleteSeoData())
    return {
      success: true
    }
  } catch (e) {
    if (e.offline) return
    let result = {
      success: false,
      validationErrors: []
    }

    if (e?.response?.status === 422) {
      const errors = e.response.data.errors
      for (const field in errors) {
        if (!errors.hasOwnProperty(field)) continue
        result.validationErrors.push({
          name: field,
          errorMessage: errors[0].detail
        })
      }
    } else {
      Notification({ noticeType: 'error', text: i18n.t('Register error') })
    }
    return result
  }
}

export async function checkPromoCode(promo, signal) {
  const transformPromo = promo.includes('#') ? promo.replace('#', '%23') : promo
  try {
    await axiosV3.get(`/union-promo-codes/${transformPromo}`, { signal })
    return 'valid'
  } catch (reason) {
    if (reason.offline || Axios.isCancel(reason)) return
    Notification({ noticeType: 'error', text: i18n.t('Promo code error'), notClose: true })
  }
}
