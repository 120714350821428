import React from 'react'
import MqAfterSmall from '../mq/MqAfterSmall'
import MqSmall from '../mq/MqSmall'

export default function LoginFormIllustration() {
  return (
    <div className="login-form-illustration">
      <MqSmall>
        <svg width="220" height="122" viewBox="0 0 220 122" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M142.096 5.1091C142.328 2.9768 142.948 1.52481 144.417 0.709116C148.771 -1.66011 159.703 2.1848 164.249 8.12479C170.588 16.4069 164.805 29.1398 158.649 29.9555C151.484 30.9167 140.882 16.1091 142.096 5.1091Z" fill="#E3F0FF"/>
          <path d="M63.3311 68.8435C44.2436 86.0383 27.3994 24.8484 5.20923 32.3453C-16.19 39.5801 36.135 92.9668 22.6535 120H208.723C208.886 117.822 208.976 115.669 208.995 113.547C209.378 70.2881 188.5 38.0894 175.771 43.2242C162.062 48.7561 145.25 95.8389 132.99 70.6304C103.899 10.827 70.7553 -1.43495 66.9136 22.4659C64.4909 37.528 76.578 56.9171 63.3311 68.8435Z" fill="#E3F0FF"/>
          <path d="M127.919 113.456C131.08 111.74 132.251 107.785 130.535 104.624C128.818 101.463 124.864 100.291 121.703 102.008C118.541 103.724 117.37 107.679 119.087 110.84C120.803 114.001 124.757 115.173 127.919 113.456Z" fill="#4792F3"/>
          <path d="M94 98.5606L121.788 113L129 102.389L104.573 83L94 98.5606Z" fill="#FFC359"/>
          <path d="M101.439 99.4966C106.474 97.9245 109.281 92.5685 107.709 87.5335C106.137 82.4985 100.781 79.6913 95.7455 81.2634C90.7106 82.8354 87.9033 88.1915 89.4754 93.2265C91.0475 98.2615 96.4036 101.069 101.439 99.4966Z" fill="#FFC359"/>
          <path d="M78 98.3551L52.3561 116L44 106.206L65.7514 84L78 98.3551Z" fill="#FFC359"/>
          <path d="M82.2762 90.9483C82.6046 85.6838 78.6032 81.1499 73.3387 80.8214C68.0743 80.493 63.5403 84.4944 63.2119 89.7589C62.8834 95.0233 66.8849 99.5573 72.1493 99.8857C77.4138 100.214 81.9477 96.2127 82.2762 90.9483Z" fill="#FFC359"/>
          <path d="M66.4139 85.3069C66.102 86.147 65.9639 87.0419 66.008 87.9371L67.5263 119H103.471L104.992 87.9371C105.036 87.0419 104.898 86.147 104.586 85.3069C104.274 84.4668 103.795 83.699 103.178 83.0501C102.561 82.4013 101.818 81.885 100.995 81.5325C100.173 81.1801 99.2872 80.9989 98.3925 81H72.6075C71.7128 80.9989 70.8272 81.1801 70.0045 81.5325C69.1819 81.885 68.4394 82.4013 67.8221 83.0501C67.2048 83.699 66.7257 84.4668 66.4139 85.3069Z" fill="#FFC359"/>
          <path d="M93.2176 69.062L81.2793 68.3457L80.5031 81.2821L92.4415 81.9984L93.2176 69.062Z" fill="#FFAFAF"/>
          <path d="M91.9897 81.3507C91.9204 82.5351 91.5014 83.6724 90.7859 84.6188C90.0703 85.5651 89.0902 86.2781 87.9695 86.6675C86.8488 87.057 85.6378 87.1054 84.4896 86.8068C83.3414 86.5081 82.3074 85.8757 81.5186 84.9895C80.7297 84.1034 80.2212 83.0032 80.0574 81.8281C79.8937 80.6531 80.082 79.4558 80.5985 78.3877C81.1151 77.3196 81.9367 76.4287 82.9595 75.8274C83.9824 75.2262 85.1605 74.9417 86.3449 75.0099C87.1321 75.0553 87.9025 75.2553 88.6123 75.5985C89.3221 75.9418 89.9572 76.4216 90.4815 77.0104C91.0057 77.5993 91.4087 78.2858 91.6675 79.0305C91.9263 79.7752 92.0358 80.5637 91.9897 81.3507Z" fill="#FFAFAF"/>
          <path d="M212 117H10V122H212V117Z" fill="#4792F3"/>
          <path d="M48.5 117C52.0898 117 55 114.09 55 110.5C55 106.91 52.0898 104 48.5 104C44.9101 104 42 106.91 42 110.5C42 114.09 44.9101 117 48.5 117Z" fill="#FFC359"/>
          <path d="M114 114H91V117H114V114Z" fill="#0958BF"/>
          <path d="M159 84L157.82 109.74L157.488 117H112L113.512 84H159Z" fill="#A3C5F1"/>
          <path d="M137.633 103.207C138.986 101.886 139.061 99.7685 137.8 98.478C136.539 97.1876 134.421 97.2128 133.068 98.5342C131.716 99.8556 131.641 101.973 132.902 103.263C134.162 104.554 136.281 104.529 137.633 103.207Z" fill="white"/>
          <path d="M158 110L157.667 117H112L158 110Z" fill="#6AACFF"/>
          <path d="M99.6683 58.8596L97.9479 59L97 47.1597L98.7204 47.0194C99.4814 46.9564 100.247 47.0473 100.974 47.2867C101.7 47.5261 102.373 47.9095 102.955 48.4148C103.536 48.92 104.014 49.5374 104.362 50.2316C104.71 50.9257 104.92 51.683 104.981 52.4603C105.105 54.0278 104.615 55.5813 103.619 56.7807C102.623 57.9801 101.203 58.7276 99.6683 58.8596Z" fill="#0958BF"/>
          <path d="M76.73 62.5653L85.3836 61.869L86.1113 70.913L81.7845 71.2611C80.6369 71.3534 79.4997 70.9861 78.623 70.24C77.7463 69.4938 77.2019 68.43 77.1095 67.2824L76.7266 62.5225L76.73 62.5653Z" fill="#0958BF"/>
          <path d="M92.3457 43.896L96.544 43.5582C97.226 43.5033 97.912 43.5833 98.5631 43.7936C99.2142 44.0039 99.8174 44.3404 100.338 44.7838C100.859 45.2272 101.288 45.7689 101.6 46.378C101.911 46.987 102.1 47.6515 102.155 48.3335L102.569 53.4742C102.669 54.719 102.27 55.9525 101.461 56.9035C100.652 57.8545 99.4976 58.445 98.2528 58.5452L93.5548 58.9232L92.3457 43.896Z" fill="#0958BF"/>
          <path d="M97.9819 74.7462L82.4428 76L81.0319 58.4667C80.9288 57.1983 81.0763 55.922 81.4659 54.7108C81.8556 53.4995 82.4798 52.3771 83.3029 51.4078C84.1259 50.4385 85.1316 49.6412 86.2625 49.0616C87.3934 48.482 88.6272 48.1315 89.8934 48.0301C92.366 47.8345 94.8189 48.5988 96.7446 50.1649C98.6702 51.731 99.9211 53.9789 100.238 56.4433L101.963 69.7941C102.04 70.3834 101.996 70.982 101.836 71.554C101.675 72.126 101.4 72.6595 101.028 73.1222C100.656 73.585 100.195 73.9674 99.671 74.2466C99.1474 74.5257 98.5728 74.6956 97.9819 74.7462Z" fill="#FFAFAF"/>
          <path d="M84.179 46.878L85.4737 46.7738L86.7839 63.0576L76.8356 63.858L76.2205 56.2135C76.0398 53.9206 76.7764 51.6497 78.2685 49.8994C79.7607 48.1491 81.8864 47.0624 84.179 46.878Z" fill="#0958BF"/>
          <path d="M81.5988 64.9916L84 64.7944L83.8058 62.3004C83.7807 61.9731 83.6938 61.6542 83.55 61.3618C83.4061 61.0694 83.2083 60.8094 82.9677 60.5965C82.727 60.3836 82.4483 60.2221 82.1476 60.1211C81.8468 60.0201 81.5298 59.9817 81.2148 60.0081C80.578 60.0618 79.9879 60.376 79.574 60.8815C79.1602 61.3869 78.9565 62.0424 79.0078 62.7038C79.0328 63.0309 79.1198 63.3496 79.2637 63.6418C79.4075 63.9339 79.6055 64.1937 79.8462 64.4062C80.0869 64.6187 80.3656 64.7798 80.6663 64.8803C80.9671 64.9807 81.2839 65.0185 81.5988 64.9916Z" fill="#FFAFAF"/>
          <path d="M101 53.2252L91.5567 53.9782C89.7083 54.1237 87.8769 53.5372 86.4637 52.347C85.0506 51.1568 84.1707 49.4599 84.0167 47.6279C83.9724 47.1916 84.0163 46.7509 84.1459 46.3316C84.2755 45.9123 84.4881 45.5228 84.7714 45.186C85.0546 44.8492 85.4028 44.5717 85.7955 44.3699C86.1882 44.1681 86.6176 44.046 87.0585 44.0107C87.4994 43.9754 87.9429 44.0276 88.3632 44.1644C88.7835 44.3011 89.1721 44.5196 89.5062 44.807C89.8403 45.0944 90.1132 45.445 90.3089 45.8382C90.5047 46.2315 90.6194 46.6595 90.6462 47.0972C90.6544 47.1883 90.6983 47.2726 90.7684 47.3319C90.8386 47.3913 90.9294 47.421 91.0214 47.4147L100.465 46.6618L101 53.2252Z" fill="#0958BF"/>
          <path d="M97.7071 50.5561L92.7765 50.9877C92.319 51.0277 91.8587 50.9697 91.4218 50.8171C90.985 50.6646 90.5802 50.4203 90.2306 50.0985C89.8809 49.7766 89.5933 49.3833 89.3841 48.9411C89.1749 48.4989 89.0482 48.0164 89.0113 47.5213C88.9744 47.0261 89.028 46.5279 89.1689 46.0551C89.3099 45.5823 89.5356 45.1442 89.833 44.7658C90.1304 44.3874 90.4938 44.0761 90.9024 43.8497C91.3109 43.6233 91.7567 43.4862 92.2143 43.4462L97.1496 43.0198C97.6126 42.9683 98.0804 43.0175 98.5255 43.1645C98.9706 43.3114 99.3841 43.5531 99.7418 43.8754C100.099 44.1977 100.394 44.594 100.608 45.0412C100.822 45.4884 100.952 45.9774 100.989 46.4794C101.026 46.9815 100.97 47.4865 100.824 47.9649C100.679 48.4432 100.446 48.8853 100.14 49.265C99.8347 49.6448 99.462 49.9546 99.0441 50.1763C98.6263 50.398 98.1717 50.5271 97.7071 50.5561Z" fill="#0958BF"/>
          <path d="M101.971 72.7336C102.031 73.1773 101.997 73.6281 101.871 74.0587C101.744 74.4893 101.528 74.8907 101.235 75.2384C100.943 75.5862 100.579 75.8731 100.168 76.0817C99.7559 76.2903 99.3044 76.4162 98.8405 76.4518L92.0412 76.9784C91.1415 77.0486 90.2362 76.9473 89.3773 76.6805C88.5184 76.4136 87.7228 75.9865 87.0359 75.4235C86.349 74.8604 85.7843 74.1726 85.3744 73.3994C84.9644 72.6262 84.7172 71.7829 84.6469 70.9176L84 63.1998L86.5639 63C86.5937 63.3605 86.6972 63.7118 86.8683 64.0338C87.0394 64.3559 87.2749 64.6423 87.5613 64.8767C87.8477 65.1112 88.1793 65.289 88.5372 65.4001C88.8951 65.5112 89.2723 65.5534 89.6472 65.5242L100.885 64.6525L101.971 72.7336Z" fill="#0958BF"/>
          <path d="M96.8396 112.519C96.6178 111.854 96.1757 111.262 95.5692 110.817C94.9627 110.373 94.2192 110.095 93.4325 110.021C92.6459 109.946 91.8516 110.077 91.15 110.397C90.4484 110.717 89.8712 111.212 89.4912 111.819C89.1113 112.427 88.9458 113.119 89.0156 113.809C89.0854 114.498 89.3874 115.154 89.8834 115.694C90.3794 116.233 91.0471 116.632 91.802 116.839C92.557 117.047 93.3653 117.053 94.1246 116.859C95.1421 116.598 95.9996 115.994 96.5087 115.181C97.0178 114.367 97.1368 113.409 96.8396 112.519Z" fill="#FFAFAF"/>
          <path d="M88.88 113.16C89.0461 113.73 89.0396 114.336 88.8613 114.902C88.683 115.468 88.3409 115.968 87.8782 116.339C87.4155 116.711 86.8531 116.937 86.262 116.989C85.6709 117.04 85.0778 116.916 84.5575 116.63C84.0373 116.345 83.6133 115.912 83.3392 115.386C83.0651 114.859 82.9533 114.264 83.0178 113.674C83.0823 113.084 83.3202 112.527 83.7015 112.072C84.0828 111.617 84.5904 111.286 85.16 111.12C85.9238 110.897 86.7449 110.987 87.4425 111.37C88.1401 111.752 88.6572 112.396 88.88 113.16Z" fill="#FFAFAF"/>
          <path d="M93 117H86.7505L86 111.75L91.9994 110L93 117Z" fill="#FFAFAF"/>
          <path d="M50.2421 104L87 111.282L86.4527 117H84.5254H81.6748H49L50.2421 104Z" fill="#FFC359"/>
          <path d="M86.3423 116.995L87 111.474L84 111V117L86.3423 116.995Z" fill="#FFAFAF"/>
          <path d="M69 105H64V110H69V105Z" fill="#FFC359"/>
          <path d="M60.1953 105.797L60.0117 106.734L84.2963 111.49L84.4798 110.553L60.1953 105.797Z" fill="white"/>
          <path d="M99.9897 67C100.019 67.3598 99.9844 67.7225 99.8866 68.0674C99.7887 68.4123 99.63 68.7327 99.4194 69.0102C99.2087 69.2877 98.9504 69.517 98.6591 69.6849C98.3679 69.8528 98.0493 69.956 97.7217 69.9887C97.394 70.0214 97.0637 69.9829 96.7497 69.8755C96.4356 69.768 96.1438 69.5937 95.8911 69.3624C95.6384 69.1311 95.4296 68.8475 95.2767 68.5276C95.1238 68.2077 95.0298 67.8579 95 67.4981L99.9897 67Z" fill="white"/>
          <path d="M209 84H199V117H209V84Z" fill="#FF809F"/>
          <path d="M206 88H203V101H206V88Z" fill="white"/>
          <path d="M206 109C206 109.396 205.912 109.782 205.747 110.111C205.582 110.44 205.348 110.696 205.074 110.848C204.8 110.999 204.498 111.039 204.207 110.962C203.916 110.884 203.649 110.694 203.439 110.414C203.23 110.135 203.087 109.778 203.029 109.39C202.971 109.002 203.001 108.6 203.114 108.235C203.228 107.869 203.42 107.557 203.667 107.337C203.913 107.117 204.203 107 204.5 107C204.898 107 205.279 107.211 205.561 107.586C205.842 107.961 206 108.47 206 109Z" fill="#0958BF"/>
          <path d="M199 84H189V117H199V84Z" fill="#6AACFF"/>
          <path d="M196 88H193V101H196V88Z" fill="white"/>
          <path d="M196 109C196 109.396 195.912 109.782 195.747 110.111C195.582 110.44 195.348 110.696 195.074 110.848C194.8 110.999 194.498 111.039 194.207 110.962C193.916 110.884 193.649 110.694 193.439 110.414C193.23 110.135 193.087 109.778 193.029 109.39C192.971 109.002 193.001 108.6 193.114 108.235C193.228 107.869 193.42 107.557 193.667 107.337C193.913 107.117 194.203 107 194.5 107C194.898 107 195.279 107.211 195.561 107.586C195.842 107.961 196 108.47 196 109Z" fill="#0958BF"/>
          <path d="M189 84H179V117H189V84Z" fill="#FFC359"/>
          <path d="M186 88H183V101H186V88Z" fill="white"/>
          <path d="M186 109C186 109.396 185.883 109.782 185.663 110.111C185.443 110.44 185.131 110.696 184.765 110.848C184.4 110.999 183.998 111.039 183.61 110.962C183.222 110.884 182.865 110.694 182.586 110.414C182.306 110.135 182.116 109.778 182.038 109.39C181.961 109.002 182.001 108.6 182.152 108.235C182.304 107.869 182.56 107.557 182.889 107.337C183.218 107.117 183.604 107 184 107C184.53 107 185.039 107.211 185.414 107.586C185.789 107.961 186 108.47 186 109Z" fill="#0958BF"/>
          <path d="M30.027 117H22.973L22 103H31L30.027 117Z" fill="#FF809F"/>
          <path d="M31 92.6199C31 93.7816 30.5041 94.8957 29.6215 95.7171C28.7388 96.5385 27.5416 97 26.2934 97V93.381C25.4756 93.4031 24.6614 93.2723 23.899 92.9963C23.1365 92.7203 22.4413 92.3048 21.8545 91.7743C21.2677 91.2438 20.8012 90.6092 20.4827 89.9079C20.1641 89.2067 20 88.4531 20 87.6917C20 86.9304 20.1641 86.1768 20.4827 85.4755C20.8012 84.7743 21.2677 84.1396 21.8545 83.6091C22.4413 83.0786 23.1365 82.6631 23.899 82.3871C24.6614 82.1111 25.4756 81.9803 26.2934 82.0024V88.2255C26.9126 88.2261 27.5255 88.3403 28.0973 88.5615C28.669 88.7828 29.1883 89.1067 29.6254 89.5148C30.0625 89.923 30.4089 90.4073 30.6448 90.9401C30.8806 91.4729 31.0013 92.0437 31 92.6199Z" fill="white"/>
          <path d="M26.1513 97.9997H25.6785V94.3515C24.0974 94.243 22.6202 93.5128 21.5608 92.3161C20.5013 91.1195 19.9431 89.5506 20.0046 87.9427C20.0661 86.3348 20.7426 84.8146 21.8904 83.705C23.0381 82.5955 24.5666 81.9839 26.1513 82.0003H26.6241V88.2743C27.8596 88.3852 29.0049 88.9776 29.8196 89.9273C30.6344 90.8769 31.0553 92.11 30.9942 93.3679C30.933 94.6258 30.3945 95.8108 29.4916 96.6743C28.5887 97.5379 27.3916 98.0129 26.1513 97.9997ZM25.6785 82.9844C24.3544 83.0997 23.1256 83.7308 22.2496 84.7457C21.3737 85.7605 20.9186 87.0802 20.9797 88.4282C21.0408 89.7762 21.6134 91.0479 22.5775 91.9767C23.5416 92.9055 24.8223 93.4194 26.1513 93.4106H26.6241V97.0109C27.5926 96.8815 28.4767 96.3838 29.0979 95.6184C29.7191 94.8529 30.0312 93.8767 29.9712 92.8867C29.9112 91.8967 29.4836 90.9667 28.7746 90.2844C28.0656 89.602 27.1281 89.2182 26.1513 89.2104H25.6785V82.9844Z" fill="#0958BF"/>
          <path d="M112.709 84.4719L111.189 117.181L112.143 117.225L113.663 84.5162L112.709 84.4719Z" fill="white"/>
          <path d="M58 121H0V122H58V121Z" fill="#0958BF"/>
          <path d="M195 121H89V122H195V121Z" fill="#0958BF"/>
          <path d="M220 121H200V122H220V121Z" fill="#0958BF"/>
          <path d="M27 29H26V32H27V29Z" fill="#A3C5F1"/>
          <path d="M27 46H26V48H27V46Z" fill="#A3C5F1"/>
          <path d="M21.8271 30L21 30.4775L22.3395 32.7976L23.1666 32.3201L21.8271 30Z" fill="#A3C5F1"/>
          <path d="M30.5244 44.4102L29.6973 44.8877L31.0368 47.2078L31.8639 46.7302L30.5244 44.4102Z" fill="#A3C5F1"/>
          <path d="M18.4775 33.0001L18 33.8311L20.3307 35.1705L20.8082 34.3396L18.4775 33.0001Z" fill="#A3C5F1"/>
          <path d="M32.4775 42.0001L32 42.8311L34.3307 44.1705L34.8082 43.3396L32.4775 42.0001Z" fill="#A3C5F1"/>
          <path d="M20 38H17V39H20V38Z" fill="#A3C5F1"/>
          <path d="M36 38H33V39H36V38Z" fill="#A3C5F1"/>
          <path d="M20.7266 41.8289L18.4062 43.168L18.8836 43.9952L21.2039 42.6561L20.7266 41.8289Z" fill="#A3C5F1"/>
          <path d="M34.6465 33.8094L32.3262 35.1484L32.8036 35.9756L35.1238 34.6366L34.6465 33.8094Z" fill="#A3C5F1"/>
          <path d="M22.9777 44.3975L21.6387 46.7178L22.4659 47.1952L23.8049 44.8749L22.9777 44.3975Z" fill="#A3C5F1"/>
          <path d="M31.0071 30.4741L29.668 32.7944L30.4952 33.2718L31.8343 30.9515L31.0071 30.4741Z" fill="#A3C5F1"/>
          <path d="M144 44H143V47H144V44Z" fill="#A3C5F1"/>
          <path d="M144 60H143V63H144V60Z" fill="#A3C5F1"/>
          <path d="M139.142 45.2119L138.314 45.6895L139.654 48.0095L140.481 47.532L139.142 45.2119Z" fill="#A3C5F1"/>
          <path d="M147.179 59.1318L146.352 59.6094L147.691 61.9294L148.518 61.4519L147.179 59.1318Z" fill="#A3C5F1"/>
          <path d="M135.546 48.4786L135.068 49.3057L137.388 50.6451L137.866 49.818L135.546 48.4786Z" fill="#A3C5F1"/>
          <path d="M149.45 56.501L148.973 57.3281L151.293 58.6676L151.77 57.8405L149.45 56.501Z" fill="#A3C5F1"/>
          <path d="M137 53H134V54H137V53Z" fill="#A3C5F1"/>
          <path d="M153 53H150V54H153V53Z" fill="#A3C5F1"/>
          <path d="M137.389 56.5354L135.068 57.8745L135.546 58.7017L137.866 57.3626L137.389 56.5354Z" fill="#A3C5F1"/>
          <path d="M151.305 48.4954L148.984 49.8345L149.462 50.6617L151.782 49.3226L151.305 48.4954Z" fill="#A3C5F1"/>
          <path d="M139.673 59.1541L138.334 61.4741L139.161 61.9517L140.501 59.6316L139.673 59.1541Z" fill="#A3C5F1"/>
          <path d="M147.679 45.2046L146.34 47.5249L147.167 48.0023L148.506 45.682L147.679 45.2046Z" fill="#A3C5F1"/>
        </svg>
      </MqSmall>
      <MqAfterSmall>
        <svg width="480" height="284" viewBox="0 0 480 284" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M417.079 266.911H52.6646C5.8674 214.871 -10.471 155.909 6.5805 143.592C29.8926 126.751 94.6693 192.136 117.473 180.558C182.632 147.472 58.7836 45.1846 101.743 32.6949C141.267 21.2119 171.27 114.937 205.268 88.5998C228.863 70.3318 207.334 40.6333 211.649 17.5625C218.492 -19.0468 277.527 -0.265017 329.342 91.3368C351.181 129.949 381.126 57.8316 405.544 49.3583C428.216 41.4933 465.402 90.8125 464.721 157.073C464.375 191.087 450.113 230.145 417.079 266.911Z"
            fill="#E3F0FF"/>
          <path d="M245.385 186.992L268.424 266.382H271.072L252.233 186.992H245.385Z" fill="#6AACFF"/>
          <path d="M221.292 186.992L198.252 266.382H195.604L214.444 186.992H221.292Z" fill="#6AACFF"/>
          <path
            d="M233.336 210.325C254.765 210.325 272.137 192.953 272.137 171.524C272.137 150.094 254.765 132.723 233.336 132.723C211.906 132.723 194.535 150.094 194.535 171.524C194.535 192.953 211.906 210.325 233.336 210.325Z"
            fill="#FF809F"/>
          <path
            d="M270.91 183.17L269.908 182.855C272.734 173.683 272.027 163.785 267.926 155.107L268.87 154.656C273.087 163.573 273.814 173.744 270.91 183.17Z"
            fill="#0958BF"/>
          <path
            d="M221.643 209.083C214.594 206.866 208.308 202.717 203.5 197.106C198.691 191.496 195.552 184.649 194.44 177.344L195.489 177.192C196.571 184.303 199.626 190.967 204.307 196.427C208.988 201.888 215.107 205.925 221.968 208.081L221.643 209.083Z"
            fill="#0958BF"/>
          <path
            d="M267.958 190.196L267.035 189.697C267.697 188.47 268.292 187.208 268.818 185.917L269.788 186.31C269.249 187.638 268.638 188.935 267.958 190.196Z"
            fill="#0958BF"/>
          <path
            d="M149.41 239.431C147.896 239.431 146.416 238.982 145.157 238.141C143.898 237.299 142.917 236.104 142.338 234.705C141.758 233.306 141.607 231.767 141.902 230.282C142.197 228.797 142.926 227.433 143.997 226.362C145.068 225.292 146.432 224.563 147.917 224.267C149.402 223.972 150.941 224.123 152.34 224.703C153.739 225.282 154.934 226.263 155.775 227.522C156.617 228.781 157.065 230.261 157.065 231.775C157.063 233.805 156.255 235.75 154.82 237.186C153.385 238.621 151.44 239.428 149.41 239.431ZM149.41 225.169C148.103 225.169 146.826 225.556 145.74 226.282C144.653 227.008 143.806 228.04 143.306 229.247C142.806 230.454 142.676 231.783 142.93 233.064C143.185 234.346 143.815 235.523 144.739 236.447C145.662 237.371 146.84 238 148.121 238.255C149.403 238.51 150.731 238.379 151.938 237.879C153.146 237.379 154.177 236.532 154.903 235.446C155.629 234.359 156.017 233.082 156.017 231.775C156.015 230.024 155.319 228.344 154.08 227.105C152.842 225.867 151.162 225.17 149.41 225.169Z"
            fill="#0958BF"/>
          <path d="M174.578 231.775H124.242V266.382H174.578V231.775Z" fill="#FFAFAF"/>
          <path d="M174.578 231.775H169.335V266.382H174.578V231.775Z" fill="#FF809F"/>
          <path d="M169.335 248.555H124.242V249.603H169.335V248.555Z" fill="#0958BF"/>
          <path d="M150.459 248.555H143.118V253.274H150.459V248.555Z" fill="white"/>
          <path
            d="M252.458 186.017C252.461 188.47 251.991 190.9 251.074 193.175H215.608C214.555 190.56 214.093 187.746 214.255 184.932C214.417 182.119 215.2 179.376 216.546 176.9C217.892 174.424 219.769 172.277 222.043 170.611C224.316 168.945 226.929 167.803 229.696 167.266C232.462 166.728 235.314 166.809 238.045 167.503C240.777 168.196 243.321 169.485 245.496 171.277C247.671 173.069 249.424 175.32 250.627 177.868C251.831 180.416 252.456 183.199 252.458 186.017Z"
            fill="#0958BF"/>
          <path
            d="M233.336 191.26C236.232 191.26 238.579 188.913 238.579 186.017C238.579 183.121 236.232 180.773 233.336 180.773C230.44 180.773 228.092 183.121 228.092 186.017C228.092 188.913 230.44 191.26 233.336 191.26Z"
            fill="#0958BF"/>
          <path
            d="M279.721 161.908C283.192 160.023 284.478 155.681 282.594 152.21C280.709 148.739 276.367 147.453 272.896 149.337C269.425 151.222 268.138 155.564 270.023 159.035C271.908 162.507 276.25 163.793 279.721 161.908Z"
            fill="#4792F3"/>
          <path d="M242.606 145.805L272.949 161.945L280.825 150.084L254.152 128.413L242.606 145.805Z" fill="#FFC359"/>
          <path
            d="M250.647 146.58C256.176 144.854 259.258 138.973 257.532 133.445C255.806 127.916 249.925 124.834 244.396 126.56C238.868 128.286 235.785 134.167 237.512 139.696C239.238 145.224 245.119 148.307 250.647 146.58Z"
            fill="#FFC359"/>
          <path d="M225.114 145.165L196.858 164.739L187.65 153.875L211.618 129.241L225.114 145.165Z" fill="#FFC359"/>
          <path
            d="M229.606 137.194C229.967 131.414 225.573 126.435 219.793 126.075C214.012 125.714 209.034 130.108 208.673 135.888C208.313 141.669 212.706 146.647 218.487 147.007C224.267 147.368 229.245 142.974 229.606 137.194Z"
            fill="#FFC359"/>
          <path
            d="M221.722 200.331C225.674 200.331 228.879 197.127 228.879 193.174C228.879 189.221 225.674 186.017 221.722 186.017C217.769 186.017 214.564 189.221 214.564 193.174C214.564 197.127 217.769 200.331 221.722 200.331Z"
            fill="#0958BF"/>
          <path
            d="M244.945 200.331C248.897 200.331 252.102 197.127 252.102 193.174C252.102 189.221 248.897 186.017 244.945 186.017C240.992 186.017 237.787 189.221 237.787 193.174C237.787 197.127 240.992 200.331 244.945 200.331Z"
            fill="#0958BF"/>
          <path
            d="M255.022 133.761L252.458 186.017H214.218L211.66 133.761C211.611 132.767 211.764 131.774 212.111 130.841C212.458 129.908 212.991 129.055 213.677 128.335C214.364 127.615 215.19 127.041 216.105 126.65C217.02 126.259 218.005 126.057 219 126.059H247.682C248.677 126.057 249.662 126.259 250.577 126.65C251.492 127.041 252.318 127.615 253.004 128.335C253.691 129.055 254.224 129.908 254.571 130.841C254.918 131.774 255.071 132.767 255.022 133.761Z"
            fill="#FFC359"/>
          <path
            d="M252.453 186.017L252.102 193.174V193.525C252.015 195.362 251.225 197.096 249.894 198.365C248.563 199.634 246.794 200.342 244.955 200.342H221.737C219.898 200.342 218.129 199.634 216.798 198.365C215.468 197.096 214.677 195.362 214.591 193.525V193.174L214.239 186.017H252.453Z"
            fill="#0958BF"/>
          <path
            d="M250.919 264.213C252.606 262.444 252.54 259.643 250.772 257.956C249.004 256.268 246.203 256.334 244.515 258.102C242.828 259.87 242.894 262.672 244.662 264.359C246.43 266.046 249.231 265.981 250.919 264.213Z"
            fill="#0958BF"/>
          <path
            d="M246.088 200.441C249.438 200.441 252.154 197.725 252.154 194.374C252.154 191.024 249.438 188.308 246.088 188.308C242.737 188.308 240.021 191.024 240.021 194.374C240.021 197.725 242.737 200.441 246.088 200.441Z"
            fill="#0958BF"/>
          <path d="M243.298 261.139L240.021 194.375H252.149V261.139H243.298Z" fill="#0958BF"/>
          <path
            d="M256.595 266.387H243.293V261.144H252.149L257.23 263.912C257.438 264.025 257.612 264.193 257.733 264.397C257.854 264.601 257.917 264.834 257.917 265.071C257.917 265.244 257.882 265.416 257.816 265.576C257.749 265.736 257.652 265.881 257.529 266.003C257.407 266.126 257.261 266.222 257.101 266.288C256.94 266.354 256.769 266.388 256.595 266.387Z"
            fill="#FFAFAF"/>
          <path
            d="M279.77 204.834C280.318 202.452 278.83 200.077 276.448 199.53C274.066 198.983 271.692 200.47 271.144 202.852C270.597 205.234 272.084 207.609 274.466 208.156C276.848 208.703 279.223 207.216 279.77 204.834Z"
            fill="#0958BF"/>
          <path
            d="M214.066 177.297C215.197 177.699 216.183 178.428 216.9 179.391C217.617 180.354 218.032 181.508 218.092 182.707C218.153 183.906 217.856 185.096 217.24 186.126C216.624 187.156 215.716 187.981 214.631 188.495C213.547 189.009 212.334 189.19 211.146 189.014C209.959 188.839 208.85 188.315 207.96 187.509C207.071 186.703 206.44 185.651 206.148 184.487C205.857 183.322 205.917 182.098 206.322 180.967C206.863 179.455 207.983 178.219 209.435 177.531C210.887 176.843 212.552 176.759 214.066 177.297Z"
            fill="#0958BF"/>
          <path d="M273.993 208.028L209.997 188.722L214.066 177.302L276.966 199.691L273.993 208.028Z" fill="#0958BF"/>
          <path
            d="M277.438 213.995L281.9 201.458L276.961 199.696L273.993 208.039L274.9 213.759C274.935 213.995 275.032 214.216 275.183 214.401C275.333 214.586 275.531 214.726 275.755 214.808C275.918 214.865 276.092 214.889 276.265 214.879C276.439 214.869 276.608 214.826 276.765 214.75C276.921 214.675 277.061 214.569 277.176 214.439C277.292 214.31 277.381 214.159 277.438 213.995Z"
            fill="#FFAFAF"/>
          <path d="M241.621 113.162L228.513 112.376L227.66 126.58L240.769 127.367L241.621 113.162Z" fill="#FFAFAF"/>
          <path
            d="M240.666 126.441C240.59 127.735 240.133 128.977 239.351 130.011C238.569 131.045 237.499 131.824 236.274 132.249C235.05 132.675 233.727 132.728 232.473 132.401C231.219 132.075 230.089 131.384 229.228 130.416C228.366 129.448 227.81 128.246 227.631 126.963C227.453 125.679 227.658 124.371 228.223 123.205C228.787 122.038 229.684 121.065 230.802 120.408C231.919 119.751 233.206 119.44 234.5 119.515C235.36 119.564 236.201 119.783 236.977 120.158C237.752 120.533 238.446 121.057 239.018 121.7C239.591 122.343 240.031 123.093 240.314 123.907C240.597 124.72 240.716 125.581 240.666 126.441Z"
            fill="#FFAFAF"/>
          <path d="M216.169 197.694L207.334 186.851L216.08 178.498L226.504 187.847L216.169 197.694Z" fill="#0958BF"/>
          <path d="M144.24 166.019L143.69 171.262L133.261 266.382H130.613L138.441 166.019H144.24Z" fill="#6AACFF"/>
          <path d="M388.891 166.019L389.442 171.262L399.871 266.382H402.519L394.69 166.019H388.891Z" fill="#6AACFF"/>
          <path d="M399.409 166.019H132.789V171.262H399.409V166.019Z" fill="#4792F3"/>
          <path
            d="M192.783 166.008C196.733 166.008 199.935 162.806 199.935 158.856C199.935 154.906 196.733 151.704 192.783 151.704C188.834 151.704 185.631 154.906 185.631 158.856C185.631 162.806 188.834 166.008 192.783 166.008Z"
            fill="#FFC359"/>
          <path d="M263.905 162.872H238.736V166.018H263.905V162.872Z" fill="#0958BF"/>
          <path d="M313.879 130.102L312.574 158.117L312.207 166.019H261.87L263.543 130.102H313.879Z" fill="#A3C5F1"/>
          <path
            d="M290.389 150.655C291.874 149.204 291.956 146.88 290.572 145.463C289.188 144.046 286.862 144.073 285.377 145.524C283.891 146.975 283.809 149.3 285.193 150.717C286.578 152.134 288.904 152.106 290.389 150.655Z"
            fill="white"/>
          <path d="M312.574 158.116L312.207 166.018H261.87L312.574 158.116Z" fill="#6AACFF"/>
          <path
            d="M248.473 101.824L246.57 101.976L245.521 89.1506L247.425 88.9985C248.266 88.9304 249.114 89.0288 249.917 89.2881C250.721 89.5475 251.466 89.9627 252.109 90.51C252.752 91.0573 253.281 91.726 253.666 92.4779C254.051 93.2298 254.284 94.0502 254.351 94.8921C254.488 96.59 253.946 98.2728 252.844 99.572C251.742 100.871 250.171 101.681 248.473 101.824Z"
            fill="#0958BF"/>
          <path
            d="M223.516 106.029L233.018 105.265L233.817 115.195L229.066 115.578C227.806 115.679 226.557 115.276 225.595 114.456C224.632 113.637 224.034 112.469 223.933 111.209L223.512 105.982L223.516 106.029Z"
            fill="#0958BF"/>
          <path
            d="M240.662 85.5303L245.272 85.1594C246.021 85.0991 246.774 85.187 247.489 85.4179C248.204 85.6488 248.866 86.0182 249.439 86.5051C250.011 86.992 250.481 87.5868 250.823 88.2555C251.166 88.9243 251.373 89.6539 251.433 90.4027L251.887 96.0473C251.997 97.4141 251.56 98.7685 250.671 99.8127C249.782 100.857 248.515 101.505 247.149 101.615L241.99 102.03L240.662 85.5303Z"
            fill="#0958BF"/>
          <path
            d="M246.806 119.012L229.943 120.37L228.412 101.378C228.3 100.004 228.46 98.6219 228.883 97.3099C229.306 95.9979 229.983 94.7822 230.877 93.7322C231.77 92.6823 232.861 91.8187 234.088 91.1909C235.316 90.5631 236.654 90.1834 238.029 90.0736C240.712 89.8617 243.374 90.6896 245.463 92.386C247.553 94.0823 248.91 96.5172 249.255 99.1866L251.127 113.648C251.21 114.286 251.162 114.935 250.988 115.554C250.814 116.174 250.516 116.751 250.112 117.253C249.708 117.754 249.207 118.168 248.639 118.471C248.071 118.773 247.447 118.957 246.806 119.012Z"
            fill="#FFAFAF"/>
          <path
            d="M231.696 88.8048L233.117 88.6905L234.556 106.57L223.632 107.449L222.957 99.0554C222.759 96.5378 223.568 94.0443 225.206 92.1224C226.844 90.2006 229.178 89.0074 231.696 88.8048Z"
            fill="#0958BF"/>
          <path
            d="M229.005 108.793L231.857 108.567L231.626 105.715C231.597 105.341 231.493 104.976 231.322 104.641C231.152 104.307 230.917 104.01 230.631 103.766C230.345 103.523 230.014 103.338 229.657 103.222C229.299 103.107 228.923 103.063 228.549 103.093C227.792 103.155 227.091 103.514 226.6 104.092C226.108 104.67 225.866 105.42 225.927 106.176C225.957 106.55 226.06 106.915 226.231 107.249C226.402 107.583 226.637 107.88 226.923 108.123C227.209 108.366 227.54 108.551 227.897 108.665C228.254 108.78 228.631 108.824 229.005 108.793Z"
            fill="#FFAFAF"/>
          <path
            d="M250.539 96.1866L239.717 97.057C237.598 97.2252 235.5 96.5472 233.88 95.1713C232.261 93.7955 231.252 91.834 231.076 89.7162C231.025 89.2119 231.075 88.7024 231.224 88.2177C231.372 87.733 231.616 87.2828 231.941 86.8935C232.265 86.5041 232.664 86.1834 233.114 85.9501C233.564 85.7168 234.056 85.5756 234.562 85.5348C235.067 85.494 235.575 85.5544 236.057 85.7125C236.539 85.8705 236.984 86.1231 237.367 86.4553C237.75 86.7875 238.063 87.1928 238.287 87.6474C238.511 88.102 238.643 88.5968 238.673 89.1028C238.683 89.2081 238.733 89.3055 238.813 89.3741C238.894 89.4427 238.998 89.4771 239.103 89.4698L249.926 88.5994L250.539 96.1866Z"
            fill="#0958BF"/>
          <path
            d="M246.192 92.7113L240.813 93.1465C240.314 93.1868 239.811 93.1284 239.335 92.9745C238.858 92.8207 238.416 92.5745 238.035 92.25C237.653 91.9255 237.34 91.529 237.111 91.0832C236.883 90.6374 236.745 90.151 236.705 89.6518C236.664 89.1526 236.723 88.6503 236.877 88.1737C237.03 87.6971 237.277 87.2554 237.601 86.8739C237.926 86.4925 238.322 86.1786 238.768 85.9504C239.214 85.7221 239.7 85.5839 240.199 85.5436L245.584 85.1137C246.089 85.0618 246.6 85.1114 247.085 85.2596C247.571 85.4077 248.022 85.6514 248.413 85.9763C248.803 86.3012 249.124 86.7008 249.358 87.1516C249.591 87.6024 249.733 88.0954 249.773 88.6015C249.814 89.1077 249.753 89.6168 249.594 90.099C249.435 90.5813 249.181 91.0269 248.848 91.4098C248.514 91.7926 248.107 92.105 247.651 92.3285C247.195 92.552 246.699 92.6821 246.192 92.7113Z"
            fill="#0958BF"/>
          <path
            d="M251.646 117.475C251.713 117.988 251.675 118.508 251.534 119.006C251.394 119.503 251.154 119.966 250.829 120.368C250.504 120.77 250.1 121.101 249.643 121.342C249.186 121.583 248.684 121.728 248.169 121.77L240.619 122.378C239.62 122.459 238.614 122.342 237.661 122.034C236.707 121.725 235.823 121.232 235.061 120.582C234.298 119.932 233.671 119.137 233.215 118.244C232.76 117.351 232.486 116.377 232.408 115.378L231.689 106.464L234.536 106.233C234.57 106.65 234.684 107.055 234.874 107.427C235.064 107.799 235.326 108.13 235.644 108.401C235.962 108.672 236.33 108.877 236.728 109.005C237.125 109.134 237.544 109.182 237.96 109.149L250.44 108.142L251.646 117.475Z"
            fill="#0958BF"/>
          <path
            d="M245.416 160.634C245.184 159.837 244.72 159.128 244.084 158.595C243.448 158.062 242.668 157.73 241.843 157.64C241.019 157.55 240.186 157.707 239.45 158.091C238.714 158.475 238.109 159.068 237.71 159.796C237.312 160.524 237.138 161.353 237.212 162.18C237.285 163.007 237.601 163.793 238.122 164.439C238.642 165.086 239.342 165.564 240.134 165.812C240.925 166.061 241.773 166.069 242.569 165.835C243.636 165.523 244.536 164.799 245.069 163.824C245.603 162.849 245.728 161.701 245.416 160.634Z"
            fill="#FFAFAF"/>
          <path
            d="M237.074 161.981C237.248 162.578 237.242 163.214 237.055 163.807C236.868 164.401 236.509 164.925 236.024 165.315C235.538 165.705 234.949 165.942 234.329 165.996C233.709 166.05 233.087 165.92 232.541 165.62C231.996 165.321 231.551 164.867 231.264 164.315C230.976 163.763 230.859 163.138 230.927 162.52C230.994 161.901 231.244 161.317 231.644 160.84C232.044 160.363 232.576 160.016 233.173 159.842C233.974 159.608 234.835 159.702 235.567 160.103C236.298 160.505 236.841 161.18 237.074 161.981Z"
            fill="#FFAFAF"/>
          <path d="M241.39 166.008H234.054L233.173 159.842L240.215 157.786L241.39 166.008Z" fill="#FFAFAF"/>
          <path d="M194.157 151.841L234.657 159.779L234.054 166.014H231.931H228.79H192.789L194.157 151.841Z"
                fill="#FFC359"/>
          <path d="M234.059 166.008L234.657 159.774L231.93 159.239V166.014L234.059 166.008Z" fill="#FFAFAF"/>
          <path d="M215.084 152.364H209.143V158.305H215.084V152.364Z" fill="#FFC359"/>
          <path d="M205.362 153.498L205.16 154.527L231.825 159.749L232.027 158.72L205.362 153.498Z" fill="white"/>
          <path d="M221.455 191.655L221.152 192.659L239.59 198.222L239.893 197.218L221.455 191.655Z" fill="#4792F3"/>
          <path className="treeLogin"
                d="M59.2135 94.7766C57.672 101.923 55.087 108.803 53.0264 115.813C50.929 123.033 48.7582 130.374 48.7845 137.898C48.8674 147.797 52.6729 157.303 59.4443 164.524C62.0659 167.314 65.149 169.767 67.1782 173.003C69.2074 176.238 69.952 180.726 67.7026 183.825C64.4359 188.287 57.4465 187.322 52.6698 190.117C46.6556 193.63 45.8167 202.58 49.2459 208.642C52.675 214.703 68.2951 232.048 90.5794 227.754C100.542 225.835 114.075 216.339 113.687 206.193C113.283 195.623 103.2 187.06 103.536 176.489C103.761 169.563 108.417 163.711 112.45 158.075C116.482 152.438 120.236 145.569 118.38 138.894C115.648 129.068 102.991 126.032 96.882 117.879C92.9337 112.636 92.0633 105.725 91.8221 99.1601C91.5809 92.5953 91.7435 85.8681 89.5517 79.6757C84.1878 64.6429 65.3326 66.4361 59.2135 94.7766Z"
                fill="#A3C5F1"/>
          <path className="treeLogin" d="M83.1601 132.545H82.1114V246.457H83.1601V132.545Z" fill="#0958BF"/>
          <path className="treeLogin"
                d="M83.1548 165.463L82.1061 165.29C83.805 154.986 95.524 148.579 96.0221 148.312L96.5464 149.234C96.4101 149.297 84.7645 155.668 83.1548 165.463Z"
                fill="#0958BF"/>
          <path
            d="M82.1167 210.32C80.9736 203.362 72.6786 198.816 72.5842 198.784L73.0823 197.861C73.4441 198.055 81.9227 202.69 83.1549 210.168L82.1167 210.32Z"
            fill="#0958BF"/>
          <path
            d="M63.6705 242.524H101.423V253.404C101.423 255.109 101.088 256.797 100.436 258.372C99.7846 259.947 98.8288 261.379 97.6237 262.584C96.4185 263.79 94.9877 264.746 93.4129 265.399C91.838 266.051 90.1501 266.387 88.4454 266.387H76.6478C73.206 266.387 69.9052 265.02 67.4715 262.586C65.0377 260.152 63.6705 256.851 63.6705 253.41V242.524Z"
            fill="#FFC359"/>
          <path
            d="M70.3715 265.365C68.1893 264.222 66.3621 262.503 65.0883 260.394C63.8144 258.286 63.1427 255.868 63.1461 253.405V249.404H64.1948V253.405C64.1958 255.674 64.8172 257.899 65.9919 259.84C67.1665 261.781 68.8497 263.363 70.8591 264.416L70.3715 265.365Z"
            fill="#0958BF"/>
          <path d="M101.423 245.933H63.6705V246.981H101.423V245.933Z" fill="white"/>
          <path d="M480 266.382H1.59924V267.431H480V266.382Z" fill="#A3C5F1"/>
          <path d="M349.487 274.247H237.73V275.296H349.487V274.247Z" fill="#A3C5F1"/>
          <path d="M392.058 282.238H321.985V283.287H392.058V282.238Z" fill="#A3C5F1"/>
          <path d="M292.565 282.238H200.103V283.287H292.565V282.238Z" fill="#A3C5F1"/>
          <path d="M414.463 274.373H380.989V275.422H414.463V274.373Z" fill="#A3C5F1"/>
          <path d="M220.149 274.247H152.378V275.296H220.149V274.247Z" fill="#A3C5F1"/>
          <path d="M101.423 242H77.3766V243.049H101.423V242Z" fill="#0958BF"/>
          <path
            d="M324.261 22.8954C324.623 19.5921 325.588 17.3427 327.874 16.079C334.648 12.4087 351.658 18.3651 358.731 27.5673C368.594 40.3978 359.596 60.1234 350.017 61.3871C338.869 62.8762 322.374 39.9364 324.261 22.8954Z"
            fill="#E3F0FF"/>
          <path
            d="M249.165 110.869C249.2 111.248 249.159 111.63 249.046 111.993C248.933 112.356 248.75 112.693 248.506 112.985C248.263 113.277 247.964 113.519 247.627 113.695C247.291 113.872 246.923 113.981 246.544 114.015C246.165 114.05 245.783 114.009 245.42 113.896C245.057 113.783 244.72 113.599 244.428 113.356C244.136 113.112 243.894 112.814 243.718 112.477C243.541 112.14 243.432 111.772 243.398 111.393L249.165 110.869Z"
            fill="white"/>
          <path
            d="M373.184 221.633C374.084 216.12 370.344 210.921 364.831 210.021C359.318 209.121 354.119 212.861 353.22 218.374C352.32 223.887 356.06 229.086 361.573 229.986C367.086 230.886 372.285 227.146 373.184 221.633Z"
            fill="white"/>
          <path
            d="M363.225 230.669C361.12 230.669 359.064 230.045 357.314 228.876C355.564 227.707 354.201 226.046 353.396 224.102C352.59 222.158 352.38 220.019 352.79 217.955C353.201 215.891 354.214 213.996 355.702 212.508C357.19 211.02 359.085 210.007 361.149 209.596C363.213 209.186 365.352 209.396 367.296 210.201C369.24 211.007 370.901 212.37 372.071 214.12C373.24 215.869 373.863 217.926 373.863 220.03C373.859 222.851 372.737 225.554 370.743 227.549C368.749 229.543 366.045 230.665 363.225 230.669ZM363.225 210.44C361.328 210.44 359.474 211.003 357.897 212.057C356.32 213.11 355.09 214.608 354.364 216.36C353.639 218.113 353.449 220.041 353.819 221.901C354.189 223.762 355.102 225.47 356.443 226.812C357.785 228.153 359.493 229.066 361.354 229.436C363.214 229.806 365.142 229.616 366.895 228.891C368.647 228.165 370.145 226.936 371.199 225.358C372.252 223.781 372.815 221.927 372.815 220.03C372.812 217.488 371.801 215.05 370.003 213.252C368.205 211.454 365.767 210.443 363.225 210.44Z"
            fill="#0958BF"/>
          <path
            d="M353.098 230.229C356.806 228.678 358.555 224.414 357.004 220.706C355.453 216.998 351.189 215.25 347.481 216.801C343.773 218.352 342.025 222.615 343.576 226.323C345.127 230.032 349.39 231.78 353.098 230.229Z"
            fill="white"/>
          <path
            d="M350.295 231.303C348.75 231.304 347.24 230.847 345.956 229.99C344.671 229.133 343.669 227.914 343.078 226.487C342.486 225.061 342.331 223.491 342.631 221.976C342.932 220.461 343.675 219.069 344.767 217.977C345.858 216.885 347.25 216.141 348.764 215.839C350.279 215.537 351.849 215.692 353.276 216.282C354.703 216.873 355.922 217.874 356.781 219.158C357.639 220.442 358.097 221.951 358.097 223.496C358.094 225.565 357.271 227.548 355.809 229.012C354.346 230.475 352.363 231.299 350.295 231.303ZM350.295 216.742C348.957 216.741 347.65 217.137 346.538 217.879C345.425 218.622 344.558 219.677 344.046 220.912C343.534 222.147 343.4 223.507 343.66 224.818C343.921 226.13 344.565 227.335 345.51 228.28C346.456 229.226 347.661 229.87 348.972 230.13C350.284 230.391 351.643 230.257 352.878 229.744C354.113 229.232 355.169 228.365 355.911 227.253C356.653 226.141 357.049 224.833 357.048 223.496C357.045 221.706 356.333 219.989 355.067 218.724C353.801 217.458 352.085 216.745 350.295 216.742Z"
            fill="#0958BF"/>
          <path
            d="M376.569 221.079L375.882 226.359L375.751 227.408L373.885 241.817L371.609 259.345L371.383 261.097L370.697 266.382H341.192L340.505 261.097L340.369 260.048L338.717 247.296L336.137 227.408L336.006 226.359L335.319 221.079H376.569Z"
            fill="#6AACFF"/>
          <path d="M369.255 130.102H358.243V166.019H369.255V130.102Z" fill="#FF809F"/>
          <path d="M365.584 133.771H361.914V148.322H365.584V133.771Z" fill="white"/>
          <path
            d="M365.846 157.168C365.846 157.582 365.723 157.988 365.493 158.333C365.262 158.678 364.935 158.947 364.552 159.105C364.168 159.264 363.747 159.306 363.34 159.225C362.933 159.144 362.559 158.944 362.266 158.651C361.973 158.357 361.773 157.984 361.692 157.577C361.611 157.17 361.653 156.748 361.811 156.365C361.97 155.982 362.239 155.654 362.584 155.424C362.929 155.193 363.334 155.07 363.749 155.07C364.305 155.07 364.839 155.291 365.232 155.685C365.625 156.078 365.846 156.611 365.846 157.168Z"
            fill="#0958BF"/>
          <path d="M358.243 130.102H347.232V166.019H358.243V130.102Z" fill="#6AACFF"/>
          <path d="M354.573 133.771H350.903V148.322H354.573V133.771Z" fill="white"/>
          <path
            d="M354.835 157.168C354.835 157.582 354.712 157.988 354.482 158.333C354.251 158.678 353.924 158.947 353.541 159.105C353.157 159.264 352.736 159.306 352.329 159.225C351.922 159.144 351.548 158.944 351.255 158.651C350.962 158.357 350.762 157.984 350.681 157.577C350.6 157.17 350.641 156.748 350.8 156.365C350.959 155.982 351.228 155.654 351.573 155.424C351.918 155.193 352.323 155.07 352.738 155.07C353.294 155.07 353.828 155.291 354.221 155.685C354.614 156.078 354.835 156.611 354.835 157.168Z"
            fill="#0958BF"/>
          <path d="M347.232 130.102H336.221V166.019H347.232V130.102Z" fill="#FFC359"/>
          <path d="M343.562 133.771H339.892V148.322H343.562V133.771Z" fill="white"/>
          <path
            d="M343.824 157.168C343.824 157.582 343.701 157.988 343.471 158.333C343.24 158.678 342.913 158.947 342.53 159.105C342.146 159.264 341.725 159.306 341.318 159.225C340.911 159.144 340.537 158.944 340.244 158.651C339.95 158.357 339.751 157.984 339.67 157.577C339.589 157.17 339.63 156.748 339.789 156.365C339.948 155.982 340.217 155.654 340.562 155.424C340.907 155.193 341.312 155.07 341.727 155.07C342.283 155.07 342.817 155.291 343.21 155.685C343.603 156.078 343.824 156.611 343.824 157.168Z"
            fill="#0958BF"/>
          <path d="M172.408 166.118H164.805L163.756 149.969H173.456L172.408 166.118Z" fill="#FF809F"/>
          <path
            d="M172.911 139.498C172.911 140.77 172.406 141.99 171.506 142.89C170.606 143.79 169.386 144.295 168.113 144.295V140.331C167.28 140.356 166.45 140.212 165.673 139.91C164.895 139.608 164.187 139.152 163.589 138.571C162.99 137.99 162.515 137.295 162.19 136.527C161.865 135.759 161.698 134.934 161.698 134.1C161.698 133.266 161.865 132.44 162.19 131.672C162.515 130.904 162.99 130.209 163.589 129.628C164.187 129.047 164.895 128.592 165.673 128.289C166.45 127.987 167.28 127.844 168.113 127.868V134.684C168.744 134.685 169.369 134.81 169.952 135.052C170.535 135.295 171.064 135.65 171.51 136.097C171.955 136.544 172.308 137.074 172.549 137.658C172.789 138.241 172.912 138.866 172.911 139.498Z"
            fill="white"/>
          <path
            d="M168.113 144.814H167.589V140.829C165.835 140.711 164.197 139.913 163.022 138.606C161.847 137.299 161.228 135.585 161.296 133.829C161.364 132.073 162.115 130.412 163.388 129.2C164.661 127.988 166.356 127.32 168.113 127.338H168.638V134.191C170.008 134.313 171.278 134.96 172.182 135.997C173.085 137.034 173.552 138.381 173.484 139.755C173.417 141.129 172.819 142.423 171.818 143.367C170.817 144.31 169.489 144.829 168.113 144.814ZM167.589 128.413C166.12 128.539 164.758 129.229 163.786 130.337C162.815 131.446 162.31 132.887 162.378 134.359C162.445 135.832 163.08 137.221 164.15 138.235C165.219 139.25 166.639 139.811 168.113 139.802H168.638V143.734C169.712 143.593 170.692 143.049 171.381 142.213C172.07 141.377 172.417 140.311 172.35 139.229C172.283 138.148 171.809 137.132 171.023 136.387C170.236 135.642 169.197 135.222 168.113 135.214H167.589V128.413Z"
            fill="#0958BF"/>
          <path
            d="M373.885 241.816L371.609 259.345L371.383 261.096L370.697 266.382H341.192L340.505 261.096L340.369 260.048L338.717 247.296L373.885 241.816Z"
            fill="#4792F3"/>
          <path
            d="M345.02 260.526L343.976 260.62L343.924 260.048L340.914 227.408L340.872 226.931L341.921 226.837L341.973 227.408L344.978 260.048L345.02 260.526Z"
            fill="white"/>
          <path d="M347.736 226.867L346.689 226.925L348.543 260.615L349.59 260.557L347.736 226.867Z" fill="white"/>
          <path d="M354.161 226.17L353.112 226.191L353.786 259.885L354.834 259.864L354.161 226.17Z" fill="white"/>
          <path d="M358.322 226.862L357.699 260.55L358.747 260.569L359.371 226.881L358.322 226.862Z" fill="white"/>
          <path d="M364.143 226.833L362.266 260.521L363.313 260.58L365.19 226.891L364.143 226.833Z" fill="white"/>
          <path
            d="M371.048 226.931L371.006 227.408L367.975 260.048L367.923 260.62L366.879 260.526L366.921 260.048L369.952 227.408L370.004 226.837L371.048 226.931Z"
            fill="white"/>
          <path
            d="M336.012 226.359L336.143 227.408H375.756L375.887 226.359H336.012ZM371.52 260.048H340.374L340.51 261.097H371.389L371.614 259.345V259.319L371.52 260.048Z"
            fill="white"/>
          <path d="M263.022 130.083L261.354 165.998L262.401 166.047L264.07 130.132L263.022 130.083Z" fill="white"/>
          <path d="M203.018 170.737H132.789V171.786H203.018V170.737Z" fill="#0958BF"/>
          <path d="M353.351 170.737H237.022V171.786H353.351V170.737Z" fill="#0958BF"/>
          <path d="M399.409 170.737H369.255V171.786H399.409V170.737Z" fill="#0958BF"/>
          <path d="M363.948 170.737H359.109V171.786H363.948V170.737Z" fill="#0958BF"/>
          <path d="M350.641 220.555H335.319V221.603H350.641V220.555Z" fill="#0958BF"/>
          <path d="M382.735 266.382H353.105V267.431H382.735V266.382Z" fill="#0958BF"/>
          <path d="M208.382 266.382H143.779V267.431H208.382V266.382Z" fill="#0958BF"/>
          <path d="M107.196 266.382H83.0132V267.431H107.196V266.382Z" fill="#0958BF"/>
          <path d="M346.032 266.382H337.679V267.431H346.032V266.382Z" fill="#0958BF"/>
          <path
            d="M263.905 60.0912C263.215 60.0911 262.527 60.0279 261.849 59.9025C260.849 59.7173 259.944 59.1874 259.294 58.4047C258.643 57.6221 258.288 56.6363 258.289 55.6186V54.895H259.338V55.6029C259.337 56.3749 259.607 57.1228 260.1 57.7167C260.593 58.3105 261.279 58.7129 262.038 58.8538C263.272 59.0845 264.537 59.0845 265.771 58.8538C266.495 58.7177 267.152 58.3443 267.639 57.7926C268.127 57.2409 268.416 56.5425 268.462 55.8078C268.508 55.0731 268.307 54.3442 267.892 53.7363C267.477 53.1284 266.871 52.6764 266.17 52.4516L261.314 50.931C260.385 50.6406 259.58 50.047 259.028 49.245C258.476 48.443 258.208 47.479 258.269 46.5072C258.329 45.5354 258.714 44.6119 259.361 43.8844C260.008 43.157 260.881 42.6675 261.839 42.4944C263.197 42.2427 264.591 42.2427 265.949 42.4944C266.949 42.6794 267.853 43.2086 268.503 43.9902C269.153 44.7717 269.51 45.7563 269.51 46.773V47.5019H268.461V46.773C268.462 46.001 268.192 45.2531 267.699 44.6593C267.205 44.0654 266.52 43.6631 265.761 43.5222C264.526 43.2967 263.262 43.2967 262.027 43.5222C261.304 43.6582 260.647 44.0316 260.159 44.5833C259.672 45.135 259.383 45.8335 259.337 46.5682C259.291 47.3028 259.492 48.0318 259.907 48.6397C260.322 49.2475 260.928 49.6996 261.629 49.9243L266.474 51.4659C267.401 51.7581 268.203 52.3521 268.753 53.1533C269.303 53.9544 269.569 54.9167 269.509 55.8866C269.448 56.8565 269.065 57.7784 268.42 58.5052C267.775 59.2321 266.905 59.7221 265.949 59.8972C265.275 60.0238 264.591 60.0887 263.905 60.0912Z"
            fill="#A3C5F1"/>
          <path d="M264.429 39.6475H263.38V42.8354H264.429V39.6475Z" fill="#A3C5F1"/>
          <path d="M264.429 59.5361H263.38V62.7241H264.429V59.5361Z" fill="#A3C5F1"/>
          <path
            d="M263.905 68.6752C260.445 68.6752 257.063 67.6494 254.187 65.7273C251.31 63.8053 249.068 61.0734 247.744 57.8772C246.42 54.681 246.074 51.1639 246.749 47.7708C247.424 44.3777 249.09 41.261 251.536 38.8147C253.982 36.3684 257.099 34.7024 260.492 34.0275C263.885 33.3526 267.402 33.699 270.598 35.0229C273.795 36.3468 276.527 38.5888 278.449 41.4653C280.371 44.3419 281.397 47.7238 281.397 51.1833C281.391 55.8208 279.546 60.2667 276.267 63.5458C272.988 66.825 268.542 68.6697 263.905 68.6752ZM263.905 34.7401C260.652 34.7401 257.473 35.7045 254.769 37.5113C252.065 39.3181 249.958 41.8862 248.713 44.8908C247.469 47.8954 247.143 51.2016 247.777 54.3912C248.412 57.5809 249.978 60.5108 252.278 62.8105C254.577 65.1101 257.507 66.6761 260.697 67.3106C263.886 67.9451 267.193 67.6194 270.197 66.3749C273.202 65.1304 275.77 63.0228 277.577 60.3187C279.383 57.6146 280.348 54.4355 280.348 51.1833C280.342 46.824 278.608 42.6448 275.526 39.5623C272.443 36.4798 268.264 34.7456 263.905 34.7401Z"
            fill="#A3C5F1"/>
          <path d="M423.917 136.692H422.868V139.634H423.917V136.692Z" fill="#A3C5F1"/>
          <path d="M423.917 154.341H422.868V157.282H423.917V154.341Z" fill="#A3C5F1"/>
          <path d="M418.691 137.79L417.783 138.314L419.253 140.862L420.162 140.338L418.691 137.79Z" fill="#A3C5F1"/>
          <path d="M427.521 153.074L426.613 153.599L428.083 156.146L428.992 155.622L427.521 153.074Z" fill="#A3C5F1"/>
          <path d="M415.499 139.531L414.975 140.443L417.534 141.914L418.058 141.002L415.499 139.531Z" fill="#A3C5F1"/>
          <path d="M430.813 148.29L430.289 149.202L432.848 150.673L433.373 149.761L430.813 148.29Z" fill="#A3C5F1"/>
          <path d="M416.036 146.466H413.094V147.514H416.036V146.466Z" fill="#A3C5F1"/>
          <path d="M433.685 146.466H430.744V147.514H433.685V146.466Z" fill="#A3C5F1"/>
          <path d="M416.765 150.239L414.217 151.709L414.741 152.617L417.289 151.147L416.765 150.239Z" fill="#A3C5F1"/>
          <path d="M432.047 141.434L429.5 142.904L430.024 143.813L432.571 142.342L432.047 141.434Z" fill="#A3C5F1"/>
          <path d="M419.235 153.061L417.765 155.608L418.673 156.133L420.144 153.585L419.235 153.061Z" fill="#A3C5F1"/>
          <path d="M428.052 137.772L426.582 140.319L427.49 140.844L428.961 138.296L428.052 137.772Z" fill="#A3C5F1"/>
          <path d="M210.522 6.80859H209.473V9.75013H210.522V6.80859Z" fill="#A3C5F1"/>
          <path d="M210.522 24.457H209.473V27.3986H210.522V24.457Z" fill="#A3C5F1"/>
          <path d="M205.303 7.91902L204.395 8.44336L205.866 10.9908L206.774 10.4665L205.303 7.91902Z" fill="#A3C5F1"/>
          <path d="M214.128 23.2032L213.219 23.7275L214.69 26.275L215.598 25.7506L214.128 23.2032Z" fill="#A3C5F1"/>
          <path d="M201.352 11.5059L200.828 12.4141L203.375 13.8848L203.9 12.9767L201.352 11.5059Z" fill="#A3C5F1"/>
          <path d="M216.621 20.3145L216.097 21.2227L218.644 22.6934L219.169 21.7852L216.621 20.3145Z" fill="#A3C5F1"/>
          <path d="M202.646 16.5771H199.705V17.6258H202.646V16.5771Z" fill="#A3C5F1"/>
          <path d="M220.295 16.5771H217.354V17.6258H220.295V16.5771Z" fill="#A3C5F1"/>
          <path d="M203.376 20.3519L200.828 21.8223L201.353 22.7305L203.9 21.2602L203.376 20.3519Z" fill="#A3C5F1"/>
          <path d="M218.658 11.5238L216.11 12.9941L216.634 13.9024L219.182 12.4321L218.658 11.5238Z" fill="#A3C5F1"/>
          <path d="M205.886 23.228L204.416 25.7754L205.324 26.2997L206.795 23.7523L205.886 23.228Z" fill="#A3C5F1"/>
          <path d="M214.677 7.91128L213.206 10.459L214.114 10.9832L215.585 8.43546L214.677 7.91128Z" fill="#A3C5F1"/>
        </svg>
      </MqAfterSmall>
    </div>
  )
}
