import { SET_LOCALE, SET_RATE_USD } from './actionTypes'
import { get } from 'idb-keyval'
import { setMetaTags } from '../../utils/helpers'

export const setLocalLocale = () => async dispatch => {
  const locale = await get('locale')
  dispatch({
    type: SET_LOCALE,
    localeName: locale || 'ru'
  })
}

export const setLocale = localeName => {
  setMetaTags(localeName)
  return {
    type: SET_LOCALE,
    localeName
  }
}

export const setRateUSD = data => ({
  type: SET_RATE_USD,
  data
})
