import React, { ReactNode, useEffect, useState } from 'react'
import Span, { TextSize } from '../../common_kit/text/Span'
import { useTranslation } from 'react-i18next'
import { PaymentType } from '../../../store/payments/initialState'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/rootState'
import { Form, Select } from 'antd'
import MqAfterSmall from '../../mq/MqAfterSmall'
import BankCardIcon from '../../funds_account_icon/BankCardIcon'
import MobileIcon from '../../funds_account_icon/MobileIcon'
import QiwiIcon from '../../funds_account_icon/QiwiIcon'
import QiwiLabelIcon from '../../funds_account_icon/QiwiLabelIcon'
import PayTMIcon from '../../funds_account_icon/PayTMIcon'
import USDTIcon from '../../funds_account_icon/USDTIcon'


const { Option } = Select

type Data = {
  color?: string,
  activeType: PaymentType,
  handlerTypeChanged: (type: PaymentType) => void
}

export default function FundsAccount(props: Data) {
  const { t } = useTranslation()

  const locale = useSelector((state: RootState) => state.locale.locale.name)
  const size = useSelector((state: RootState) => state.mq.size)

  const [accountTypes, setAccountTypes] = useState<Array<PaymentType>>([])

  useEffect(() => {
    const accountTypes: Array<PaymentType> = {
      ru: [
        //PaymentType.MOBILE,
        PaymentType.BANK,
        //PaymentType.QIWI_MONEY
      ],
      en: [
        //PaymentType.BANK,
        PaymentType.PAYTM,
        PaymentType.USDTTRC20
      ]
    }[locale] || []

    setAccountTypes(accountTypes)
    props.handlerTypeChanged(accountTypes[0])
  }, [locale])

  const getIcon = (item: PaymentType): ReactNode => {
    const icon = {
      [PaymentType.MOBILE]: <MobileIcon/>,
      [PaymentType.BANK]: <BankCardIcon masterCard={locale === 'en'}/>,
      [PaymentType.QIWI_MONEY]: <QiwiIcon/>,
      [PaymentType.PAYTM]: <PayTMIcon/>,
      [PaymentType.USDTTRC20]: <USDTIcon/>
    }[item]
    return (
      <div className='funds-account__icon'>
        {icon}
      </div>
    )
  }

  const getTitle = (item: PaymentType): ReactNode => {
    const text = {
      [PaymentType.MOBILE]: t('Cell phone account'),
      [PaymentType.BANK]: t('Bank card account'),
      [PaymentType.QIWI_MONEY]: <QiwiLabelIcon/>,
      [PaymentType.USDTTRC20]: 'USDT TRC-20'
    }[item]
    return (
      <div className='funds-account__title'>
        {text}
      </div>
    )
  }

  const getAccounts = (): ReactNode => {
    if (size === 's') {
      const getAccountOptions = () => accountTypes.map(item => {
        const isActive: boolean = item === props.activeType
        const activeClass = isActive ? 'active' : ''
        return (
          <Option key={item} value={item} className={`funds-account__option ${activeClass}`}>
            {getIcon(item)}
            {getTitle(item)}
          </Option>
        )
      })
      return (
        <Form layout={'vertical'}>
          <Form.Item label={t('Credit account')}>
            <Select
              className='funds-account__select'
              popupClassName='funds-account__dropdown'
              suffixIcon={(
                <svg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M1.625 1.50098L5.89329 5.25098L10.375 1.50098' stroke='#859AB6' strokeWidth='1.5'
                        strokeLinecap='round' strokeLinejoin='round'/>
                </svg>
              )}
              value={props.activeType}
              onChange={value => props.handlerTypeChanged(value)}>
              {getAccountOptions()}
            </Select>
          </Form.Item>
        </Form>
      )
    }
    const buttons = accountTypes.map((item) => {
      const isActive: boolean = item === props.activeType
      const activeClass = isActive ? 'active' : ''
      return (
        <div
          key={item}
          className={`funds-account__button ${activeClass} type-${item}`}
          onClick={() => props.handlerTypeChanged(item)}>
          {getIcon(item)}
          {getTitle(item)}
        </div>
      )
    })
    return (
      <div className='funds-account__buttons'>
        {buttons}
      </div>
    )
  }

  return (
    <div className='withdrawal-funds-account'>
      <MqAfterSmall>
        <Span className='label' size={TextSize.P18} text={t('Credit account')} color='#859AB6'/>
      </MqAfterSmall>
      {getAccounts()}
    </div>
  )
}
