import { FileUploadStatus } from '../../utils/helpers'

export interface Screenshot {
  type: ScreenshotType,
  displayMode: DisplayMode,
  status: FileUploadStatus,
  path: string

  image?: File
}

export interface ScreenshoterState {
  screenshots: Array<Screenshot>
}

export enum ScreenshotType {
  COMPENSATION = 'compensation',
  VERIFICATION = 'verification',
  PUBLISH_MODERATION = 'publish_moderation',
  UPDATE_MODERATION = 'update_moderation',
  UPDATE_ASSEMBLY = 'update_assembly',
  PASSPORT = 'passport',
  SELFIE = 'selfie',
  CODE = 'codes',
  GOOGLE_ADMIN_SHARING = 'sharing_screen',
}

export enum DisplayMode {
  NONE,
  SPINNER,
  PREVIEW
}

const initialState: ScreenshoterState = {
  screenshots: []
}

export default initialState
