import { SET_LOCALE, SET_RATE_USD } from './actionTypes'
import initialState from '../locale/initialState'
import { set } from 'idb-keyval'

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOCALE:
      set('locale', action.localeName)
      return {
        ...state,
        locale: { name: action.localeName }
      }
    case SET_RATE_USD:
      return {
        ...state,
        rate: { en: action.data }
      }
    default:
      return state
  }
}
