import {
  SET_DELETED_APPLICATIONS,
  SET_SELECTED_DELETED_APP,
  UPDATE_DELETED_APPLICATION
} from './actionTypes'
import initialState from './initialState'

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_DELETED_APP:
      return {
        ...state,
        selectedDeletedApp: action.app
      }
    case SET_DELETED_APPLICATIONS:
      return {
        ...state,
        deletedApplications: action.deletedApplications
      }
    case UPDATE_DELETED_APPLICATION:
      const deletedApplications = state.deletedApplications.map(item => {
        if (item.application.id === action.app.application.id) return action.app
        return item
      })
      return {
        ...state,
        deletedApplications,
        selectedDeletedApp: action.app
      }
    default:
      return state
  }
}
