import React, { useState } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import SubmitButton from '../inputs/SubmitButton'


interface Data {
  clickBtnHandler: () => void

  reason?: string | null
  btnTitleText?: string
}

export default function RejectReason(props: Data) {
  const { t } = useTranslation()

  const [showReason, setShowReason] = useState<boolean>(false)

  const getContent = () => {
    const btn = (
      <SubmitButton
        text={props.btnTitleText || t('Upload new screenshots')}
        onClick={props.clickBtnHandler}
      />
    )
    if (!props.reason) return (
      <div className="reason-wrap">
        {btn}
      </div>
    )
    if (showReason) return (
      <div className="reason-wrap">
        <div className="reason-text">
          {props.reason}
        </div>
        <div className="hide-btn-wrap">
          <div className="hide-btn" onClick={() => setShowReason(false)}>
            {t('Close')}
          </div>
        </div>
        {btn}
      </div>
    )
    return (
      <div className="show-btn" onClick={() => setShowReason(true)}>
        {t('Reject reason')}
      </div>
    )
  }

  return (
    <div className="reject-reason">
      <SwitchTransition>
        <CSSTransition
          key={!!showReason ? 1 : 0}
          timeout={200}
          classNames="fade">
          {getContent()}
        </CSSTransition>
      </SwitchTransition>
    </div>
  )
}
