import 'core-js/actual'
import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.scss'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import './i18n'
import { Provider } from 'react-redux'
import store from './store'
import { dataTransferFromLsToIdb, setGeneralLocalData } from './utils/helpers'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory();


dataTransferFromLsToIdb()

// Сделано так, чтобы не рендерилась страница логина, пока происходит запрос токена в IDB,
// и не падали ошибки из-за отсутствия данных в Redux
setGeneralLocalData()
  .finally(() => {
    ReactDOM.render(
      <Provider store={store}>
        <Router history={history}>
          <App/>
        </Router>
      </Provider>,
      document.getElementById('root')
    )
  })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
