import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import Tutorial from './tutorial/Tutorial'
import TaskList from './tasksPage/TaskList'
import SimpleTasksList from './tasksPage/SimpleTasksList'
import '../style/sass/TasksPage.scss'
import '../style/sass/tasksPage/Modal.scss'
import H3 from './common_kit/text/H3'
import BlockedTaskListIllustration from './illustrations/BlockedTaskListIllustration'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserData } from '../store/user/actions'
import { RootState } from '../store/rootState'
import { setSidebarAvailability } from '../store/sidebar/actions'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import InfoModal from './InfoModal/InfoModal'
import { get, set } from 'idb-keyval'
import { Select, Tabs } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'
import { parseParameters } from '../utils/helpers'
import AdBanner from './AdBanner'
import MqSmall from './mq/MqSmall'
import CompletedTasksList from './tasksPage/CompletedTasksList'
import { downloadAllTasks, resetTaskList, setSelectedTaskForDetails, setSelectedPublicTaskForDetails } from '../store/taskList/actions'
import {ExclamationCircleOutlined} from "@ant-design/icons"
import _ from "lodash"


const { Option } = Select

export default function TasksPage() {

  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const history = useHistory()

  const userId = useSelector((state: RootState) => state.user.userData?.id)
  const userBlocked = useSelector((state: RootState) => state.user.userData?.is_task_block)
  const size = useSelector((state: RootState) => state.mq.size)
  const publicationTasksCount = useSelector((state: RootState) => state.taskList.publicationTasks.meta?.page?.total)
  const simpleTasksCount = useSelector((state: RootState) => state.taskList.simpleTasks.meta?.page?.total)
  const completedTasks = useSelector((state: RootState) => state.taskList.completedTasks.data, _.isEqual)
  const completedTasksCount = useSelector((state: RootState) => state.taskList.completedTasks.meta?.page?.total)
  const deletedApplications = useSelector((state: RootState) => state.application.deletedApplications, _.isEqual)

  const [loading, setLoading] = useState<boolean>(true)
  const [showBlockModal, setShowBlockModal] = useState<boolean>(false)
  const [currentList, setCurrentList] = useState<string>()
  const [showWarningIcon, setWarningIcon] = useState<boolean>()

  const locale = i18n.language

  const controller = new AbortController()

  const taskListComponent = <TaskList loading={loading} signal={controller.signal}/>
  const simpleTaskListComponent = <SimpleTasksList loading={loading} signal={controller.signal}/>
  const completedTaskListComponent = <CompletedTasksList loading={loading} signal={controller.signal}/>

  const getTabLabel = (key, count) => (
    <span className='tab-label'>
      {t(key)}
      {count !== undefined && (
        <span className='counter'>
          {count}
        </span>
      )}
      {key === 'Completed tasks' && showWarningIcon && <ExclamationCircleOutlined/>}
    </span>
  )

  const items = [
    {
      label: getTabLabel('Publication tasks', publicationTasksCount),
      key: 'publication',
      children: taskListComponent
    },
    {
      label: getTabLabel('Other tasks', simpleTasksCount),
      key: 'other',
      children: simpleTaskListComponent
    },
    {
      label: getTabLabel('Completed tasks', completedTasksCount),
      key: 'completed',
      children: completedTaskListComponent
    }
  ]

  const checkStopMarathon_deletionReason = () => {
    const marathonTaskStop = completedTasks
      // @ts-ignore
      .find(task => ['stop_task_update', 'stop_app_manager'].includes(task.application?.marathon?.name))
    const emptyDeletionReasonTask = deletedApplications.find(task => !task.deletionReason)
    const completedEmptyDeletionTask = completedTasks.find(task => task.id === emptyDeletionReasonTask?.id)
    setWarningIcon(!!marathonTaskStop || !!completedEmptyDeletionTask)
  }

  useEffect(() => {
    let mounted = true
    const asyncEffect = async () => {
      await dispatch(updateUserData())
      await dispatch(downloadAllTasks(controller.signal))
    }

    dispatch(setSidebarAvailability(true))
    setCurrentList(getDefaultActiveKey())
    asyncEffect()
      .finally(() => {
        if (mounted) setLoading(false)
      })
    checkIndexedDB()
    return () => {
      if (!history.location.pathname.includes('google-account')) dispatch(resetTaskList())
      controller.abort()
      mounted = false
    }
  }, [])

  useEffect(() => {
    checkStopMarathon_deletionReason()
  }, [completedTasks, deletedApplications])

  const listTypeChangeHandler = useCallback(value => {
    setCurrentList(value)
    dispatch(setSelectedTaskForDetails())
    dispatch(setSelectedPublicTaskForDetails())
  }, [])

  const getDefaultActiveKey = () => {
    const params = parseParameters(search)
    const typeParam = params.find(i => i.key === 'type')
    return typeParam ? typeParam.value : 'publication'
  }

  const checkIndexedDB = () => {
    ['actualPhone', 'codeCountry'].forEach(item => {
      get(item)
        .then(data => {
          if (!data) return
          const titleKey = item === 'actualPhone' ? 'phone' : 'code'
          const keys = Object.keys(data)
          const stringVal = typeof data[keys[0]] === 'string'
          if (!stringVal) return
          for (const [key, val] of Object.entries(data)) {
            data[key] = {[titleKey]: val}
          }
          set(item, data).catch((err) => console.log('Произошла ошибка при записи в БД', err));
        })
    })
  }

  const getContent = () => {
    if (userBlocked) return <BlockedTaskListIllustration/>

    if (size === 's') {
      return (
        <div className='task-list__type-selector'>
          <Select
            className='task-list__select'
            popupClassName='task-list__dropdown'
            value={currentList}
            suffixIcon={(
              <svg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M1.625 1.50098L5.89329 5.25098L10.375 1.50098' stroke='#859AB6' strokeWidth='1.5'
                      strokeLinecap='round' strokeLinejoin='round'/>
              </svg>
            )}
            onChange={listTypeChangeHandler}>
            {items.map(item => (
              <Option key={item.key} value={item.key}>
                {item.label}
              </Option>
            ))}
          </Select>
          {items.find(item => item.key === currentList)?.children}
        </div>
      )
    }

    return (
      <Tabs
        defaultActiveKey={getDefaultActiveKey()}
        tabBarGutter={46}
        destroyInactiveTabPane
        items={items}
        animated
        onTabClick={listTypeChangeHandler}
      />
    )
  }

  const updateAlertVisibility = (clickFromModal: boolean = false): void => {
    if (!userId || userBlocked === undefined) return
    let canShowAlert = userBlocked && !clickFromModal
    get('informedBlockAlert')
      .then(data => {
        const informed = data && data[userId]
        const needShowAlert = canShowAlert && !informed
        const setNewValue = value => {
          if (data) {
            data[userId] = value
            set('informedBlockAlert', data)
            return
          }
          set('informedBlockAlert', { [userId]: value })
        }

        setShowBlockModal(needShowAlert)
        if (clickFromModal) {
          setNewValue(true)
        } else if (!userBlocked) {
          setNewValue(false)
        }
      })
  }

  const getTaskBlockModal = (): ReactNode => {
    const className = locale === 'ru' ? ' blockTask' : ''
    const width = {
      s: '280px',
      m: '500px',
      l: '680px'
    }[size]
    return (
      <InfoModal showModal={showBlockModal} wrapClassName={className} width={width}>
        <div>
          <img src='/task_blocked.svg' alt='' className='modal__icon'/>
          <p className='ui-kit-regular-18 modal__text'>
            {t('Blocked task list part 1')} {t('Blocked task list part 2')}
          </p>
          <button
            className='btn btn-primary modal__button'
            onClick={() => updateAlertVisibility(true)}>
            {t('Clear')}
          </button>
        </div>
      </InfoModal>
    )
  }

  return <div className='tasks-page'>
    <MqSmall>
      <AdBanner/>
    </MqSmall>
    <H3 className='tasks-page__title' text={t('Available tasks')}/>
    <SwitchTransition>
      <CSSTransition
        key={!!userBlocked ? 1 : 0}
        timeout={200}
        classNames='fade'>
        {getContent()}
      </CSSTransition>
    </SwitchTransition>
    <Tutorial
      title='Как пользоваться сервисом?'
      description='Посмотрите небольшое видео и вам станет намного легче выполнять задания и получать вознаграждения'
      video_url='https://www.youtube.com/embed/E7wJTI-1dvQ'
      button_image={'/play.svg'}
      video_background={'/video_background.png'}
    />
    {getTaskBlockModal()}
  </div>
}
