import React, { useEffect, useState } from 'react'
import '../../style/sass/common_kit/Timer.scss'
import Paragraph, { ParagraphData } from './text/Paragraph'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { getCorrectWordVariant } from '../../utils/helpers'


interface Data {
  endMoment: string | number,

  className?: string,
  paragraphOptions?: ParagraphData,
  countingDown?: boolean,
  handleTimeIsOver?: () => void,
  showWords?: boolean,
  hideSeconds?: boolean,
  hideHours?: boolean,
  inGenitive?: boolean
}

Timer.defaultProps = {
  countingDown: true
}

export default function Timer(props: Data) {

  const { t } = useTranslation()

  const [countdownValue, setCountdownValue] = useState<string>()

  useEffect(() => {
    if (!props.countingDown) return
    setCountdownValue(evaluateRemains())
    const id: NodeJS.Timeout = setInterval(() => {
      setCountdownValue(evaluateRemains())
    }, 1000)

    return () => { clearInterval(id) }
  }, [])

  const evaluateRemains = (): string => {
    const now = moment()
    const end = moment(props.endMoment)
    const time = end.diff(now)

    if (isNaN(time) || time < 0) {
      if (props.handleTimeIsOver) props.handleTimeIsOver()
      return t('Time is up')
    }

    const getSeparator = (key: string, value: number): string => {
      if (!props.showWords) return key === 'sec' ? '' : ':'
      const word = props.inGenitive ? `${key}Genitive` : key
      return ` ${getCorrectWordVariant(word, value)} `
    }

    let result = ''

    const days = Math.floor(time / (1000 * 60 * 60 * 24))
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((time % (1000 * 60)) / 1000)

    if (days) {
      result += formatTime(days) + getSeparator('day', days)
    }
    if (!props.hideHours) {
      result += formatTime(hours) + getSeparator('hour', hours)
    }
    result += formatTime(minutes) + getSeparator('minute', minutes)
    if (!props.hideSeconds) {
      result += formatTime(seconds) + getSeparator('sec', seconds)
    }

    return result
  }

  const formatTime = (time: number) => !props.showWords && time < 10 ? `0${time}` : time

  const getParagraph = () => {
    const text = props.countingDown ? countdownValue : t('Time is suspended')
    if (props.paragraphOptions) {
      return (
        <Paragraph
          text={text}
          size={props.paragraphOptions.size}
          textStyle={props.paragraphOptions.textStyle}
        />
      )
    }
    return <p className="default">{text}</p>
  }

  return (
    <div className={`timer ${props.className ?? ''}`}>
      {getParagraph()}
    </div>
  )
}
