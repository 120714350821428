import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export interface ResultInputProps {
  labelKey: string
  className: string
  children: ReactElement
}

export default function LabeledResultInput(props: ResultInputProps) {

  const { t } = useTranslation()

  const getClass = () => ['result-input-wrap', props.className].join(' ')

  return (
    <div className={getClass()}>
      <div className="result-input-label">
        {t(props.labelKey)}
      </div>
      {props.children}
    </div>
  )
}
