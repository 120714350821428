import React, { useEffect, useMemo, useState } from 'react'
import '../../style/sass/instructions/guides/GuideSteps.scss'
import NextButton from '../common_kit/button/NextButton'
import PrevButton from '../common_kit/button/PrevButton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'
import _ from 'lodash'
import { setBtnDisabled, setNextStepIndex, setPrevStepIndex } from '../../store/task/actions'
import {
  uploadModerationScreenshotAndCompleteTask,
  uploadVerificationScreenshot
} from '../../store/taskScreenshots/api'
import { deleteFinishedTaskFromIDB } from '../../utils/helpers'
import { ScreenshotType } from '../../store/taskScreenshots/initialState'
import { useTranslation } from 'react-i18next'
import { PublisherTaskType } from '../../store/taskList/initialState'
import {setNextStepSimpleIndex, setPrevStepSimpleIndex} from '../../store/taskList/actions'
import {uploadGmailText} from '../../store/taskList/api'
import {del, get } from 'idb-keyval'
import {clearScreenshots} from "../../store/taskScreenshots/actions";


type Data = {
  moderationScreenshotType?: ScreenshotType,
  typeTask: string
  text?: string
}

export default function PrevNext(props: Data) {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const typeSelectTask = props.typeTask === 'task' ? 'selectedTask' : 'selectedSimpleTask'
  const taskId = useSelector((state: RootState) => state[props.typeTask][typeSelectTask]?.id)
  const taskStatus = useSelector((state: RootState) => state[props.typeTask][typeSelectTask]?.status.name)
  const taskType = useSelector((state: RootState) => state[props.typeTask][typeSelectTask]?.type)
  const currentIndex = useSelector((state: RootState) => props.typeTask === 'task' ? state.task.currentIndex : state[props.typeTask].simpleTaskGmail.currentIndex)
  const btnDisabled = useSelector((state: RootState) => state.task.nextBtnDisabled)
  const currentStep = useSelector((state: RootState) => props.typeTask === 'task' ? state.task.currentStep : state[props.typeTask].simpleTaskGmail.currentStep, _.isEqual)

  const isLastStep = useMemo(() => !!currentStep?.stepOptions.isLastStep, [currentStep])

  const [endTask, setEndTask] = useState(false)

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp)
    return () => document.removeEventListener('keyup', handleKeyUp)
  })

  useEffect(() => {
    return () => {
      if (endTask) {
        dispatch(clearScreenshots())
        del('whatsapp_phone')
      }
    };
  }, [endTask]);

  const handleKeyUp = (event) => {
    switch (event.code) {
      case 'ArrowLeft':
        if (currentIndex === 0) return
        handleOnBackClick()
        break
      case 'ArrowRight':
        if (btnDisabled || isLastStep) return
        handleOnForwardClick()
        break
    }
  }

  const handleOnBackClick = () => {
    const getSetter = () => {
      const mapper = {
        task: setPrevStepIndex,
        taskList: setPrevStepSimpleIndex
      }
      return mapper[props.typeTask]
    }
    const setter = getSetter()
    dispatch(setter())
  }

  const handleOnForwardClick = async () => {
    if (!taskId || !taskStatus || !taskType || !currentStep) return
    dispatch(setBtnDisabled(true))
    if (isLastStep) {
      let method, payload
      const verifySimpleTask = await get('verifySimpleTask')
      const selectMethod = () => {
        if ([PublisherTaskType.SIMPLE, PublisherTaskType.USER_SIMPLE].includes(taskType)) {
          method = uploadGmailText
          payload = {id: taskId, text: props.text}
        } else {
          if (taskType === PublisherTaskType.VERIFICATION ) {
            method = uploadVerificationScreenshot
            payload = { taskId }
            if (verifySimpleTask) {
              payload = {
                taskId,
                text: props.text
              }
            }
          } else {
            method = uploadModerationScreenshotAndCompleteTask
            payload = {
              type: props.moderationScreenshotType,
              taskId
            }
          }
        }
      }
      selectMethod()
      await method(payload)
      if (![PublisherTaskType.SIMPLE, PublisherTaskType.USER_SIMPLE].includes(taskType)) deleteFinishedTaskFromIDB(taskId, 'viewedFailureNotice')
      setEndTask(true)
    } else {
      const getSetter = () => {
        const mapper = {
          task: setNextStepIndex,
          taskList: setNextStepSimpleIndex
        }
        return mapper[props.typeTask]
      }
      const setter = getSetter()
      dispatch(setter())
    }
  }

  return (
    <div className="prev-next">
      <NextButton
        isDisabled={btnDisabled}
        onClick={handleOnForwardClick}
        text={isLastStep ? t('Complete the task') : undefined}
      />
      {!!currentIndex && (
        <PrevButton onClick={handleOnBackClick}/>
      )}
    </div>
  )
}
