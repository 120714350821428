import React from 'react'
import '../../style/sass/text/Text.scss'
import { PasswordValidationItem } from '../../utils/helpers'

type ListData = {
  className?: string
  label?: string
  items?: Array<PasswordValidationItem>
}

HintList.defaultProps = {
  className: ''
}

export default function HintList(props: ListData) {
  return (
    <div className={`hints-list-container ${props.className}`}>
      <p className="hints-list-label">{props.label}</p>
      <ul className="hints-list">
        {props.items?.map(
          item => <li key={item.id} className={`hints-list-item ${item.options.value ? 'faded' : ''}`}>
            {item.options.text}
          </li>
        )}
      </ul>
    </div>
  )
}
