import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

type Data = {
  header: string,
  links: Array<string>
  href: string
}

export default function FooterItem(props: Data) {
  const { t } = useTranslation()

  const getItem = (items): ReactElement => {
    return items.map(item => (
      <a
        href={props.href}
        className="footer-main__info-link"
        key={item}>
        {t(item)}
      </a>
    ))
  }

  return (
    <div className={`footer-main__info ${props.header === 'Documentation' ? 'documentation' : ''}`}>
      <h5 className="footer-main__info-header">{t(props.header)}</h5>
      {getItem(props.links)}
    </div>
  )
}
