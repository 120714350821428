import React from 'react'
import Paragraph from '../../common_kit/text/Paragraph'
import PayTMIcon from '../../funds_account_icon/PayTMIcon'


type Data = {
  countDetails: string
}

export default function PaytmMoney(props: Data) {
  return (
    <div className="payment-count">
      <PayTMIcon/>
      <Paragraph text={props.countDetails}/>
    </div>
  )
}
