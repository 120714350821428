import { SET_INTERCOM_SETTINGS, STOP_INTERCOM_CHAT } from './actionTypes'
import initialState from '../intercom/initialState'

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_INTERCOM_SETTINGS:
      return {
        ...state,
        ...action.settings
      }
    case STOP_INTERCOM_CHAT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
