import React from "react"
import '../../../style/sass/common_kit/Button.scss'
import IconArrowBack from "../../icons/IconArrowBack";
import {useTranslation} from "react-i18next";


type Data = {
  className?: string,
  onClick: () => void,
}

export default function PrevButton(props: Data) {

  const {t} = useTranslation()

  return <button
    onClick={props.onClick}
    type="button"
    className={`btn btn-primary button-prev ${props.className ?? ""}`}>
    <IconArrowBack className={"prev-next__ic-arrow-back"} fill={"#859AB6"}/>
    {t('Back')}
  </button>
}
