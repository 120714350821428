import React from 'react'


type Data = {
  className?: string
  fill?: string,
  fillBack?: string,
  width?: number,
  height?: number,
  viewBox?: string
}

IconQiwi.defaultProps = {
  fill: '#859AB6',
  fillBack: '#F5F6FA',
  width: 180,
  height: 44,
  viewBox: '0 0 180 44'
}

export default function IconQiwi(props: Data) {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="180" height="44" rx="15" fill={props.fillBack}/>
      <path
        d="M77.5602 29.1586C77.42 27.71 76.7954 26.3452 75.7812 25.2717C74.767 24.1982 73.4187 23.4746 71.9411 23.2109C72.2809 23.0338 72.3593 22.9578 72.4377 22.9578C75.0512 22.7048 75.0512 22.6541 74.633 20.0726C74.1491 17.8722 72.846 15.9212 70.9721 14.5914C69.0982 13.2616 66.7846 12.6459 64.4721 12.8617C62.1597 13.0775 60.01 14.1097 58.4329 15.7616C56.8558 17.4135 55.9614 19.5695 55.9202 21.8189C55.9256 24.0749 56.7764 26.2524 58.3146 27.9467C59.8528 29.6411 61.9737 30.7372 64.2835 31.0315C65.2766 31.0315 65.8255 31.0315 65.6948 29.8419C65.6684 29.4205 65.6684 28.9979 65.6948 28.5765C66.3482 28.5765 66.4266 28.9561 66.6357 29.2345C67.3712 30.3036 68.3222 31.2178 69.4314 31.9223C70.5407 32.6268 71.7855 33.1072 73.0911 33.3346C76.3032 33.8423 79.2325 35.4173 81.3759 37.789C81.6634 38.118 82.0555 38.3964 82.0032 38.9026C81.4544 39.2063 81.193 38.7255 80.9055 38.4724C79.3343 37.2096 77.502 36.2875 75.532 35.768C73.5619 35.2485 71.4998 35.1437 69.4844 35.4606C67.0894 35.8657 64.64 35.8657 62.2449 35.4606C59.6825 34.9109 57.3248 33.691 55.4285 31.9338C53.5322 30.1767 52.1699 27.9495 51.4899 25.4946C50.8099 23.0398 50.8383 20.4513 51.5719 18.011C52.3055 15.5707 53.7163 13.3719 55.6506 11.6541C57.585 9.93627 59.9689 8.76515 62.5428 8.26819C65.1167 7.77124 67.7821 7.96748 70.2489 8.83557C72.7156 9.70366 74.8893 11.2103 76.5332 13.1916C78.1772 15.1729 79.2284 17.5529 79.5726 20.0726C80.0205 23.236 79.3084 26.4513 77.5602 29.1586Z"
        fill={props.fill}/>
      <path
        d="M74.2685 31.2094C74.2685 31.9687 73.8765 32.2471 73.3015 32.2471C73.1211 32.2542 72.9411 32.2251 72.7729 32.1615C72.6046 32.0979 72.4518 32.0012 72.3241 31.8775C72.1964 31.7539 72.0966 31.6059 72.0309 31.443C71.9652 31.2801 71.9351 31.1058 71.9425 30.931C71.9425 30.3742 72.2038 29.9693 72.805 30.0199C73.1417 30.0596 73.4592 30.1931 73.7191 30.4043C73.9789 30.6155 74.1698 30.8952 74.2685 31.2094Z"
        fill={props.fill}/>
      <path
        d="M76.1752 29.7412C75.9092 29.6261 75.6808 29.4425 75.5149 29.2105C75.349 28.9784 75.252 28.7068 75.2344 28.4251C75.2344 28.0961 75.2344 27.6912 75.7048 27.7671C76.1752 27.843 76.4889 28.4504 76.5934 28.9566C76.6979 29.4628 76.6718 29.64 76.1752 29.7412Z"
        fill={props.fill}/>
      <path
        d="M122.145 20.2392C121.337 22.5636 120.476 24.7869 119.771 27.0607C119.624 27.5191 119.33 27.9199 118.931 28.2046C118.532 28.4892 118.049 28.6428 117.554 28.6428C117.058 28.6428 116.576 28.4892 116.177 28.2046C115.778 27.9199 115.483 27.5191 115.336 27.0607C114.006 22.9426 112.545 18.8749 111.136 14.782C110.614 13.2914 110.718 13.1903 112.388 13.1398C114.867 13.1398 114.867 13.1398 115.623 15.4389C116.222 17.4959 116.98 19.506 117.893 21.4519C118.702 19.1023 119.563 16.7779 120.319 14.4283C120.401 14.0338 120.629 13.682 120.961 13.4398C121.293 13.1977 121.705 13.0819 122.119 13.1145C122.361 13.071 122.609 13.0744 122.85 13.1248C123.09 13.1751 123.318 13.2713 123.519 13.4077C123.721 13.5441 123.892 13.718 124.024 13.9192C124.155 14.1205 124.244 14.3451 124.285 14.5799C124.989 16.8537 125.85 19.1023 126.633 21.3761C127.311 21.2245 127.259 20.694 127.389 20.3403C128.042 18.3191 128.642 16.2979 129.294 14.302C129.633 13.2408 132.79 12.6092 133.494 13.4177C133.808 13.7714 133.625 14.1504 133.494 14.5041C132.007 18.8749 130.468 23.2205 129.007 27.6166C128.642 28.7029 127.807 28.5766 126.972 28.5766C126.137 28.5766 125.302 28.7282 124.963 27.6166C124.337 25.5954 123.606 23.5994 122.902 21.6035C122.746 21.174 122.746 20.6435 122.145 20.2392Z"
        fill={props.fill}/>
      <path
        d="M105.528 20.8203C105.528 18.6981 105.528 16.6011 105.528 14.4789C105.528 12.8366 106.728 13.1904 107.693 13.1398C108.659 13.0893 109.572 13.1398 109.546 14.5294C109.546 18.757 109.546 22.9679 109.546 27.1618C109.546 28.6525 108.606 28.5514 107.589 28.5514C106.571 28.5514 105.45 28.7282 105.502 27.1113C105.58 25.0396 105.528 22.9173 105.528 20.8203Z"
        fill={props.fill}/>
      <path
        d="M135.112 20.7949C135.112 18.698 135.112 16.5757 135.112 14.4787C135.112 13.1902 135.843 13.0639 136.808 13.1649C137.773 13.266 139.025 12.7354 139.025 14.4282C139.025 18.7232 139.025 23.0435 139.025 27.3385C139.025 28.2733 138.608 28.5513 137.695 28.5765C135.086 28.5765 135.086 28.7028 135.086 26.2269L135.112 20.7949Z"
        fill={props.fill}/>
      <path
        d="M102.474 26.6058C102.051 26.2618 101.767 25.7845 101.672 25.2577C101.576 24.731 101.675 24.1885 101.952 23.7256C102.519 22.4076 102.69 20.9614 102.445 19.5534C102.201 18.1454 101.551 16.833 100.569 15.7672C99.6955 14.6713 98.5282 13.8281 97.1944 13.3293C95.8606 12.8304 94.4111 12.695 93.0034 12.9375C91.4112 13.1172 89.9143 13.766 88.7151 14.7962C87.5159 15.8264 86.6724 17.1884 86.2984 18.6979C85.8242 20.1615 85.8197 21.7299 86.2856 23.196C86.7514 24.6621 87.6656 25.9568 88.9074 26.909C90.0868 27.9516 91.5586 28.6327 93.1372 28.8665C94.7158 29.1003 96.3305 28.8763 97.7778 28.2228C98.1952 28.0459 98.6649 27.5406 99.1084 28.0207C99.5519 28.5007 100.752 28.5007 101.717 28.5765C102.683 28.6523 103.387 28.9555 103.726 28.2986C104.065 27.6417 102.97 27.0606 102.474 26.6058ZM97.256 22.3361C96.9609 22.3108 96.6641 22.3108 96.369 22.3361C95.8994 22.3361 95.2471 22.1592 94.9862 22.6392C94.7253 23.1193 95.2993 23.4983 95.6124 23.8267C96.4733 24.7362 96.0559 25.0647 95.0123 25.2415C94.3751 25.3275 93.7261 25.2765 93.1115 25.0922C92.4969 24.9079 91.9317 24.5949 91.456 24.1753C90.9804 23.7557 90.606 23.2398 90.3594 22.6644C90.1128 22.0889 90.0001 21.4679 90.0292 20.8454C90.0042 20.2702 90.101 19.6961 90.3137 19.1585C90.5263 18.6209 90.8504 18.1311 91.2659 17.7193C91.6813 17.3075 92.1794 16.9824 92.7295 16.764C93.2796 16.5456 93.87 16.4385 94.4644 16.4494C95.0436 16.4689 95.613 16.5988 96.1403 16.8317C96.6675 17.0645 97.1422 17.3957 97.5372 17.8064C97.9321 18.217 98.2396 18.699 98.4421 19.2248C98.6445 19.7506 98.738 20.3099 98.717 20.8707C98.6127 21.8813 98.2474 22.4371 97.1517 22.3361H97.256Z"
        fill={props.fill}/>
    </svg>
  )
}
