import React, { useCallback, useEffect, useState } from 'react'
import Photo from './userProfile/Photo'
import Form from './userProfile/Form'
import Toolbar from './userProfile/Toolbar'
import '../style/sass/UserProfile.scss'
import { useDispatch } from 'react-redux'
import { updateUserData } from '../store/user/actions'
import { setSidebarAvailability } from '../store/sidebar/actions'
import H3 from './common_kit/text/H3'
import { useTranslation } from 'react-i18next'
import { clearEditUserDataOutSave } from '../store/userProfile/actions'


export default function UserProfile() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [photoChanged, setPhotoChanged] = useState<boolean>(false)
  const [formChanged, setFormChanged] = useState<boolean>(false)
  const [photoIsValid, setPhotoIsValid] = useState<boolean>(true)
  const [formIsValid, setFormIsValid] = useState<boolean>(true)

  const hasChanges = photoChanged || formChanged
  const hasError = !photoIsValid || !formIsValid

  useEffect(() => {
    dispatch(setSidebarAvailability(true))
    dispatch(updateUserData())
    return clearEditUserDataOutSave
  }, [])

  const handlePhotoChange = useCallback((changed: boolean, valid: boolean) => {
    setPhotoChanged(changed)
    setPhotoIsValid(valid)
  }, [])

  const handleFormChange = useCallback((value: boolean, valid: boolean) => {
    setFormChanged(value)
    setFormIsValid(valid)
  }, [])

  return (
    <div className="user-profile">
      <H3 className="user-profile__title" text={t('Personal info')}/>
      <Photo onChange={handlePhotoChange}/>
      <Form onChange={handleFormChange}/>
      <Toolbar hasChanges={hasChanges} hasError={hasError}/>
    </div>
  )
}
