import React from "react";

import '../../style/sass/tasksPage/Cards.scss'


type ReferralCardProps = {
  children: any,
  className?: string,
}

export default function ReferralCard(props: ReferralCardProps) {

  return <div className={`card-box referral-card ${props.className ?? ''}`}>
    {props.children}
  </div>

}
