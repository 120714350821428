import React, { useCallback, useEffect, useState } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { checkFileFormat, FileUploadStatus, getFullFileUrl, getUrlFromFile, uploadFile } from '../../../utils/helpers'
import { useTranslation } from 'react-i18next'
import { Image, Popover, Spin } from 'antd'
import { CheckCircleTwoTone, ExclamationCircleTwoTone, EyeOutlined } from '@ant-design/icons'


interface Data {
  value: string | null
  fieldName: string
  disabled: boolean
  onChange: (value: string, valid: boolean) => void
}

export default function ResultFileInput(props: Data) {

  const { t } = useTranslation()

  const [path, setPath] = useState<string>(props.value || '')
  const [image, setImage] = useState<string>(() => {
    if (!props.value) return ''
    return props.fieldName === 'image' ? getFullFileUrl(props.value) : 'fileIcon.svg'
  })
  const [name, setName] = useState<string>(props.value?.split('/').reverse()[0] ?? '')
  const [isDragOver, setIsDragOver] = useState<boolean>(false)
  const [status, setStatus] = useState<FileUploadStatus>()

  const isImage = props.fieldName === 'image'

  useEffect(() => {
    if (props.value) {
      setStatus(FileUploadStatus.SUCCESS)
      props.onChange(props.value, true)
    }
  }, [])

  useEffect(() => {
    if (props.disabled) setStatus(undefined)
  }, [props.disabled])

  const handleDragEnter = useCallback(() => { setIsDragOver(true) }, [])

  const handleDragLeave = useCallback(() => { setIsDragOver(false) }, [])

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files.item(0) : null
    if (!file) return

    const formatIsValid: boolean = checkFileFormat(file, isImage ? ['jpg', 'jpeg', 'png'] : ['zip'])
    if (!formatIsValid) return

    if (isImage) {
      const image = await getUrlFromFile(file)
      setImage(image)
    } else {
      setName(file.name)
      setImage('fileIcon.svg')
    }
    setStatus(FileUploadStatus.PROGRESS)

    const { path, status } = await uploadFile(file)
    setPath(path)
    setStatus(status)
    props.onChange(path, status === FileUploadStatus.SUCCESS)
  }

  const handleFileRemove = () => {
    setImage('')
    setPath('')
    setStatus(undefined)
    props.onChange('', false)
  }

  const getPreview = () => {

    const getLoadingIcon = () => {
      return {
        [FileUploadStatus.PROGRESS]: (<Spin size="small" spinning/>),
        [FileUploadStatus.SUCCESS]: (<CheckCircleTwoTone twoToneColor="#25ec2a"/>),
        [FileUploadStatus.FAIL]: (<ExclamationCircleTwoTone twoToneColor="#ff1f1f"/>)
      }[status!]
    }

    return (
      <div className="files-uploader-preview">
        <Image
          src={image}
          height={isImage ? 100 : 70}
          width={isImage ? 100 : 70}
          preview={isImage && {
            mask: <div className="files-uploader-preview-mask">
              <EyeOutlined/>
              <div className="preview-text">
                {t('View')}
              </div>
            </div>
          }}/>
        {!isImage && (
          <Popover content={name} placement="bottom">
            <div className="files-uploader-name">
              {name}
            </div>
          </Popover>
        )}
        {status && (
          <Popover content={<span>{t('Delete file')}</span>} overlayClassName="file-status-popover">
            <div className="loading-status" onClick={handleFileRemove}>
              {getLoadingIcon()}
            </div>
          </Popover>
        )}
      </div>
    )
  }

  const getContent = () => {
    if (status || path) return getPreview()

    const getClass = () => {
      const list = ['files-uploader-input']
      if (isDragOver) list.push('drag-over')
      return list.join(' ')
    }

    return (<div
      className={getClass()}
      draggable
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDragLeave}>
      <img src="/uploadIcon.svg" alt=""/>
      {t('File uploader tip')}
      <input
        type="file"
        onChange={handleFileInputChange}/>
    </div>)
  }

  return (
    <div className="input-type-file">
      <SwitchTransition>
        <CSSTransition
          key={Number(!!path)}
          timeout={200}
          classNames="fade">
          { getContent() }
        </CSSTransition>
      </SwitchTransition>
    </div>
  )
}
