import { SET_TASK_COMPLETE_MODAL, VERIFICATION_MODAL_SHOW } from './actionTypes'
import initialState from './initialState'

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case VERIFICATION_MODAL_SHOW:
      return {
        ...state,
        verificationModal: action.data
      }
    case SET_TASK_COMPLETE_MODAL:
      const { show, iconType, text } = action.data
      return {
        ...state,
        taskCompleteModal: {
          show,
          iconType,
          text
        }
      }
    default:
      return state
  }
}
