import { SET_ACCRUALS_HISTORY, RESET_ACCRUALS_PAYMENTS } from './actionTypes'
import initialState from './initialState'

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case SET_ACCRUALS_HISTORY:
      const { data, meta } = action.history
      const history = [
        ...state.history,
        ...data
      ]
      return {
        ...state,
        history,
        meta
      }
    case RESET_ACCRUALS_PAYMENTS:
      return {
        history: [],
        meta: {}
      }
    default:
      return state
  }
}
