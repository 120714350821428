import { LocaleMeta, PublisherTaskType, Status } from '../taskList/initialState'

export type Answer = {
  id: string | number,
  name: string,
  value: boolean,
}

export interface Task {
  id: number
  title: string
  available: boolean
  cost: number | null
  description: string
  application: Application
  moderation: {
    screen: string
  }
  type: PublisherTaskType
  original_country_show_prices: Array<string>
  complete_in: number
  task_time: number
  status: Status
  task: {
    type: { name: MainTaskType }
    purchases?: Array<Purchase>
  }
  calcCompleteAt: string
  user_id: number,
  task_id: number,
  completed_at: string,
  started_at: string | null,
  comment: string | null,
  verification: {
    id: number,
    screen: string | null,
    passport_pic: string | null,
    selfie_pic: string | null,
    user_id: number,
    verified: boolean,
    status: string,
    sub_task_id: number,
    transaction_id: number,
    completed_at: string | null
  },
  compensation: {
    id: number,
    screen: string | null,
    user_id: number,
    status: boolean,
    sub_task_id: number,
    completed_at: string | null,
    created_at: string | null
  }
  calcAddingCountries?: Array<string>,
  calcRemovingCountries?: Array<string>,
  google_account_password?: string,
  showReserveEmailSteps?: boolean
}

export interface Language {
  icon: string
  store_name: string
  short_description: string
  description: string
  table_screenshot: Array<string>
  phone_screenshot: Array<string>
  screen: Array<string>
  name: string
  title: string
}

export interface Category {
  id: number
  name: string
  title: string
  description: string
  type: string
  state_id?: string | null
  created_at: string
  updated_at: string
  deleted_at?: string | null
  children?: Array<any>
  meta: {
    en: LocaleMeta,
    ru: LocaleMeta,
  }
}

interface Application {
  apk_production_file: string
  data_safety_file: string
  status: AppStatus
  marathon_id: number
  age_limit: Array<string>
  answer: Array<string>
  locales: Array<Language>
  default_locale: Language
  type: {
    meta: {
      en: LocaleMeta,
      ru: LocaleMeta,
    }
  }
  category: {
    meta: {
      en: LocaleMeta,
      ru: LocaleMeta,
    }
  },
  country_show_prices: Array<string>
  policy_url: string
  restriction: {
    name: string,
    meta: {
      en: LocaleMeta,
      ru: LocaleMeta,
    }
  },
  version: string,
  has_ad: boolean
}

interface AppStatus {
  title: string
  description: string
  name: string
  meta: {
    en: LocaleMeta,
    ru: LocaleMeta,
  };
}

export interface Purchase {
  template_name: string
  product_id: number
  product_name: string
  product_description: string
}

export enum MainTaskType {
  VERIFICATION = 'verification',
  PUBLISH = 'create_application',
  NO_DEVELOP = 'no_develop',
  WITH_DEVELOP = 'with_develop',
  UNITY = 'unity_export_application',
  UPDATE_ASSEMBLY = 'update_app_assembly',
  UPDATE_ASO_IMG = 'update_app_aso_image',
  UPDATE_ASO_IMG_ASSEMBLY = 'update_app_aso_image_assembly',
  UPDATE_ASO_TEXT_IMG = 'update_aso_text',
  UPDATE_PURCHASES = 'update_assembly_add_purchases',
  UPDATE_ASO_ASSEMBLY = 'update_aso_assembly_image',
  UPDATE_IMG = 'update_aso_image'
}

export enum VerificationTaskSection {
  NONE,
  REG_IN_GOOGLE,
  REG_GMAIL_ACCOUNT,
  RESERVE_PASSWD
}

export enum PublishTaskSection {
  GO_TO_GOOGLE,
  MAIN_STORE_LISTING,
  STORE_SETTINGS,
  PRODUCTION,
  DASHBOARD,
  APP_CONTENT,
}

export enum UpdateTaskSection {
  GO_TO_GOOGLE,
  APP_DESCRIPTION,
  APP_VERSIONS,
}

export enum GoogleAccountTaskSection {
  REG_GMAIL_ACCOUNT,
  RESERVE_PASSWD,
}

export interface StepOptions {
  section: PublishTaskSection | UpdateTaskSection | VerificationTaskSection | GoogleAccountTaskSection | null
  linkId?: string
  langName?: string
  isLastStep?: boolean
  blockExecution?: boolean,
  stepsToBeCompleted?: Array<string>
}

export type TaskStep = {
  componentPath: string,
  stepOptions: StepOptions
}

export interface OrderedStepOptions extends StepOptions {
  stepNumInSection: number,
  totalStepsInSection: number,
}

export type OrderedTaskStep = {
  componentPath: string,
  stepOptions: OrderedStepOptions
}

export type TaskState = {
  nextBtnDisabled: boolean,
  selectedTask?: Task,
  steps?: Array<TaskStep>,
  currentIndex?: number,
  currentStep?: OrderedTaskStep,
  progress?: number,
  repeatReceivePhone: number,
  availablePhone: boolean,
  googleAdminEmail: string
}

const initialState: TaskState = {
  nextBtnDisabled: false,
  selectedTask: undefined,
  steps: undefined,
  currentIndex: undefined,
  currentStep: undefined,
  progress: undefined,
  repeatReceivePhone: 1,
  availablePhone: true,
  googleAdminEmail: ''
}

export default initialState
