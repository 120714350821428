import initialState from './initialState'
import { SET_USER_DATA, SET_USER_ID, UPDATE_USER_DATA } from './actionTypes'
import { del, set } from 'idb-keyval'

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_ID:
      return {
        ...state,
        id: action.id
      }
    case SET_USER_DATA:
      if (action.userData) {
        set('userData', action.userData)
      } else {
        del('userData')
      }
      return {
        ...state,
        userData: action.userData
      }
    case UPDATE_USER_DATA:
      const userData = {
        ...state.userData,
        ...action.userData
      }
      set('userData', userData)
      return {
        ...state,
        userData
      }
    default:
      return state
  }
}
