export interface MQState {
  size: string,
  height: string,
  orientation: string
}

const initialState: MQState = {
  size: '',
  height: '',
  orientation: ''
}

export default initialState
