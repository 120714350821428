import { checkValidateErrors } from '../../utils/helpers'
import { axios, axiosV3 } from '../../axios'
import { setDisplayMode } from './actions'
import { ScreenshotType, DisplayMode } from './initialState'
import store from '../index'
import { setTaskCompleteModal, setVerificationModal } from '../modal/actions'
import i18n from 'i18next'
import Notification from '../../components/notification/Notification'
import FormDataV3 from "../../utils/formDataApiV3";

// TODO: Перенести в модуль task
export async function uploadVerificationScreenshot(payload) {
  const { taskId, text } = payload

  const data = new FormDataV3()
  data.addParam('id', taskId)
  data.addParam('type', 'subtasks')
  const needUploadPassport = store.getState().types.needUploadPassport
  store.dispatch(setDisplayMode(ScreenshotType.VERIFICATION, DisplayMode.SPINNER))
  const compensationScreen = store.getState().taskScreenshots.screenshots[`${taskId}-${ScreenshotType.COMPENSATION}`].path
  const verificationScreen = store.getState().taskScreenshots.screenshots[`${taskId}-${ScreenshotType.VERIFICATION}`].path

  data.addMeta('compensation_screen', compensationScreen)
  data.addMeta('verification_screen', verificationScreen)
  if (needUploadPassport) {
    const selfie = store.getState().taskScreenshots.screenshots[`${taskId}-${ScreenshotType.SELFIE}`].path
    const passport = store.getState().taskScreenshots.screenshots[`${taskId}-${ScreenshotType.PASSPORT}`].path
    data.addMeta('selfie_pic', selfie)
    data.addMeta('passport_pic', passport)
  }
  if (text) {
    const email = text.match(/\(\w+@gmail\.com\)/gm)[0].replace('(', '').replace(')', '')
    const codes = text.match(/\d.\s\d{4}\s\d{4}/gm)
    const transformCodes = codes.map(code => code.replace(/\d\.\s/, ''))
    data.addMeta('google_account', {
      email,
      codes: transformCodes
    })
  }
  try {
    await axiosV3.post(`subtasks/${taskId}/review`, data)
    store.dispatch(setDisplayMode(ScreenshotType.VERIFICATION, DisplayMode.PREVIEW))
    if (process.env.REACT_APP_ENV === 'prod') {
      window.dataLayer.push({event: 'sendVerificationToCheck'})
    }
    store.dispatch(setVerificationModal(true))
  } catch (e) {
    console.info('Ошибка в методе uploadVerificationScreenshot:', e)
    if (e.response && e.response.status === 422) {
      checkValidateErrors(e)
    } else {
      Notification({ noticeType: 'error', text: i18n.t('Verification error') })
    }
  }
}

// TODO: Перенести в модуль task
export async function uploadModerationScreenshotAndCompleteTask(payload) {
  const { type, taskId } = payload
  store.dispatch(setDisplayMode(type, DisplayMode.SPINNER))
  const data = new FormDataV3()
  data.addParam('id', taskId)
  data.addParam('type', 'subtasks')
  const screenshots = store.getState().taskScreenshots.screenshots
  const moderationScreen = screenshots[`${taskId}-${type}`].path
  const assemblyScreen = screenshots[`${taskId}-${ScreenshotType.UPDATE_ASSEMBLY}`]?.path
  const sharingScreen = screenshots[`${taskId}-${ScreenshotType.GOOGLE_ADMIN_SHARING}`]?.path
  data.addMeta('published_screen', moderationScreen)
  if (assemblyScreen) data.addMeta('upload_screen', assemblyScreen)
  if (sharingScreen) data.addMeta('sharing_screen', sharingScreen)
  try {
    await axiosV3.post(`subtasks/${taskId}/review`, data)
    store.dispatch(setTaskCompleteModal({ show: true, iconType: 'success', text: 'Modal Complete task' }))
    if (process.env.REACT_APP_ENV === 'prod') {
      if (type === ScreenshotType.PUBLISH_MODERATION) {
        window.dataLayer.push({event: 'sendTaskPublishToModeration'})
      } else if (type === ScreenshotType.UPDATE_MODERATION) {
        window.dataLayer.push({event: 'sendTaskUpdateToModeration'})
      }
    }
  } catch (e) {
    console.info('Ошибка в методе uploadModerationScreenshotAndCompleteTask:', e)
    checkValidateErrors(e)
    store.dispatch(setTaskCompleteModal({ show: true, iconType: 'failed', text: 'Modal Error task' }))
  }
}

export async function downloadProtectedImage(url) {
  try {
    const response = await axios.get(url)
    return response.data.response.image
  } catch (e) {
    return ''
  }
}
