import React, { useCallback, useState } from 'react'
import SubmitButton from '../inputs/SubmitButton'
import { useTranslation } from 'react-i18next'
import { editUserData } from '../../store/user/actions'
import { useDispatch } from 'react-redux'
import {
  clearEditUserDataOutSave,
  resetForm,
  setAvatarImage,
  setImgStatus
} from '../../store/userProfile/actions'


type Data = {
  hasChanges: boolean,
  hasError: boolean
}

export default function Toolbar(props: Data) {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [loading, setLoading] = useState<boolean>(false)

  const handleClickSaveBtn = useCallback(async (): Promise<void> => {
    setLoading(true)
    await dispatch(editUserData())
    dispatch(resetForm())
    setLoading(false)

    dispatch(setAvatarImage())
    dispatch(setImgStatus(undefined))
  }, [])

  const handleClickCancelBtn = useCallback(() => {
    clearEditUserDataOutSave()
  }, [])

  return (
    <div className="user-profile__form-toolbar">
      <SubmitButton
        text={loading ? t('Saving') : t('Save')}
        onClick={handleClickSaveBtn}
        isDisabled={loading || !props.hasChanges || props.hasError}
      />
      <SubmitButton
        text={t('Dont save')}
        onClick={handleClickCancelBtn}
        outline
        isDisabled={loading || !props.hasChanges}
      />
    </div>
  )
}
