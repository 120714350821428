import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import reducer from './reducer'
import { composeWithDevTools } from 'redux-devtools-extension'
import Sentry from '../sentry'

const devMiddlewares = []

// if (process.env.NODE_ENV === `development`) {
//     const { logger } = require(`redux-logger`)
//     devMiddlewares.push(logger)
// }

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const store = createStore(
    reducer,
    composeWithDevTools(
        applyMiddleware(thunk, ...devMiddlewares),
        sentryReduxEnhancer
    ),
)

window.onunload   = function () {
  window.scrollTo(0, 0);
}

export default store
