import React from 'react'


type Data = {
  className?: string,
  size?: number,
}

IconWallet.defaultProps = {
  className: '',
  size: 26
}

export default function IconWallet(props: Data) {
  return <svg className={props.className} width={props.size} height={props.size} viewBox="0 0 38 38" fill="none"
              xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.0702 17.7702V17.5202H36.8202H35.343V9.82485C35.343 7.78195 33.6814 6.12038 31.6385 6.12038H29.4462L27.1238 1.94484C26.7148 1.20741 25.9366 0.75 25.0929 0.75C24.6993 0.75 24.3093 0.85143 23.965 1.04252L23.9648 1.04263L14.8339 6.12027H4.63611C2.59321 6.12027 0.931641 7.78184 0.931641 9.82474V33.5454C0.931641 35.5883 2.59321 37.2499 4.63611 37.2499H31.6385C33.6814 37.2499 35.343 35.5883 35.343 33.5454V26.8291H36.8202H37.0702V26.5791V17.7702ZM24.88 2.6873L24.8807 2.68686C25.0826 2.57365 25.3663 2.6553 25.4787 2.85811L25.4789 2.85843L29.086 9.34404H12.9085L24.88 2.6873ZM31.6385 8.0021C32.4743 8.0021 33.1755 8.57103 33.3867 9.34469H31.239L30.4929 8.0021H31.6385ZM33.4611 33.5454C33.4611 34.5501 32.6431 35.3682 31.6384 35.3682H4.63611C3.63144 35.3682 2.81337 34.5501 2.81337 33.5454V9.82485C2.81337 8.82017 3.63144 8.0021 4.63611 8.0021H11.4503L9.03584 9.3448H5.06795C4.54767 9.3448 4.12703 9.76638 4.12703 10.2857C4.12703 10.805 4.54766 11.2266 5.06795 11.2266H6.61555H31.8603H33.4611V17.5209H28.6587C26.1742 17.5209 24.1528 19.5425 24.1528 22.0268V22.3239C24.1528 24.8083 26.1743 26.8298 28.6587 26.8298H33.4611V33.5454ZM35.1884 24.9473H35.093H28.6587C27.2118 24.9473 26.0345 23.7701 26.0345 22.3232V22.026C26.0345 20.5792 27.2117 19.4018 28.6587 19.4018H35.093H35.1884V24.9473Z"
      fill="#2A3E58" stroke="#2A3E58" strokeWidth="0.5"/>
    <rect x="3.25" y="8.25" width="30.5" height="3.5" fill="#2A3E58" stroke="#2A3E58" strokeWidth="0.5"/>
  </svg>
}
