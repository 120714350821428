import React from 'react'
import '../../../style/sass/common_kit/Text.scss'


type Data = {
  className?: string
  text?: string
  size?: TextSize,
  align?: TextAlign
  fontWeight?: TextWeight,
  textStyle?: TextStyle,
  color?: string,
  maxWidth?: string,
  ref?: React.RefObject<HTMLSpanElement>,
  onClick?: () => void
}

export enum TextWeight {
  NORMAL = 'normal',
  BOLD = 'bold',
  BOLDER = 'bolder'
}

export enum TextStyle {
  NORMAL = 'normal',
  ITALIC = 'italic'
}

export enum TextSize {
  P10 = 'ui-kit-regular-10',
  P12 = 'ui-kit-regular-12',
  P14 = 'ui-kit-regular-14',
  P18 = 'ui-kit-regular-18'
}

export enum TextAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

const Span = React.forwardRef<HTMLSpanElement, Data>((props, ref) => {
  return (
    <span
      ref={ref}
      className={`${props.size} ${props.className ?? ''}`}
      style={{
        textAlign: props.align,
        fontWeight: props.fontWeight,
        color: props.color ?? '',
        fontStyle: props.textStyle,
        maxWidth: props.maxWidth ?? 'none'
      }}
      onClick={props.onClick}>
      {props.text}
    </span>
  )
})

Span.defaultProps = {
  size: TextSize.P14,
  align: TextAlign.LEFT,
  fontWeight: TextWeight.NORMAL,
  textStyle: TextStyle.NORMAL
}

export default Span;
