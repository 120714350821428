import React, { ReactNode } from 'react'
import MobileCount from './MobileCount'
import QiwiMoney from './QiwiMoney'
import BankCard from './BankCard'
import WebMoney from './WebMoney'
import YandexMoney from './YandexMoney'
import moment from 'moment'
import IconAccepted from '../../icons/IconAccepted'
import IconWallet from '../../icons/IconWallet'
import H3 from '../../common_kit/text/H3'
import { Payment, PaymentType } from '../../../store/payments/initialState'
import UsdtMoney from './UsdtMoney'
import PaytmMoney from './PaytmMoney'
import { FieldTimeOutlined } from '@ant-design/icons'


type Data = {
  payment: Payment
}

export default function PaymentHistoryItem({ payment }: Data) {

  const getAccount = (): ReactNode => {
    const purse = payment.purse
    switch (payment.payment_type) {
      case PaymentType.MOBILE:
        return <MobileCount countDetails={purse}/>
      case PaymentType.BANK:
        return <BankCard countDetails={purse}/>
      case PaymentType.QIWI_MONEY:
        return <QiwiMoney countDetails={purse}/>
      case PaymentType.WEB_MONEY:
        return <WebMoney countDetails={purse}/>
      case PaymentType.YANDEX_MONEY:
        return <YandexMoney countDetails={purse}/>
      case PaymentType.USDTTRC20:
      case PaymentType.USDTTRC20_SPECIAL:
        return <UsdtMoney countDetails={purse}/>
      case PaymentType.PAYTM:
        return <PaytmMoney countDetails={purse}/>
    }
  }

  const getAmount = () => {
    return (!payment.currency || payment.currency === 'RUB') ? `- ${Math.abs(payment.amount)} ₽` : `- $${Math.abs(payment.amount)}`
  }

  return <div className={'payment-history-item'}>
    <div className={'history-item-head'}>
      {payment.confirmed ? (<IconAccepted/>) : (<FieldTimeOutlined/>)}
      <div className={'history-date-id'}>
        <p className={'payment-history-date'} style={{ color: '#859AB6' }}>
          {moment(payment.created_at).format('DD.MM.YYYY')}
        </p>
        <span style={{ color: '#2A3E58', fontWeight: 'bold', fontSize: '18px' }}>
          id{payment.id}
        </span>
      </div>
    </div>
    <div className={'history-item-content'}>
      <svg
        className={'payment-history-arrow'}
        width='25'
        height='67'
        viewBox='0 0 25 67'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M24.7071 59.7071C25.0976 59.3166 25.0976 58.6834 24.7071 58.2929L18.3431 51.9289C17.9526 51.5384 17.3195 51.5384 16.9289 51.9289C16.5384 52.3195 16.5384 52.9526 16.9289 53.3431L22.5858 59L16.9289 64.6569C16.5384 65.0474 16.5384 65.6805 16.9289 66.0711C17.3195 66.4616 17.9526 66.4616 18.3431 66.0711L24.7071 59.7071ZM9 2H24V0H9V2ZM24 58H9V60H24V58ZM2 51V9H0V51H2ZM9 58C5.13401 58 2 54.866 2 51H0C0 55.9706 4.02944 60 9 60V58ZM9 0C4.02944 0 0 4.02944 0 9H2C2 5.13401 5.13401 2 9 2V0Z'
          fill='#859AB6'/>
      </svg>
      <div className={'history-item-count'}>
        <div className='payment-history-item__amount'>
          <IconWallet/>
          <H3 text={getAmount()}/>
        </div>
        {getAccount()}
      </div>
    </div>
  </div>
}
