import React, {useState, useEffect} from 'react'
import '../../style/sass/tasksPage/TaskProgress.scss'


interface Data {
  steps: Array<string>,
  currentStepIndex: Promise<number>,
  verifyTask: boolean,
  stepSimpleTask: string | number
}

export default function TaskProgress(props: Data) {

  const [actIndex, setActIndex] = useState<number>(0)

  useEffect(() => {
    const fetchData = async () => {
      setActIndex(await props.currentStepIndex)
    }
    fetchData();
  }, [props.currentStepIndex])

  const getStepClasses = index => {
    const list = ['task-progress-step']
    if (index < actIndex) list.push('passed')
    if (index === actIndex) list.push('active')
    if (index < props.steps.length - 1) list.push('with-line')
    return list.join(' ')
  }

  const getIconStep = (index) => {
    return index >= actIndex ? index + 1 : (
      <img src={"/checkMark.svg"} alt='выбрано'/>
    )
  }

  const getSteps = () => props.steps.map((step, index) => (
    <div key={step} className={getStepClasses(index)}>
      <div className="step-number">
        {getIconStep(index)}
      </div>
      <div className="step-title">
        {step}
      </div>
    </div>
  ))

  return (
    <div className="task-progress">
      {getSteps()}
    </div>
  )
}
