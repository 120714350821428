import { RESET_FORM, SET_AVATAR_IMAGE, SET_IMG_PATH, SET_IMG_STATUS, SET_UNSAVED_DATA } from './actionTypes'
import { getFullFileUrl, uploadFile } from '../../utils/helpers'
import store from '../index'

export const setAvatarImage = image => ({
  type: SET_AVATAR_IMAGE,
  image: getFullFileUrl(image)
})

export const setImgStatus = status => ({
  type: SET_IMG_STATUS,
  status
})

export const setImgPath = file => async dispatch => {
  if (file) {
    const { path, status } = await uploadFile(file)
    dispatch({
      type: SET_IMG_PATH,
      path,
      status
    })
  } else {
    dispatch({
      type: SET_IMG_PATH,
      path: null,
      status: undefined
    })
  }
}

export const setUnsavedUserData = data => ({
  type: SET_UNSAVED_DATA,
  data
})

export const resetForm = () => ({
  type: RESET_FORM
})

export const clearEditUserDataOutSave = () => {
  store.dispatch(setAvatarImage())
  store.dispatch(setImgPath(null))
  store.dispatch(resetForm())
}
