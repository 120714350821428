import React, {useState} from 'react'
import '../../style/sass/tasksPage/Cards.scss'
import '../../style/sass/tasksPage/TaskCard.scss'
import IconClear from '../icons/IconClear'
import H3 from '../common_kit/text/H3'
import H5 from '../common_kit/text/H5'
import Paragraph from '../common_kit/text/Paragraph'
import { useTranslation } from 'react-i18next'
import { getAmountWithSign, allowGoogleAccountInVerification } from '../../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedPublicTaskForDetails } from '../../store/taskList/actions'
import { RootState } from '../../store/rootState'
import { TaskCardData } from './TaskPreviewCard'
import TaskProgress from './TaskProgress'
import { PublisherTaskType } from '../../store/taskList/initialState'
import {get} from 'idb-keyval'


type adaptiveCss = {
  widthSVG: number,
  heightSVG: number
}

type taskDetailCssModel = {
  s: adaptiveCss,
  m: adaptiveCss,
  l: adaptiveCss
}

export default function TaskDetailsCard(props: TaskCardData) {

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const size = useSelector((state: RootState) => state.mq.size)

  const [stepSimpleTask, setStepSimpleTask] = useState<number | string>('')

  const statusName = props.task.status.name
  const isProcess = ['process', 'process_assigned'].includes(statusName)
  const isVerificationTask = props.task.type === PublisherTaskType.VERIFICATION
  const isPublishTask = props.task.type === PublisherTaskType.PUBLICATION

  let taskDetailCss: taskDetailCssModel = {
    s: {
      widthSVG: 12,
      heightSVG: 12
    },
    m: {
      widthSVG: 20,
      heightSVG: 20
    },
    l: {
      widthSVG: 20,
      heightSVG: 20
    }
  }

  const getCurrentStep = async(): Promise<number> => {
    if (isVerificationTask) {
      const result = await get('verifySimpleTaskStatus')
      setStepSimpleTask(result)
      switch (statusName) {
        case 'published':
          return 0
        case 'process':
          return 1
        case 'confirmation':
          if (!result || result === 'pass') return 4
          return 3
        case 'verified':
          return 4
      }
      return 2
    }
    switch (statusName) {
      case 'published':
      case 'assigned':
      case 'process':
      case 'process_assigned':
        return 0
      case 'pre_moderation':
        return 1
      case 'moderation':
      case 'post_moderation':
        return 2
      case 'completed':
        return 3
    }
    return 0
  }

  const getWizardItems = () => {
    if (isVerificationTask) {
    const step3 = t('Verification task wizard step 3')
    const step4 = t('Verification task wizard step 4')
    const items = [
        t('Verification task wizard step 1'),
        t('Verification task wizard step 2'),
        t('Verification task wizard step 5'),
      ]
    if (allowGoogleAccountInVerification()) {
     items.splice(2, 0, step3)
     items.splice(3, 0, step4)
    }
    return items
    }
    return [
      isPublishTask ? t('Task wizard step 1') : t('Update task wizard step 1'),
      t('Task wizard step 2'),
      t('Task wizard step 3'),
      t('Task wizard step 4')
    ]
  }

  return (
    <div className={`card-box task-card task-details-card ${isVerificationTask ? 'verification-task' : ''}`}>
      <div className={'task-card__top'}>
        <div className={`task-card__status-block ${isProcess ? 'is-process' : ''}`}>
          {props.statusBlock}
        </div>
        {props.cost && (
          <div className={'task-card__price'}>
            <H3 text={'+ ' + getAmountWithSign(props.cost)}/>
          </div>
        )}
        <IconClear
          className="task-card__close"
          fill={'#859AB6'}
          width={taskDetailCss[size].widthSVG}
          height={taskDetailCss[size].heightSVG}
          onClick={() => { dispatch(setSelectedPublicTaskForDetails()) }}
        />
      </div>
      <div className={'task-card__middle'}>
        <H5 text={props.cardTitle ?? ''}/>
        <Paragraph text={t(isVerificationTask ? 'Verification task description' : 'Task description')}/>
      </div>
      <TaskProgress
        steps={getWizardItems()}
        currentStepIndex={getCurrentStep()}
        verifyTask={isVerificationTask}
        stepSimpleTask={stepSimpleTask}/>
      {props.btnBlock && (
        <div className="task-card__bottom-toolbar">
          {props.btnBlock}
        </div>
      )}
    </div>
  )
}
