import {
  downloadAllTaskList,
  downloadCompletedTaskList,
  downloadSimpleTaskList,
  sendSimpleTaskToModeration,
  takeSimpleTaskAgain,
  downloadSimpleTask,
  getTmpPhone,
  sendBrowserData
} from './api'
import {
  CLEAR_TASKS,
  RESET_TASK_LIST_STATE,
  SET_MORE_COMPLETED_TASKS,
  SET_MORE_SIMPLE_TASKS,
  SET_RESPONSE_ID,
  SET_SELECTED_TASK_FOR_DETAILS,
  SET_TASKS,
  UPDATE_TASK_GMAIL,
  SET_CURRENT_SIMPLE_INDEX,
  RESET_SIMPLE_TASK_DATA,
  RESET_PAGE,
  UPDATE_TASK_LIST,
  SET_PUBLIC_SELECTED_TASK_FOR_DETAILS
} from './actionTypes'
import { updateRepeatReceivePhone } from '../task/actions'
import { calculateCompleteAt, removeProgressFromIDB, getTextFromFile, collectBrowserData, updateDataIndexedDB } from '../../utils/helpers'
import store from "../index"
import { get, set } from "idb-keyval"
import countryPhoneCodes from '../../tempDictionary/countryPhoneCodes.json'
import { DELAY_SMS_NUM, FORCED_REGENERATE_PHONE, HANDLE_REGENERATE_PHONE } from '../../utils/constants'
import moment from "moment/moment"
import { getStepsForOtherTask } from '../task/api'

export const downloadAllTasks = signal => async dispatch => {
  const {
    publicationTasks: initPublicationTasks,
    simpleTasks: initSimpleTasks,
    completedTasks
  } = await downloadAllTaskList(signal)

  const publicationTasks = {
    ...initPublicationTasks,
    data: initPublicationTasks.data?.map(item => calculateCompleteAt(item)) ?? []
  }
  const simpleTasks = {
    ...initSimpleTasks,
    data: initSimpleTasks.data?.map(item => calculateCompleteAt(item)) ?? []
  }

  dispatch({
    type: SET_TASKS,
    data: { publicationTasks, simpleTasks, completedTasks }
  })
}

export const setMoreSimpleTasks = signal => async dispatch => {
  const initSimpleTasks = await downloadSimpleTaskList(signal)
  if (!initSimpleTasks) return
  const tasks = {
    ...initSimpleTasks,
    data: initSimpleTasks.data.map(item => calculateCompleteAt(item))
  }
  dispatch({
    type: SET_MORE_SIMPLE_TASKS,
    tasks
  })
}

export const setMoreCompletedTasks = (signal) => async dispatch => {
  const tasks = await downloadCompletedTaskList(signal)
  dispatch({
    type: SET_MORE_COMPLETED_TASKS,
    tasks
  })
}

export const setSelectedTaskForDetails = task => {
  return {
    type: SET_SELECTED_TASK_FOR_DETAILS,
    task
  }
}

export const setSelectedPublicTaskForDetails = task => {
  return {
    type: SET_PUBLIC_SELECTED_TASK_FOR_DETAILS,
    task
  }
}

export const setSelectedSimpleGmailTask = () => {
  return {
    type: UPDATE_TASK_GMAIL,
    data: {
      steps: getStepsForOtherTask(),
      main_task_type: 'otherTask'
    }
  }
}

export const updSelectedSimpleGmailTask = (data) => {
  return {
    type: UPDATE_TASK_GMAIL,
    data
  }
}

export const resetTaskList = () => ({
  type: RESET_TASK_LIST_STATE
})

export const updateTasks = (name, reset = false) => ({
  type: SET_RESPONSE_ID,
  id: reset ? 0 : Date.now(),
  name
})

export const clearTasks = (name) => ({
  type: CLEAR_TASKS,
  name
})

const changeSimpleTaskList = (tasks) => ({
  type: UPDATE_TASK_LIST,
  tasks
})

export const takeSimpleTask = (task, oldId) => async dispatch => {
  if (task.type === 'user_simple' && task.settings.google_account) {
    const simpleTaskList = store.getState().taskList.simpleTasks.data
    const updSimpleTaskList = simpleTaskList.map(simpleTask => {
      if (Number(simpleTask.id) === oldId) {
        return {
          ...simpleTask,
          id: task.id
        }
      }
      return simpleTask
    })
    dispatch(changeSimpleTaskList(updSimpleTaskList))
    const result = collectBrowserData('', task?.id)
    sendBrowserData(result)
  }
  dispatch(setStartStepSimpleIndex(task.id))
  dispatch(setSelectedTaskForDetails(calculateCompleteAt(task)))
  dispatch(setSelectedSimpleGmailTask())
  dispatch(updateTasks('simpleTasks'))
}

export const sendSimpleTask = (id, model) => async dispatch => {
  const successSaving = await sendSimpleTaskToModeration(id, model)
  if (!successSaving) return
  dispatch(setSelectedTaskForDetails())
  dispatch(updateTasks('simpleTasks'))
}

export const restartSimpleTask = id => async dispatch => {
  const task = await takeSimpleTaskAgain(id)
  if (!task) return
  if (!task.status) task.status = store.getState().types.statuses.find(item => item.id === String(task.status_id))
  dispatch(setSelectedTaskForDetails(calculateCompleteAt(task)))
  dispatch(updateTasks('simpleTasks'))
}

export const setCurrentSimpleIndex = (taskId, index, needUpdateProgress = false, prevIndex = 0,) => {
  const userId = store.getState().user.userData.id
  const locale = store.getState().locale.locale.name
  return {
    type: SET_CURRENT_SIMPLE_INDEX,
    prevIndex,
    index,
    taskId,
    userId,
    locale,
    needUpdateProgress
  }
}

export const setStartStepSimpleIndex = id => setCurrentSimpleIndex(id, 0, true)


export const setNextStepSimpleIndex = () => {
  const state = store.getState().taskList
  const id = state.selectedSimpleTask.id
  const nextIndex = state.simpleTaskGmail.currentIndex + 1
  const prevIndex = state.simpleTaskGmail.currentIndex
  return setCurrentSimpleIndex(id, nextIndex, false, prevIndex)
}

export const setPrevStepSimpleIndex = () => {
  const state = store.getState().taskList
  const id = state.selectedSimpleTask.id
  const prevIndex = state.simpleTaskGmail.currentIndex - 1
  return setCurrentSimpleIndex(id, prevIndex)
}

export const setStepSimpleIndexFromProgress = id => async dispatch => {
  const getLocalProgress = async () => {
    const allProgress = await get('advancement')
    const state = store.getState()
    const userId = state.user.userData.id
    const steps = state.taskList?.simpleTaskGmail?.steps
    if (state.taskList.selectedSimpleTask?.related_manager_simple?.status?.name === 'moderation_rejected') {
      return steps.length - 1
    }
    if (!allProgress || !userId) return 0
    const locale = state.locale.locale.name
    const key = `${userId}_${id}_${locale}`
    if (!allProgress[key]) return 0
    if (steps && steps.length - 1 < allProgress[key]) return steps.length - 1
    return allProgress[key] || 0
  }
  const index = await getLocalProgress()
  dispatch(setCurrentSimpleIndex(id, index))
}

export const resetSimpleTaskData = (resetAdvancement = false) => {
  const state = store.getState()
  const taskId = state.taskList?.selectedSimpleTask?.id
  const userId = state.user?.userData?.id
  const locale = state.locale.locale.name
  if (resetAdvancement && taskId && userId) {
    removeProgressFromIDB(taskId, userId, locale)
  }
  return {
    type: RESET_SIMPLE_TASK_DATA
  }
}

export const resetPage = () => {
  return {
    type: RESET_PAGE
  }
}

export const downloadAndSetSimpleTask = (id) => async dispatch => {
  const state = store.getState()
  const selectedTask = state.taskList.selectedSimpleTask
  if (selectedTask) {
    dispatch(setSelectedSimpleGmailTask())
    return
  }
  if (!id) return
  const types = state.types
  const task = await downloadSimpleTask(id);
  task.status = types.statuses.find(type => type.name === 'process')
  task.type = 'simple'
  if (!task) return
  dispatch(setSelectedTaskForDetails(calculateCompleteAt(task)))
  dispatch(setSelectedSimpleGmailTask())
  dispatch(updateTasks('simpleTasks'))
}

export const setSimpleBtnDisabled = (data) => {
  return {
    type: UPDATE_TASK_GMAIL,
    data: {
      nextBtnDisabled: data
    }
  }
}

export const getVerifyCode = (id, handleClick = false, initialStart = false, oldPhoneData, startTime, oldTime = 0, restart = false) => async dispatch => {
  if (!oldTime) oldTime = startTime
  const now = moment().parseZone()
  const diff = moment(now).diff(oldTime)
  const maxTimeGetAutoCode = moment.duration(HANDLE_REGENERATE_PHONE, "minutes").asMilliseconds();
  const maxTimeDuration = moment.duration(FORCED_REGENERATE_PHONE, "minutes").asMilliseconds();
  const userInInstruction = !handleClick && !initialStart && !restart
  const recreate = diff > maxTimeGetAutoCode || handleClick
  const stopAutoGetCode = diff > maxTimeDuration || diff > maxTimeGetAutoCode
  if (stopAutoGetCode && !initialStart && !handleClick && startTime === oldTime) return

  let oldPhone = undefined
  if (oldPhoneData && oldPhoneData[id]) oldPhone = oldPhoneData[id]

  const tmpPhone = await getTmpPhone(id, 1, recreate)
  if (tmpPhone === 'notPhones') {
    dispatch(updSelectedSimpleGmailTask({
      phone: 'notPhones',
    }))
    return
  }
  if (!tmpPhone) {
    setTimeout(() => {
      dispatch(getVerifyCode(id, handleClick, initialStart, oldPhoneData, startTime, 0, false))
    }, DELAY_SMS_NUM)
    return
  }

  const countryCode = countryPhoneCodes.find(item => item['short_name'] === tmpPhone?.country)?.code
  const regCodeCountry = new RegExp(`^${countryCode}`)
  const formatNewPhone = tmpPhone?.phone?.replace(regCodeCountry, '')

  if (userInInstruction) {
    dispatch(updSelectedSimpleGmailTask({
      phone: formatNewPhone,
      countryCode,
      changePhone: false
    }))
    if (!oldPhoneData) {
      set('actualPhone', { [id]: {phone: formatNewPhone}})
      set('codeCountry', { [id]: {code: countryCode}})
    } else {
      oldPhoneData = updateDataIndexedDB(oldPhoneData, id, 'phone', formatNewPhone)
      set('actualPhone', oldPhoneData)
      get('codeCountry')
        .then(data => {
          data = updateDataIndexedDB(data, id, 'code', countryCode)
          set('codeCountry', data)
        })
    }
  } else {
    if (oldPhone?.phone !== formatNewPhone) {
      dispatch(updSelectedSimpleGmailTask({
        phone: formatNewPhone,
        countryCode,
        changePhone: true
      }))
      if (!oldPhoneData) {
        set('actualPhone', { [id]: {phone: formatNewPhone}})
        set('codeCountry', { [id]: {code: countryCode}})
      } else {
        oldPhoneData = updateDataIndexedDB(oldPhoneData, id, 'phone', formatNewPhone)
        set('actualPhone', oldPhoneData)
        get('codeCountry')
          .then(data => {
            data = updateDataIndexedDB(data, id, 'code', countryCode)
            set('codeCountry', data)
          })
      }
    } else {
      dispatch(updSelectedSimpleGmailTask({
        phone: formatNewPhone,
        countryCode,
        changePhone: false
      }))
    }
  }

  if (!tmpPhone.text) {
    setTimeout(() => {
      dispatch(getVerifyCode(id, false, false, oldPhoneData, startTime, 0, true))
    }, DELAY_SMS_NUM)
    return
  }
  dispatch(updSelectedSimpleGmailTask({
    codeVerify: tmpPhone?.text?.replace(/[^0-9]/, '')
  }))
  dispatch(updateRepeatReceivePhone(1))
}

export const get2AuthCode = (id, handleClick = false, initialStart = false, oldPhoneData, startTime, oldTime = 0, restart = false) => async dispatch => {
  if (!oldTime) oldTime = startTime
  const now = moment().parseZone()
  const diff = moment(now).diff(oldTime)
  const maxTimeGetAutoCode = moment.duration(HANDLE_REGENERATE_PHONE, "minutes").asMilliseconds();
  const maxTimeDuration = moment.duration(FORCED_REGENERATE_PHONE, "minutes").asMilliseconds();
  const userInInstruction = !handleClick && !initialStart && !restart
  const recreate = diff > maxTimeGetAutoCode || handleClick
  const stopAutoGetCode = diff > maxTimeDuration || diff > maxTimeGetAutoCode
  if (stopAutoGetCode && !initialStart && !handleClick && startTime === oldTime) return

  let oldPhone = undefined
  if (oldPhoneData && oldPhoneData[id]) oldPhone = oldPhoneData[id]
  const tmpPhone = await getTmpPhone(id, 1, recreate)
  if (tmpPhone === 'notPhones') {
    dispatch(updSelectedSimpleGmailTask({
      phone: 'notPhones',
    }))
    return
  }
  if (!tmpPhone) {
    setTimeout(() => {
      dispatch(get2AuthCode(id, handleClick, initialStart, oldPhoneData, startTime, 0, false))
    }, DELAY_SMS_NUM)
    return
  }
  const countryCode = countryPhoneCodes.find(item => item['short_name'] === tmpPhone?.country)?.code
  const regCodeCountry = new RegExp(`^${countryCode}`)
  const formatNewPhone = tmpPhone?.phone?.replace(regCodeCountry, '')

  if (userInInstruction) {
    dispatch(updSelectedSimpleGmailTask({
      phone: formatNewPhone,
      countryCode,
      changePhone: false
    }))
    if (!oldPhoneData) {
      set('actualPhone', { [id]: {phone2auth: formatNewPhone}})
      set('codeCountry', { [id]: {code2auth: countryCode}})
    } else {
      oldPhoneData = updateDataIndexedDB(oldPhoneData, id, 'phone2auth', formatNewPhone)
      set('actualPhone', oldPhoneData)
      get('codeCountry')
        .then(data => {
          data = updateDataIndexedDB(data, id, 'code2auth', countryCode)
          set('codeCountry', data)
        })
    }
  } else {
    if (oldPhone?.phone !== formatNewPhone) {
      dispatch(updSelectedSimpleGmailTask({
        phone: formatNewPhone,
        countryCode,
        changePhone: true
      }))
      if (!oldPhoneData) {
        set('actualPhone', { [id]: {phone2auth: formatNewPhone}})
        set('codeCountry', { [id]: {code2auth: countryCode}})
      } else {
        oldPhoneData = updateDataIndexedDB(oldPhoneData, id, 'phone2auth', formatNewPhone)
        set('actualPhone', oldPhoneData)
        get('codeCountry')
          .then(data => {
            data = updateDataIndexedDB(data, id, 'code2auth', countryCode)
            set('codeCountry', data)
          })
      }
    } else {
      dispatch(updSelectedSimpleGmailTask({
        phone: formatNewPhone,
        countryCode,
        changePhone: false
      }))
    }
  }

  if (!tmpPhone.text) {
    setTimeout(() => {
      dispatch(get2AuthCode(id, false, false, oldPhoneData, startTime, 0,  true))
    }, DELAY_SMS_NUM)
    return
  }
  dispatch(updSelectedSimpleGmailTask({
    code2Auth: tmpPhone?.text?.replace(/[^0-9]/, '')
  }))
  dispatch(updateRepeatReceivePhone(1))
}

export const setCodeVerify = (code) => async dispatch => {
  dispatch(updSelectedSimpleGmailTask({
    codeVerify: code
  }))
}

export const setPhone = (payload) => async dispatch => {
  const { phone, codeCountry } = payload
  dispatch(updSelectedSimpleGmailTask({
    phone,
    codeCountry
  }))
}

export const setCode2Auth = (code) => async dispatch => {
  dispatch(updSelectedSimpleGmailTask({
    code2Auth: code
  }))
}

export const setTextData = (file, fileStatus) => async dispatch => {
  const result = await getTextFromFile(file)
  dispatch(updSelectedSimpleGmailTask({
    textFile: result,
    fileName: file.name,
    fileStatus
  }))
}

export const removeTextData = () => async dispatch => {
  dispatch(updSelectedSimpleGmailTask({
    textFile: undefined,
    fileName: undefined,
    fileStatus: undefined
  }))
}
