import React, { useEffect, useState } from 'react'
import { CheckOutlined, ClockCircleOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons'
import { Form, Select } from 'antd'
import moment from 'moment'
import _ from 'lodash'
import { addressValidator, bankCardValidator, MONTHS } from '../../../utils/constants'
import { formatAddressData, formatCardholderData, checkTranslate } from '../../../utils/helpers'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/rootState'
import { Controller, useForm } from 'react-hook-form'
import IconClear from "../../icons/IconClear";
import Paragraph, { Align, TextStyle } from '../../common_kit/text/Paragraph'

const { Option } = Select


type Data = {
  handlerChange: (valid: boolean, model: BankCardUsdFormModel) => void
}

export type BankCardUsdFormModel = CardholderModel & AddressModel & BankCardModel

type CardholderModel = {
  firstName: string,
  lastName: string,
  birthDateYear: number | undefined,
  birthDateMonth: number | undefined,
  birthDateDay: number | undefined
}

type AddressModel = {
  countryCode: string,
  city: string,
  street: string
}

type BankCardModel = {
  cardExpiryMonth: string,
  cardExpiryYear: number | undefined,
}

export default function BankCardUsdForm(props: Data) {
  const countries = useSelector((state: RootState) => state.types.countries, _.isEqual)

  const [cardholderDataValid, setCardholderDataValid] = useState<boolean>(false)
  const [addressDataValid, setAddressDataValid] = useState<boolean>(false)
  const [bankCardDataValid, setBankCardDataValid] = useState<boolean>(false)
  const [formValid, setFormValid] = useState<boolean>(false)
  const [countryOptions, setCountryOptions] = useState<Array<{ value: string | number, label: string }>>([])
  const [touchedFields, setTouchedFields] = useState<Array<string>>([])

  const {
    register,
    control,
    watch,
    setError,
    clearErrors,
    setFocus,
    setValue,
    formState: {
      errors
    }
  } = useForm<{cardHolder: CardholderModel, address: AddressModel, bankCard: BankCardModel}>({
    mode: 'onChange',
    defaultValues: {
      cardHolder: {
        firstName: '',
        lastName: '',
        birthDateYear: undefined,
        birthDateMonth: undefined,
        birthDateDay: undefined
      },
      address: {
        countryCode: '',
        city: '',
        street: ''
      },
      bankCard: {
        cardExpiryMonth: '',
        cardExpiryYear: undefined
      }
    }
  })

  const cardHolder = watch("cardHolder")
  const address = watch('address')
  const bankCard = watch('bankCard')

  useEffect(() => {
    const excludedCountries = ['xa', 'xb']
    const filteredCountries = countries.filter(item => !excludedCountries.includes(item.name))
    const values = filteredCountries.map(item => ({
      value: item.name,
      label: checkTranslate(item, 'en')
    }))
    setCountryOptions(values)
  }, [])

  useEffect(() => {
    const correctDay = getDayOptions().some(element => element.props.value === cardHolder.birthDateDay)
    if (!cardHolder.birthDateDay || correctDay) {
      return
    }
    changeCardholderModel('birthDateDay', undefined)
  }, [cardHolder.birthDateMonth])

  useEffect(() => {
    const correctMonth = getExpiryMonthOptions().some(element => element.props.value === bankCard.cardExpiryMonth)
    if (!bankCard.cardExpiryMonth || correctMonth) {
      return
    }
    changeBankCardModel('cardExpiryMonth', '')
  }, [bankCard.cardExpiryYear])

  useEffect(() => {
    const { valid } = validateCardholderData()
    setCardholderDataValid(valid)
  }, [cardHolder])

  useEffect(() => {
    const { valid } = validateAddressData()
    setAddressDataValid(valid)
  }, [address])

  useEffect(() => {
    const { valid } = validateBankCardData()
    setBankCardDataValid(valid)
  }, [bankCard, touchedFields])

  useEffect(() => {
    const allDataValid = [
      cardholderDataValid,
      addressDataValid,
      bankCardDataValid
    ].every(item => item)
    setFormValid(allDataValid)
  }, [cardholderDataValid, addressDataValid, bankCardDataValid])

  useEffect(() => {
    const model = {
      ...cardHolder,
      ...address,
      ...bankCard
    }
    props.handlerChange(formValid, model)
  }, [
    formValid,
    cardHolder,
    address,
    bankCard
  ])

  const getYearOptions = () => {
    const start = moment().subtract(80, 'years').year()
    const end = moment().subtract(14, 'years').year()
    const values = _.range(start, end + 1).reverse()
    return values.map(item => <Option key={item} value={item}>
      {item}
    </Option>)
  }

  const getMonthOptions = () => {
    const range = _.range(1, 13)
    const values = range.map(item => ({
      value: item,
      label: MONTHS[item - 1].name
    }))
    return values.map(item => <Option key={item.value} value={item.value}>
      {item.label}
    </Option>)
  }

  const getDayOptions = () => {
    if (!cardHolder.birthDateMonth) return []
    const end = MONTHS[cardHolder.birthDateMonth - 1].days
    const values = _.range(1, end + 1)
    return values.map(item => <Option key={item} value={item}>
      {item}
    </Option>)
  }

  const getCountryOptions = () => {
    return countryOptions.map(item => <Option key={item.value} value={item.value}>
      {item.label}
    </Option>)
  }

  const getExpiryMonthOptions = () => {
    let range
    const fullRange = _.range(1, 13)
    const currentYear = moment().year()
    if (bankCard.cardExpiryYear === currentYear) {
      const currentMonth = moment().month() + 1
      range = fullRange.filter(item => item >= currentMonth)
    } else {
      range = fullRange
    }
    const values = range.map(item => {
      const str = String(item)
      if (str.length === 1) return `0${str}`
      return str
    })
    return values.map(item => <Option key={item} value={item}>
      {item}
    </Option>)
  }

  const getExpiryYearOptions = () => {
    const start = moment().year()
    const end = moment().add(20, 'years').year()
    const values = _.range(start, end + 1)
    return values.map(item => <Option key={item} value={item}>
      {item}
    </Option>)
  }

  const changeCardholderModel = (field, value) => {
    const formattedValue = formatCardholderData(field, value)
    if (formattedValue === null) {
      if (field === 'firstName' || field === 'lastName') return value.slice(0, -1)
      return
    }
    touchField(field)
    setValue('cardHolder', {...cardHolder, [field]: value})
    if (field === 'firstName' || field === 'lastName') return formattedValue
  }

  const changeAddressModel = (field, value) => {
    const formattedValue = formatAddressData(field, value)
    if (formattedValue === null) {
      if (field === 'city' || field === 'street') return value.slice(0, -1)
      return
    }
    touchField(field)
    setValue('address', {...address, [field]: value})
    if (field === 'city' || field === 'street') return value
  }

  const changeBankCardModel = (field, value) => {
    touchField(field)
    setValue('bankCard', {...bankCard, [field]: value})
  }

  const touchField = field => {
    if (!touchedFields.includes(field)) {
      const _touchedFields = [...touchedFields]
      _touchedFields.push(field)
      setTouchedFields(_touchedFields)
    }
  }

  const validateCardholderData = (): { valid: boolean } => {
    let allFieldsTouched: boolean = true
    for (const [field] of Object.entries(cardHolder)) {
      if (!touchedFields.includes(field) || errors?.cardHolder?.hasOwnProperty(field)) {
        allFieldsTouched = false
      }
    }
    return {
      valid: allFieldsTouched,
    }
  }

  const validateAddressData = (): { valid: boolean} => {
    let valid: boolean = true
    let allFieldsTouched: boolean = true
    for (const [field, value] of Object.entries(address)) {
      const _field = field as keyof AddressModel
      if (!touchedFields.includes(field)) {
        allFieldsTouched = false
        continue
      }
      if (field === 'countryCode' && !address.city) continue
      const rules = addressValidator[field]
      for (const rule of rules) {
        if (!rule.condition(value)) {
          valid = false
          setError(`address.${_field}`, { type: 'required', message: rule.errorMessage })
          break
        }
        clearErrors(`address.${_field}`)
      }
    }

    return {
      valid: allFieldsTouched && valid
    }
  }

  const validateBankCardData = (): { valid: boolean } => {
    let valid: boolean = true
    let allFieldsTouched: boolean = true
    for (const [field, value] of Object.entries(bankCard)) {
      const _field = field as keyof BankCardModel
      if (!touchedFields.includes(field)) {
        allFieldsTouched = false
        continue
      }
      const rules = bankCardValidator[field]
      for (const rule of rules) {
        if (!rule.condition(value)) {
          valid = false
          setError(`bankCard.${_field}`, { type: 'required', message: rule.errorMessage })
          break
        }
        clearErrors(`bankCard.${_field}`)
      }
    }
    return {
      valid: allFieldsTouched && valid,
    }
  }

  const filterCountryOptions = (value, option) => option?.children.toLowerCase().includes(value.toLowerCase())

  return (
    <div className="bank-card-form">
      <div className="bank-card-form__block">
        <div className="block-title">
          <UserOutlined/>
          Cardholder
          {cardholderDataValid && <div className="success-icon">
            <CheckOutlined/>
          </div>}
        </div>
        <div className="block-form">
          <div className="form-group">
            <label htmlFor="firstNameCard">
              First name on card
            </label>
            <div className="form-control__container">
              <input
                id="firstNameCard"
                className={errors?.cardHolder?.firstName ? 'form-control invalid' : 'form-control outline'}
                type="text"
                {...register(`cardHolder.firstName`, {
                  required: true,
                  onChange: event => {
                    event.preventDefault()
                    event.target.value = changeCardholderModel('firstName', event.target.value)
                  }
                })}/>
              {cardHolder.firstName && (
                <div className="form-control__icon">
                  <IconClear
                    width={10}
                    height={10}
                    fill={'#FF4848'}
                    className={'form-control__close'}
                    onClick={() => {
                      setValue('cardHolder', {...cardHolder, 'firstName': ''})
                      setError('cardHolder.firstName', { type: 'required' })
                      setFocus('cardHolder.firstName')
                    }}
                  />
                </div>
              )}
            </div>
            {errors?.cardHolder?.firstName && (
              <p className="ui-kit-regular-14 form-control__error-text" style={{textAlign: 'center', fontWeight: 'normal', color: 'rgb(255, 72, 72)', fontStyle: 'italic'}}>
                First name is required
              </p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="lastNameCard">
              Last name on card
            </label>
            <div className="form-control__container">
              <input
                id="lastNameCard"
                className={errors?.cardHolder?.lastName ? 'form-control invalid' : 'form-control outline'}
                type="text"
                {...register(`cardHolder.lastName`, {
                  required: true,
                  onChange: event => {
                    event.preventDefault()
                    event.target.value = changeCardholderModel('lastName', event.target.value)
                  }
                })}/>
              {cardHolder.lastName && (
                <IconClear
                  width={10}
                  height={10}
                  fill={'#FF4848'}
                  className={'form-control__close'}
                  onClick={() => {
                    setValue('cardHolder', {...cardHolder, 'lastName': ''})
                    setError('cardHolder.lastName', { type: 'required' })
                    setFocus('cardHolder.lastName')
                  }}
                />
              )}
            </div>
            {errors?.cardHolder?.lastName && (
              <p className="ui-kit-regular-14 form-control__error-text" style={{textAlign: 'center', fontWeight: 'normal', color: 'rgb(255, 72, 72)', fontStyle: 'italic'}}>
                Last name is required
              </p>
            )}
          </div>
          <Form layout={'vertical'}>
            <Form.Item label={'Date of birth:'} className={'input-birth-date'}>
              <Form.Item>
                <Controller
                  name={`cardHolder.birthDateYear`}
                  control={control}
                  rules={{ required: true }}
                  render={() => (
                    <Select
                      placeholder={'year'}
                      showArrow={false}
                      value={cardHolder.birthDateYear}
                      onChange={(value) => changeCardholderModel('birthDateYear', value)}>
                      options={getYearOptions()}
                    </Select>
                  )}
                />
              </Form.Item>
              <Form.Item>
                <Controller
                  name={`cardHolder.birthDateMonth`}
                  control={control}
                  rules={{ required: true }}
                  render={() => (
                    <Select
                      placeholder={'month'}
                      showArrow={false}
                      value={cardHolder.birthDateMonth}
                      onChange={(value) => changeCardholderModel('birthDateMonth', value)}>
                      {getMonthOptions()}
                    </Select>
                  )}
                />
              </Form.Item>
              <Form.Item>
                <Controller
                  name={`cardHolder.birthDateDay`}
                  control={control}
                  rules={{ required: true }}
                  render={() => (
                    <Select
                      className={errors?.cardHolder?.birthDateDay && 'warning'}
                      placeholder={'day'}
                      showArrow={false}
                      notFoundContent={'Select month'}
                      value={cardHolder.birthDateDay}
                      onChange={(value) => changeCardholderModel('birthDateDay', value)}>
                      {getDayOptions()}
                    </Select>
                  )}
                />
              </Form.Item>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="bank-card-form__block">
        <div className="block-title">
          <HomeOutlined/>
          Address
          {addressDataValid && <div className="success-icon">
            <CheckOutlined/>
          </div>}
        </div>
        <div className="block-form">
          <Form layout={'vertical'} className={'code-country-input'}>
            <Form.Item label={'Country'}>
              <Controller
                name={`address.countryCode`}
                control={control}
                rules={{ required: true }}
                render={() => (
                  <Select
                    className={errors?.address?.countryCode && 'warning'}
                    value={address.countryCode}
                    showSearch
                    showArrow={false}
                    filterOption={filterCountryOptions}
                    onChange={(value) => changeAddressModel('countryCode', value)}>
                    {getCountryOptions()}
                  </Select>
                )}
              />
              <Paragraph
                className={`form-control__error-text`}
                textStyle={TextStyle.ITALIC}
                align={Align.CENTER}
                text={errors?.address?.countryCode?.message}
                color={'#FF4848'}
              />
            </Form.Item>
          </Form>
          <div className="form-group">
            <label htmlFor="cityCardHolder">
              City
            </label>
            <div className="form-control__container">
              <input
                id="cityCardHolder"
                type="text"
                className={errors?.address?.city ? 'form-control invalid' : 'form-control outline'}
                placeholder="London"
                {...register(`address.city`, {
                  required: true,
                  onChange: event => {
                    event.preventDefault()
                    event.target.value = changeAddressModel('city', event.target.value)
                  }
                })}/>
              {address.city && (
                <div className="form-control__icon">
                  <IconClear
                    width={10}
                    height={10}
                    fill={'#FF4848'}
                    zIndex={1}
                    className={'form-control__close'}
                    onClick={() => {
                      setValue('address', {...address, 'city': ''})
                      setError(`address.city`, { type: 'required' })
                      setFocus(`address.city`)
                    }}
                  />
                </div>
              )}
            </div>
            {errors?.address?.city && (
              <p className="ui-kit-regular-14 form-control__error-text" style={{textAlign: 'center', fontWeight: 'normal', color: 'rgb(255, 72, 72)', fontStyle: 'italic'}}>
                City is required
              </p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="houseNumberCardHolder">
              House number, street
            </label>
            <div className="form-control__container">
              <input
                id="houseNumberCardHolder"
                type="text"
                className={errors?.address?.street ? 'form-control invalid' : 'form-control outline'}
                placeholder={'221b Baker Street'}
                {...register(`address.street`, {
                  required: true,
                  onChange: event => {
                    event.preventDefault()
                    event.target.value = changeAddressModel('street', event.target.value)
                  }
                })}/>
              {address.street && (
                <div className="form-control__icon">
                  <IconClear
                    width={10}
                    height={10}
                    fill={'#FF4848'}
                    className={'form-control__close'}
                    onClick={() => {
                      setValue('address', {...address, 'street': ''})
                      setError(`address.street`, { type: 'required' })
                      setFocus(`address.street`)
                    }}
                  />
                </div>
              )}
            </div>
            {errors?.address?.street && (
              <p className="ui-kit-regular-14 form-control__error-text" style={{textAlign: 'center', fontWeight: 'normal', color: 'rgb(255, 72, 72)', fontStyle: 'italic'}}>
                House number, street are required
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="bank-card-form__block bank-card-data">
        <div className="block-title">
          <ClockCircleOutlined/>
          Card expiry
          {bankCardDataValid && <div className="success-icon">
            <CheckOutlined/>
          </div>}
        </div>
        <div className="block-form">
          <Form layout={'vertical'} className={'card-expiry-input'}>
            <Form.Item label={'Month'}>
              <Controller
                name={`bankCard.cardExpiryMonth`}
                control={control}
                rules={{ required: true }}
                render={() => (
                  <Select
                    className={errors?.bankCard?.cardExpiryMonth && 'warning'}
                    placeholder={''}
                    showArrow={false}
                    value={bankCard.cardExpiryMonth}
                    onChange={(value) => changeBankCardModel('cardExpiryMonth', value)}>
                    {getExpiryMonthOptions()}
                  </Select>
                )}
              />
              <Paragraph
                className={`form-control__error-text`}
                textStyle={TextStyle.ITALIC}
                align={Align.CENTER}
                text={errors?.bankCard?.cardExpiryMonth?.message}
                color={'#FF4848'}
              />
            </Form.Item>
            <Form.Item label={'Year'}>
              <Controller
                name={`bankCard.cardExpiryYear`}
                control={control}
                rules={{ required: true }}
                render={() => (
                  <Select
                    placeholder={''}
                    showArrow={false}
                    value={bankCard.cardExpiryYear}
                    onChange={(value) => changeBankCardModel('cardExpiryYear', value)}>
                    {getExpiryYearOptions()}
                  </Select>
                )}
              />
            </Form.Item>
          </Form>
          <div className="bank-card-help">
            <img src="/bankcard_help.png" alt=""/>
          </div>
        </div>
      </div>
    </div>
  )
}
