import React from 'react'


type Data = {
  className?: string
  fill?: string,
  fillBack?: string,
  width?: number,
  height?: number,
  viewBox?: string
}

IconWebMoney.defaultProps = {
  fill: '#859AB6',
  fillBack: '#F5F6FA',
  width: 180,
  height: 44,
  viewBox: '0 0 180 44'
}

export default function IconWebMoney(props: Data) {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="180" height="44" rx="15" fill={props.fillBack}/>
      <path d="M48.1498 15.9815L46.3223 17.6309L47.9915 19.4814L49.8191 17.832L48.1498 15.9815Z"
            fill={props.fill}/>
      <path d="M40.613 10.9341L37.8574 13.415L40.3376 16.1713L43.0932 13.6904L40.613 10.9341Z"
            fill={props.fill}/>
      <path d="M43.1597 16.5196L40.4062 19.0029L42.8889 21.757L45.6423 19.2737L43.1597 16.5196Z"
            fill={props.fill}/>
      <path d="M34.7852 11.8904L31.1035 15.2051L34.4172 18.8876L38.0989 15.5729L34.7852 11.8904Z"
            fill={props.fill}/>
      <path d="M37.4082 18.1912L33.7266 21.5059L37.0403 25.1883L40.7219 21.8737L37.4082 18.1912Z"
            fill={props.fill}/>
      <path d="M40.0156 24.5379L36.334 27.8525L39.6477 31.535L43.3293 28.2204L40.0156 24.5379Z"
            fill={props.fill}/>
      <path d="M45.518 22.3468L42.7637 24.8291L45.2453 27.584L47.9996 25.1017L45.518 22.3468Z"
            fill={props.fill}/>
      <path d="M45.8052 10.4624L43.9531 12.1299L45.6201 13.9824L47.4723 12.3149L45.8052 10.4624Z"
            fill={props.fill}/>
      <path
        d="M74.4908 30.5406H72.0296L68.9304 19.1739H68.7785L65.7705 30.5406H63.3093L59.6328 14.4023H62.1851L64.707 26.4376H64.8893L67.8366 14.4023H69.8723L73.0323 26.4376H73.1842L75.5238 14.4023H78.1977L74.4908 30.5406Z"
        fill={props.fill}/>
      <path
        d="M118.64 30.5406H116.179V18.8396H115.997L111.348 30.5406H109.889L105.241 19.1131H105.028V30.5406H102.992V14.4023H105.727L110.831 27.1367H110.953L115.814 14.4023H118.64V30.5406Z"
        fill={props.fill}/>
      <path
        d="M143.25 30.5406H140.88V22.821C140.88 21.0278 139.999 20.6935 138.844 20.6935C137.872 20.7524 136.926 21.0331 136.079 21.5141V30.6318H133.709V20.572C135.481 19.5047 137.505 18.9278 139.573 18.9004C142.308 18.9004 143.31 19.9641 143.31 22.3043L143.25 30.5406Z"
        fill={props.fill}/>
      <path
        d="M162.333 30.6622C161.179 33.7014 159.781 35.4337 157.168 35.4337V33.5798C158.717 33.5798 159.69 32.3641 160.206 30.5406L155.861 19.1436H158.353L161.391 27.5318L163.883 19.1436H166.314L162.333 30.6622Z"
        fill={props.fill}/>
      <path
        d="M44.1977 35.95C41.8767 37.2798 39.2512 37.9859 36.5764 37.9998C33.9017 38.0137 31.269 37.3348 28.9343 36.0293C26.5996 34.7237 24.6426 32.8359 23.2535 30.5496C21.8644 28.2632 21.0907 25.6561 21.0075 22.982C20.9243 20.3079 21.5346 17.6577 22.7789 15.2894C24.0231 12.9211 25.8591 10.9153 28.1081 9.46708C30.3572 8.01889 32.9426 7.17764 35.613 7.02519C38.2834 6.87274 40.9478 7.41427 43.347 8.5971L44.3193 9.08337L42.8912 10.3598L40.7339 8.11083L37.1182 11.3628L34.9913 8.9922L28.2459 15.0706L32.5605 19.8422L30.859 21.3314L35.1128 26.103L33.4417 27.5922L39.5185 34.3393L43.1039 31.0265L46.1423 34.5824C45.5269 35.0831 44.8771 35.5401 44.1977 35.95Z"
        fill={props.fill}/>
      <path d="M47.9435 27.8893L45.1914 30.374L47.6755 33.1268L50.4276 30.642L47.9435 27.8893Z"
            fill={props.fill}/>
      <path d="M50.5357 21.4224L48.6836 23.0898L50.3506 24.9424L52.2027 23.2749L50.5357 21.4224Z"
            fill={props.fill}/>
      <path
        d="M126.356 18.9004C123.317 18.9004 121.16 20.8759 121.16 24.9788C121.16 29.0818 123.226 31.0573 126.356 31.0573C129.485 31.0573 131.521 28.9906 131.521 24.9788C131.521 20.9671 129.334 18.9004 126.356 18.9004ZM126.356 29.2337C124.715 29.2337 123.53 27.9876 123.53 24.8573C123.53 21.7269 124.837 20.4808 126.356 20.4808C127.875 20.4808 129.151 21.7876 129.151 24.8573C129.151 27.9269 127.966 29.2337 126.356 29.2337Z"
        fill={props.fill}/>
      <path
        d="M96.1272 18.9C95.0637 18.9289 94.0223 19.2102 93.0887 19.7206V13.2471L90.7188 13.8245V29.7196C92.104 30.3926 93.6156 30.7654 95.1549 30.8137C98.4668 30.8137 100.503 28.7471 100.503 24.4922C100.472 20.8451 98.9529 18.9 96.1272 18.9ZM95.1245 29.2333C94.4087 29.2479 93.6968 29.1241 93.028 28.8686V21.4226C93.7691 20.937 94.6336 20.6734 95.5195 20.6628C97.0387 20.6628 98.1022 21.6049 98.1022 24.4922C98.1022 28.2608 96.826 29.2333 95.1245 29.2333Z"
        fill={props.fill}/>
      <path
        d="M155.161 24.4622C155.161 20.7239 153.398 18.9004 150.542 18.9004C147.139 18.9004 145.377 21.3925 145.377 24.9788C145.377 28.5651 146.957 31.0573 150.785 31.0573C152.201 30.9897 153.574 30.5493 154.766 29.7808V28.0484C153.698 28.7793 152.443 29.1905 151.15 29.2337C148.81 29.2337 147.686 28.018 147.686 25.2827H155.13L155.161 24.4622ZM147.777 23.7631C148.02 21.4837 149.084 20.42 150.482 20.42C151.879 20.42 152.882 21.3622 152.973 23.7631H147.777Z"
        fill={props.fill}/>
      <path
        d="M88.6197 24.4622C88.6197 20.7239 86.8574 18.9004 84.0013 18.9004C80.5982 18.9004 78.8359 21.3925 78.8359 24.9788C78.8359 28.5651 80.4159 31.0573 84.2444 31.0573C85.6576 31.0641 87.0489 30.7083 88.2855 30.0239V28.0484C87.2043 28.7758 85.9414 29.1863 84.6394 29.2337C82.2998 29.2337 81.2059 28.018 81.2059 25.2827H88.6197V24.4622ZM81.2363 23.7631C81.4794 21.4837 82.5428 20.42 83.9405 20.42C85.3382 20.42 86.3409 21.3622 86.4624 23.7631H81.2363Z"
        fill={props.fill}/>
    </svg>
  )
}
