import React from 'react'
import Paragraph, { TextSize } from '../common_kit/text/Paragraph'
import Tooltip, { CardStatusColor } from '../common_kit/Tooltip'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'


type Data = {
  title: string,
  text: string,

  className?: string,
  color?: CardStatusColor,
  hint?: string,
  bottomTitle?: boolean
}

StatusBlock.defaultProps = {
  className: '',
  color: CardStatusColor.BLUE,
  bottomTitle: false
}

type adaptiveCssModel = {
  textSize: string
}

type statusTaskCssModel = {
  s: adaptiveCssModel,
  m: adaptiveCssModel,
  l: adaptiveCssModel
}

export default function StatusBlock(props: Data) {
  const size = useSelector((state: RootState) => state.mq.size)

  let statusTaskCss: statusTaskCssModel = {
    s: {
      textSize: 'P_12'
    },
    m: {
      textSize: 'P_14'
    },
    l: {
      textSize: 'P_14'
    }
  }

  return (
    <div className={`status-block ${!props.bottomTitle ? 'onlyTime' : ''} ${props.className}`}>
      {!props.bottomTitle && <div className="status-block__title">
        <Paragraph text={props.title} size={TextSize[statusTaskCss[size].textSize]}/>
        {props.hint && (
          <Tooltip
            className={'status-block__tooltip'}
            svg={props.color}
            text={props.hint}
            color={props.color}/>
        )}
      </div>}
      <h5 className={`ui-kit status-block__text`} style={{ color: props.color }}>
        <span>{props.text ?? ''}</span>
      </h5>
      {props.bottomTitle && <Paragraph text={props.title}/>}
    </div>
  )
}
