import React from 'react'

type Data = {
  className?: string
  fill?: string,
  fillBack?: string,
  stroke?: string,
  width?: number,
  height?: number
}

IconAccepted.defaultProps = {
  fill: '#2684FE',
  fillBack: '#E5F1FF',
  stroke: '#2684FE',
  width: 52,
  height: 52
}

export default function IconAccepted(props: Data) {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="26" cy="26" r="26" fill={props.fillBack}/>
      <path
        d="M31.3525 22.3525L31.3525 22.3525C30.9857 21.9307 30.3745 21.9307 30.0078 22.3526L24.156 29.0846L21.165 25.6437C20.7982 25.2217 20.1871 25.2218 19.8203 25.6436C19.4714 26.045 19.4714 26.6832 19.8203 27.0846L23.4836 31.299C23.6625 31.5049 23.9054 31.6155 24.156 31.6155C24.4066 31.6155 24.6495 31.5048 24.8283 31.299L31.3525 23.7935C31.7014 23.3921 31.7014 22.7539 31.3525 22.3525Z"
        fill={props.fill} stroke={props.stroke} strokeWidth="0.5"/>
    </svg>
  )
}
