export const SET_TASKS = 'SET_TASKS'
export const SET_SELECTED_TASK_FOR_DETAILS = 'SET_SELECTED_TASK_FOR_DETAILS'
export const RESET_TASK_LIST_STATE = 'RESET_TASK_LIST_STATE'
export const SET_MORE_SIMPLE_TASKS = 'SET_MORE_SIMPLE_TASKS'
export const SET_MORE_COMPLETED_TASKS = 'SET_MORE_COMPLETED_TASKS'
export const SET_RESPONSE_ID = 'SET_RESPONSE_ID'
export const CLEAR_TASKS = 'CLEAR_TASKS'
export const UPDATE_TASK_GMAIL = 'UPDATE_TASK_GMAIL'
export const SET_CURRENT_SIMPLE_INDEX = 'SET_CURRENT_SIMPLE_INDEX'
export const RESET_SIMPLE_TASK_DATA = 'RESET_SIMPLE_TASK_DATA'
export const RESET_PAGE = 'RESET_PAGE'
export const UPDATE_TASK_LIST = 'UPDATE_TASK_LIST'
export const SET_PUBLIC_SELECTED_TASK_FOR_DETAILS = 'SET_PUBLIC_SELECTED_TASK_FOR_DETAILS'
