import React from 'react'


type Data = {
  className?: string
  onClick?: (event: React.MouseEvent) => void
  fill?: string,
  width?: number,
  height?: number,
  zIndex?: number
}

IconClear.defaultProps = {
  fill: '#000000',
  width: 24,
  height: 24
}

export default function IconClear(props: Data) {

  const className = 'icon-clear' + (props.className ? ` ${props.className}` : '')

  const handleOnClick = (event: React.MouseEvent) => {
    if (!props.onClick) return
    props.onClick(event)
  }

  return (
    <svg
      style={{
        width: `${props.width}px`,
        height: `${props.height}px`,
        zIndex: props.zIndex
      }}
      className={className}
      onClick={handleOnClick}
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1 L23 23" stroke={props.fill} strokeWidth="2" strokeLinecap="round"/>
      <path d="M1 23 L23 1" stroke={props.fill} strokeWidth="2" strokeLinecap="round"/>
    </svg>
  )
}
