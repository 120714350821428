import initialState from '../sidebar/initialState'
import {
  CLEAR_SIDEBAR_OPTIONS,
  HIDE_MENU,
  HIDE_SIDEBAR,
  SET_SIDEBAR_AVAILABILITY,
  SHOW_MENU,
  SHOW_SIDEBAR
} from './actionTypes'

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SIDEBAR_AVAILABILITY:
      return {
        ...state,
        available: action.data
      }
    case SHOW_SIDEBAR:
      return {
        ...state,
        show: true,
        shownMenu: false
      }
    case HIDE_SIDEBAR:
      return {
        ...state,
        show: false
      }
    case SHOW_MENU:
      return {
        ...state,
        show: false,
        shownMenu: true
      }
    case HIDE_MENU:
      return {
        ...state,
        shownMenu: false
      }
    case CLEAR_SIDEBAR_OPTIONS:
      return {
        ...initialState
      }
    default:
      return state
  }
}
