export type BinomState = {
  cnv_id: string,
  alreadyReqCode: boolean,
  utm_source: string
}

const initialState: BinomState = {
  cnv_id: '',
  alreadyReqCode: false,
  utm_source: ''
}

export default initialState
