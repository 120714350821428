import React from 'react'

export default function SignupSocialInstagram() {
  return (
    <div className="social-icon">
      <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.5006 11.25C7.5006 9.17901 9.17901 7.4997 11.25 7.4997C13.321 7.4997 15.0003 9.17901
          15.0003 11.25C15.0003 13.321 13.321 15.0003 11.25 15.0003C9.17901 15.0003 7.5006 13.321
          7.5006 11.25ZM5.47326 11.25C5.47326 14.4405 8.0595 17.0267 11.25 17.0267C14.4405 17.0267
          17.0267 14.4405 17.0267 11.25C17.0267 8.0595 14.4405 5.47326 11.25 5.47326C8.0595 5.47326
          5.47326 8.0595 5.47326 11.25ZM15.9054 5.24421C15.9053 5.51121 15.9844 5.77225 16.1326
          5.99432C16.2809 6.21639 16.4917 6.3895 16.7383 6.49178C16.9849 6.59406 17.2564 6.6209 17.5183
          6.56891C17.7802 6.51693 18.0208 6.38845 18.2096 6.19973C18.3985 6.011 18.5272 5.7705 18.5794
          5.50865C18.6316 5.2468 18.605 4.97535 18.5029 4.72863C18.4008 4.4819 18.2278 4.27099 18.0059
          4.12257C17.784 3.97414 17.523 3.89486 17.256 3.89475H17.2554C16.8975 3.89492 16.5543 4.03713
          16.3012 4.29015C16.0481 4.54317 15.9057 4.88631 15.9054 5.24421ZM6.705 20.4072C5.60817 20.3573
          5.01201 20.1746 4.61583 20.0202C4.09059 19.8157 3.71583 19.5722 3.32181 19.1787C2.92779 18.7852
          2.68389 18.4108 2.48031 17.8856C2.32587 17.4896 2.14317 16.8933 2.09331 15.7964C2.03877 14.6106
          2.02788 14.2544 2.02788 11.2501C2.02788 8.2458 2.03967 7.89057 2.09331 6.70374C2.14326 5.60691
          2.32731 5.01174 2.48031 4.61457C2.68479 4.08933 2.92833 3.71457 3.32181 3.32055C3.71529 2.92653
          4.08969 2.68263 4.61583 2.47905C5.01183 2.32461 5.60817 2.14191 6.705 2.09205C7.89084 2.03751
          8.24706 2.02662 11.25 2.02662C14.2529 2.02662 14.6095 2.03841 15.7963 2.09205C16.8932 2.142 17.4883
          2.32605 17.8855 2.47905C18.4108 2.68263 18.7855 2.92707 19.1795 3.32055C19.5736 3.71403 19.8166
          4.08933 20.021 4.61457C20.1755 5.01057 20.3582 5.60691 20.408 6.70374C20.4626 7.89057 20.4735
          8.2458 20.4735 11.2501C20.4735 14.2544 20.4626 14.6096 20.408 15.7964C20.3581 16.8933 20.1745
          17.4894 20.021 17.8856C19.8166 18.4108 19.573 18.7856 19.1795 19.1787C18.7861 19.5718 18.4108
          19.8157 17.8855 20.0202C17.4895 20.1747 16.8932 20.3574 15.7963 20.4072C14.6105 20.4618 14.2543
          20.4727 11.25 20.4727C8.24571 20.4727 7.89048 20.4618 6.705 20.4072ZM6.61185 0.06813C5.41422 0.12267
          4.59585 0.31257 3.88116 0.59067C3.141 0.87786 2.51442 1.26315 1.88829 1.88829C1.26216 2.51343 0.87786
          3.141 0.59067 3.88116C0.31257 4.5963 0.12267 5.41422 0.06813 6.61185C0.01269 7.81137 0 8.19486 0 11.25C0
          14.3051 0.01269 14.6886 0.06813 15.8881C0.12267 17.0859 0.31257 17.9037 0.59067 18.6188C0.87786 19.3585
          1.26225 19.9868 1.88829 20.6117C2.51433 21.2366 3.141 21.6213 3.88116 21.9093C4.5972 22.1874 5.41422
          22.3773 6.61185 22.4319C7.812 22.4864 8.19486 22.5 11.25 22.5C14.3051 22.5 14.6886 22.4873 15.8881
          22.4319C17.0859 22.3773 17.9037 22.1874 18.6188 21.9093C19.3585 21.6213 19.9856 21.2368 20.6117
          20.6117C21.2378 19.9866 21.6213 19.3585 21.9093 18.6188C22.1874 17.9037 22.3782 17.0858 22.4319
          15.8881C22.4864 14.6877 22.4991 14.3051 22.4991 11.25C22.4991 8.19486 22.4864 7.81137 22.4319
          6.61185C22.3773 5.41413 22.1874 4.59585 21.9093 3.88116C21.6213 3.14145 21.2368 2.51442 20.6117
          1.88829C19.9866 1.26216 19.3586 0.87786 18.6197 0.59067C17.9037 0.31257 17.0858 0.12177 15.889
          0.06813C14.6895 0.01359 14.306 0 11.2509 0C8.19576 0 7.812 0.01269 6.61185 0.06813Z"
          fill="#859AB6"/>
      </svg>
    </div>
  )
}
