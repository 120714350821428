import React, { ReactElement } from 'react'
import SignupPage from './SignupPage'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import LoginPage from './LoginPage'
import PaymentsPage from './PaymentsPage'
import UpdateTaskPage from './task/UpdateTaskPage'
import Referrals from './Referrals'
import UserProfile from './UserProfile'
import TasksPage from './TasksPage'
import TaskPage from './task/TaskPage'
import OtherTaskPage from './task/OtherTaskPage'
import { useSelector } from 'react-redux'
import { RootState } from '../store/rootState'
import { useTranslation } from 'react-i18next'
import Sentry from '../sentry'

const SentryRoute = Sentry.withSentryRouting(Route)

export default function Body(): ReactElement | null {
  const { i18n } = useTranslation()
  const location = useLocation()

  const isAuth = useSelector((state: RootState) => {
    const id = state.user.userData?.id || state.user.id
    const token = state.auth.access_token
    return !!id && !!token
  })
  const size = useSelector((state: RootState) => state.mq.size)

  if (!size) return null

  const getPrivateRoute = ({ ...rest }) => {
    return (
      <SentryRoute {...rest}>
        {!isAuth && <Redirect to={`/${i18n.language ?? 'ru'}/sign-in`}/>}
      </SentryRoute>
    )
  }

  const routeClass = location.pathname.split('/')[1]

  return (
    <div className={`content content-${routeClass}`}>
      <Switch>
        <SentryRoute exact path="/">
          {isAuth
            ? <Redirect to="/available-tasks"/>
            : location.search.includes('clickid')
              ? <Redirect to={`/${i18n.language ?? 'ru'}/sign-up`}/>
              : <Redirect to={`/${i18n.language ?? 'ru'}/sign-in`}/>
          }
        </SentryRoute>
        <SentryRoute exact path="/:locale/sign-up" component={SignupPage}>
          {isAuth && <Redirect to="/available-tasks"/>}
        </SentryRoute>
        <SentryRoute path="/:locale/sign-in" component={LoginPage}>
          {isAuth && <Redirect to="/available-tasks"/>}
        </SentryRoute>
        {getPrivateRoute({ exact: true, path: '/available-tasks', component: TasksPage })}
        {getPrivateRoute({ path: '/task/publish', component: TaskPage })}
        {getPrivateRoute({ path: '/update/task/:id', component: UpdateTaskPage })}
        {getPrivateRoute({ path: '/verification/task/:id', component: TaskPage })}
        {getPrivateRoute({ path: '/google-account/task/:id', component: OtherTaskPage })}
        {getPrivateRoute({ path: '/withdrawal', component: PaymentsPage })}
        {getPrivateRoute({ path: '/referrals', component: Referrals })}
        {getPrivateRoute({ path: '/profile', component: UserProfile })}
      </Switch>
    </div>
  )
}
