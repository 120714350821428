import { downloadUserData, uploadUserData } from './api'
import { SET_USER_DATA, SET_USER_ID, UPDATE_USER_DATA } from './actionTypes'
import { del, get } from 'idb-keyval'
import store from '../index'

export const setLocalUserData = () => async dispatch => {
  const userData = await get('userData')
  if (!userData) return
  dispatch({
    type: SET_USER_DATA,
    userData
  })
}

export const setUserId = id => ({
  type: SET_USER_ID,
  id
})

export const setUserData = userData => ({
  type: SET_USER_DATA,
  userData
})

export const clearUserData = () => {
  del('userData')
  return {
    type: SET_USER_DATA,
    userData: undefined
  }
}

export const updateUserData = () => async dispatch => {
  const userData = await downloadUserData()
  if (!userData) return
  dispatch({
    type: SET_USER_DATA,
    userData
  })
}

export const editUserData = () => async dispatch => {
  const { user, userProfile } = store.getState()
  const id = user.userData.id
  const data = {
    first_name: user.userData.first_name,
    email: user.userData.email,
    ...userProfile.form.unsavedData
  }
  if (userProfile.path !== user.image) {
    data.image = userProfile.path
  }
  const userData = await uploadUserData(data, id)
  if (!userData) return
  dispatch({
    type: UPDATE_USER_DATA,
    userData
  })
}
