import { combineReducers } from 'redux'
import accruals from './accruals/reducer'
import payments from './payments/reducer'
import types from './types/reducer'
import user from './user/reducer'
import sidebar from './sidebar/reducer'
import intercom from './intercom/reducer'
import locale from './locale/reducer'
import taskList from './taskList/reducer'
import auth from './auth/reducer'
import taskScreenshots from './taskScreenshots/reducer'
import modal from './modal/reducer'
import phone from './phone/reducer'
import task from './task/reducer'
import application from './application/reducer'
import mq from './mq/reducer'
import userProfile from './userProfile/reducer'
import binom from './binom/reducer'

export default combineReducers({
  accruals,
  payments,
  sidebar,
  types,
  user,
  intercom,
  locale,
  taskList,
  auth,
  taskScreenshots,
  modal,
  phone,
  task,
  application,
  mq,
  userProfile,
  binom
})
