import initialState from './initialState'
import {
  RESET_TASK_DATA,
  SET_BTN_DISABLED,
  SET_CURRENT_INDEX,
  SET_SELECTED_TASK,
  SET_SELECTED_TASK_ONLY,
  UPDATE_RECEIVE_PHONE,
  UPDATE_AVAILABLE_PHONE,
  UPDATE_GOOGLE_ADMIN_EMAIL
} from './actionTypes'
import { setProgressToIDB } from '../../utils/helpers'


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_BTN_DISABLED:
      return {
        ...state,
        nextBtnDisabled: action.data
      }
    case SET_SELECTED_TASK:
      return {
        ...state,
        selectedTask: action.task,
        steps: action.steps
      }
    case SET_SELECTED_TASK_ONLY:
      return {
        ...state,
        selectedTask: action.task
      }
    case SET_CURRENT_INDEX:

      const needUpdateProgress = action.needUpdateProgress || (!state.progress || state.progress < action.index)
      const progressOption = needUpdateProgress ? { progress: action.index } : {}
      if (needUpdateProgress) setProgressToIDB(action.taskId, action.userId, action.index, action.locale, action.taskType)
      return {
        ...state,
        currentIndex: action.index,
        currentStep: state.steps ? state.steps[action.index] : undefined,
        ...progressOption
      }
    case RESET_TASK_DATA:
      return {
        ...state,
        selectedTask: undefined,
        steps: undefined,
        currentIndex: undefined,
        currentStep: undefined,
        progress: undefined
      }
    case UPDATE_RECEIVE_PHONE:
      return {
        ...state,
        repeatReceivePhone: action.count
      }
    case UPDATE_AVAILABLE_PHONE:
      return {
        ...state,
        availablePhone: action.status
      }
    case UPDATE_GOOGLE_ADMIN_EMAIL:
      return {
        ...state,
        googleAdminEmail: action.email
      }
    default:
      return state
  }
}
