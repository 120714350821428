import { SET_TYPES_LIST } from './actionTypes'
import { downloadType } from './api'
import { get } from 'idb-keyval'

export const setLocalTypes = () => async dispatch => {
  const types = await get('types')
  if (!types) return
  dispatch({
    type: SET_TYPES_LIST,
    types
  })
}

export const setTypes = () => async dispatch => {
  const types = await downloadType()
  if (!types) return
  dispatch({
    type: SET_TYPES_LIST,
    types
  })
}
