import React, { useState } from 'react'
import '../../style/sass/tutorial/Tutorial.scss'


type TutorialData = {
  title: string,
  description: string,
  video_url: string,
  video_background: string,
  button_image: string
}

export default function Tutorial(props: TutorialData) {

  const [videoVisibility, setVideoVisibility] = useState<boolean>(false)

  const showVideo = () => {
    setVideoVisibility(true)
  }

  return (
    <div className={'tutorial-container'} style={{ display: 'none' }}>
      <div className={'video-container'}>
        <div className={'video-controls'}>
          <img className={'video-preview'} src={props.video_background} alt=""/>
          <img onClick={showVideo} className={'play'} src={props.button_image} alt=""/>
        </div>
        <div className={'video ' + (videoVisibility ? '' : 'hidden')}>
          <iframe
            src={props.video_url}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="video"
            width="750px"
            height="428px"
          />
        </div>
      </div>
      <div className={'video-hint'}>
        <h3>{props.title}</h3>
        <p className={'ui-kit-regular-18'} style={{ fontWeight: 'bold' }}>{props.description}</p>
      </div>
    </div>
  )
}

