import { FileUploadStatus } from '../../utils/helpers'

export interface UserProfileState {
  image: string | null,
  path: string | null,
  form: UserProfileForm,
  status?: FileUploadStatus
}

type UserProfileForm = {
  dataId: number,
  unsavedData: {
    lastName?: string,
    firstName?: string,
    email?: string,
    password?: string
  }
}

const initialStore: UserProfileState = {
  image: '',
  path: null,
  status: undefined,
  form: {
    dataId: Date.now(),
    unsavedData: {}
  }
}

export default initialStore
