import React, { useCallback, useEffect, useState } from 'react'
import CleanPayoutsStory from './CleanPayoutsStory'
import PaymentHistoryItem from './PaymentHistoryItem'
import WithdrawalStoryTabs from './WithdrawalStoryTabs'
import CleanAccrualsStory from './CleanAccrualsStory'
import AccrualHistoryItem from './AccrualHistoryItem'
import Img from '../../common_kit/Img'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/rootState'
import { resetHistoryPayments, setPaymentsHistory } from '../../../store/payments/actions'
import { resetAccrualsHistory, setAccrualsHistory } from '../../../store/accruals/actions'
import MqAfterSmall from '../../mq/MqAfterSmall'
import MqSmall from '../../mq/MqSmall'
import { Collapse, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import DownloadElseCard from '../../common_kit/DownloadElseCard'


const { Panel } = Collapse

type Tab = {
  name: string,
  title: string,
}

export const STORY_TABS: Array<Tab> = [
  {
    name: 'payouts',
    title: 'History of withdrawal'
  },
  {
    name: 'accruals',
    title: 'History of profit'
  }
]

export default function WithdrawalStory() {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const accruals = useSelector((state: RootState) => state.accruals.history, _.isEqual)
  const allAccrualsLoaded = useSelector((state: RootState) => {
    const pageInfo = state.accruals.meta.page
    if (!pageInfo || !pageInfo.current_page || !pageInfo.last_page) return true
    return pageInfo.current_page === pageInfo.last_page
  })
  const payments = useSelector((state: RootState) => state.payments.history, _.isEqual)
  const allPaymentsLoaded = useSelector((state: RootState) => {
    const pageInfo = state.payments.meta.page
    if (!pageInfo || !pageInfo.current_page || !pageInfo.last_page) return true
    return pageInfo.current_page === pageInfo.last_page
  })
  const paymentsResponseId = useSelector((state: RootState) => state.payments.responseId)

  const [activeTab, setActiveTab] = useState<string>(STORY_TABS[0].name)
  const [activePanels, setActivePanels] = useState<Array<string>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingPayments, setLoadingPayments] = useState<boolean>(false)
  const [loadingAccruals, setLoadingAccruals] = useState<boolean>(false)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)

  useEffect(() => {
    setLoadingPayments(true)
    setLoadingAccruals(true)
    const downloadPayments = async () => {
      await dispatch(setPaymentsHistory())
    }
    const downloadAccruals = async () => {
      await dispatch(setAccrualsHistory())
    }
    downloadPayments()
      .finally(() => {setLoadingPayments(false)})
    downloadAccruals()
      .finally(() => {setLoadingAccruals(false)})
    return () => {
      dispatch(resetAccrualsHistory())
      dispatch(resetHistoryPayments())
    }
  }, [])

  useEffect(() => {
    if (!paymentsResponseId || activeTab !== 'payouts') return
    const asyncEffect = async () => {
      await dispatch(setPaymentsHistory())
    }

    setLoading(true)
    dispatch(resetHistoryPayments())
    asyncEffect()
      .finally(() => {setLoading(false)})
  }, [paymentsResponseId])

  const handleLoadMoreAccrualsClick = useCallback(async () => {
    setLoadingMore(true)
    await dispatch(setAccrualsHistory())
    setLoadingMore(false)
  }, [])

  const handleLoadMorePaymentsClick = useCallback(async () => {
    setLoadingMore(true)
    await dispatch(setPaymentsHistory())
    setLoadingMore(false)
  }, [])

  const getPayments = () => {
    if (!payments.length) return <CleanPayoutsStory/>

    return (
      <div className="transactions-history__container">
        {
          payments.map(payment => {
            return <PaymentHistoryItem key={payment.id} payment={payment}/>
          })
        }
        {!allPaymentsLoaded && (
          <DownloadElseCard
            className='accruals-card'
            onMoreLoadClickHandler={handleLoadMorePaymentsClick}
            isLoading={loadingMore}/>
        )}
      </div>
    )
  }

  const getAccruals = () => {
    if (!accruals.length) return <CleanAccrualsStory/>

    return (
      <div className="transactions-history__container">
        {
          accruals.map(accrual => {
            return <AccrualHistoryItem key={accrual.id} data={accrual}/>
          })
        }
        {!allAccrualsLoaded && (
          <DownloadElseCard
            className='accruals-card'
            onMoreLoadClickHandler={handleLoadMoreAccrualsClick}
            isLoading={loadingMore}/>
        )}
      </div>
    )
  }

  const getContent = () => {
    if (loading) return <div className="transactions-history__loader">
      <Img src={'/loader.gif'}/>
    </div>
    switch (activeTab) {
      case 'payouts':
        return getPayments()
      case 'accruals':
        return getAccruals()
    }
  }

  return (
    <div className="transactions-history">
      <MqSmall>
        <Collapse
          activeKey={activePanels}
          expandIcon={() => (
            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.375 4.49902L6.10671 0.749024L1.625 4.49902" stroke="#859AB6" strokeWidth="1.5"
                    strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}
          onChange={keys => setActivePanels(keys as Array<string>)}>
          <Panel header={t(STORY_TABS.find(item => item.name === 'payouts')!.title)} key="payouts">
            <Spin spinning={loadingPayments}>
              {getPayments()}
            </Spin>
          </Panel>
          <Panel header={t(STORY_TABS.find(item => item.name === 'accruals')!.title)} key="accruals">
            <Spin spinning={loadingAccruals}>
              {getAccruals()}
            </Spin>
          </Panel>
        </Collapse>
      </MqSmall>
      <MqAfterSmall>
        <WithdrawalStoryTabs
          activeTab={activeTab}
          handlerTabClick={(tabName) => setActiveTab(tabName)}/>
        {getContent()}
      </MqAfterSmall>
    </div>
  )
}
