import React from 'react'
import Span, { TextSize } from '../common_kit/text/Span'
import { CardStatusColor } from '../common_kit/Tooltip'


type RadioButtonData = {
  isActive: boolean,
  value: string,
  label: string,
  handler: (value:string) => void,
}

export default function RadioButton (props:RadioButtonData) {

  const handleClick = () => props.handler(props.value)

  const src = props.isActive ? '/radioBtnIcons/RadioAfter.svg' : '/radioBtnIcons/RadioBefore.svg'

  return <div className={`radio-button ${props.isActive && 'active'}`} onClick={handleClick}>
    <img src={src} alt=""/>
    <Span color={CardStatusColor.GRAY} text={props.label} size={TextSize.P18}/>
  </div>
}
