import React from "react";
import {useTranslation} from "react-i18next";


type Data = {
  className?: string
  fill?: string,
  fillBack?: string,
  width?: number,
  height?: number,
  viewBox?: string
}

IconMobile.defaultProps = {
  fill: "#859AB6",
  fillBack: "#F5F6FA",
  width: 18,
  height: 31,
  viewBox: "0 0 18 31"
}

export default function IconMobile(props: Data) {

  const {t} = useTranslation()

  return <div className='icon'>
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5385 0H3.46154C2.54348 0 1.66303 0.371143 1.01386 1.03178C0.364697 1.69242 0 2.58844 0 3.52273V27.4773C0 28.4116 0.364697 29.3076 1.01386 29.9682C1.66303 30.6289 2.54348 31 3.46154 31H14.5385C15.4565 31 16.337 30.6289 16.9861 29.9682C17.6353 29.3076 18 28.4116 18 27.4773V3.52273C18 2.58844 17.6353 1.69242 16.9861 1.03178C16.337 0.371143 15.4565 0 14.5385 0ZM9 29.5909C7.85077 29.5909 6.92308 28.6468 6.92308 27.4773C6.92308 26.3077 7.85077 25.3636 9 25.3636C10.1492 25.3636 11.0769 26.3077 11.0769 27.4773C11.0769 28.6468 10.1492 29.5909 9 29.5909ZM15.2308 23.9545H2.76923V4.22727H15.2308V23.9545Z"
        fill={props.fill}/>
    </svg>
    <div className="title">{t('Cell phone account')}</div>
  </div>
}
