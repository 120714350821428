import React, { useCallback, useEffect, useState } from 'react'
import '../../style/sass/signupPage/PhoneVerification.scss'
import Checkbox from '../inputs/Checkbox'
import Paragraph, { TextSize, TextStyle } from '../common_kit/text/Paragraph'
import Timer from '../common_kit/Timer'
import SubmitButton from '../inputs/SubmitButton'
import { useTranslation } from 'react-i18next'
import { sendSms, verifyUser } from '../../store/auth/api'
import { defineCountryIP } from '../../store/phone/api'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/rootState'
import { changePhoneModel, setPhoneVerified } from '../../store/phone/actions'
import { CUSTOM_VALID_PHONE, RESEND_SMS_TIMEOUT } from '../../utils/constants'
import { updPhoneCodeStatus } from '../../store/binom/actions'
import { Button, Popover } from 'antd'
import { get } from 'idb-keyval'
import CountrySelect from '../common_kit/CountrySelect'
import { useForm } from 'react-hook-form'
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { CheckOutlined, WarningFilled } from '@ant-design/icons'
import Tooltip, { CardStatusColor } from '../common_kit/Tooltip'
import IconClear from '../icons/IconClear'
import moment from 'moment'
import CodeInput from './CodeInput'
import FAQ from './FAQ'
import MqMedium from "../mq/MqMedium"

type Data = {
  typePhone?: string
}

export default function PhoneVerification(props: Data) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    register,
    trigger,
    watch,
    handleSubmit,
    resetField,
    setValue,
    setError,
    setFocus,
    formState: { errors, isValid }
  } = useForm<{ phone: string }>({ mode: props.typePhone ? 'onChange' : 'onTouched' })

  const codeCountry = useSelector((state: RootState) => state.phone.model.codeCountry)
  const phoneVerified = useSelector((state: RootState) => state.phone.phoneVerified)
  const alreadyReqCode = useSelector((state: RootState) => state.binom.alreadyReqCode)
  const sizeMq = useSelector((state: RootState) => state.mq.size)

  const [showVerifiedPhonePopover, setShowVerifiedPhonePopover] = useState<boolean>(false)
  const [localPhone, setLocalPhone] = useState<{
    codeCountry: string,
    phone: string
  }>()
  const [confirmationRequest, setConfirmationRequest] = useState<{
    codeCountry: string,
    phone: string,
    time: number
  }>()
  const [resolution, setResolution] = useState<boolean>(false)
  const [enableTrigger, setEnableTrigger] = useState<boolean>(false)
  const [phoneInputDisabled, setPhoneInputDisabled] = useState<boolean>(false)
  const [phoneInputFocused, setPhoneInputFocused] = useState<boolean>(false)
  const [waitingForCodeSend, setWaitingForCodeSend] = useState<boolean>(false)
  const [timerEnabled, setTimerEnabled] = useState<boolean>(false)
  const [confirmationRequested, setConfirmationRequested] = useState<boolean>(false)
  const [btnTextKey, setBtnTextKey] = useState<string>('Confirm with code')
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(true)
  const [waitingForCodeCheck, setWaitingForCodeCheck] = useState<boolean>(false)
  const [clearCode, setClearCode] = useState<boolean>(false)
  const [firstValid, setFirstValid] = useState<boolean>(false)

  const phone = watch('phone')


  useEffect(() => {
    const fieldPhone = props.typePhone ? `${props.typePhone}_phone` : 'verified_phone'
    if (!codeCountry) defineCountryIP()
    get('confirmationRequest')
      .then(data => {
        if (!data || !data.codeCountry || !data.phone || !data.time) return
        setConfirmationRequest(data)
      })
    get(fieldPhone)
      .then(data => {
        if (!data || !data.codeCountry || !data.phone) return
        setLocalPhone(data)
        if (!props.typePhone) {
          setShowVerifiedPhonePopover(true)
          return
        }
        handleApplyPhone(data.codeCountry, data.phone, true)
      })
  }, [])

  useEffect(() => {
    if (!enableTrigger) return
    trigger('phone')
  }, [codeCountry])

  useEffect(() => {
    if (!firstValid && isValid) setFirstValid(true)
  }, [isValid])

  useEffect(() => {
    setPhoneInputDisabled(waitingForCodeSend || waitingForCodeCheck || phoneVerified)
  }, [waitingForCodeSend, waitingForCodeCheck, phoneVerified])

  useEffect(() => {
    if (!localPhone || !localPhone.codeCountry || !localPhone.phone) return
    const codesMatch = localPhone.codeCountry === codeCountry
    const phonesMatch = localPhone.phone === phone
    if (codesMatch && phonesMatch) {
      handleApplyPhone(codeCountry, phone, true)
    }
  }, [codeCountry, phone])

  useEffect(() => {
    const getTimerEnabled = () => {
      if (!confirmationRequest) return false

      const codesIsEqual = codeCountry === confirmationRequest.codeCountry
      const phonesIsEqual = phone === confirmationRequest.phone
      const fullEqual = codesIsEqual && phonesIsEqual
      setConfirmationRequested(fullEqual)
      if (!fullEqual || !confirmationRequest.time) return false

      const now = moment().parseZone()
      const diff = moment(confirmationRequest.time).diff(now)
      return diff < 300000
    }
    setTimerEnabled(getTimerEnabled())
  }, [
    codeCountry,
    phone,
    confirmationRequest?.codeCountry,
    confirmationRequest?.phone,
    confirmationRequest?.time
  ])

  useEffect(() => {
    setBtnTextKey(confirmationRequested ? 'Resend' : 'Confirm with code')
  }, [confirmationRequested])

  useEffect(() => {
    const customCheck = [
      waitingForCodeSend,
      timerEnabled,
      waitingForCodeCheck
    ].some(item => item)
    if (props.typePhone) {
      setSubmitBtnDisabled(!isValid || customCheck)
      return
    }
    setSubmitBtnDisabled(!resolution || customCheck)
  }, [
    resolution,
    waitingForCodeSend,
    timerEnabled,
    waitingForCodeCheck,
    isValid
  ])

  const resetCodeCallback = useCallback(() => {
    setClearCode(false)
  }, [])

  const handleCountryChange = value => {
    dispatch(changePhoneModel({ codeCountry: value }))
    if (phone) setEnableTrigger(true)
  }

  const transformPhone = value => {
    if (!value.match(/\D+/)) return value
    if (value.length === 1) {
      resetField('phone', { keepError: true })
    }
    return value.replaceAll(/\D+/g, '')
  }

  const autoValidatePhone = (value: string): boolean => {
    const fullPhone: string = `+${codeCountry}${value}`
    const isPossible: boolean = isPossiblePhoneNumber(fullPhone)
    const isValid: boolean = isValidPhoneNumber(fullPhone)
    return isPossible && isValid
  }

  const customValidatePhone = (value: string): boolean => {
    return CUSTOM_VALID_PHONE[codeCountry]?.validate(value)
  }

  const handleApplyPhone = (codeCountry: string, phone: string, verified: boolean = false) => {
    if (verified) {
      dispatch(setPhoneVerified(true))
      dispatch(changePhoneModel({ phone, codeCountry }))
      setResolution(true)
      setShowVerifiedPhonePopover(false)
    }
    setValue('phone', phone)
    trigger('phone')
  }

  const sendPhoneNumber = async () => {
    if (!phone || (!props.typePhone && !resolution)) return

    setClearCode(true)
    setWaitingForCodeSend(true)
    const success: boolean|undefined = await sendSms({
      phone,
      resolution,
      codeCountry,
      alreadyReqCode,
      typePhone: props.typePhone
    })
    setWaitingForCodeSend(false)
    if (!success) return
    const time = moment().add(RESEND_SMS_TIMEOUT, 'minutes').valueOf()
    setConfirmationRequest({ codeCountry, phone, time })

    if (!alreadyReqCode) dispatch(updPhoneCodeStatus(true))

    if (process.env.REACT_APP_ENV === 'prod') {
      window.ym(61234636, 'reachGoal', 'sms')
    }
  }

  const checkConfirmationCode = async (code: string) => {
    setWaitingForCodeCheck(true)
    await verifyUser(codeCountry, phone, code, props.typePhone)
    setWaitingForCodeCheck(false)
    setShowVerifiedPhonePopover(false)
    if (process.env.REACT_APP_ENV === 'prod') {
      window.dataLayer.push({event: 'phoneVerified'})
    }
  }

  const getTooltipContent = () => (
    <div className="phone-tooltip-content">
      <p className="tooltip-title">
        {t('Example phone')}
      </p>
      <div className="format-example">
        <div className="format-example-number">
          <p className="number">+{codeCountry}</p>
          <p className="tip">{t('Country code')}</p>
        </div>
        <div className="format-example-number">
          <p className="number">92198736782</p>
          <p className="tip">{t('Phone number code')}</p>
        </div>
      </div>
    </div>
  )

  const getPopoverContent = () => {
    if (errors.phone?.type !== 'custom' && !showVerifiedPhonePopover) return null
    let _phone, _codeCountry, popoverTitle
    const isFormatWarning = errors.phone?.type === 'custom'
    const handleReject = () => {
      setShowVerifiedPhonePopover(false)
    }

    if (isFormatWarning) {
      if (!CUSTOM_VALID_PHONE[codeCountry]?.showCorrect) return null
      const correctPhone = CUSTOM_VALID_PHONE[codeCountry]?.showCorrect(phone)
      popoverTitle = (
        <div className="format-phone-tip">
          <WarningFilled style={{ color: '#faad14', fontSize: 20 }}/>
          <span>{t('Correct format phone')}</span>
          <span className="phone-number">{correctPhone}</span>
        </div>
      )
      _phone = correctPhone
      _codeCountry = codeCountry
    } else if (localPhone?.codeCountry && localPhone?.phone) {
      popoverTitle = t('Verified phone popover', { phone: '+' + localPhone?.codeCountry + localPhone?.phone })
      _phone = localPhone?.phone
      _codeCountry = localPhone?.codeCountry
    } else {
      return null
    }

    return (
      <div className="phone-popover">
        <div className="phone-popover__title">
          {popoverTitle}
        </div>
        <div className="phone-popover__toolbar">
          <Button type="primary" onClick={() => handleApplyPhone(_codeCountry, _phone, !isFormatWarning)}>
            {t('Apply')}
          </Button>
          {!isFormatWarning && (
            <button className="btn modal__button reject-btn" onClick={handleReject}>
              {t('Hide')}
            </button>
          )}
        </div>
      </div>
    )
  }

  const getErrorMessage = () => {
    if (!firstValid && props.typePhone) return
    let text
    const phoneFormatError = ['auto', 'custom'].includes(errors.phone?.type ?? '')

    if (errors.phone?.type === 'required') {
      text = t('This field is required')
    } else if (phoneFormatError) {
      text = t('Validator error phone')
    }
    return <div className="error-message">{text}</div>
  }

  const getIcon = () => {
    let icon, className = ''
    if (phoneVerified) {
      icon = <CheckOutlined style={{ color: '#2684fe' }}/>
    } else if (phone) {
      icon = <IconClear
        width={10}
        height={10}
        fill={'#FF4848'}
        onClick={() => {
          setValue('phone', '')
          setError('phone', { type: 'required' })
          setFocus('phone')
        }}
      />
      className = ' clear-icon'
    }
    if (!icon) return
    return <div className={`form-control__icon${className}`}>
      {icon}
    </div>
  }

  const getTimer = () => (
    <div className="resend-timer">
      <Paragraph
        text={t('Resend via')}
        textStyle={TextStyle.ITALIC}
        size={TextSize.P_12}
      />
      <Timer
        paragraphOptions={{
          textStyle: TextStyle.ITALIC,
          size: TextSize.P_12
        }}
        endMoment={confirmationRequest!.time}
        handleTimeIsOver={() => setTimerEnabled(false)}
      />
    </div>
  )

  const getInvalidClass = () => {
    if (!firstValid && props.typePhone) return ''
    return errors.phone ? ' invalid' : ''
  }

  const disabledClass = phoneInputDisabled ? ' disabled' : ''
  const focusedClass = phoneInputFocused ? ' focused' : ''

  return (
    <div className={`phone-verification ${phoneVerified ? 'verified' : ''}`}>

      {/*Номер телефона*/}
      <div className="phone-input-block">
        <label htmlFor="signup-phone-input">
          {t('Phone number 2', {typePhone: props.typePhone ?? ''})}
          {!phoneVerified && (
            <Tooltip className="phone-format-tooltip" svg={CardStatusColor.BLUE}>
              {getTooltipContent()}
            </Tooltip>
          )}
        </label>
        <Popover
          content={getPopoverContent()}
          overlayClassName="phone-popover-wrap"
          open={!!getPopoverContent()}>
          <div className={`country-phone-input${getInvalidClass()}${disabledClass}${focusedClass}`}>
            <CountrySelect
              value={codeCountry}
              onChange={handleCountryChange}
              isDisabled={phoneInputDisabled}
              onFocus={() => {
                setPhoneInputFocused(true)
              }}
              onBlur={() => {
                setPhoneInputFocused(false)
              }}
            />
            <input
              id="signup-phone-input"
              autoComplete="off"
              disabled={phoneInputDisabled}
              type="text"
              onFocus={() => {
                setPhoneInputFocused(true)
              }}
              {...register('phone', {
                required: true,
                validate: {
                  auto: autoValidatePhone,
                  custom: customValidatePhone
                },
                setValueAs: transformPhone,
                onBlur: () => {
                  setPhoneInputFocused(false)
                },
                onChange: event => {
                  event.preventDefault()
                  event.target.value = transformPhone(event.target.value)
                }
              })}
            />
            {getIcon()}
          </div>
        </Popover>
        {errors.phone && getErrorMessage()}
        {!props.typePhone && <Checkbox
          value={resolution}
          isDisabled={phoneInputDisabled}
          label={t('I agree to receive SMS messages')}
          handler={() => setResolution(!resolution)}
        />}
        {['s', 'l'].includes(sizeMq) && !props.typePhone && <FAQ/>}
      </div>

      {/*Кнопка "Подтвердить"*/}
      {!phoneVerified && (
        <div className="verify-btn-block">
          {timerEnabled && getTimer()}
          {/*
            !!! ВНИМАНИЕ !!!
            Изменять значение id допускается только по согласованию с руководством
          */}
          <SubmitButton
            id={submitBtnDisabled ? undefined : 'uploader__phone-verify-btn'}
            text={t(btnTextKey)}
            onClick={handleSubmit(sendPhoneNumber)}
            isDisabled={submitBtnDisabled}
          />
          {!resolution && !props.typePhone && (
            <div className="send-btn-tooltip">
              <span>{t('Need resolution')}</span>
            </div>
          )}
          <MqMedium>
            <FAQ/>
          </MqMedium>
        </div>
      )}

      {/*Код подтверждения*/}
      {!phoneVerified && (
        <CodeInput
          disabled={!confirmationRequested || waitingForCodeCheck}
          handleCodeEntered={checkConfirmationCode}
          showIndicator={waitingForCodeCheck}
          emptyValue={clearCode}
          resetValueCallback={resetCodeCallback}
          typePhone={props.typePhone}
        />
      )}

    </div>
  )
}
