import React from 'react'

export default function SignupSocialLinkedIn() {
  return (
    <div className="social-icon">
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.99246 4.62649C3.42265 4.86993 4.67026 4.17005 4.97455 2.95287C5.37014 1.3401 4.24424 -0.0292245
          2.54019 0.00120508C1.4143 -0.0292245 0.531839 0.518507 0.166684 1.43139C-0.381047 2.89201 0.47098
          4.38306 1.99246 4.62649ZM20.5853 19.994C19.3072 19.9636 18.0596 19.9636 16.7816 19.994C16.5077
          19.994 16.4469 19.9332 16.4469 19.6593V12.8735C16.4469 12.3562 16.4164 11.8389 16.2643 11.3521C15.8078
          9.76972 13.9821 9.19156 12.6736 10.2566C11.9737 10.8043 11.6999 11.5651 11.6999 12.4779V18.8682C11.6999
          18.9583 11.6966 19.0485 11.6932 19.1398C11.6864 19.3257 11.6795 19.5161 11.6999 19.7202C11.7303 19.9636
          11.6086 20.0245 11.3956 19.994H7.50058C7.25715 19.994 7.19629 19.9332 7.19629 19.6897C7.22672 17.7727
          7.22672 15.8556 7.22672 13.9081C7.22672 11.5346 7.22672 9.16113 7.19629 6.81805C7.19629 6.54419 7.25715
          6.48333 7.50058 6.48333H11.3956C11.639 6.48333 11.6999 6.54419 11.6999 6.78762V8.33953L11.852
          8.18738C13.0388 6.4529 14.7124 5.96603 16.6903 6.27032C18.9725 6.63548 20.4331 8.21781 20.7983
          10.6522C20.8896 11.2303 20.92 11.8085 20.92 12.3867V19.6593C20.92 19.9028 20.8592 19.994 20.5853
          19.994ZM4.7622 13.2378V19.628C4.7622 19.9018 4.70135 19.9931 4.42748 19.9931C3.57545 19.9728
          2.72343 19.9796 1.8714 19.9864C1.44538 19.9898 1.01937 19.9931 0.593358 19.9931C0.349922 19.9931
          0.289062 19.9323 0.289062 19.6888V6.78672C0.289062 6.57371 0.349922 6.48242 0.593358
          6.48242H4.51877C4.79263 6.48242 4.82306 6.57371 4.82306 6.81715C4.7622 8.94721 4.7622 11.0773 4.7622 13.2378Z"
          fill="#859AB6"/>
      </svg>

    </div>
  )
}
