import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import '../style/sass/App.scss'
import 'antd/dist/antd.css'

import Header from './Header'
import Body from './Body'

import UserSidebar from './userSidebar/UserSidebar'
import { useTranslation } from 'react-i18next'
import LocaleChecker from './LocaleChecker'
import Footer from './footer/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store/rootState'
import { setTypes } from '../store/types/actions'
import { setLocale, setRateUSD } from '../store/locale/actions'
import { defineCountryIP } from '../store/phone/api'
import { checkSearchAndLogIn, checkSearchAndTrack, startIntercomChat } from '../utils/helpers'
import { setIntercomSettings, stopIntercomChat } from '../store/intercom/actions'
import MediaQueryChecker from './mq/MediaQueryChecker'
import AsideMenu from './asideMenu/AsideMenu'
import _ from 'lodash'
import ScrollToTop from './ScrollToTop'
import { setSeoData } from '../store/binom/actions'
import { userAction } from '../store/binom/api'
import { Spin } from 'antd'
import { getMany } from 'idb-keyval'


declare global {
  interface Window {
    ym: any,
    dataLayer: any,
    gtag: any,
    Intercom: any,
  }
}

export default function App() {
  const { t, i18n } = useTranslation()

  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const UserID = useSelector((state: RootState) => state.user.userData?.id, _.isEqual)
  const userDataUuid = useSelector((state: RootState) => state.user.userData?.uuid, _.isEqual)
  const currency = useSelector((state: RootState) => state.types.currencies, _.isEqual)
  const isAuth = useSelector((state: RootState) => state.auth.access_token, _.isEqual)
  const codeCountry = useSelector((state: RootState) => state.phone.model.codeCountry, _.isEqual)

  const [showCountryPage, setShowCountryPage] = useState<boolean>(false)
  const [binom, setBinom] = useState<boolean>(false)
  const [clickIdServer, setClickIdServer] = useState<string>('')

  useEffect(() => {

    dispatch(setTypes())
    dispatch(setLocale(i18n.language))
    dispatch(setIntercomSettings({ language_override: i18n.language }))
    if (location.search) {
      const cutSearch = checkSearchAndLogIn(location.search)
      const handledSearch = checkSearchAndTrack(cutSearch)
      if (handledSearch !== location.search) {
        history.push({ search: handledSearch })
      }
    }

    startIntercomChat()

    i18n.on('languageChanged', (value) => {
      dispatch(setLocale(value))
      dispatch(stopIntercomChat())
      startIntercomChat()
      dispatch(setIntercomSettings({ language_override: value }))
    })

    if (process.env.REACT_APP_ENV === 'prod') {

      window.gtag('config', 'UA-170135385-1')

      let scriptsOnlyProd: string[] = ['/js/facebook.js', '/js/tiktok.js', '/js/googleTagManager.js']

      scriptsOnlyProd.forEach(script => {
        let pageScript = document.createElement('script')
        pageScript.setAttribute('src', script)
        document.head.appendChild(pageScript)
      })
    }

    const cookieClickId = document.cookie.includes('clickid')
    const urlClickId = location.search.includes('clickid')
    if (cookieClickId || urlClickId) setClickIdServer(cookieClickId ? 'cookie' : 'url')
  }, [])

  useEffect(() => {
    if (clickIdServer && !isAuth) {
      const searchParam = {
        cookie: {
          target: document.cookie,
          separate: '; '
        },
        url: {
          target: location.search,
          separate: '&'
        }
      }

      setBinom(true)
      getMany(['cnvId', 'utmSource'])
        .then(([cnvIdDB, utmSourceDB]) => {
          const args = searchParam?.[clickIdServer].target.split(searchParam?.[clickIdServer].separate)
          const argsUtm = searchParam?.url.target.split(searchParam?.url.separate)
          const cnvIdArg = args?.find(i => i.includes('clickid'))
          const utmSourceArg = argsUtm?.find(i => i.includes('utm_source'))
          const cnvId = cnvIdArg!.split('=')[1]
          const utmSource = utmSourceArg!.split('=')[1]
          if (cnvIdDB !== cnvId || utmSourceDB !== utmSource) dispatch(setSeoData({cnvId, utmSource}))
          if (cnvIdDB !== cnvId) {
            userAction('event_1')
          }
        })
      defineCountryIP()
    }
  }, [clickIdServer])

  useEffect(() => {
    if (!isAuth){
      window.Intercom('show')
      window.Intercom('hide')
    }
  }, [isAuth])

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'prod') {
      window.ym(87223011, 'userParams', { UserID })
      window.dataLayer.push({ user_id: UserID })
    }
  }, [UserID])

  useEffect(() => {
    window.document.title = `AppWork - ${t('Tab title')}`
  }, [i18n.language])

  useEffect(() => {
    if (!userDataUuid) return
    dispatch(setIntercomSettings({ user_id: userDataUuid }))
  }, [userDataUuid])

  useEffect(() => {
    const rateUSD = currency.find(item => item.name === 'currency_dollar')?.value
    if (rateUSD) {
      dispatch(setRateUSD(parseFloat(rateUSD)))
    }
  }, [currency])

  useEffect(() => {
    if (!isAuth && binom) {
      codeCountry === '7' ? i18n.changeLanguage('ru') : i18n.changeLanguage('en')
      setShowCountryPage(true)
    }
  }, [codeCountry])

  const getBody = () => {
    return (
      <ScrollToTop>
        <MediaQueryChecker>
          <LocaleChecker>
            <Header/>
            <div className="app">
              <div id="main-container" className="container">
                <Body/>
              </div>
            </div>
            <UserSidebar/>
            <AsideMenu/>
            <Footer/>
          </LocaleChecker>
        </MediaQueryChecker>
      </ScrollToTop>
    )
  }

  const getContent = () => {
    const clickId = clickIdServer || binom

    if (!isAuth && clickId && !showCountryPage) return (
      <div className="CPASpinner">
        <Spin tip="Loading..."/>
      </div>
    )

    return getBody()
  }

  return (
    getContent()
  )
}
